import React from 'react';
import './Support.css';
import { useNavigate } from 'react-router-dom';
import { ulmsUrl } from '../Api';
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Support = () => {

    const initialUserData = {
        "name": "",
        "email": "",
        "msg": "",
    }
    const navigate = useNavigate();
    const [supportCreate, setSupportCreate] = React.useState(initialUserData);
    const [loading, setLoading] = useState(false);

    const handleFormInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setSupportCreate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    };

    const handleFormSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();

    
        console.log(JSON.stringify(supportCreate));

        // fetch(ulmsUrl.concat(''), {
        //     method: 'POST',
        //     body: JSON.stringify(supportCreate), 
        // })
        // .then((result) =>
        //     result.json()
        // )
        // .then((result) => {
        //     console.log(result)
        //     if (result.status == true) {
        //         setSupportCreate(initialUserData)
        //         setLoading(false)
        //         navigate("/support", { replace: true })

        //     } else if (result.status == false) {
        //         setLoading(false)
        //         setSupportCreate(supportCreate)
        //         toast.error(result.message, {
        //             position: toast.POSITION.TOP_RIGHT,
        //             toastId: 'success1',
        //         });
               
        //     }
        // })
        // .catch((form_err) => {
        //     //console.log(form_err)
        // })
    }

  return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <h1>Support - Holisol Logistics Pvt Ltd</h1>
                    <div style={{borderBottom: '2px solid #46B653', marginTop: '5px', marginBottom: '5px' }}></div>
                    <div className="container bgform">
                    <form onSubmit={handleFormSubmit}>

                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Name</label>
                            <input type="text" className="form-control" 
                            id="name" 
                            name="name" 
                            value={supportCreate.name}
                            onChange={handleFormInput}
                            aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                            <input type="email"
                             className="form-control" 
                            id="email" 
                            name="email" 
                            value={supportCreate.email}
                            onChange={handleFormInput}
                            aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Message</label>
                            <textarea type="text" className="form-control" 
                            id="msg" 
                            name="msg" 
                            value={supportCreate.msg}
                            onChange={handleFormInput}
                            aria-describedby="emailHelp"></textarea>
                        </div>
                    
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </>
  )
}

export default Support