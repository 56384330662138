import { useEffect, useState , useRef } from "react";
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './Commercial.css';
import Loader from '../../loader/Loader';
import Select from 'react-select';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { roundToTwo } from "../helper/Helpers";

const CommercialUpload = () => {

    const aRef = useRef(null);

    const navigate = useNavigate();
    let base64     = require('base-64');

    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token  = localStorage.getItem('authToken');
    const usr_name   = localStorage.getItem('name');
    const usr_id     = localStorage.getItem('usrId');
    const user_role  = localStorage.getItem('usrRole');

    const initialComData = {
        "lead_id": lead_id,
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const [selectedFile, setSelectedFile]      = useState([]);
    const [loading, setLoading]                = useState(false);
    const [commUploadData, setCommUploadData]  = useState(initialComData);

    const handleFileInput = (e) => {
        console.log(e.target.files); 
        setSelectedFile(e.target.files)
        console.log(selectedFile);
    };

    const handleComFileSubmit = async (e) => {

        setLoading(true);
        e.preventDefault();
  
        console.log(JSON.stringify(commUploadData))
  
        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`CommercialFile`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(commUploadData));
        console.log(formData);  
  
        // fetch(ulmsUrl.concat("/wip/ref_file"), {
        //     method: 'POST',
        //     body: formData,
        // })
        // .then((result) =>
        //     result.json()
        // )
        // .then((result) => {
        //     console.log(result)
        //     if (result.status == true) {
        //         setLoading(false);
        //         aRef.current.value = null;
        //         setCommUploadData(commUploadData);
        //         toast.success(result.message, {
        //           position: toast.POSITION.TOP_RIGHT,
        //           toastId: 'success1',
        //         })
        //         navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
  
        //     } else if (result.status == false) {
        //         setLoading(false);
        //         setCommUploadData(commUploadData);
        //         toast.error(result.message, {
        //           position: toast.POSITION.TOP_RIGHT,
        //           toastId: 'success1',
        //         });
               
        //     }
        // })
        // .catch((form_err) => {
        //     //console.log(form_err)
        // })
    }

  return (
        <>
            {loading && (
                <>
                <Loader/>
                </>
            )}
            <div className="col-md-12" style={{marginTop:'20px', float:'left'}}>
                <div className="card border-dark" style={{borderRadius:'2px'}}>
                    <div className='row' style={{marginTop: '15px', margin:'20px'}}>
                        <div className="col-md-5" controlid="formFileMultiple" >
                            <Form.Label>Commercial File (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                            <Form.Control multiple type="file"  
                              ref={aRef}  
                              name="opportunity_photo_web"
                              onChange={handleFileInput}
                            />
                        </div>
                        <div className="col-md-12" style={{marginTop: '26px'}}>
                            <button type="submit" className="btn btn-primary" onClick={handleComFileSubmit} >Upload</button>
                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommercialUpload