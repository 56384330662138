import React, { useCallback, useReducer } from 'react';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { useNavigate,NavLink, Link, useParams } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import DateFormat from '../dateformate/DateFormat';

const ClientView = () => {

    const navigate = useNavigate();
    var base64 = require('base-64');
    const clientdataurl = '/mst/client_detail';
    const [client_id, setClientId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id = localStorage.getItem('usrId');

    const [clientViewData, setClientViewData] = React.useState([""]);

    const getClientViewData = async () => {
        const arr = [];
        try {
            fetch(ulmsUrl.concat(clientdataurl) , {
                method: 'POST',
                body: JSON.stringify({client_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;   
                setClientViewData(result.data)
            });
        } catch (error) {
            console.log(error);
        }
    }

    console.log(clientViewData);
  
    useEffect(() => {
        getClientViewData();
    }, []);



  return (
    <>
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">CLIENT VIEW</h3>
                    </div>
                    <div className="card-body">
                        <div className="col-sm-12">
                            <div className="table-scrollable">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th style={{width: '30%'}}>Client Name</th>
                                            <td>{clientViewData != '' && clientViewData.client_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Display Name</th>
                                            <td>{clientViewData != '' && clientViewData.display_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Client Code</th>
                                            <td>{clientViewData != '' && clientViewData.client_code}</td>
                                        </tr>
                                        <tr>
                                            <th>GST No</th>
                                            <td>{clientViewData != '' && clientViewData.gst_no}</td>
                                        </tr>
                                        <tr>
                                            <th>ARN No</th>
                                            <td>{clientViewData != '' && clientViewData.arn_no}</td>
                                        </tr>
                                        <tr>
                                            <th>Contact Person</th>
                                            <td>{clientViewData != '' && clientViewData.ctc_person}</td>
                                        </tr>
                                        <tr>
                                          <th>Email Id</th>
                                          <td>{clientViewData != '' && clientViewData.email_id}</td>
                                        </tr>
                                        <tr>
                                            <th>Mobile No</th>
                                            <td>{clientViewData != '' && clientViewData.phone_no}</td>
                                        </tr>
                                        <tr>
                                            <th>Address</th>
                                            <td>{clientViewData != '' && clientViewData.vendor_address}</td>
                                        </tr>
                                        <tr>
                                            <th>City</th>
                                            <td>{clientViewData != '' && clientViewData.city_name}</td>
                                        </tr>
                                        <tr>
                                            <th>State</th>
                                            <td>{clientViewData != '' && clientViewData.state_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Pincode</th>
                                            <td>{clientViewData != '' && clientViewData.pin_code}</td>
                                        </tr>
                                        <tr>
                                            <th>Country</th>
                                            <td>{clientViewData != '' && clientViewData.country}</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>
                                               {clientViewData != '' &&  clientViewData.status == 0 &&
                                                    <span className='badge bg-info'>Pending</span>
                                                }
                                                {clientViewData != '' &&  clientViewData.status == 1 &&
                                                    <span className='badge bg-success'>Active</span>
                                                }
                                                {clientViewData != '' &&  clientViewData.status == 3 &&
                                                    <span className='badge bg-danger' >Deleted</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Created At</th>
                                            <td>{clientViewData != '' && DateFormat(clientViewData.created_at)}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                    </tfoot>
                                </table>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </>
  )
}

export default ClientView