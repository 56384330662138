import React from 'react';
import { useEffect, useState } from "react";
import { ulmsUrl, username, password } from '../Api';
import { encode } from "base-64";


export function CustomerData(){

    let base64 = require('base-64');
    const [options, setOptions] = React.useState([""]);

    useEffect(() => {
        const getCustomerData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/customer_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setOptions(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getCustomerData();
    }, []);

    return {options};
}


export function SupplierData(){

    let base64 = require('base-64');
    const [supplierDropdown, setSupplierDropdown] = React.useState([""]);

    useEffect(() => {
        const getSupplierData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/supplier_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setSupplierDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getSupplierData();
    }, []);

    return {supplierDropdown};
}

export function OEMData(){

    let base64 = require('base-64');
    const [oemDropdown, setOemDropdown] = React.useState([""]);

    useEffect(() => {
        const getOEMData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/oem_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setOemDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getOEMData();
    }, []);

    return {oemDropdown};
}

export function SupplierOEMData(){

    let base64 = require('base-64');
    const [supplierOEMDropdown, setSupplierOEMDropdown] = React.useState([""]);

    useEffect(() => {
        const getSupplierOEMData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/supp_oem_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setSupplierOEMDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getSupplierOEMData();
    }, []);

    return {supplierOEMDropdown};
}

export function OppDestinationData(){

    let base64 = require('base-64');
    const [oppDestDropdown, setoppDestDropdown] = React.useState([""]);

    useEffect(() => {
        const getOppDestinationData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/opp_dest_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setoppDestDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getOppDestinationData();
    }, []);

    return {oppDestDropdown};
}

export function IndustryVerticle(){

    let base64 = require('base-64');
    const [IndustryVertOptions, setIndustryVertOptions] = React.useState([""]);

    useEffect(() => {
        const getIndustryVertData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/industry_vertical') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setIndustryVertOptions(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getIndustryVertData();
    }, []);

    return {IndustryVertOptions};
}


export function LocationData(){

    let base64 = require('base-64');
    const [locationOptions, setLocationOptions] = React.useState([""]);

    useEffect(() => {
        const getlocationtData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/location_oem_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setLocationOptions(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getlocationtData();
    }, []);

    return {locationOptions};
}


export function DistrictData(){

    let base64 = require('base-64');
    const [districtOptions, setDistrictOptions] = React.useState([""]);

    useEffect(() => {
        const getDistrictData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/district_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setDistrictOptions(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getDistrictData();
    }, []);

    return {districtOptions};
}


export function ComponentFamily(){

    const usr_token = localStorage.getItem('authToken');
    //const usr_token = "167283765161839864";
    const [comFamOptions, setComFamOptions] = React.useState([""]);

    useEffect(() => {
        const getComFamilyData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/family/list'), {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.name });
                    });
                    setComFamOptions(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getComFamilyData();
    }, []);

    return {comFamOptions};
}

export function LeadSource(){

    const usr_token = localStorage.getItem('authToken');
    //const usr_token = "167283765161839864";
    const [leadsourceOptions, setLeadSourceOptions] = React.useState([""]);

    useEffect(() => {
        const getLeadSourceData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/lead_source/list'), {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.name });
                    });
                    setLeadSourceOptions(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getLeadSourceData();
    }, []);

    return {leadsourceOptions};
}


export function SupLocationAdd(){

    let base64 = require('base-64');
    const [supplocAddOptions, setsuppLocAddOptions] = React.useState([""]);

    useEffect(() => {
        const getLocationAddData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/location_supp_addr_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setsuppLocAddOptions(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getLocationAddData();
    }, []);

    return {supplocAddOptions};
}

export function OemLocationAdd(){

    let base64 = require('base-64');
    const [oemlocAddOptions, setOemLocAddOptions] = React.useState([""]);

    useEffect(() => {
        const getOemLocationAddData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/location_oem_addr_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setOemLocAddOptions(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getOemLocationAddData();
    }, []);

    return {oemlocAddOptions};
}

export function PincodeData(){

    let base64 = require('base-64');
    const [pincodeOptions, setPincodeOptions] = React.useState([""]);

    useEffect(() => {
        const getPincodeData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/pincode_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setPincodeOptions(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getPincodeData();
    }, []);

    return {pincodeOptions};
}

export function LeadCount(){

    const usr_token = localStorage.getItem('authToken');
    const [leadCountData, setLeadCountData] = React.useState([""]);

    useEffect(() => {
        const getleadCountData = async () => {
            try {
                fetch(ulmsUrl.concat('/dashboard/dashboard_item_count') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                
                    let result = response.data;
                    setLeadCountData(result)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getleadCountData();
    }, []);

    return {leadCountData};
}

export function UserList(){

    const usr_token = localStorage.getItem('authToken');
    const [userData, setUserData] = React.useState([""]);

    useEffect(() => {
        const getUserData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/user/list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        let nameEmp = (data.name).concat(' ('+ data.emp_id +')');
                        return arr.push({ value: data.id, label: nameEmp });
                    });
                    setUserData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getUserData();
    }, []);

    return {userData};
}

export function PermissionList(){

    const usr_token = localStorage.getItem('authToken');
    const [permissionData, setPermissisonData] = React.useState([""]);

    useEffect(() => {
        const getPermissionData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/img/configs') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    // result?.map((data) => {
                    //     return arr.push({ value: data.id, label: data.name });
                    // });
                    setPermissisonData(result)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getPermissionData();
    }, []);

    return {permissionData};
}

export function BDUserList(){

    let base64 = require('base-64');
    const [bdUserListData, setBDUserListData] = React.useState([""]);

    useEffect(() => {
        const getBDUserListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/bd_user_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setBDUserListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getBDUserListData();
    }, []);

    return {bdUserListData};
}

export function UserPermissionList(){

    const usr_token = localStorage.getItem('authToken');
    const [permissionList, setPermissionList] = React.useState([""]);

    useEffect(() => {
        const getPermissionListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/user/permission_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ menuid: data.menu_id, web: data.use_for_web });
                    });
                    setPermissionList(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getPermissionListData();
    }, []);

    return {permissionList};
}

export function ListView(){

    const usr_token = localStorage.getItem('authToken');
    const [listViewData, setListViewData] = React.useState([""]);

    useEffect(() => {
        const getListViewData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/sdm_lead/view') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    // result?.map((data) => {
                    //     return arr.push({ value: data.menu_name, label: data.use_for_web });
                    // });
                    setListViewData(result)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getListViewData();
    }, []);

    return {listViewData};
}

export function RoleList(){

    const usr_token = localStorage.getItem('authToken');
    const [roleListData, setRoleListData] = React.useState([""]);

    useEffect(() => {
        const getRoleListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/role/list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.name });
                    });
                    setRoleListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getRoleListData();
    }, []);

    return {roleListData};
}

export function LOBList(){

    const usr_token = localStorage.getItem('authToken');
    const [lobListData, setLobListData] = React.useState([""]);

    useEffect(() => {
        const getLobListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/filter/lob') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setLobListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getLobListData();
    }, []);

    return {lobListData};
}


export function ProductList(){

    const usr_token = localStorage.getItem('authToken');
    const [productListData, setProductListData] = React.useState([""]);

    useEffect(() => {
        const getProductListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/filter/product') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setProductListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getProductListData();
    }, []);

    return {productListData};
}

export function ServicesList(){

    const usr_token = localStorage.getItem('authToken');
    const [servicesListData, setServicesListData] = React.useState([""]);

    useEffect(() => {
        const getServicesListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/filter/services') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setServicesListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getServicesListData();
    }, []);

    return {servicesListData};
}

export function OperationTypeList(){

    const usr_token = localStorage.getItem('authToken');
    const [operatTypeListData, setOperatTypeListData] = React.useState([""]);

    useEffect(() => {
        const getOperatTypeListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/operation_type') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setOperatTypeListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getOperatTypeListData();
    }, []);

    return {operatTypeListData};
}

export function BDList(){

    const usr_token = localStorage.getItem('authToken');
    const [bdListData, setBDListData] = React.useState([""]);

    useEffect(() => {
        const getBDListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/bd_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setBDListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getBDListData();
    }, []);

    return {bdListData};
}

export function SDList(){

    const usr_token = localStorage.getItem('authToken');
    const [sdListData, setSDListData] = React.useState([""]);

    useEffect(() => {
        const getSDListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/sd_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setSDListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getSDListData();
    }, []);

    return {sdListData};
}

export function PortList(){

    let base64 = require('base-64');
    const [portListData, setPortListData] = React.useState([""]);

    useEffect(() => {
        const getPortListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/port_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setPortListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getPortListData();
    }, []);

    return {portListData};
}

export function UnitLoadList(){

    const usr_token = localStorage.getItem('authToken');
    const [outerULData, setOuterULData] = React.useState([""]);
    const [innerULData, setInnerULData] = React.useState([""]);

    useEffect(() => {
        const getUnitLoadData = async () => {
            const outerarr = [];
            const innerarr = [];
            try {
                fetch(ulmsUrl.concat('/unitload/list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let resultouter = response.outerdropdown;
                    resultouter?.map((data) => {
                        return outerarr.push({ value: data.id, label: data.value });
                    });
                    setOuterULData(outerarr)

                    let resultinner = response.innerdropdown;
                    resultinner?.map((data) => {
                        return innerarr.push({ value: data.id, label: data.value });
                    });
                    setInnerULData(innerarr)
                    
                });
            } catch (error) {
                console.log(error);
            }
        }
        getUnitLoadData();
    }, []);

    return {outerULData, innerULData};
}

export function VehicleTypeList(){

    const usr_token = localStorage.getItem('authToken');
    const [vehicleTypeData, setVehicleTypeData] = React.useState([""]);

    useEffect(() => {
        const getVehicleTypeData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/vehicle_type/list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.dropdown;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setVehicleTypeData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getVehicleTypeData();
    }, []);

    return {vehicleTypeData};
}


export function VehicleLoadTypeList(){

    const usr_token = localStorage.getItem('authToken');
    const [vehicleLoadTypeData, setVehicleLoadTypeData] = React.useState([""]);

    useEffect(() => {
        const getVehicleLoadTypeData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/vehicle_load_type/list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.dropdown;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setVehicleLoadTypeData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getVehicleLoadTypeData();
    }, []);

    return {vehicleLoadTypeData};
}

export function VehicleTypeULList(){

    const usr_token = localStorage.getItem('authToken');
    const [vehicleTypeULData, setVehicleTypeULData] = React.useState([""]);

    useEffect(() => {
        const getVehicleTypeULData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/vehicle_load_type_unit/list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.dropdown;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setVehicleTypeULData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getVehicleTypeULData();
    }, []);

    return {vehicleTypeULData};
}

export function StateList(){

    let base64 = require('base-64');
    const [stateListData, setStateListData] = React.useState([""]);

    useEffect(() => {
        const getStateListData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/holibook/state_dropdown') , {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setStateListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getStateListData();
    }, []);

    return {stateListData};
}


export function LocationsData(){

    const usr_token = localStorage.getItem('authToken');
    const [locationDropdown, setLocationDropdown] = React.useState([""]);

    useEffect(() => {
        const getLocData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/location_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setLocationDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getLocData();
    }, []);

    return {locationDropdown};
}

export function ZoneData(){

    const usr_token = localStorage.getItem('authToken');
    const [zoneDropdown, setZoneDropdown] = React.useState([""]);

    useEffect(() => {
        const getZoneData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/zone_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setZoneDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getZoneData();
    }, []);

    return {zoneDropdown};
}

export function CategoryData(){

    const usr_token = localStorage.getItem('authToken');
    const [categoryDropdown, setCategoryDropdown] = React.useState([""]);

    useEffect(() => {
        const getCategoryData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/retail_category_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setCategoryDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData();
    }, []);

    return {categoryDropdown};
}

export function OperationTypeData(){

    const usr_token = localStorage.getItem('authToken');
    const [operationTypeDropdown, setOperationTypeDropdown] = React.useState([""]);

    useEffect(() => {
        const getOperationTypeData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/retail_operation_type_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setOperationTypeDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getOperationTypeData();
    }, []);

    return {operationTypeDropdown};
}

export function RackTypeData(){

    const usr_token = localStorage.getItem('authToken');
    const [rackTypeDropdown, setRackTypeDropdown] = React.useState([""]);

    useEffect(() => {
        const getRackTypeData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/retail_rack_type_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setRackTypeDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getRackTypeData();
    }, []);

    return {rackTypeDropdown};
}

export function CommodityData(){

    const usr_token = localStorage.getItem('authToken');
    const [commedityDropdown, setCommedityeDropdown] = React.useState([""]);

    useEffect(() => {
        const getCommedityData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/retail_commodity_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setCommedityeDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getCommedityData();
    }, []);

    return {commedityDropdown};
}

export function StorageTypeData(){

    const usr_token = localStorage.getItem('authToken');
    const [storagerTypeDropdown, setStorageTypeDropdown] = React.useState([""]);

    useEffect(() => {
        const getStorageTypeData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/retail_storage_type_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setStorageTypeDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getStorageTypeData();
    }, []);

    return {storagerTypeDropdown};
}

export function PickingTypeData(){

    const usr_token = localStorage.getItem('authToken');
    const [pickingTypeDropdown, setPickingTypeDropdown] = React.useState([""]);

    useEffect(() => {
        const getPickingTypeData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/retail_picking_type_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setPickingTypeDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getPickingTypeData();
    }, []);

    return {pickingTypeDropdown};
}

export function PickingThroughData(){

    const usr_token = localStorage.getItem('authToken');
    const [pickingThroughDropdown, setPickingThroughDropdown] = React.useState([""]);

    useEffect(() => {
        const getPickingThroughData = async () => {
            const arr = [];
            try {
                fetch(ulmsUrl.concat('/mst/retail_picking_through_list') , {
                    method: 'POST',
                    body: JSON.stringify({ usr_token }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push(data);
                    });
                    setPickingThroughDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getPickingThroughData();
    }, []);

    return {pickingThroughDropdown};
}