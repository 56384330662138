import { useEffect, useState , useRef } from "react";
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './Commercial.css';
import Loader from '../../loader/Loader';
import Select from 'react-select';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { roundToTwo } from "../helper/Helpers";

const Commercial = ({lead_id}) => {

    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id   = localStorage.getItem('usrId');

    let con_total_capex_ul = 0;
    let con_total_capex_ul_up  = 0;
    let con_totat_no_of_bin_needed = 0;
    let con_total_investment_needed = 0;
    let con_cost_of_capitals = 0;
    let con_total_admin_cost = 0;
    let con_total_admin_cost_up = 0;
    let con_total_warehouse_cost = 0;
    let con_total_warehouse_cost_up = 0;
    let con_total_maintenance_cost = 0;
    let con_total_maintenance_cost_up = 0;
    let con_total_losses_theft_cost = 0;
    let con_total_losses_theft_cost_up = 0;
    let con_total_consumable_replmt_cost = 0;
    let con_total_consumable_replmt_cost_up = 0;
    let con_network_service_cost = 0;
    let con_vehicle_Load_type_val = '';
    let con_vehicle_ul_type_val = '';
    let con_total_reverse_tpt_cost = 0;
    let con_total_reverse_tpt_cost_up = 0;
    let con_total_forward_tpt_cost = 0;
    let con_total_forward_tpt_cost_up = 0;
    let con_total_tpt_cost = 0;
    let con_total_management_fee_cost = 0;
    let con_total_cost_of_line  = 0;

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    const navigate = useNavigate();
    let base64 = require('base-64');

    const outerULData = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
    ];

    const [loading, setLoading]  = useState(false);
    const [commercials, setCommercials] = useState([{
        tbl_component_id: '',
        component_name: '',
        outer_cost: '',
        inner_cost: '',
        RevTotalCost: '',
        ForwardTotalCost: '',
        monthly_admin_cost: '',
        monthly_admin_cost_value: '',
        monthly_wh_cost: '',
        monthly_wh_cost_value: '',
        maintenance_cost: '',
        maintenance_cost_value: '',
        life_of_assets: '',
        starting_month: '',
        losses_theft: '',
        losses_theft_value: '',
        consumable_replacement: '',
        consumable_replacement_value: '',
        holisol_mgnt_fee: '',
        holisol_mgnt_fee_value: '',
        cost_of_capital: '',
        cost_of_capital_value: '',
        ul_savage: '',
        ul_savage_value: '',
        total_lead_time: '',
        buffer_inventory: '',
        solution_cost: '',
        prop_comp_ul_qty: '',
        monthly_volume_avg: '',
        bins_per_month: '',
        req_bin_inventory : '', 
        asset_outer:'',
        asset_insert_sep:'',
        capex_ul:'',
        lead_time:'',
        no_of_bin_needed:'',
        include_Sunday:'',
        no_of_cycle_inlife:'',
        total_invest_needed:'',
        cost_of_capitals:'',
        admin_cost:'',
        warehouse_cost:'',
        maintenance_costs:'',
        losses_theft_cost:'',
        consumable_replacement_cost:'',
        network_service_cost:'',
        reverse_tpt_cost:'',
        forward_tpt_cost:'',
        management_fee:'',

    }]);

    
    const [whVal, setWHval]         = useState([]);
    const [commercialFields, setCommercialFields] = useState([
        {  
            comp_id: "",
            comp_name: "",
            solution_cost: "",
            prop_comp_ul_qty:"",
            monthly_volume_avg:"",
            monthly_admin_cost: "",
            monthly_admin_cost_value: "",
            monthly_wh_cost: "",
            monthly_wh_cost_value: "",
            maintenance_cost: "",
            maintenance_cost_value: "",
            life_of_assets: "",
            starting_month: defaultValue,
            losses_theft: "",
            losses_theft_value: "",
            consumable_replacement: "",
            consumable_replacement_value: "",
            holisol_mgnt_fee: "",
            cost_of_capital:"",
            cost_of_capital_value:"",
            ul_savage: "",
            ul_savage_value: "",
            total_lead_time:"",
            buffer_inventory:"",
            buffer_inventory_value: "",
            bin_per_month:"",
            req_bin_inventry:"",
        },
       
    ]);
    const [copiedData, setCopiedData] = useState({}); 
    const [oldData, setoldData] = useState([]); 
    const [conincludetransport, setConIncludetransport]  = useState(0);

    const handleCopy = (e,index) => {

        if (index >= commercialFields.length - 1) return; 
    
        const sourceObject = commercialFields[index];
    
        if (e.target.checked) {

            const newData = commercialFields.map((item, i) => {

                if (i > index) {
                    
                    return {
                        ...item,
                        // bin_per_month: sourceObject.bin_per_month,
                        monthly_admin_cost: sourceObject.monthly_admin_cost,
                        monthly_admin_cost_value: sourceObject.monthly_admin_cost_value,
                        monthly_wh_cost: sourceObject.monthly_wh_cost,
                        monthly_wh_cost_value: sourceObject.monthly_wh_cost_value,
                        maintenance_cost: sourceObject.maintenance_cost,
                        maintenance_cost_value: sourceObject.maintenance_cost_value,
                        losses_theft: sourceObject.losses_theft,
                        losses_theft_value: sourceObject.losses_theft_value,
                        consumable_replacement: sourceObject.consumable_replacement,
                        consumable_replacement_value: sourceObject.consumable_replacement_value,
                        cost_of_capital: sourceObject.cost_of_capital,
                        cost_of_capital_value: sourceObject.cost_of_capital_value,
                        ul_savage: sourceObject.ul_savage,
                        ul_savage_value: sourceObject.ul_savage_value,
                        buffer_inventory: sourceObject.buffer_inventory,
                        buffer_inventory_value: sourceObject.buffer_inventory_value,
                        holisol_mgnt_fee: sourceObject.holisol_mgnt_fee,
                        life_of_assets: sourceObject.life_of_assets,
                        starting_month: sourceObject.starting_month,
                        total_lead_time: sourceObject.total_lead_time,
                        // req_bin_inventry: sourceObject.req_bin_inventry,

                    };

                }
                    return item;
            });

            setCommercialFields(newData);

        } else {

            const newData = oldData.map((item, i) => {
                if (i > index) {

                    return {
                        ...item,
                        // bin_per_month: item.bin_per_month,
                        monthly_admin_cost: item.monthly_admin_cost,
                        monthly_admin_cost_value: item.monthly_admin_cost_value,
                        monthly_wh_cost: item.monthly_wh_cost,
                        monthly_wh_cost_value: item.monthly_wh_cost_value,
                        maintenance_cost: item.maintenance_cost,
                        maintenance_cost_value: item.maintenance_cost_value, 
                        losses_theft: item.losses_theft,
                        losses_theft_value: item.losses_theft_value,
                        consumable_replacement: item.consumable_replacement,
                        consumable_replacement_value: item.consumable_replacement_value,
                        cost_of_capital: item.cost_of_capital,
                        cost_of_capital_value: item.cost_of_capital_value,
                        ul_savage: item.ul_savage,
                        ul_savage_value: item.ul_savage_value,
                        buffer_inventory: item.buffer_inventory,
                        buffer_inventory_value: item.buffer_inventory_value,
                        holisol_mgnt_fee: item.holisol_mgnt_fee,
                        life_of_assets: item.life_of_assets,
                        starting_month: item.starting_month,
                        total_lead_time: item.total_lead_time,
                        // req_bin_inventry: item.req_bin_inventry,
                    
                    };
                }

                return item;
            });

            setCommercialFields(newData);
        }
    };
    
    const handleIncludeSunday = (parentIndex, event) => {

        if (event && (event.target.checked)) {

            const list = [...commercials];
            list[parentIndex]["include_Sunday"] = 1;
            setCommercials(list);

        }else {

            const list = [...commercials];
            list[parentIndex]["include_Sunday"] = 0;
            setCommercials(list);
        }

    } 

    const handleTransport = (parentIndex, event) => {

        if (event && (event.target.checked)) {

            const list = [...commercials];
            list[parentIndex]["include_transport"] = 1;
            setCommercials(list);

        }else {

            const list = [...commercials];
            list[parentIndex]["include_transport"] = 0;
            setCommercials(list);
        }

    } 

    const handleTransportCon = (event) => {

        if (event && (event.target.checked)) {
            setConIncludetransport(1);
        }else{
            setConIncludetransport(0);
        }

    }

    const monthlywhQty = (data) => {
        setWHval(data);
    }

    const getCommercialData = async () => {
        setLoading(true);
        const arr = [];

        console.log(JSON.stringify({lead_id,usr_token}));

        try {
            fetch(ulmsUrl.concat('/wip/sol_commercial') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                setLoading(false);
                let result = response.data;  
                console.log('commer',result); 
                if(result != ''){
                    setCommercialFields(result);
                    setoldData(result)
                    setWHval({ value: response.selected_wh_val, label: response.selected_wh_val})
                }
                
            });
        } catch (error) {
            console.log(error);
        }
    }

    const getCommercialCal = async () => {
        setLoading(true);
        const arr = [];

        console.log(JSON.stringify({lead_id,usr_token}));

        try {
            fetch(ulmsUrl.concat('/wip/sol_commercial_field') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                setLoading(false);
                let result = response.data;  
                console.log('commer',result); 
                if(result != ''){
                    setCommercials(result)
                }
                
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getCommercialData();
        getCommercialCal();
    }, []);   

    console.log('com',commercials)

    const handleFormInput = (index, event) => {

        const { name, value } = event.target;
        const list = [...commercialFields];

         list[index][name] = value;
         console.log(list)
         setCommercialFields(list);

    };


    const handleFormSubmit = async (e) => {

        e.preventDefault();

        const createdata = {
            lead_id : lead_id,
            selected_wh_val : whVal.value,
            data: commercialFields,
            img_data_app: [], 
            solution_creater_id: usr_id,
            solution_creater_name: usr_name,
            usr_token: usr_token,
        }

        console.log(JSON.stringify(createdata))
    
        fetch(ulmsUrl.concat('/wip/sol_commercial_store'), {
            method: 'POST',
            body: JSON.stringify(createdata),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                // setCommercialFields('');
               
                toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
                });
                getCommercialData();
                getCommercialCal();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setCommercialFields(commercialFields);
                toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })

    }

    const handleCommercialSubmit = async (e) => {

        e.preventDefault();

        const commConsolidateData = {
            'con_total_capex_ul': con_total_capex_ul,
            'con_total_investment_needed': con_total_investment_needed,
            'con_cost_of_capitals': con_cost_of_capitals,
            'con_total_admin_cost': con_total_admin_cost,
            'con_total_warehouse_cost': con_total_warehouse_cost,
            'con_total_maintenance_cost': con_total_maintenance_cost,
            'con_total_losses_theft_cost': con_total_losses_theft_cost,
            'con_total_consumable_replmt_cost': con_total_consumable_replmt_cost,
            'con_network_service_cost': con_network_service_cost,
            'con_vehicle_Load_type_val': con_vehicle_Load_type_val,
            'con_vehicle_ul_type_val': con_vehicle_ul_type_val,
            'con_total_reverse_tpt_cost' : con_total_reverse_tpt_cost,
            'con_total_forward_tpt_cost': con_total_forward_tpt_cost,
            'con_total_tpt_cost': con_total_tpt_cost,
            'con_total_management_fee_cost' : con_total_management_fee_cost,
            'con_total_cost_of_line' : con_total_cost_of_line

        }

        const createdata = {
            lead_id : lead_id,
            data: commercials,
            consolidate: commConsolidateData,
            solution_creater_id: usr_id,
            solution_creater_name: usr_name,
            usr_token: usr_token,
        }

        console.log(JSON.stringify(createdata))
    
        fetch(ulmsUrl.concat('/wip/sol_compwise_commercial_store'), {
            method: 'POST',
            body: JSON.stringify(createdata),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
             
                toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
                });
                getCommercialData();
                getCommercialCal();

            } else if (result.status == false) {
                setLoading(false);
                toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })

    }

    const audioRef = useRef(null);

    const handlePlay = () => {
      audioRef.current.play();
    };



  return (
    <>
        {/* <ToastContainer /> */}
        {loading && (
            <>
              <Loader/>
            </>
        ) }
        <audio ref={audioRef}>
            <source src="../../ulms_assets/sound/mixkit-bubble-pop-up-alert-notification-2357.wav" type="audio/mpeg" />
        </audio>
        <div className="col-md-12" style={{marginTop:'20px', float:'left'}}>
            <div className="card border-dark" style={{borderRadius:'2px'}}>
                <div className="row">
                    <div className="col-md-3" style={{ marginTop:'10px', font:'20px', marginLeft:'21px'}}>
                        <label htmlFor="inputState" className="form-label"> W/H Count: <span className="required-mark">*</span></label>
                        <Select
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="month_wh_qty"
                            options={outerULData}
                            defaultValue={whVal}
                            onChange={monthlywhQty}
                            key={whVal}
                        />   
                    </div>  
                    <div className="col-md-8" style={{ marginTop:'40px', font:'20px', float:'right'}}>
                        <Form.Check
                            inline
                            label="Copy Data to all SKU"
                            name="Copy Data to all SKU"
                            value="1"
                            // checked={isChecked}
                            onChange={(e)=>handleCopy(e,0)}
                            // onClick={handlePlay}
                            // key={value.inner}
                            // onChange={(event)=>handleInnerInput(parentIndex, event)}
                            // defaultChecked = {(value.inner) ? true : false}
                            style={{fontSize: '18px', fontWeight:'bold', float:'right'}}
                        />
                        {/* <button onClick={() => setCommercialFields(commercialFields.map((item, index) => index === 0 ? { ...item } : { ...previousValue }))}>Undo Copy</button> */}
                    </div>  
                   
                </div>
                <form className="row g-3" encType="multipart/form-data" onSubmit={handleFormSubmit} style={{margin:'10px'}}>
                {commercialFields != '' && commercialFields.map((input, index) => {

                    const updatedData = [...commercialFields];

                    if(input.monthly_volume_avg != '' && input.prop_comp_ul_qty != ''){
                        updatedData[index]["bin_per_month"] = Number(updatedData[index].monthly_volume_avg) / Number(updatedData[index].prop_comp_ul_qty); 
                    }

                    if(input.total_lead_time != ''){
                        updatedData[index]["hidd_req_bin_invt"] =  ((Number(updatedData[index].total_lead_time) / 30) * Number(updatedData[index].bin_per_month));
                    }

                    if(input.buffer_inventory != '' && updatedData[index]["hidd_req_bin_invt"] != ''){
                        updatedData[index]["buffer_inventory_value"] = ((Number(updatedData[index].buffer_inventory)/100) * updatedData[index]["hidd_req_bin_invt"]);
                    }

                    if(input.total_lead_time != '' && input.bin_per_month != '' && input.buffer_inventory){
                        updatedData[index]["req_bin_inventry"] = ((updatedData[index]["hidd_req_bin_invt"]) + (updatedData[index]["buffer_inventory_value"]));
                    }

                    if(input.solution_cost != '' && input.monthly_admin_cost != ''){
                        updatedData[index]["monthly_admin_cost_value"] = (Number(updatedData[index].monthly_admin_cost)/100) * Number(updatedData[index].solution_cost);
                    }

                    if(input.solution_cost != '' && input.monthly_wh_cost != ''){
                        updatedData[index]["monthly_wh_cost_value"]  =  (((Number(updatedData[index].monthly_wh_cost)/100) * Number(updatedData[index].solution_cost)) * whVal.value);
                    }

                    if(input.solution_cost != '' && input.maintenance_cost != ''){
                        updatedData[index]["maintenance_cost_value"]  = (Number(updatedData[index].maintenance_cost)/100) * Number(updatedData[index].solution_cost);
                    }

                    if(input.solution_cost != '' && input.losses_theft != ''){
                        updatedData[index]["losses_theft_value"]  = (Number(updatedData[index].losses_theft)/100) * Number(updatedData[index].solution_cost);
                    }
                    if(input.solution_cost != '' && input.consumable_replacement != ''){
                        updatedData[index]["consumable_replacement_value"] = (Number(updatedData[index].consumable_replacement)/100) * Number(updatedData[index].solution_cost);
                    }

                    // if(input.solution_cost != '' && input.holisol_mgnt_fee != ''){
                    //     updatedData[index]["holisol_mgnt_fee_value"] = (Number(updatedData[index].holisol_mgnt_fee)/100) * Number(updatedData[index].solution_cost);
                    // }
                    if(input.solution_cost != '' && input.cost_of_capital != ''){
                        updatedData[index]["cost_of_capital_value"] = (Number(updatedData[index].cost_of_capital)/100) * Number(updatedData[index].solution_cost);
                    }
                    if(input.solution_cost != '' && input.ul_savage != ''){
                        updatedData[index]["ul_savage_value"] = (Number(updatedData[index].ul_savage)/100) * Number(updatedData[index].solution_cost);
                    }

                    if(updatedData > -1){
                        setCommercialFields(updatedData)
                    }


                return(
                <div className="accordion" id="accordionExample" style={{marginTop:'1px'}}>
                    <div className="card border-dark">
                        <div className="card-header row" id={"heading"+ input.comp_id} data-toggle="collapse" data-target={"#collapse"+ input.comp_id} aria-expanded="false" aria-controls={"collapse"+ input.comp_id} style={{backgroundColor:'#0D4C7B', fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                            <div className="col-md-8" >
                                <h3 className="mb-0" >
                                    <span className="btn btn collapsed" style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                                      Component Name :  {input.comp_name}
                                    </span>
                                </h3>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="inputState" className="form-label">Bins Per Month: <span className="required-mark">*</span></label>
                                <Form.Control
                                    type="number"
                                    name="bin_per_month"
                                    value={roundToTwo(input.bin_per_month)}
                                    // value={copiedData.id === input.ul_savage ? copiedData.ul_savage : input.ul_savage}
                                    onChange={(event)=>handleFormInput(index, event)}
                                    id="bin_per_month"
                                    className="form-control" readOnly />      
                            </div>
                            <div className="col-md-2" >
                                <label htmlFor="inputState" className="form-label">Required Bin Inventory: <span className="required-mark">*</span></label>
                                <Form.Control
                                    type="number"
                                    name="req_bin_inventry"
                                    value={roundToTwo(input.req_bin_inventry)}
                                    // value={copiedData.id === input.ul_savage ? copiedData.ul_savage : input.ul_savage}
                                    onChange={(event)=>handleFormInput(index, event)}
                                    id="req_bin_inventry"
                                    className="form-control" readOnly />      
                            </div>
                        </div>
                        <div id={"collapse"+ input.comp_id} className="collapse" aria-labelledby={"heading"+ input.comp_id} data-parent="#accordionExample">
                            <div className="card-body">
                                <div className='row' key={index}>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">Monthly Admin Cost (%): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    key={index}
                                                    type="number"
                                                    name="monthly_admin_cost"
                                                    // value={input.monthly_admin_cost}
                                                    value={input.monthly_admin_cost}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="monthly_admin_cost"
                                                    className="form-control" 
                                                    />    
                                            </div>  
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    key={index}
                                                    type="number"
                                                    name="monthly_admin_cost_value"
                                                    value={roundToTwo(input.monthly_admin_cost_value)}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="monthly_admin_cost_value"
                                                    className="form-control"
                                                    readOnly 
                                                    />    
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                              <label htmlFor="inputState" className="form-label">Monthy W/H Cost: <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="monthly_wh_cost"
                                                    value={input.monthly_wh_cost}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="monthly_wh_cost"
                                                    className="form-control" />   
                                            </div>   
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="monthly_wh_cost_value"
                                                    value={roundToTwo(input.monthly_wh_cost_value)}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="monthly_wh_cost_value"
                                                    className="form-control" readOnly />   
                                            </div>  
                                        </div> 
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                               <label htmlFor="inputState" className="form-label">Maintenance Cost (%): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="maintenance_cost"
                                                    value={input.maintenance_cost}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="maintenance_cost"
                                                    className="form-control" />  
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="maintenance_cost_value"
                                                    value={roundToTwo(input.maintenance_cost_value)}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="maintenance_cost_value"
                                                    className="form-control" readOnly />  
                                            </div>
                                        </div>    
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label"> Losses / Theft (%): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="losses_theft"
                                                    value={input.losses_theft}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="losses_theft"
                                                    className="form-control" />    
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="losses_theft_value"
                                                    value={roundToTwo(input.losses_theft_value)}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="losses_theft_value"
                                                    className="form-control" readOnly />    
                                            </div>
                                        </div>  
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">Consumable Replacement(%)<span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="consumable_replacement"
                                                    value={input.consumable_replacement}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="consumable_replacement"
                                                    className="form-control" />   
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="consumable_replacement_value"
                                                    value={roundToTwo(input.consumable_replacement_value)}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="consumable_replacement_value"
                                                    className="form-control" readOnly/>   
                                            </div>
                                        </div>   
                                    </div>
                                  
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">Cost Of Capital (%): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="cost_of_capital"
                                                    value={input.cost_of_capital}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="cost_of_capital"
                                                    className="form-control" />  
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="cost_of_capital_value"
                                                    value={roundToTwo(input.cost_of_capital_value)}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="cost_of_capital_value"
                                                    className="form-control" readOnly/>  
                                            </div>
                                        </div>    
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">UL Salvage (%): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="ul_savage"
                                                    value={input.ul_savage}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="ul_savage"
                                                    className="form-control" />    
                                            </div>  
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="ul_savage_value"
                                                    value={roundToTwo(input.ul_savage_value)}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="ul_savage_value"
                                                    className="form-control" readOnly/>    
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className="row">
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">Buffer Inventory (%): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="buffer_inventory"
                                                    value={input.buffer_inventory}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="buffer_inventory"
                                                    className="form-control" />   
                                            </div> 
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="buffer_inventory_value"
                                                    value={roundToTwo(input.buffer_inventory_value)}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="buffer_inventory_value"
                                                    className="form-control" readOnly />   
                                            </div> 
                                        </div>  
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">Holisol Management Fee(%): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="holisol_mgnt_fee"
                                                    value={input.holisol_mgnt_fee}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="holisol_mgnt_fee"
                                                    className="form-control" />  
                                            </div>
                                          
                                        </div>    
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className="row">
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">Life of Assets (in yrs): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="life_of_assets"
                                                    value={input.life_of_assets}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="life_of_assets"
                                                    className="form-control" />    
                                            </div>
                                        </div>  
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className="row">
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">Starting Month: <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="date"
                                                    name="starting_month"
                                                    value={input.starting_month}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="starting_month"
                                                    className="form-control" />   
                                            </div>
                                        </div>   
                                    </div>
                                    <div className="col-md-6" style={{marginTop: '5px'}}>
                                        <div className="row">
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <label htmlFor="inputState" className="form-label">Total Lead Time (In Days): <span className="required-mark">*</span></label>
                                            </div>
                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                <Form.Control
                                                    type="number"
                                                    name="total_lead_time"
                                                    value={input.total_lead_time}
                                                    onChange={(event)=>handleFormInput(index, event)}
                                                    id="total_lead_time"
                                                    className="form-control" />  
                                            </div>
                                        </div>    
                                    </div>    
                                </div> 
                            </div>
                        </div>
                    </div>
                </div> )
                })} 
                    <div className="col-md-12" style={{margin: '10px'}}>
                        <button type="submit" className="btn btn-primary">Calculate</button>
                    </div> 
                </form>
            </div>
        </div>

        <div className="row">
        <div className="col-md-6" style={{ marginTop:'20px'}}>
            <div className="card-header" style={{backgroundColor:'#0D4C7B', fontWeight: 'bold', fontSize:'16px', color:'white', borderRadius:'1px'}}>
                <div className="col-md-8" >
                    <h3 className="mb-0" >
                        <span className="btn btn collapsed" style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                            SKU-wise Commercials 
                        </span>
                    </h3>
                </div>
            </div>
            <div className="accordion" id="accordionSKU" >

                {commercials != '' && commercials.map((data, index) => {

                console.log('dddddd',data);

                const upldatedata = [...commercials];

                upldatedata[index]["asset_outer"] = Number(upldatedata[index].outer_cost);

                upldatedata[index]["asset_insert_sep"] = Number(upldatedata[index].inner_cost);

                upldatedata[index]["capex_ul"] = Number(upldatedata[index].asset_outer) +  Number(upldatedata[index].asset_insert_sep);

                upldatedata[index]["lead_time"] = Number(upldatedata[index].total_lead_time);

                upldatedata[index]["no_of_bin_needed"] = Number(upldatedata[index].req_bin_inventory);

                upldatedata[index]["life_of_assets"] = Number(upldatedata[index].life_of_assets);

                if(upldatedata[index]["include_Sunday"] == 1){

                    upldatedata[index]["no_of_cycle_inlife"] = ((Number(upldatedata[index].life_of_assets) * 365) / Number(upldatedata[index].total_lead_time));
                   
                }else{
                    upldatedata[index]["no_of_cycle_inlife"] = ((Number(upldatedata[index].life_of_assets) * (365 - 52)) / Number(upldatedata[index].total_lead_time));
                }

                upldatedata[index]["total_invest_needed"] = Number(upldatedata[index].no_of_bin_needed) *  Number(upldatedata[index].capex_ul);

                upldatedata[index]["cost_of_capitals"] = Number(upldatedata[index].cost_of_capital_value);

                upldatedata[index]["admin_cost"] = Number(upldatedata[index].monthly_admin_cost_value);

                upldatedata[index]["warehouse_cost"] = Number(upldatedata[index].monthly_wh_cost_value);
              
                upldatedata[index]["maintenance_costs"] = Number(upldatedata[index].maintenance_cost_value);
               
                upldatedata[index]["losses_theft_cost"] = Number(upldatedata[index].losses_theft_value);

                upldatedata[index]["consumable_replacement_cost"] = Number(upldatedata[index].consumable_replacement_value);

                upldatedata[index]["network_service_cost"] = (Number(upldatedata[index].admin_cost) + Number(upldatedata[index].warehouse_cost) + Number(upldatedata[index].maintenance_costs) + Number(upldatedata[index].losses_theft_cost) +  Number(upldatedata[index].cost_of_capitals));

                upldatedata[index]["reverse_tpt_cost"] = (Number(upldatedata[index].RevTotalCost) / Number(upldatedata[index].rev_moq));

                upldatedata[index]["forward_tpt_cost"] = (Number(upldatedata[index].ForwardTotalCost)  / Number(upldatedata[index].forw_moq));

                upldatedata[index]["transportation_cost"] = (Number(upldatedata[index].reverse_tpt_cost) + upldatedata[index].forward_tpt_cost);

                if(upldatedata[index]["include_transport"] == 1){

                    upldatedata[index]["management_fee"] = ((Number(upldatedata[index].holisol_mgnt_fee) / 100) * (upldatedata[index].capex_ul + upldatedata[index].cost_of_capitals + upldatedata[index].network_service_cost  + upldatedata[index].transportation_cost));
                }else{

                    upldatedata[index]["management_fee"] = ((Number(upldatedata[index].holisol_mgnt_fee) / 100) * (upldatedata[index].capex_ul + upldatedata[index].cost_of_capitals + upldatedata[index].network_service_cost));
                }

                upldatedata[index]["total_cost_of_line"] = (Number(upldatedata[index].network_service_cost) + upldatedata[index].capex_ul + upldatedata[index].management_fee + Number(upldatedata[index].cost_of_capitals));


                con_total_capex_ul_up  += (Number(upldatedata[index].capex_ul) * Number(upldatedata[index].no_of_bin_needed)); 

                con_totat_no_of_bin_needed += Number(upldatedata[index].no_of_bin_needed);

                con_total_capex_ul = (con_total_capex_ul_up / con_totat_no_of_bin_needed);

                // updatecommconsolidate["con_total_lead_time"] = Math.max(upldatedata[index].lead_time);

                con_total_investment_needed += upldatedata[index].total_invest_needed;

                con_cost_of_capitals += upldatedata[index].cost_of_capitals;

                con_total_admin_cost_up  += (Number(upldatedata[index].admin_cost) * Number(upldatedata[index].no_of_bin_needed)); 

                con_total_admin_cost = (con_total_admin_cost_up / con_totat_no_of_bin_needed);

                con_total_warehouse_cost_up  += (Number(upldatedata[index].warehouse_cost) * Number(upldatedata[index].no_of_bin_needed)); 

                con_total_warehouse_cost = (con_total_warehouse_cost_up / con_totat_no_of_bin_needed);

                con_total_maintenance_cost_up  += (Number(upldatedata[index].maintenance_costs) * Number(upldatedata[index].no_of_bin_needed)); 

                con_total_maintenance_cost = (con_total_maintenance_cost_up / con_totat_no_of_bin_needed);

                con_total_losses_theft_cost_up  += (Number(upldatedata[index].losses_theft_cost) * Number(upldatedata[index].no_of_bin_needed)); 

                con_total_losses_theft_cost = (con_total_losses_theft_cost_up / con_totat_no_of_bin_needed);

                con_total_consumable_replmt_cost_up  += (Number(upldatedata[index].consumable_replacement_cost) * Number(upldatedata[index].no_of_bin_needed)); 

                con_total_consumable_replmt_cost = (con_total_consumable_replmt_cost_up / con_totat_no_of_bin_needed);

                con_network_service_cost =  con_total_admin_cost + con_total_warehouse_cost + con_total_maintenance_cost + con_total_losses_theft_cost +con_total_consumable_replmt_cost;

                con_vehicle_Load_type_val =  upldatedata[index].vehicle_Load_type_val;

                con_vehicle_ul_type_val = upldatedata[index].vehicle_ul_type_val;

                con_total_reverse_tpt_cost_up  += (Number(upldatedata[index].reverse_tpt_cost) * Number(upldatedata[index].no_of_bin_needed)); 

                con_total_reverse_tpt_cost = (con_total_reverse_tpt_cost_up / con_totat_no_of_bin_needed);

                con_total_forward_tpt_cost_up  += (Number(upldatedata[index].forward_tpt_cost) * Number(upldatedata[index].no_of_bin_needed)); 

                con_total_forward_tpt_cost = (con_total_forward_tpt_cost_up / con_totat_no_of_bin_needed);

                con_total_tpt_cost = (con_total_reverse_tpt_cost + con_total_forward_tpt_cost);

                if(conincludetransport == 1){
                    con_total_management_fee_cost = (con_total_capex_ul + con_cost_of_capitals + con_network_service_cost + con_total_tpt_cost);
                }else{
                    con_total_management_fee_cost = (con_total_capex_ul + con_cost_of_capitals + con_network_service_cost);
                }

                con_total_cost_of_line = con_total_capex_ul + con_cost_of_capitals + con_network_service_cost + con_total_management_fee_cost;


                if(upldatedata > -1){
                    setCommercials(upldatedata)
                }

                console.log('up',upldatedata);

                return(
                 
                <div className="card">
                    <div className="card-header" id={"headingVeriable"+index} data-toggle="collapse" data-target={"#collapsesku"+index} aria-expanded="false" aria-controls={"collapsesku"+index} style={{ backgroundColor:'#46B653', borderRadius:'5px'}}>
                        <div className='row'>
                            <div className="col-md-12" >
                                <h2 className="card-title"style={{fontWeight: 'bold', fontSize:'16px', color:'white'}} >Component Name - {data.component_name} </h2>
                            </div>
                        </div>
                    </div>
                    <div id={"collapsesku"+index} className="collapse" aria-labelledby={"headingVeriable"+index} data-parent="#accordionSKU">
                        <div className='card-body'>
                            <div className="card" style={{borderRadius:'2px', borderColor: 'black',}}>
                                <div className="col-md-12">
                                    <table id="mainTable" className="table padded-table">
                                        <thead>
                                            <tr>
                                                <th style={{width:'100px'}}>S.NO.</th>
                                                <th style={{width:'350px'}}>Particulars</th>
                                                <th style={{width:'350px'}}>Rate / UOM</th>
                                                <th style={{width:'100px'}} >Cost</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div> 
                            </div>
                            <div className="accordion" id="accordionAssetsku">
                                <div className="card">
                                    <div className="card-header" id={"headingSix"+index} data-toggle="collapse" data-target={"#collapseAssetSKU"+index} aria-expanded="false" aria-controls={"collapseAssetSKU"+index} style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                                        <div className='row'>
                                            <div className="col-md-6" >
                                                <h2 className="card-title" style={{fontSize: '20px'}}> A. Asset Cost </h2>
                                            </div>
                                            <div  className="col-md-6" >
                                                <h3 style={{float:'right'}}>₹ {roundToTwo(data.capex_ul)} </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"collapseAssetSKU"+index} className="collapse" aria-labelledby={"headingSix"+index} data-parent="#accordionAssetsku">
                                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                                            <div className="col-md-12">
                                                <table id="mainTable" className="table padded-table">
                                                    {/* <thead>
                                                        <tr>
                                                            <th >S.NO.</th>
                                                            <th >Particulars</th>
                                                            <th >Rate / UOM</th>
                                                            <th >Cost</th>
                                                        </tr>
                                                    </thead> */}
                                                    <tbody>
                                                        <tr>
                                                            <th style={{width:'50px'}}>1</th>
                                                            <td>Capex / UL</td>
                                                            <td style={{width:'200px'}}> Rs per bin</td>
                                                            <td style={{width:'100px'}}>₹ {roundToTwo(data.capex_ul)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width:'100px'}}> &nbsp; &nbsp; &nbsp; 1.1</th>
                                                            <td>Outer</td>
                                                            <td>Rs per bin </td>
                                                            <td>₹ {roundToTwo(data.asset_outer)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width:'100px'}}>&nbsp; &nbsp; &nbsp; 1.2</th>
                                                            <td>Insert / Seperator</td>
                                                            <td>Rs per bin </td>
                                                            <td>₹ {roundToTwo(data.asset_insert_sep)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion" id="accordionUtilizSKU">
                                <div className="card">
                                    <div className="card-header" id={"headingVeriable"+index} data-toggle="collapse" data-target={"#collapseUtilizsku"+index} aria-expanded="false" aria-controls={"collapseUtilizsku"+index} style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                                        <div className='row'>
                                            <div className="col-md-6" >
                                                <h2 className="card-title" style={{fontSize: '20px'}}> B. Asset Chargeback Cost</h2>
                                            </div>
                                            <div  className="col-md-6" >
                                                <h3 style={{float:'right'}}>₹ {roundToTwo(data.cost_of_capitals)}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"collapseUtilizsku"+index} className="collapse" aria-labelledby={"headingVeriable"+index} data-parent="#accordionUtilizSKU">
                                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                                            <div  className="col-md-12">
                                                <table id="mainTable" className="table padded-table">
                                                    {/* <thead>
                                                        <tr>
                                                            <th >S.NO.</th>
                                                            <th >Particulars</th>
                                                            <th >Rate / UOM</th>
                                                            <th >Cost</th>
                                                        </tr>
                                                    </thead> */}
                                                    <tbody>
                                                        <tr>
                                                            <th style={{width:'50px'}}>1</th>
                                                            <td>Lead Time</td>
                                                            <td style={{width:'200px'}}>in days</td>
                                                            <td style={{width:'100px'}}> {roundToTwo(data.lead_time)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width:'50px'}}>2</th>
                                                            <td>No. of Bins Needed</td>
                                                            <td>nos.</td>
                                                            <td> {roundToTwo(data.no_of_bin_needed)} </td>
                                                        </tr>
                                                        <tr>
                                                            <th >3</th>
                                                            <td>Life of Assets</td>
                                                            <td>in year</td>
                                                            <td> {roundToTwo(data.life_of_assets)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th >4</th>
                                                            <td>
                                                                No. of cycle in a lifetime
                                                                <Form.Check
                                                                    inline
                                                                    label="Include Sunday"
                                                                    name="include_Sunday"
                                                                    value="1"
                                                                    type= "checkbox" 
                                                                    // key={value.outer}
                                                                    onChange={(event)=>handleIncludeSunday(index, event)}
                                                                    // defaultChecked = {(value.outer) ? true : false  }

                                                                    style={{ marginTop:'5px'}}
                                                                />
                                                            </td>
                                                            <td>nos.</td>
                                                            <td> {Math.round(data.no_of_cycle_inlife)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th >5</th>
                                                            <td>Total Investment Needed</td>
                                                            <td>Rs per UL</td>
                                                            <td>₹ {roundToTwo(data.total_invest_needed)}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th >6</th>
                                                            <td>Cost of Capital</td>
                                                            <td>Rs per UL </td>
                                                            <td>₹ {roundToTwo(data.cost_of_capitals)}</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion" id="accordionNetworkSKU">
                                <div className="card">
                                    <div className="card-header" id={"headingVeriable"+index} data-toggle="collapse" data-target={"#collapseNetworksku"+index} aria-expanded="false" aria-controls={"collapseNetworksku"+index} style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                                        <div className='row'>
                                            <div className="col-md-6" >
                                                <h2 className="card-title" style={{fontSize: '20px'}}> C. Network & Service Cost</h2>
                                            </div>
                                            <div  className="col-md-6" >
                                                <h3 style={{float:'right'}}>₹ {roundToTwo(data.network_service_cost)} </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"collapseNetworksku"+index} className="collapse" aria-labelledby={"headingVeriable"+index} data-parent="#accordionNetworkSKU">
                                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                                            <div  className="col-md-12">
                                                <table id="mainTable" className="table padded-table">
                                                    {/* <thead>
                                                        <tr>
                                                            <th >S.NO.</th>
                                                            <th >Particulars</th>
                                                            <th >Rate / UOM</th>
                                                            <th >Cost</th>
                                                        </tr>
                                                    </thead> */}
                                                    <tbody>
                                                        <tr>
                                                            <th style={{width:'50px'}}>1</th>
                                                            <td>Admin Cost</td>
                                                            <td  style={{width:'230px'}}>Rs per UL</td>
                                                            <td>₹ {roundToTwo(data.admin_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width:'50px'}}>2</th>
                                                            <td>Warehouse Cost</td>
                                                            <td>Rs per UL </td>
                                                            <td>₹ {roundToTwo(data.warehouse_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width:'50px'}}>3</th>
                                                            <td>Maintenance Cost</td>
                                                            <td>Rs per UL  </td>
                                                            <td>₹ {roundToTwo(data.maintenance_costs)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width:'50px'}}>4</th>
                                                            <td>Losses / Theft Cost</td>
                                                            <td>Rs per UL</td>
                                                            <td>₹ {roundToTwo(data.losses_theft_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width:'50px'}}>5</th>
                                                            <td>Consumable Replacement Cost</td>
                                                            <td>Rs per UL </td>
                                                            <td>₹ {roundToTwo(data.consumable_replacement_cost)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion" id="accordionTranspSKU">
                                <div className="card">
                                    <div className="card-header" id={"headingVeriable"+index} data-toggle="collapse" data-target={"#collapseTranspsku"+index} aria-expanded="false" aria-controls={"collapseTranspsku"+index} style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                                        <div className='row'>
                                            <div className="col-md-8" >
                                                <h2 className="card-title" style={{fontSize: '20px'}}> D. Transportation Cost </h2>
                                            </div>
                                            <div  className="col-md-4" >
                                                {/* <p style={{float:'right', margin:'5px'}}> per bin </p> */}
                                                <h3 style={{float:'right'}}>₹ {roundToTwo(data.transportation_cost)} </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"collapseTranspsku"+index} className="collapse" aria-labelledby={"headingVeriable"+index} data-parent="#accordionTranspSKU">
                                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                                            <div  className="col-md-12">
                                                <table id="mainTable" className="table padded-table">
                                                    {/* <thead>
                                                        <tr>
                                                            <th >S.NO.</th>
                                                            <th >Particulars</th>
                                                            <th >Rate / UOM</th>
                                                            <th >Cost</th>
                                                        </tr>
                                                    </thead> */}
                                                    <tbody>
                                                        <tr>
                                                            <th style={{width:'50px'}}>1</th>
                                                            <td>Reverse TPT Cost</td>
                                                            <td  style={{width:'220px'}}>{data.vehicle_ul_type_val} / {data.vehicle_Load_type_val}</td>
                                                            <td style={{width:'100px'}}>₹ {roundToTwo(data.reverse_tpt_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{width:'50px'}}>2</th>
                                                            <td>Forward TPT Cost</td>
                                                            <td>{data.vehicle_ul_type_val} / {data.vehicle_Load_type_val}</td>
                                                            <td>₹ {roundToTwo(data.forward_tpt_cost)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion" id="accordionMngmtSKU">
                                <div className="card">
                                    <div className="card-header" id={"headingVeriable"+index} data-toggle="collapse" data-target={"#collapseMngmtsku"+index} aria-expanded="false" aria-controls={"collapseMngmtsku"+index} style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                                        <div className='row'>
                                            <div className="col-md-8" >
                                                <h2 className="card-title" style={{fontSize: '20px'}}> E. Holisol Management Fee </h2>
                                            </div>
                                            <div  className="col-md-4" >
                                                {/* <p style={{float:'right', margin:'5px'}}> per bin </p> */}
                                                <h3 style={{float:'right'}}>₹ {roundToTwo(data.management_fee)} </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"collapseMngmtsku"+index} className="collapse" aria-labelledby={"headingVeriable"+index} data-parent="#accordionMngmtSKU">
                                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                                            <div  className="col-md-12">
                                                <table id="mainTable" className="table padded-table">
                                                    {/* <thead>
                                                        <tr>
                                                            <th >S.NO.</th>
                                                            <th >Particulars</th>
                                                            <th >Rate / UOM</th>
                                                            <th >Cost</th>
                                                        </tr>
                                                    </thead> */}
                                                    <tbody>
                                                        <tr>
                                                            <th style={{width:'50px'}}>1</th>
                                                            {/* <td>Management Fee</td> */}
                                                            <td style={{width:'200px'}}>
                                                                Management Fee <br/>
                                                                <Form.Check
                                                                    inline
                                                                    label="Transportation (D)"
                                                                    name="include_Sunday"
                                                                    value="1"
                                                                    type= "checkbox" 
                                                                    // key={value.outer}
                                                                    onChange={(event)=>handleTransport(index, event)}
                                                                    // defaultChecked = {(value.outer) ? true : false  }

                                                                    style={{ marginTop:'5px'}}
                                                                />
                                                            </td>
                                                            <td style={{width:'150px'}}>@ {data.holisol_mgnt_fee} %</td>
                                                            <td style={{width:'90px'}}>₹ {roundToTwo(data.management_fee)}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header"  style={{borderColor: 'black', backgroundColor:'black', borderRadius:'5px', border: '2px solid black'}}>
                                <div className='row'>
                                    <div className="col-md-8" >
                                        <h2 className="card-title" style={{fontSize: '20px', color:'white'}}>TOTAL COST OF LINE FRIENDLY GREEN PACKAGING (Excluding TPT.) </h2>
                                    </div>
                                    <div  className="col-md-4" >
                                        <p style={{float:'right', color:'white'}}>Rs per box / per cycle </p>
                                        <h3 style={{float:'right', color:'white'}}>₹ {roundToTwo(data.total_cost_of_line)} </h3>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)

                })}
            </div>
            <div className="col-md-6" >
                <div className="col-md-12" style={{margin: '20px'}}>
                    <button type="submit" onClick={handleCommercialSubmit} className="btn btn-primary">Sent to client</button>
                </div> 
            </div>
        </div>

        <div className="col-md-6" style={{marginTop:'20px'}}>
            <div className="card-header" style={{backgroundColor:'#0D4C7B', fontWeight: 'bold', fontSize:'16px', color:'white', borderRadius:'1px'}}>
                <div className="col-md-8" >
                    <h3 className="mb-0" >
                        <span className="btn btn collapsed" style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                            Combined Commercials 
                        </span>
                    </h3>
                </div>
            </div>
            <div className="card" style={{borderRadius:'2px', borderColor: 'black',}}>
                <div  className="col-md-12">
                    <table id="mainTable" className="table padded-table">
                        <thead>
                            <tr>
                                <th style={{width:'100px'}}>S.NO.</th>
                                <th style={{width:'350px'}}>Particulars</th>
                                <th style={{width:'350px'}}>Rate / UOM</th>
                                <th style={{width:'100px'}} >Cost</th>
                            </tr>
                        </thead>
                    </table>
                </div> 
            </div>
            <div className="accordion" id="accordionAsset">
                <div className="card">
                    <div className="card-header" id="headingSix" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                        <div className='row'>
                            <div className="col-md-6" >
                                <h2 className="card-title" style={{fontSize: '20px'}}> A. Asset Cost</h2>
                            </div>
                            <div  className="col-md-6" >
                                <h3 style={{float:'right'}}>₹ {roundToTwo(con_total_capex_ul)} </h3>
                            </div>
                        </div>
                    </div>
                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionAsset">
                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                            <div  className="col-md-12">
                                <table id="mainTable" className="table padded-table">
                                    {/* <thead>
                                        <tr>
                                            <th >S.NO.</th>
                                            <th >Particulars</th>
                                            <th >Rate / UOM</th>
                                            <th >Cost</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <th style={{width:'50px'}}>1</th>
                                            <td>Capex / UL</td>
                                            <td style={{width:'210px'}}> Rs per bin</td>
                                            <td style={{width:'110px'}}>₹ {roundToTwo(con_total_capex_ul)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion" id="accordionVariable">
                <div className="card">
                    <div className="card-header" id="headingVeriable" data-toggle="collapse" data-target="#collapseVeriable" aria-expanded="false" aria-controls="collapseVeriable" style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                        <div className='row'>
                            <div className="col-md-6" >
                                <h2 className="card-title" style={{fontSize: '20px'}}> B. Asset Chargeback Cost </h2>
                            </div>
                            <div  className="col-md-6" >
                                <h3 style={{float:'right'}}>₹ {roundToTwo(con_cost_of_capitals)} </h3>
                            </div>
                        </div>
                    </div>
                    <div id="collapseVeriable" className="collapse" aria-labelledby="headingVeriable" data-parent="#accordionVariable">
                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                            <div  className="col-md-12">
                                <table id="mainTable" className="table padded-table">
                                    {/* <thead>
                                        <tr>
                                            <th >S.NO.</th>
                                            <th >Particulars</th>
                                            <th >Rate / UOM</th>
                                            <th >Cost</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        {/* <tr>
                                            <th style={{width:'50px'}}>1</th>
                                            <td>Lead Time</td>
                                            <td></td>
                                            <td>{con_total_lead_time} </td>
                                        </tr>
                                        <tr>
                                            <th style={{width:'50px'}}>2</th>
                                            <td>No of Bins Needed</td>
                                            <td></td>
                                            <td>0 </td>
                                        </tr>
                                        <tr>
                                            <th >3</th>
                                            <td>Life Of Assets</td>
                                            <td></td>
                                            <td>0 </td>
                                        </tr>
                                        <tr>
                                            <th >4</th>
                                            <td>No. of Cycle in a life</td>
                                            <td></td>
                                            <td>80</td>
                                        </tr> */}
                                        <tr>
                                            <th style={{width:'50px'}} >1</th>
                                            <td>Total Investment Needed</td>
                                            <td style={{width:'220px'}}>Rs per UL</td>
                                            <td style={{width:'100px'}}>₹ {roundToTwo(con_total_investment_needed)}</td>
                                        </tr>
                                        <tr>
                                            <th >2</th>
                                            <td>Cost of Capital</td>
                                            <td>Rs per UL</td>
                                            <td>₹ {roundToTwo(con_cost_of_capitals)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion" id="accordionNetwork">
                <div className="card">
                    <div className="card-header" id="headingVeriable" data-toggle="collapse" data-target="#collapseNetwork" aria-expanded="false" aria-controls="collapseNetwork" style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                        <div className='row'>
                            <div className="col-md-6" >
                                <h2 className="card-title" style={{fontSize: '20px'}}> C. Network & Service Cost</h2>
                            </div>
                            <div  className="col-md-6" >
                                <h3 style={{float:'right'}}>₹ {roundToTwo(con_network_service_cost)} </h3>
                            </div>
                        </div>
                    </div>
                    <div id="collapseNetwork" className="collapse" aria-labelledby="headingVeriable" data-parent="#accordionNetwork">
                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                            <div  className="col-md-12">
                                <table id="mainTable" className="table padded-table">
                                    {/* <thead>
                                        <tr>
                                            <th >S.NO.</th>
                                            <th >Particulars</th>
                                            <th >Rate / UOM</th>
                                            <th >Cost</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <th style={{width:'50px'}}>1</th>
                                            <td>Admin Cost</td>
                                            <td style={{width:'230px'}}>Rs per UL</td>
                                            <td> ₹ {roundToTwo(con_total_admin_cost)}</td>
                                        </tr>
                                       
                                        <tr>
                                            <th style={{width:'50px'}}>2</th>
                                            <td>Warehouse Cost</td>
                                            <td>Rs per UL </td>
                                            <td>₹ {roundToTwo(con_total_warehouse_cost)}</td>
                                        </tr>
                                        <tr>
                                            <th style={{width:'50px'}}>3</th>
                                            <td>Maintenance Cost</td>
                                            <td>Rs per UL </td>
                                            <td>₹ {roundToTwo(con_total_maintenance_cost)}</td>
                                        </tr>
                                        <tr>
                                            <th style={{width:'50px'}}>4</th>
                                            <td>Losses / Theft Cost</td>
                                            <td>Rs per UL</td>
                                            <td>₹ {roundToTwo(con_total_losses_theft_cost)}</td>
                                        </tr>
                                        <tr>
                                            <th style={{width:'50px'}}>5</th>
                                            <td>Consumable Replacement Cost</td>
                                            <td>Rs per UL</td>
                                            <td>₹ {roundToTwo(con_total_consumable_replmt_cost)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion" id="accordionTransp">
                <div className="card">
                    <div className="card-header" id="headingVeriable" data-toggle="collapse" data-target="#collapseTransp" aria-expanded="false" aria-controls="collapseTransp" style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                        <div className='row'>
                            <div className="col-md-8" >
                                <h2 className="card-title" style={{fontSize: '20px'}}> D. Transportation Cost </h2>
                            </div>
                            <div  className="col-md-4" >
                                {/* <p style={{float:'right', margin:'5px'}}> per bin </p> */}
                                <h3 style={{float:'right'}}>₹ {roundToTwo(con_total_tpt_cost)} </h3>
                            </div>
                        </div>
                    </div>
                    <div id="collapseTransp" className="collapse" aria-labelledby="headingVeriable" data-parent="#accordionTransp">
                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                            <div  className="col-md-12">
                                <table id="mainTable" className="table padded-table">
                                    {/* <thead>
                                        <tr>
                                            <th >S.NO.</th>
                                            <th >Particulars</th>
                                            <th >Rate / UOM</th>
                                            <th >Cost</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <th style={{width:'50px'}}>1</th>
                                            <td>Reverse TPT Cost</td>
                                            <td style={{width:'230px'}}>{con_vehicle_ul_type_val} / {con_vehicle_Load_type_val} </td>
                                            <td style={{width:'100px'}}>₹ {roundToTwo(con_total_reverse_tpt_cost)}</td>
                                        </tr>
                                        <tr>
                                            <th style={{width:'50px'}}>2</th>
                                            <td>Forward TPT Cost</td>
                                            <td>{con_vehicle_ul_type_val} / {con_vehicle_Load_type_val} </td>
                                            <td>₹ {roundToTwo(con_total_forward_tpt_cost)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion" id="accordionMngmt">
                <div className="card">
                    <div className="card-header" id="headingVeriable" data-toggle="collapse" data-target="#collapseMngmt" aria-expanded="false" aria-controls="collapseMngmt" style={{borderColor: 'black', backgroundColor:'#a3f3a3', borderRadius:'5px', border: '2px solid black'}}>
                        <div className='row'>
                            <div className="col-md-8" >
                                <h2 className="card-title" style={{fontSize: '20px'}}> E. Holisol Management Fee </h2>
                            </div>
                            <div  className="col-md-4" >
                                {/* <p style={{float:'right', margin:'5px'}}> per bin </p> */}
                                <h3 style={{float:'right'}}>₹ {roundToTwo(con_total_management_fee_cost)} </h3>
                            </div>
                        </div>
                    </div>
                    <div id="collapseMngmt" className="collapse" aria-labelledby="headingVeriable" data-parent="#accordionMngmt">
                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                            <div  className="col-md-12">
                                <table id="mainTable" className="table padded-table">
                                    {/* <thead>
                                        <tr>
                                            <th >S.NO.</th>
                                            <th >Particulars</th>
                                            <th >Rate / UOM</th>
                                            <th >Cost</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <th style={{width:'50px'}}>1</th>
                                            <td style={{width:'200px'}}>
                                                Management Fee <br/>
                                                <Form.Check
                                                    inline
                                                    label="Transportation (D)"
                                                    name="include_Sunday"
                                                    value="1"
                                                    type= "checkbox" 
                                                    // key={value.outer}
                                                    onChange={handleTransportCon}
                                                    // defaultChecked = {(value.outer) ? true : false  }

                                                    style={{ marginTop:'5px'}}
                                                />
                                            </td>
                                            <td style={{width:'150px'}}>Rs per bin</td>
                                            <td style={{width:'90px'}}>₹ {roundToTwo(con_total_management_fee_cost)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-header"  style={{borderColor: 'black', backgroundColor:'black', borderRadius:'5px', border: '2px solid black'}}>
                <div className='row'>
                    <div className="col-md-8" >
                        <h2 className="card-title" style={{fontSize: '20px', color:'white'}}>TOTAL COST OF LINE FRIENDLY GREEN PACKAGING </h2>
                    </div>
                    <div  className="col-md-4" >
                        <p style={{float:'right', color:'white'}}>Rs per box / per cycle </p>
                        <h3 style={{float:'right', color:'white'}}>₹ {roundToTwo(con_total_cost_of_line)} </h3>
                        
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-6">
        </div>
        <div className="col-md-6">
            <div className="accordion" id="accordionBussView">
                <div className="card">
                    <div className="card-header" id="headingVeriable" data-toggle="collapse" data-target="#collapseBussView" aria-expanded="false" aria-controls="collapseBussView" style={{borderColor: 'black', backgroundColor:'#46B653', borderRadius:'5px', border: '2px solid black'}}>
                        <div className='row'>
                            <div className="col-md-8" >
                                <h2 className="card-title" style={{fontSize: '20px', color:'white'}}> Business View </h2>
                            </div>
                           
                        </div>
                    </div>
                    <div id="collapseBussView" className="collapse" aria-labelledby="headingVeriable" data-parent="#accordionBussView">
                        <div className='card' style={{borderColor: 'black', borderRadius:'1px'}}>
                            <div className="row" style={{margin:'10px'}}>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Revenue / Month (Returnable) (%): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="revenue_month_rn"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="revenue_month_rn"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="revenue_month_rn_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="revenue_month_rn_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Ebidta (%): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="ebita_returnable"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="ebita_returnable"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="ebita_returnable_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="ebita_returnable_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Revenue / Month (Transport) (%):  <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="revenue_month_transport"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="revenue_month_transport"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="revenue_month_transport_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="revenue_month_transport_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Ebidta (%): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="ebita_transport"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="ebita_transport"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="ebita_transport_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="ebita_transport_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Capex (%): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="capex"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="capex"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="capex_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="capex_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Investment (INR): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="investment"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="investment"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="investment_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="investment_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Average Revenue (PM): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="average_revenue"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="average_revenue"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="average_revenue_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="average_revenue_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">ROI (%): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="roi"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="roi"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="roi_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="roi_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">PAT (%): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="pat"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="pat"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="pat_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="pat_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">ATO (Times): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="ato"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="ato"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="ato_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="ato_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '5px'}}>
                                    <div className='row'>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Payback (In Yrs): <span className="required-mark">*</span></label>
                                        </div>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="payback"
                                            //  value={input.monthly_admin_cost}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="payback"
                                            className="form-control" 
                                            />    
                                        </div>  
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Control
                                            type="number"
                                            name="payback_value"
                                            // value={input.monthly_admin_cost_value}
                                            // value={copiedData.monthly_admin_cost === input.monthly_admin_cost ? copiedData.monthly_admin_cost : input.monthly_admin_cost}
                                            // onChange={(event)=>handleFormInput(index, event)}
                                            id="payback_value"
                                            className="form-control"
                                            readOnly 
                                            />    
                                        </div>  
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Commercial