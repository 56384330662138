import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { CustomerData, IndustryVerticle, LocationData, DistrictData, ComponentFamily, LeadSource, SupLocationAdd, OemLocationAdd } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { encode } from "base-64";
import DropdownTool from './DropdownTool';

const InitialForm = () => {

    const { options }             = CustomerData();
    const { IndustryVertOptions } = IndustryVerticle();
    const { locationOptions }     = LocationData();
    const { districtOptions }     = DistrictData();
    const { comFamOptions }       = ComponentFamily();
    const { leadsourceOptions }   = LeadSource();
    const { supplocAddOptions }   = SupLocationAdd();
    const { oemlocAddOptions }    = OemLocationAdd();

    let base64 = require('base-64');
    const usrlist = "/users";
    const leadcreateurl = "/leads/create";
    const navigate = useNavigate();
    const usr_token = localStorage.getItem('authToken');
    //const usr_token = "167283765161839864";
    const usr_name = localStorage.getItem('name');
    const usr_id = localStorage.getItem('usrId');
    const isUserLogin = localStorage.getItem('isAuthenticatedUser');

    const initialUserData = {
        "project_type": "",
        "poc_name": "",   
        "poc_number": "",
        "poc_email_id": "",
        "customer_type": "",
        "service_type": "",
        "supplier_name": "",
        "new_supplier_name": "",
        "supplier_loc": "",
        "new_supplier_loc": "",
        "zone": "",
        "supp_component_family": "",
        "oem_name": "",
        "new_oem_name": "",
        "oem_loc": "",
        "new_oem_loc": "",
        "oem_industry_verticle": "",
        "lead_source": "",
        "min_monthly_volume": "",
        "max_monthly_volume": "",
        "remark": "",
        "cust_industry_verticle": "",
        "lead_req_id": usr_id,
        "lead_req_name": usr_name,
        "usr_token": usr_token,
    }

    const optionarr = [
        { value: '4', label: 'Test1' },
        { value: '5', label: 'Test2' },
        { value: '2', label: 'Test3' },
        { value: '666666', label: 'Other' }
      ]

    const [UsersCreate, setUserscreate] = React.useState(initialUserData);
    const [usermessage, setMessage] = React.useState('');
    const [type, setTopping] = React.useState("");
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [selected, setSelected] = useState("");
    const [lobSelected, setLobSelected] = useState("");
    const [custypeSelected, setcustypeSelected] = useState("");
    const [otherselected, setOtherSelected] = React.useState(optionarr[0]);
    const [zoneselected, setZoneSelected] = React.useState('');

    const handleFormInput = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setUserscreate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    };
   
    const handleSuppNameInput = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "supplier_name": value,
        }))  
    };
    const handleSuppAddInput = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "supplier_loc": value,
        }))  
    };
    const handleSuppLocInput  = (data) => { 
        console.log(data);
        let zonedata = data.value;
        let zone  = zonedata.split(',');
        setZoneSelected(zone[2]);
        console.log(zone[2]);
        setUserscreate(oldValue => ({
            ...oldValue,
            "new_supplier_loc": data,
        }))  
    };
    const handleComFamInput = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "supp_component_family": value,
        }))  
    };
    const handleOEMNameInput = (data) => {
        const test = data.label;
        setOtherSelected(data.value);
        console.log(otherselected);
        setUserscreate(oldValue => ({
            ...oldValue,
            "oem_name": data,
        }))  
    };
    const handleExistOemLocInput = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "oem_loc": value,
        }))  
    };
    const handleNewOemLocInput = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "new_oem_loc": value,
        }))  
    };
    const handleIndVerticleInput = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "oem_industry_verticle": value,
        }))  
    };
    const handleLeadSourceInput = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "lead_source": value,
        }))  
    };
    const handleNRPIndVerticle = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "cust_industry_verticle": value,
        }))  
    };
    const handleFileInput = (e) => {

       console.log(e.target.files); 
       setSelectedFile(e.target.files)
       console.log(selectedFile);
      
    };
    const handleFormSubmit = async (e) => {
        console.log(selectedFile);
        e.preventDefault();

        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`current_packeging_photo`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(UsersCreate));
        console.log(JSON.stringify(UsersCreate));
        console.log(formData);  

        // fetch(ulmsUrl.concat(leadcreateurl), {
        //     method: 'POST',
        //     body: formData,
        // })
        // .then((result) =>
        //     result.json()
        // )
        // .then((result) => {
        //     console.log(result)
        //     if (result.status == true) {
        //         setUserscreate(initialUserData)
        //         sessionStorage.setItem("message", result.message);
        //         navigate("/leads", { replace: true })

        //     } else if (result.status == false) {
        //         setUserscreate(UsersCreate)
        //         setMessage(result.message);
               
        //     }
        // })
        // .catch((form_err) => {
        //     //console.log(form_err)
        // })
    };
    

  return (
    <>
        {usermessage && <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{usermessage}</strong>
            
        </div>}
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">LEAD</h3>
                    </div>
                    <div className="card-body">
                        <form className="row g-3" onSubmit={handleFormSubmit} encType="multipart/form-data">
                            <div className="col-md-4">  
                                <Form.Label style={{marginRight: '15px'}} > LOB :</Form.Label>
                                <Form.Check
                                    inline
                                    label="AUTO"
                                    name="project_type"
                                    value="AUTO"
                                    type= "radio"
                                    onClick={()=>setLobSelected("AUTO")}
                                    onChange={handleFormInput}
                                    id={`inline-radio-2`}
                                />
                                <Form.Check
                                    inline
                                    label="RETAIL"
                                    name="project_type"
                                    value="RETAIL"
                                    type= "radio"
                                    onClick={()=>setLobSelected("RETAIL")}
                                    onChange={handleFormInput}
                                    id={`inline-radio-1`}
                                />
                            </div>
                            { (lobSelected === "AUTO" || lobSelected === "RETAIL") &&
                                <div className="col-md-6">  
                                    <Form.Label style={{marginRight: '15px'}} >Customer Type :</Form.Label>
                                    <Form.Check
                                        inline
                                        label="EXISTING"
                                        name="customer_type"
                                        value="EXISTING"
                                        type= "radio"
                                        onClick={()=>setcustypeSelected("EXISTING")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="NEW"
                                        name="customer_type"
                                        value="NEW"
                                        type= "radio"
                                        onClick={()=>setcustypeSelected("NEW")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-2`}
                                    />
                                </div> }
                            { (custypeSelected == "EXISTING" || custypeSelected == "NEW") && (lobSelected === "AUTO" || lobSelected === "RETAIL") && 
                            <div className='row' style={{marginTop: '10px'}}>
                                <div className="col-md-4">
                                    <Form.Label>Customer Contact Name :</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Customer name"
                                        name="poc_name"
                                        value={UsersCreate.poc_name}
                                        onChange={handleFormInput}
                                        autoComplete='off'
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Form.Label>Customer Contact Number :</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="poc_number"
                                        value={UsersCreate.poc_number}
                                        onChange={handleFormInput}
                                        className="form-control" id="poc_number" placeholder="Enter Customer number" />
                                </div>
                                <div className="col-md-4">
                                    <Form.Label>Customer Contact Email ID :</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="poc_email_id"
                                        value={UsersCreate.poc_email_id}
                                        onChange={handleFormInput}
                                        className="form-control" id="poc_email_id" placeholder="Enter Customer Email" />
                                </div> 
                               { lobSelected === "AUTO" && <div className="col-md-4">  
                                <Form.Label style={{marginRight: '15px', marginTop: '12px'}} >Products :</Form.Label>
                                    <Form.Check
                                        inline
                                        label="FG"
                                        name="products"
                                        value="FG"
                                        type= "radio"
                                        onChange={handleFormInput}
                                        id={`inline-radio-3`}
                                    />
                                    <Form.Check
                                        inline
                                        label="RM"
                                        name="products"
                                        value="RM"
                                        type= "radio"
                                        onChange={handleFormInput}
                                        id={`inline-radio-4`}
                                    /> 
                                </div>  } 
                                { lobSelected === "RETAIL" && <div className="col-md-4">  
                                <Form.Label style={{marginRight: '15px', marginTop: '12px'}} >Products :</Form.Label>
                                    <Form.Check
                                        inline
                                        label="FC"
                                        name="products"
                                        value="FC"
                                        type= "radio"
                                        onChange={handleFormInput}
                                        id={`inline-radio-3`}
                                    />
                                    <Form.Check
                                        inline
                                        label="LMD"
                                        name="products"
                                        value="LMD"
                                        type= "radio"
                                        onChange={handleFormInput}
                                        id={`inline-radio-4`}
                                    /> 
                                </div>  }
                                { lobSelected === "AUTO" &&     
                                <div className="col-md-6">  
                                <Form.Label style={{marginRight: '15px', marginTop: '12px'}} >Services :</Form.Label>
                                    <Form.Check
                                        inline
                                        label="RP"
                                        name="service_type"
                                        value="RP"
                                        type= "radio"
                                        onClick={()=>setSelected("RP")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-3`}
                                    />
                                    <Form.Check
                                        inline
                                        label="NRP"
                                        name="service_type"
                                        value="NRP"
                                        type= "radio"
                                        onClick={()=>setSelected("NRP")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-4`}
                                    />
                                    <Form.Check
                                        inline
                                        label="LS"
                                        name="service_type"
                                        value="LS"
                                        type= "radio"
                                        onClick={()=>setSelected("Logistics Services")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-5`}
                                    />
                                </div> }
                                { lobSelected === "RETAIL" &&     
                                <div className="col-md-6">  
                                <Form.Label style={{marginRight: '15px', marginTop: '12px'}} >Services :</Form.Label>
                                    <Form.Check
                                        inline
                                        label="MFC"
                                        name="service_type"
                                        value="MFC"
                                        type= "radio"
                                        onClick={()=>setSelected("MFC")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-3`}
                                    />
                                    <Form.Check
                                        inline
                                        label="DFC"
                                        name="service_type"
                                        value="DFC"
                                        type= "radio"
                                        onClick={()=>setSelected("DFC")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-4`}
                                    />
                                    <Form.Check
                                        inline
                                        label="HFC"
                                        name="service_type"
                                        value="HFC"
                                        type= "radio"
                                        onClick={()=>setSelected("HFC")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-5`}
                                    />
                                    <Form.Check
                                        inline
                                        label="LMD"
                                        name="service_type"
                                        value="LMD"
                                        type= "radio"
                                        onClick={()=>setSelected("LMD")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-5`}
                                    />
                                    <Form.Check
                                        inline
                                        label="LS"
                                        name="service_type"
                                        value="LS"
                                        type= "radio"
                                        onClick={()=>setSelected("LS")}
                                        onChange={handleFormInput}
                                        id={`inline-radio-5`}
                                    />
                                </div> }
                            </div> }
                           { selected == "RP" && lobSelected === "AUTO" && 
                            <div className='row' style={{marginTop: '5px'}}>
                                { custypeSelected === "EXISTING" ?
                                 <div className="col-md-4" style={{marginTop: '5px'}}>
                                 <label htmlFor="inputState" className="form-label">Supplier Name : </label>
                                 <Select
                                     className="basic-multi-select"
                                     classNamePrefix="select"
                                     name="supplier_name"
                                     options={options}
                                     onChange={handleSuppNameInput}
                                 /> 
                                </div> : <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Supplier Name : </label>
                                    <Form.Control
                                    type="text"
                                    name="new_supplier_name"
                                    value={UsersCreate.new_supplier_name}
                                    onChange={handleFormInput}
                                    id="new_supplier_name"
                                    className="form-control" placeholder="Enter Supplier name" />
                                </div> }
                                { custypeSelected === "EXISTING" ?
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Supplier Location : </Form.Label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="supplier_loc"
                                            options={supplocAddOptions}
                                            onChange={handleSuppAddInput}
                                        />
                                    </div> : <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Supplier Location : </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="new_supplier_loc"
                                        options={districtOptions}
                                        onChange={handleSuppLocInput}
                                    /> 
                                </div>  }
                                { custypeSelected === "NEW" && 
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Zone :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zone"
                                        value={zoneselected}
                                        id="zone"
                                        className="form-control" placeholder="Zone" disabled />
                                </div> }
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                <Form.Label>Component Family: </Form.Label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="supp_component_family"
                                        options={comFamOptions}
                                        onChange={handleComFamInput}
                                    />
                                </div>
                                { custypeSelected === "EXISTING" &&
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">OEM Name : </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="oem_name"
                                        options={locationOptions}
                                        onChange={handleOEMNameInput}
                                    />
                                </div>  }
                                { custypeSelected === "NEW" &&
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">OEM Name : </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="oem_name"
                                        options={locationOptions}
                                        onChange={handleOEMNameInput}
                                    />
                                </div> }
                                { otherselected  === '666666' ?
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">OEM Name : </label>
                                    <Form.Control
                                    type="text"
                                    name="new_oem_name"
                                    value={UsersCreate.new_oem_name}
                                    onChange={handleFormInput}
                                    id="new_oem_name"
                                    className="form-control" placeholder="Enter oem name" />
                                  
                                </div> : '' }    
                                { custypeSelected === "EXISTING" ?
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>OEM Location :</Form.Label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="oem_loc"
                                        options={oemlocAddOptions}
                                        onChange={handleExistOemLocInput}
                                    />
                                </div>   :
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>OEM Location :</Form.Label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="new_oem_loc"
                                        options={districtOptions}
                                        onChange={handleNewOemLocInput}
                                    />
                                </div> } 
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Industry Vertical :</Form.Label>
                                        <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="oem_industry_verticle"
                                        options={IndustryVertOptions}
                                        onChange={handleIndVerticleInput}
                                    />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Monthly Volume (Min.) :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="min_monthly_volume"
                                        value={UsersCreate.min_monthly_volume}
                                        onChange={handleFormInput}
                                        id="min_monthly_volume"
                                        className="form-control" placeholder="Enter monthly volume (min)" />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Monthly Volume (Max.) :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="max_monthly_volume"
                                        value={UsersCreate.max_monthly_volume}
                                        onChange={handleFormInput}
                                        id="max_monthly_volume"
                                        className="form-control" placeholder="Enter monthly volume (max)" />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Lead Source :</Form.Label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="lead_source"
                                        options={leadsourceOptions}
                                        onChange={handleLeadSourceInput}
                                    />
                                </div>  
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Remarks :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="remark"
                                        value={UsersCreate.remark}
                                        onChange={handleFormInput}
                                        id="remark"
                                        className="form-control" placeholder="Enter remarks" />
                                </div>
                                <div className="col-md-4" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                    <Form.Label>Upload Photographs :</Form.Label>
                                    <Form.Control multiple type="file"  
                                    name="current_packeging_photo"
                                    onChange={handleFileInput}  />
                                </div>
                            
                                <div className="col-md-12" style={{marginTop: '15px'}}>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>   
                            </div> }   

                            { (selected == "NRP" ||  selected == "Logistics Services")  && lobSelected === "AUTO" && 
                            <div className='row' style={{marginTop: '5px'}}>
                                { custypeSelected === "EXISTING" ?
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Customer Name : </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="supplier_name"
                                        options={options}
                                        onChange={handleSuppNameInput}
                                    />
                                </div> : <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Customer Name : </label>
                                        <Form.Control
                                        type="text"
                                        name="new_supplier_name"
                                        value={UsersCreate.new_supplier_name}
                                        onChange={handleFormInput}
                                        id="new_supplier_name"
                                        className="form-control" placeholder="Enter Customer name" />
                                    </div>
                                }
                                { custypeSelected === "EXISTING" ?
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                <Form.Label>Customer Location : </Form.Label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="supplier_loc"
                                        options={supplocAddOptions}
                                        onChange={handleSuppAddInput}
                                    />
                                </div> :  <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Customer Location : </Form.Label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="new_supplier_loc"
                                            options={districtOptions}
                                            onChange={handleSuppLocInput}
                                        />
                                </div> }
                                { custypeSelected === "NEW" && 
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Zone :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zone"
                                        value={zoneselected}
                                        id="zone"
                                        className="form-control" placeholder="Zone" disabled />
                                </div>  }
                                          
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Industry Vertical :</Form.Label>
                                        <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="cust_industry_verticle"
                                        options={IndustryVertOptions}
                                        onChange={handleNRPIndVerticle} />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Monthly Volume (Min.) :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="min_monthly_volume"
                                        value={UsersCreate.min_monthly_volume}
                                        onChange={handleFormInput}
                                        id="min_monthly_volume"
                                        className="form-control" placeholder="Enter monthly volume (min)" />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Monthly Volume (Max.) :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="max_monthly_volume"
                                        value={UsersCreate.max_monthly_volume}
                                        onChange={handleFormInput}
                                        id="max_monthly_volume"
                                        className="form-control" placeholder="Enter monthly volume (max)" />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Lead Source :</Form.Label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="lead_source"
                                        options={leadsourceOptions}
                                        onChange={handleLeadSourceInput}
                                    />
                                </div>   
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Remarks :</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="remark"
                                        value={UsersCreate.remark}
                                        onChange={handleFormInput}
                                        id="remark"
                                        className="form-control" placeholder="Enter remarks" />
                                </div>
                                <div className="col-md-4" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                    <Form.Label>Upload Photographs :</Form.Label>
                                    <Form.Control multiple type="file"  
                                    name="current_packeging_photo"
                                    onChange={handleFileInput}  />
                                </div>
                            
                                <div className="col-md-12" style={{marginTop: '15px'}}>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>   
                            </div> }     
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default InitialForm