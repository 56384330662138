import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { ulmsUrl } from "../../Api";
// import { DatePicker } from 'antd';
import { Routes, Route, NavLink, useNavigate, Link } from "react-router-dom";
import {
    LeadCount,
    LOBList,
    ProductList,
    ServicesList,
    BDList, 
    SDList, 
} from "../../apidata/fetchapi";
import moment from "moment/moment";
// const {RangePicker} = DatePicker;
import Loader from '../../loader/Loader';

const Dashboard = () => {

    const navigate = useNavigate();
    let base64 = require('base-64');

    const { lobListData } = LOBList();
    const { productListData }  = ProductList();
    const { servicesListData } = ServicesList();
    const { bdListData }       = BDList();
    const { sdListData }       = SDList();

    const passwordget = localStorage.getItem("password");
    const emailget    = localStorage.getItem("email");

    let permissionmessage = sessionStorage.getItem("permissionmessage");
    
    let lobname = sessionStorage.getItem("lobnamemydash");
    let lobnamemydash = JSON.parse(lobname);

    let productname = sessionStorage.getItem("productnamemydash");
    let productnamemydash = JSON.parse(productname);

    let servicename = sessionStorage.getItem("servicenamemydash");
    let servicenamemydash = JSON.parse(servicename);

    let bdname = sessionStorage.getItem("bdnamemydash");
    let bdnamemydash = JSON.parse(bdname);

    let sdname = sessionStorage.getItem("sdnamemydash");
    let sdnamemydash = JSON.parse(sdname);

    console.log(servicenamemydash);
    
    const usr_token = localStorage.getItem("authToken");
    
    const initialData = {
        lob_name: lobnamemydash,
        product_name: productnamemydash,
        service_name: servicenamemydash,
        bd_name: bdnamemydash,
        sd_name: sdnamemydash,
        usr_token: usr_token,
    };

    const [selected, setSelected] = useState(1);
    const [isActive, setIsActive] = useState(1);
    const [isDashActive, setIsDashActive] = useState(1);
    const [dashboardVal, setDashboardVal] = useState(1);
    const [leadCountData, setLeadCountData] = useState([""]);
    const [dashLeadCountData, setDashLeadCountData] = useState([""]);
    const [dropdownData, setDropdownData] = useState(initialData);
    const [loading, setLoading] = useState(false);

    const handleLOBNameInput = (data) => {
        sessionStorage.removeItem("lobnamemydash");
        sessionStorage.setItem("lobnamemydash", JSON.stringify(data));

        setDropdownData((oldValue) => ({
            ...oldValue,
            lob_name: data,
        }));
        getleadCountData();
    };

    const handleProductInput = (data) => {
        sessionStorage.removeItem("productnamemydash");
        sessionStorage.setItem("productnamemydash", JSON.stringify(data));

        setDropdownData((oldValue) => ({
            ...oldValue,
            product_name: data,
        }));
        getleadCountData();
    };

    const handleServicesInput = (data) => {
        sessionStorage.removeItem("servicenamemydash");
        sessionStorage.setItem("servicenamemydash", JSON.stringify(data));

        setDropdownData((oldValue) => ({
            ...oldValue,
            service_name: data,
        }));
        getleadCountData();
    };

    const handleBDNameInput = (data) => {
        sessionStorage.removeItem("bdnamemydash");
        sessionStorage.setItem("bdnamemydash", JSON.stringify(data));

        setDropdownData((oldValue) => ({
            ...oldValue,
            bd_name: data,
        }));
        getleadCountData();
    }

    const handleSDNameInput = (data) => {
        sessionStorage.removeItem("sdnamemydash");
        sessionStorage.setItem("sdnamemydash", JSON.stringify(data));

        setDropdownData((oldValue) => ({
            ...oldValue,
            sd_name: data,
        }));
        getleadCountData();
    };

    console.log(JSON.stringify(dropdownData));

    const clearFilter = () =>{
        sessionStorage.removeItem("lobnamemydash");
        sessionStorage.removeItem("productnamemydash");
        sessionStorage.removeItem("servicenamemydash");
        sessionStorage.removeItem("bdnamemydash");
        sessionStorage.removeItem("sdnamemydash");

        setDropdownData({
            lob_name: "",
            product_name: "",
            service_name: "",
            bd_name: "",
            sd_name: "",
            usr_token: usr_token,
        })

        getleadCountData();
    }

    const getleadCountData = async (e) => {
        //e.preventDefault();
        console.log(JSON.stringify(dropdownData));
        setLoading(true);
        try {
            fetch(ulmsUrl.concat("/dashboard/my_dashboard_item_count"), {
                method: "POST",
                body: JSON.stringify(dropdownData),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setLoading(false);
                    let result = response.data;
                    setLeadCountData(result);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const getDashleadCountData = async (e) => {
        //e.preventDefault();
        console.log(JSON.stringify(dropdownData));
        setLoading(true);
        try {
            fetch(ulmsUrl.concat("/dashboard/comp_dashboard_item_count"), {
                method: "POST",
                body: JSON.stringify(dropdownData),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setLoading(false);
                    let result = response.data;
                    setDashLeadCountData(result);
                    
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getleadCountData();
        getDashleadCountData();
    }, [dropdownData]);

    console.log(leadCountData);

    let leadcountArr = [];
    let dashleadcountArr = [];
    let i = 0;
    let j = 0;
    {
        leadCountData.map((index) => {
            leadcountArr[i] = index.RowCount;
            i++;
        });
    }

    {
        dashLeadCountData.map((index) => {
            dashleadcountArr[j] = index.RowCount;
            j++;
        });
    }

    console.log(dashleadcountArr);

    const [dates, setDates] = useState([]);

    const optionarr = [
        { value: "4", label: "Test1" },
        { value: "5", label: "Test2" },
        { value: "2", label: "Test3" },
        { value: "666666", label: "Other" },
    ];

    const leadurl = () => {
        navigate("/create_leads");
    }

    const handleClick = (index) => {
        console.log(index);
        setIsActive(index);
    };

    const handleDashClick = (index) => {
        console.log(index);
        setIsDashActive(index);
    };

    const handleleadlits = (data) => {
        sessionStorage.removeItem("leadStatus");
        sessionStorage.setItem("leadStatus", data);
        navigate("/all_leads");
    };

    const comdashleadlists = (data) => {
        sessionStorage.removeItem("leadStatus");
        sessionStorage.setItem("leadStatus", data);
        navigate("/lead_history");
    };

    useEffect(() => {
        if (permissionmessage != "") {
            setTimeout(() => {
                sessionStorage.removeItem("permissionmessage");
            }, 8000);
        }
    }, []);

    const galleryLogin = async () => {

        // let password     = base64.decode(passwordget);
        // let email        = emailget;

        
        // let concatedata = password+'|'+email;

        // let logindata = base64.encode(concatedata);

        // console.log(concatedata);
        // let firstPart = 'http://localhost:3001/';
        // let second = logindata;

        // window.location.href = firstPart + second;

        // window.location = `http://localhost:3001?id=${logindata}`
       

    }

    return (
        <>
            {permissionmessage && (
                <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                >
                    <strong>{permissionmessage}</strong>
                </div>
            )}
            {loading && (
                <>
                    <Loader/>
                </>
            ) }
            <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col-md-12" style={{ marginTop: "-35px" }}>
                    <div className="card widget">
                        {/* <div className="card-header">
                            <h5 className="card-title">Dashboard</h5>
                        </div> */}
                        {/* <div className="row">
                            <label style= {{float: 'left', fontSize: '20px'}} className="col-md-2"><input className="float-left" type="radio" name="dashboardData" id="mydashboard" value="1" defaultChecked={selected === 1}  onClick={()=>setDashboardVal(1)} /> &nbsp; My Dashboard</label>
                            <label style= {{float: 'left', fontSize: '20px'}} className="col-md-3"><input className="float-left" type="radio" name="dashboardData" id="companydashboard" value="2" onClick={()=>setDashboardVal(2)} /> &nbsp; Company Dashboard</label>
                        </div> */}
                        <div className="row">
                            <div className="row g-4">
                                {/* <div className="col-md-3" id="box" >
                                    <NavLink onClick={() => handleDashClick(1)}>
                                        <div
                                            className={
                                                isDashActive == 1
                                                    ? "card bg-primary text-white-90"
                                                    : "card bg-teal text-white-90"
                                            }
                                            style={{ borderRadius: "0px" }} id="button1"
                                        >
                                            <div className="card-body d-flex align-items-center" >
                                                <i className="fa fa-user me-3"></i>
                                                <div className="button1">
                                                    <span style={{ fontSize: "21px" }}>
                                                        {" "}
                                                        My Dashboard
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div> */}
                                {/* <div className="col-md-3" id="box" >
                                    <NavLink onClick={() => handleDashClick(2)}>
                                        <div
                                            className={
                                                isDashActive == 2
                                                    ? "card bg-primary text-white-90"
                                                    : "card bg-teal text-white-90"
                                            }
                                            style={{ borderRadius: "0px" }}
                                        >
                                            <div className="card-body d-flex align-items-center">
                                                <i className="fa fa-users me-3"></i>
                                                <div>
                                                    <span style={{ fontSize: "21px" }}>
                                                        {" "}
                                                        Company Dashboard
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div> */}
                                </div>
                                <div className="row g-4" style={{ marginTop: "-5px" }}>
                                <div className="col-md-3"id="box" >
                                    <NavLink onClick={() => handleClick(1)}>
                                        <div
                                            className={
                                                isActive === 1
                                                    ? "card bg-primary text-white-90"
                                                    : "card bg-teal text-white-90"
                                            }
                                            style={{ borderRadius: "0px" }}
                                        >
                                            <div className="card-body d-flex align-items-center">
                                                <i className="bi bi-box-seam  me-3"></i>
                                                <div>
                                                    <span style={{ fontSize: "21px" }}> Open</span>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-md-3" id="box">
                                    <NavLink onClick={() => handleClick(2)}>
                                        <div
                                            className={
                                                isActive === 2
                                                    ? "card bg-primary text-white-90"
                                                    : "card bg-teal text-white-90"
                                            }
                                            style={{ borderRadius: "0px" }}
                                        >
                                            <div className="card-body d-flex align-items-center">
                                                <i className="bi bi-heart  me-3"></i>
                                                <div>
                                                    <span style={{ fontSize: "21px" }}>Won</span>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col-md-3" id="box" >
                                    <NavLink onClick={() => handleClick(3)}>
                                        <div
                                            className={
                                                isActive === 3
                                                    ? "card bg-primary text-white-90"
                                                    : "card bg-red text-white-90"
                                            }
                                            style={{ borderRadius: "0px" }}
                                        >
                                            <div className="card-body d-flex align-items-center">
                                                <i className="bi bi-wallet2 me-3"></i>
                                                <div>
                                                    <span style={{ fontSize: "21px" }}>Lost</span>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <form className="row " encType="multipart/form-data">                          
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    LOB Name :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={lobListData}
                                    defaultValue={lobnamemydash}
                                    key={lobnamemydash}
                                    onChange={handleLOBNameInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    Product :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={productListData}
                                    defaultValue={productnamemydash}
                                    key={productnamemydash}
                                    onChange={handleProductInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    Services :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={servicesListData}
                                    defaultValue={servicenamemydash}
                                    key={servicenamemydash}
                                    onChange={handleServicesInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    BD Name :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={bdListData}
                                    defaultValue={bdnamemydash}
                                    key={bdnamemydash}
                                    onChange={handleBDNameInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    Sol. Design Exe. :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={sdListData}
                                    defaultValue={sdnamemydash}
                                    key={sdnamemydash}
                                    onChange={handleSDNameInput}
                                />
                            </div>
                            <div className="col-md-2" id="impbutton">
                                <button style={{font_size: '12px', float: 'right'}} type="button" onClick={clearFilter} className="btn btn-danger btn-sm  me-2"><strong>Clear Filter</strong></button>
                                {/* <button style={{font_size: '12px', float: 'right'}} type="button" onClick={leadurl} className="btn btn-success btn-sm  me-2"><strong>Create  Lead</strong></button> */}
                            </div>
                           
                        </form>
                        {isDashActive == 1 && isActive == 1 && (
                            <div className="row g-4">
                                {leadcountArr[0] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => handleleadlits(0)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/all_leads.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Total Leads</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {leadcountArr[0]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leadcountArr[1] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => handleleadlits(2)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/unallocated_leads.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Un-Allocated</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {leadcountArr[1]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-danger"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leadcountArr[2] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => handleleadlits(3)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/mapping.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Request Mapping</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {leadcountArr[2]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-danger"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leadcountArr[3] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => handleleadlits(4)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/pending_leads.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Pending @ SD Head</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {leadcountArr[3]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-danger"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leadcountArr[4] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => handleleadlits(5)}
                                            className="card border-0"
                                        >
                                            <div className="card border-0">
                                                <div className="card-body text-center">
                                                    <div className="display-5">
                                                        <img src="ulms_assets/images/wip.png" alt="logo" />
                                                    </div>
                                                    <h5 className="my-3">WIP</h5>
                                                    <div
                                                        className="text-muted"
                                                        style={{ fontWeight: "bold", fontSize: "20px" }}
                                                    >
                                                        {" "}
                                                        {leadcountArr[4]} Leads
                                                    </div>
                                                    <div
                                                        className="progress mt-3"
                                                        style={{ height: "5px" }}
                                                    >
                                                        <div
                                                            className="progress-bar bg-danger"
                                                            role="progressbar"
                                                            style={{ width: "100%" }}
                                                            aria-valuenow="25"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leadcountArr[8] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => handleleadlits(9)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/delivery.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Transportation</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {leadcountArr[8]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leadcountArr[5] > 0 && (
                                <div className="col-md-3">
                                    <div className="card border-0"  onClick={() => handleleadlits(6)} >
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/tech_sub.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Technical Submitted</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                {leadcountArr[5]} Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                {leadcountArr[6] > 0 && (
                                <div className="col-md-3">
                                    <div className="card border-0" onClick={() => handleleadlits(7)}> 
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/comm_sub.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Commercial Shared</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                {leadcountArr[6]} Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div className="col-md-3">
                                    <div className="card border-0">
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/trial.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Trial Done</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                0 Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card border-0">
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img
                                                    src="ulms_assets/images/implemented.png"
                                                    alt="logo"
                                                />
                                            </div>
                                            <h5 className="my-3">Implemented</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                0 Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {leadcountArr[7] > 0 && (
                                <div className="col-md-3">
                                    <div className="card border-0" onClick={() => handleleadlits(8)}>
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/dropped.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Dropped</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                {leadcountArr[7]} Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div className="col-md-3">
                                    <div className="card border-0">
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/hold.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Hold</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                0 Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card border-0">
                                        <Link to={'https://gallery.holisollogistics.com/'} style={{ fontWeight: "bold", color:'black'}}>
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <i className="bi bi-cursor text-success"></i>
                                                </div>
                                                <h5 className="my-3">Sol. Design Gallary </h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px", marginTop:'20px' }}
                                                >
                                                &nbsp;
                                                </div>
                                                <div className="progress mt-3" style={{ height: "5px" }}>
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isDashActive == 2 && isActive == 1 && (
                            <div className="row g-4" >
                                {dashleadcountArr[0] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => comdashleadlists(0)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/all_leads.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Total Leads</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {dashleadcountArr[0]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {dashleadcountArr[1] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => comdashleadlists(2)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/unallocated_leads.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Un-Allocated</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {dashleadcountArr[1]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {dashleadcountArr[2] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => comdashleadlists(3)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/mapping.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Request Mapping</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {dashleadcountArr[2]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {dashleadcountArr[3] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => comdashleadlists(4)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/pending_leads.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Pending @ SD Head</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {dashleadcountArr[3]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {dashleadcountArr[4] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => comdashleadlists(5)}
                                            className="card border-0"
                                        >
                                            <div className="card border-0">
                                                <div className="card-body text-center">
                                                    <div className="display-5">
                                                        <img src="ulms_assets/images/wip.png" alt="logo" />
                                                    </div>
                                                    <h5 className="my-3">WIP</h5>
                                                    <div
                                                        className="text-muted"
                                                        style={{ fontWeight: "bold", fontSize: "20px" }}
                                                    >
                                                        {" "}
                                                        {dashleadcountArr[4]} Leads
                                                    </div>
                                                    <div
                                                        className="progress mt-3"
                                                        style={{ height: "5px" }}
                                                    >
                                                        <div
                                                            className="progress-bar bg-success"
                                                            role="progressbar"
                                                            style={{ width: "100%" }}
                                                            aria-valuenow="25"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leadcountArr[8] > 0 && (
                                    <div className="col-md-3">
                                        <div
                                            onClick={() => handleleadlits(9)}
                                            className="card border-0"
                                        >
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <img
                                                        src="ulms_assets/images/delivery.png"
                                                        alt="logo"
                                                    />
                                                </div>
                                                <h5 className="my-3">Transportation</h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px" }}
                                                >
                                                    {" "}
                                                    {leadcountArr[8]} Leads
                                                </div>
                                                <div
                                                    className="progress mt-3"
                                                    style={{ height: "5px" }}
                                                >
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {leadcountArr[5] > 0 && (
                                <div className="col-md-3">
                                    <div className="card border-0"  onClick={() => handleleadlits(6)}>
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/tech_sub.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Technical Submitted</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                {leadcountArr[5]} Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                {leadcountArr[6] > 0 && (
                                <div className="col-md-3">
                                    <div className="card border-0"  onClick={() => handleleadlits(7)}>
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/comm_sub.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Commercial Shared</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                {leadcountArr[6]} Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div className="col-md-3">
                                    <div className="card border-0">
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/trial.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Trial Done</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                0 Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card border-0">
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img
                                                    src="ulms_assets/images/implemented.png"
                                                    alt="logo"
                                                />
                                            </div>
                                            <h5 className="my-3">Implemented</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                0 Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {leadcountArr[7] > 0 && (
                                <div className="col-md-3">
                                    <div className="card border-0"  onClick={() => handleleadlits(8)}>
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/dropped.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Dropped</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                {leadcountArr[7]} Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div className="col-md-3">
                                    <div className="card border-0">
                                        <div className="card-body text-center">
                                            <div className="display-5">
                                                <img src="ulms_assets/images/hold.png" alt="logo" />
                                            </div>
                                            <h5 className="my-3">Hold</h5>
                                            <div
                                                className="text-muted"
                                                style={{ fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                0 Leads
                                            </div>
                                            <div className="progress mt-3" style={{ height: "5px" }}>
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: "100%" }}
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card border-0">
                                        <Link to={'https://gallery.holisollogistics.com/'} style={{ fontWeight: "bold", color:'black'}}>
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <i className="bi bi-cursor text-success"></i>
                                                </div>
                                                <h5 className="my-3">Sol. Design Gallary </h5>
                                                <div
                                                    className="text-muted"
                                                    style={{ fontWeight: "bold", fontSize: "20px", marginTop:'20px' }}
                                                >
                                                &nbsp;
                                                </div>
                                                <div className="progress mt-3" style={{ height: "5px" }}>
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                        {((isDashActive == 1 && isActive == 2) || (isDashActive == 1 && isActive == 3) || (isDashActive == 2 && isActive == 2) || (isDashActive == 2 && isActive == 3)) && (
                                <div className="row g-4" style={{ marginTop: "15px" }}>
                                    <div className="col-md-4">
                                        <div className="card border-0">
                                            <div className="card-body text-center">
                                                <div className="display-5">
                                                    <i className="bi bi-cursor text-success"></i>
                                                </div>
                                                <h5 className="my-3">COMING SOON </h5>
                                                <div className="progress mt-3" style={{ height: "5px" }}>
                                                    <div
                                                        className="progress-bar bg-success"
                                                        role="progressbar"
                                                        style={{ width: "100%" }}
                                                        aria-valuenow="25"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
