import React from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { CustomerData, IndustryVerticle, LeadSource, DistrictData, SupplierData, OEMData, StateList } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { encode } from "base-64";
import DropdownTool from './DropdownTool';
import { DatePicker } from 'antd';
import { confirmAlert } from "react-confirm-alert";
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateLead = () => {

    // const { options }          = CustomerData();
    const { IndustryVertOptions } = IndustryVerticle();
    const { leadsourceOptions }   = LeadSource();
    const { districtOptions }     = DistrictData();
    const { supplierDropdown }    = SupplierData();
    const { oemDropdown }         = OEMData();
    const { stateListData }       = StateList();

    let base64 = require('base-64');
    const usrlist = "/users";
    const leadcreateurl = "/leads/create";
    const navigate = useNavigate();
    const usr_token = localStorage.getItem('authToken');
    //const usr_token = "167283765161839864";
    const usr_name = localStorage.getItem('name');
    const usr_id = localStorage.getItem('usrId');
    const isUserLogin = localStorage.getItem('isAuthenticatedUser');

    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    const initialUserData = {
        "project_type": "",
        "service_type": "",
        "entity_name": "",
        "bd_head_id": "",
        "date": defaultValue,
        "supplier_name": "",
        "oem_name":"",
        "new_oem_name":"",
        "new_oem_pincode":"",
        "new_oem_loc":"",
        "new_supplier_name": "",
        "new_pincode": "",
        "pincode": "",
        "supplier_loc": "",
        "new_supplier_loc": "",
        "lead_source": "",
        "cust_industry_verticle": "",
        "oem_industry_verticle": "",
        "lead_description": "",
        "poc_data": "",
        "img_data": "",
        "lead_req_id": usr_id,
        "lead_req_name": usr_name,
        "usr_token": usr_token,
    }

    const initialPincodeData = {
        "pincode": "",
        "city_name": "",
        "district_name": "",
        "state_name": "",
        "lead_req_id": usr_id,
        "lead_req_name": usr_name,
        "usr_token": usr_token,
    }

    const optionarr = [
        { value: '4', label: 'Test1' },
        { value: '5', label: 'Test2' },
        { value: '2', label: 'Test3' },
        { value: '666666', label: 'Other' }
      ]

    // const [UsersCreate, setUserscreate] = React.useState(initialUserData);
    const [usermessage, setMessage] = React.useState('');
    const [type, setTopping] = React.useState("");
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [selected, setSelected] = useState("");
    const [lsSelected, setLSSelected] = useState('');
    const [lobSelected, setLobSelected] = useState("");
    const [custypeSelected, setcustypeSelected] = useState("");
    const [otherselected, setOtherSelected] = React.useState(optionarr[0]);
    const [oemOtherSelected, setOemOtherSelected] = React.useState(optionarr[0]);
    const [pincodeSelected, setPincodeSelected] = React.useState('');
    const [oemPincodeSelected, setOemPincodeSelected] = React.useState('');
    const [productOption, setProductOptions] = React.useState([""]);
    const [productselected, setProductSelected] = React.useState("");
    const [serServiceOption, setServiceOptions] = React.useState([""]);
    const [setLSServiceOption, setLSServiceOptions] = React.useState([""]);
    const [customerData, setCustomerData] = React.useState([""]);
    const [oemData, setOemData] = React.useState([""]);
    const [bdHeadData, setBdHeadData] = React.useState([""]);
    const [customerName, setCustomerName] = React.useState('');
    const [oemName, setOemName] = React.useState('');
    const [pocData, setPOCData] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [createPincode, setCreatePincode] = React.useState(initialPincodeData);

    const [inputFields, setInputFields] = useState([
        { poc_name: '', poc_number: '', poc_email_id: '', designation: ''}
    ]);

    const [UsersCreate, setUserscreate] = useState(
        {  
            project_type: "",
            products: "",
            service_type: "",
            cycle_time: "",
            entity_name: "",
            date: defaultValue,
            supplier_name: "",
            oem_name: "",
            new_oem_name: "",
            new_oem_pincode: "",
            new_oem_loc: "",
            new_supplier_name: "",
            new_pincode: "",
            pincode: "",
            supplier_loc: "",
            new_supplier_loc: "",
            lead_source: "",
            cust_industry_verticle: "",
            oem_industry_verticle: "",
            lead_description: "",
            img_data: "",
            poc_data: [{
                // id: 1,
                poc_name: "", 
                poc_number: '', 
                poc_email_id: '', 
                designation: '',
                
            }],
            lead_req_id: usr_id,
            lead_req_name: usr_name,
            usr_token: usr_token,
        
        }
    );

    // state for checkbox
    const [isMfcChecked,setIsMfcChecked] = useState(false);
    const [isDfcChecked,setIsDfcChecked] = useState(false);
    const handleFormInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            if(name == 'new_pincode'){
              
                getPincodeData(value);
            }
            if(name == 'new_oem_pincode'){
              
                getOemPincodeData(value);
            }
            setUserscreate(oldValue => ({
                ...oldValue,
                [name]: value,
                "multi_ls": lsSelected,
            }))
        }
    };
    console.log('daaaa',UsersCreate);

    const getProductData = async (e) => {
        
        if (e && e.target) {
            const { name, value } = e.target;
            setUserscreate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }

        let project_type = e.target.value;

        getBDHeadData(project_type, productselected, selected);
    
        if(project_type != ""){

            fetch(ulmsUrl.concat('/mst/product_list'), {
                method: 'POST',
                body: JSON.stringify({ usr_token, project_type }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                setProductOptions(response.data)
            });
        }
    }

    const getServiceData = async (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setUserscreate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }

        let project_type = lobSelected;
        let products  = e.target.value;

        getBDHeadData(project_type, products, selected);

        if(products != ""){
        
            fetch(ulmsUrl.concat('/mst/service_list'), {
                method: 'POST',
                body: JSON.stringify({ usr_token, project_type, products }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                
                let result = response.data;
                setServiceOptions(response.data)
                console.log('LS',response.include_ls)
                setLSServiceOptions(response.include_ls)
            });
        }
    }

    const getBDHeadData = async (lobdata, productdata, data) => {
      
        let project_type = lobdata;
        let products  = productdata;
        let service_type = data;
        console.log(JSON.stringify({ usr_token, project_type, products, service_type }));

        if(service_type != "" ){
        
            fetch(ulmsUrl.concat('/mst/bdhead_list_by_permission'), {
                method: 'POST',
                body: JSON.stringify({ usr_token, project_type, products, service_type }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                
                let result = response.data;
                console.log('LssS',response.data)
                setBdHeadData(response.data)

            });
        }
    }

    console.log('bd name',selected);
    
    useEffect(() => {

        if(lobSelected != ""){
            getProductData();
        }
        if(productselected != ""){
            getServiceData();
        }

        if(lobSelected != "" && productselected != "" && selected != ""){
            getBDHeadData();
        }
        
    }, []);
  
    const handleSuppNameInput = (data) => { 
        console.log(data);
        setCustomerName(data.label);
        setCustomerData([""]);
        let supp_id = data.value;
        setOtherSelected(data.value);
        setUserscreate(oldValue => ({
            ...oldValue,
            "supplier_name": data,
        }))  

        if(supp_id != "" && supp_id != '666666'){
            console.log(JSON.stringify({ supp_id }))

            fetch(ulmsUrl.concat('/holibook/supplier_info'), {
                method: 'POST',
                body: JSON.stringify({ supp_id }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                setCustomerData(response.data)
                setUserscreate(oldValue => ({
                    ...oldValue,
                    "entity_name" : result.entity_name,
                    "pincode"     : result.pin_code,
                    "supplier_loc": result.address,
                    "cust_industry_verticle": result.cust_industry_verticle,
                })) 
            });
        } 

        if(supp_id != ""){
        
            fetch(ulmsUrl.concat('/mst/poc_details'), {
                method: 'POST',
                body: JSON.stringify({ supp_id, usr_token }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                console.log(result);
                setPOCData(response.data)
                setInputFields(result)
                let data = [{ poc_name: result.poc_name, poc_number: result.poc_number, poc_email_id: result.poc_email_id, designation: result.designation}];
                setUserscreate(oldValue => ({
                    ...oldValue,
                    "poc_data": data,
                })) 
                // setUserscreate({...inputFields, poc_data})
                // setInputFields(oldValue => ([
                //     ...oldValue,
                //     "poc_name"     : result.poc_name,
                //     "poc_number"   : result.poc_number,
                //     "poc_email_id" : result.poc_email_id,
                //     "designation"  : result.designation,
                // ])) 
            });
        } 

    };

    const handleOemNameInput = (data) => {
        let oem_id = data.value;
        setOemName(data.label);
        setOemData([""]);
        setOemOtherSelected(data.value);
        setUserscreate(oldValue => ({
            ...oldValue,
            "oem_name": data,
        })) 

        if(oem_id != ""  && oem_id != '666666'){
            console.log(JSON.stringify({ oem_id }))

            fetch(ulmsUrl.concat('/holibook/oem_info'), {
                method: 'POST',
                body: JSON.stringify({ oem_id }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                setOemData(response.data)
                setUserscreate(oldValue => ({
                    ...oldValue,
                    "oem_entity_name" : result.entity_name,
                    "oem_pincode"     : result.pin_code,
                    "oem_loc": result.address,
                    "oem_industry_verticle": result.oem_industry_verticle,
                })) 
            });
        } 
    }

    const clearValue = () => {
        setUserscreate({
          ...UsersCreate,
          'new_pincode': ''
        });
     };

    const getPincodeData = async(pincode) => {
        setPincodeSelected("");
        if(pincode.length == 6){

            console.log(JSON.stringify({ pincode }))

            fetch(ulmsUrl.concat('/holibook/pincode_district'), {
                method: 'POST',
                body: JSON.stringify({ pincode }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                if(result != ''){
                   setPincodeSelected({value: result.value, label: result.value})
                }else{

                    confirmAlert({
                        title: "Pincode Alert",
                        message: "This pincode is not available. Please click on 'Add New Pincode' to add",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => <p data-bs-toggle="modal" data-bs-target="#createpincodemodal"></p>
                          },
                          {
                            label: "Cancel"
                            // onClick: () => alert("Click No")
                          }
                        ]
                    });
                   setPincodeSelected('1');
                }
                setUserscreate(oldValue => ({
                    ...oldValue,
                    "new_supplier_loc" : {value: result.value, label: result.value},
                })) 
               console.log(result);
            });
        } 
    }

    const clearOEMValue = () => {
        setUserscreate({
          ...UsersCreate,
          'new_oem_pincode': ''
        });
     };

    const getOemPincodeData = async(pincode) => {
        setOemPincodeSelected("");
        if(pincode.length == 6){

            console.log(JSON.stringify({ pincode }))

            fetch(ulmsUrl.concat('/holibook/pincode_district'), {
                method: 'POST',
                body: JSON.stringify({ pincode }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                if(result != ''){
                    setOemPincodeSelected({value: result.value, label: result.value})
                }else{
                    confirmAlert({
                        title: "Pincode Alert",
                        message: "This pincode is not available. Please click on 'Add New Pincode' to add ",
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => <p data-bs-toggle="modal" data-bs-target="#createpincodemodal"></p>
                          },
                          {
                            label: "Cancel"
                            // onClick: () => alert("Click No")
                          }
                        ]
                    });
                    setOemPincodeSelected('1');
                }
                setUserscreate(oldValue => ({
                    ...oldValue,
                    "new_oem_loc" : {value: result.value, label: result.value},
                })) 
               console.log(result);
            });
        } 
    }



    const handleLSInput = (e) => {
        const { name, value } = e.target;
        
        if (e && (e.target.checked)) {
            setLSSelected(oldval => ([...oldval, value]))

        }else {
            setLSSelected(lsSelected =>
                lsSelected.filter(item => item !== value)
            );
        }
    }
    const handleLSInput2 = (e) => {
        
        const { name, value } = e.target;
        //console.log('checkbox',lsSelected);
      
        if (e && (e.target.checked)) {

            if(value == 'TPT'){

                setLSSelected(oldval => ([...oldval, value]))
                
            }else{

                let removedChkbox = (value=='MFC') ? 'DFC' : 'MFC';
                console.log('ddd',removedChkbox);
               
                document.getElementById(`chbk_${removedChkbox}`).checked = false;
    
                let oldValues = [...lsSelected];
                let indexOfRemovedChkbx = oldValues.indexOf(removedChkbox);
                if(indexOfRemovedChkbx>=0){
                     oldValues.splice(indexOfRemovedChkbx,1);
                }
                oldValues.push(value);
                setLSSelected(oldValues)

            }

        }else {
            setLSSelected(lsSelected =>
                lsSelected.filter(item => item !== value)
            );
        }
    }

    const addFields = () => {
        const newData = {
            ...UsersCreate,
            poc_data: [
              ...UsersCreate.poc_data,
              {
                poc_name: "",
                poc_number: "",
                poc_email_id: "",
                designation: ""
              }
            ]
          };
          setUserscreate(newData);

    }
    const handleChange = (index, event) => {
       const { name, value } = event.target;
    //    const list = [...inputFields];
    //    list[index][name] = value;

       const updatedData = {...UsersCreate};
       updatedData.poc_data[index][name] = value;
       setUserscreate(updatedData);

    }
   
    const handleNRPIndVerticle = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "cust_industry_verticle": value,
        }))  
    };
    const handleOEMIndVerticle = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "oem_industry_verticle": value,
        }))  
    };
    const handleLeadSourceInput = (value) => { 
        setUserscreate(oldValue => ({
            ...oldValue,
            "lead_source": value,
        }))  
    };
    const handleSuppLocInput = (value) => {
        setUserscreate(oldValue => ({
            ...oldValue,
            "new_supplier_loc": value,
        })) 
    }
    const handleOEMLocInput = (value) => {
        setUserscreate(oldValue => ({
            ...oldValue,
            "new_oem_loc": value,
        })) 
    }
    const handleFileInput = (e) => {
        console.log(e.target.files); 
        setSelectedFile(e.target.files)
        console.log(selectedFile);
    };

    const removeInputFields = (index, event) => {
        const newData = {
            ...UsersCreate,
            poc_data: UsersCreate.poc_data.filter((item, i) => i !== index)
        };
        setUserscreate(newData);
    }

    const handleFormSubmit = async (e) => {
         setLoading(true)
        e.preventDefault();

        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`current_packeging_photo`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(UsersCreate));
        console.log(JSON.stringify(UsersCreate));
        console.log(formData);  

        fetch(ulmsUrl.concat(leadcreateurl), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setUserscreate(initialUserData)
                sessionStorage.setItem("createmessage", result.message);
                setLoading(false)
                navigate("/all_leads", { replace: true })

            } else if (result.status == false) {
                setLoading(false)
                setUserscreate(UsersCreate)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    function handleCloseModal(){            
        document.getElementById("createpincodemodal").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop")
        .forEach(el => el.classList.remove("modal-backdrop"));
        document.body.style.overflow = 'unset';
     }

    const handlePincodeInput = (e) => {
        
        if (e && e.target) {
            const { name, value } = e.target;
            setCreatePincode(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    }

    const handlePincodeStateInput = (value) => {
        setCreatePincode(oldValue => ({
            ...oldValue,
            "state_name": value,
        })) 
    }

    const handleFormPincode = async (e) => {
        setLoading(true)
        e.preventDefault();

        console.log(JSON.stringify(createPincode));

       fetch(ulmsUrl.concat('/holibook/pincode_create'), {
           method: 'POST',
           body: JSON.stringify(createPincode),
           headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + base64.encode(username + ":" + password)
           }
       })
       .then((result) =>
           result.json()
       ) 
       .then((result) => {
           console.log(result)
           if (result.status == true) {
               setCreatePincode(initialPincodeData)
               toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
               });
               setLoading(false)
               handleCloseModal();
              
           } else if (result.status == false) {
               setLoading(false)
               setCreatePincode(createPincode)
               toast.error(result.message, {
                   position: toast.POSITION.TOP_RIGHT,
                   toastId: 'success1',
               });
              
           }
       })
       .catch((form_err) => {
           //console.log(form_err)
       })
    }

  return (
    <>
    
     {/* {usermessage && <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{usermessage}</strong>    
        </div>} */}
        <ToastContainer />
        {loading && (
         <>
            <Loader/>
         </>
        ) }
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">LEAD CREATE</h3>
                    </div>
                    <div className="card-body">
                        <form className="row g-3" onSubmit={handleFormSubmit} encType="multipart/form-data">
                            <div className="col-md-6">  
                                <Form.Label style={{marginRight: '15px'}} >Select LOB : <span className="required-mark">*</span></Form.Label>
                                <Form.Check
                                    inline
                                    label="AUTO"
                                    name="project_type"
                                    value="AUTO"
                                    type= "radio"
                                    onClick={()=>setLobSelected("AUTO")}
                                    onChange={getProductData}
                                    id={`inline-radio-2`}
                                />
                                <Form.Check
                                    inline
                                    label="RETAIL"
                                    name="project_type"
                                    value="RETAIL"
                                    type= "radio"
                                    onClick={()=>setLobSelected("RETAIL")}
                                    onChange={getProductData}
                                    id={`inline-radio-1`}
                                />
                               
                            </div>
                            {customerData != '' ?
                            <div className="col-md-6" style={{backgroundColor: '#ADD8E6'}}>
                                <h8>{ customerName != '' && <> <strong> Supplier Name : </strong> {customerName}, </> } 
                                {customerData != '' &&  <> <strong> Entity Name : </strong> {customerData.entity_name}, <strong> Pincode : </strong> {customerData.pin_code}</> }</h8><br/>
                                <h8>{customerData != '' && <> <strong> Location : </strong> {customerData.address}</> } </h8>
                            </div> :
                            <div className="col-md-6">

                            </div>}

                            { (lobSelected === "AUTO" || lobSelected === "RETAIL") && <div className="col-md-6">  
                                <Form.Label style={{marginRight: '15px'}} >Select Products :<span className="required-mark">*</span></Form.Label>
                                    {productOption.map(value => (
                                        <Form.Check
                                            inline
                                            key={value.id}
                                            label={value.full_name}
                                            name="products"
                                            value={value.name}
                                            type= "radio"
                                            onClick={()=>setProductSelected(value.name)}
                                            onChange={getServiceData}
                                        />
                                    ))}
                            </div> }
                            {(oemData != '' && selected == 'RP') ?
                            <div className="col-md-6" style={{backgroundColor: '#90EEBF'}}>
                                <h8>{ oemData != '' && <> <strong> OEM Name : </strong> {oemName}, </> } 
                                {oemData != '' &&  <> <strong>OEM Entity Name : </strong> {oemData.entity_name}, <strong>OEM Pincode : </strong> {oemData.pin_code}</> }</h8><br/>
                                <h8>{oemData != '' && <> <strong>OEM Location : </strong> {oemData.address}</> } </h8>
                            </div>:
                            <div className="col-md-6">

                            </div>} 

                            { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && productselected != "" && productselected != "LS") && <div className="col-md-12">  
                            <Form.Label style={{marginRight: '15px'}} >Select Services :<span className="required-mark">*</span></Form.Label>
                                {serServiceOption.map(value => (
                                    <Form.Check
                                        inline
                                        key={value.id}
                                        label={value.full_name}
                                        name="service_type"
                                        style={{marginTop: '5px'}}
                                        value={value.name}
                                        type= "radio"
                                        onClick={()=>{setSelected(value.name); getBDHeadData(lobSelected, productselected, value.name); }}
                                        onChange={handleFormInput}
                                    />
                                ))}
                            </div> } 
                            { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && productselected != "" && productselected == "LS" ) && <div className="col-md-12">  
                            <Form.Label style={{marginRight: '15px'}} >Select Services :<span className="required-mark">*</span></Form.Label>
                                {serServiceOption.map(value => (
                                    <Form.Check
                                        inline
                                        key={value.id}
                                        label={value.full_name}
                                        name="service_type"
                                        value={value.name}
                                        type= "checkbox"
                                        onClick={()=>{setSelected(value.name); getBDHeadData(lobSelected, productselected, value.name); }}
                                       // onClick={()=>setSelected(oldval => ([...oldval, value.name]))}
                                        onChange={handleLSInput}
                                    />
                                ))}
                            </div> } 
                            { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && (selected == 'RP' || selected == 'NRP') && productselected != "" && productselected != "LS" ) && <div className="col-md-12">  
                            <Form.Label style={{marginRight: '15px'}} >Including LS service :</Form.Label>
                                {setLSServiceOption.map(value => (
                                    <Form.Check
                                        inline
                                        key={value.id}
                                        label={value.full_name}
                                        name="service_type"
                                        value={value.name}
                                        type= "checkbox"
                                       // onClick={()=>setSelected(oldval => ([...oldval, value.name]))}
                                        onChange={handleLSInput2}
                                        id={'chbk_'+value.name}
                                
                                    />
                                ))}
                            </div> }
                            { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && productselected != "" && selected != '') && <div className="col-md-12">  
                            <Form.Label style={{marginRight: '15px'}} >Select Lead Approver :<span className="required-mark">*</span></Form.Label>
                                {bdHeadData.map((data, index) => (
                                    <Form.Check
                                        inline
                                        key={data.value}
                                        label={data.label}
                                        name="bd_head_id"
                                        style={{marginTop: '5px'}}
                                        value={data.value}
                                        type= "radio"
                                        onChange={handleFormInput}
                                    />
                                ))}
                            </div> }
                            { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && (selected != "" || lsSelected != "")) && 
                                <div className='row' style={{marginTop: '5px'}}>
                                    {selected == 'RP' && 
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Supplier Name :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="supplier_name"
                                            options={supplierDropdown}
                                            onChange={handleSuppNameInput}
                                        />
                                    </div> }
                                    {selected == 'NRP' && 
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Customer Name :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="supplier_name"
                                            options={supplierDropdown}
                                            onChange={handleSuppNameInput}
                                        />
                                    </div>}
                                    { otherselected == 666666 ?
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Supplier Name :<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="text"
                                            name="new_supplier_name"
                                            value={UsersCreate.new_supplier_name}
                                            onChange={handleFormInput}
                                            id="new_supplier_name"
                                            className="form-control" placeholder="Enter customer name" />      
                                    </div> : '' }
                                    { otherselected == 666666 ?
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Supplier Pincode: </label>
                                        {pincodeSelected == 1 && 
                                            <Link><strong style={{font_size: '6px', float: 'right', marginBottom: '1px', color:'blue'}} data-bs-toggle="modal" data-bs-target="#createpincodemodal" onClick={clearValue}>Add New Pincode</strong></Link>
                                        }
                                        <Form.Control
                                            type="text"
                                            name="new_pincode"
                                            value={UsersCreate.new_pincode}
                                            onChange={handleFormInput}
                                            id="new_pincode"
                                            className="form-control" placeholder="Enter Pincode" />      
                                    </div> : '' }
                                    { otherselected == 666666 ?
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Select Supplier Location :<span className="required-mark">*</span> </label> 
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="new_supplier_loc"
                                            options={districtOptions}
                                            defaultValue={pincodeSelected}
                                            key={pincodeSelected}
                                            onChange={handleSuppLocInput}
                                        />
                                    </div> :  ''}
                                    { otherselected == 666666 ?
                                      <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Label>Industry Vertical :<span className="required-mark">*</span></Form.Label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="cust_industry_verticle"
                                            options={IndustryVertOptions}
                                            onChange={handleNRPIndVerticle} />
                                     </div> : 
                                     <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Label>Industry Vertical :<span className="required-mark">*</span></Form.Label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="cust_industry_verticle"
                                            options={IndustryVertOptions}
                                            key={customerData.cust_industry_verticle}
                                            defaultValue={customerData.cust_industry_verticle}
                                            onChange={handleNRPIndVerticle} />
                                     </div>
                                    }
                                    { selected == 'RP' && productselected !== "LS" &&
                                      <>
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">OEM Name :<span className="required-mark">*</span> </label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="oem_name"
                                                options={oemDropdown}
                                                onChange={handleOemNameInput}
                                            />
                                        </div>
                                        { oemOtherSelected == 666666 ?
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Enter Oem Name :<span className="required-mark">*</span> </label>
                                            <Form.Control
                                                type="text"
                                                name="new_oem_name"
                                                value={UsersCreate.new_oem_name}
                                                onChange={handleFormInput}
                                                id="new_oem_name"
                                                className="form-control" placeholder="Enter oem name" />      
                                        </div> : '' }
                                        { oemOtherSelected == 666666 ?
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Oem Pincode:</label>
                                            {oemPincodeSelected == 1 && 
                                                <Link><strong style={{font_size: '6px', float: 'right', marginBottom: '1px', color:'blue'}} data-bs-toggle="modal" data-bs-target="#createpincodemodal" onClick={clearOEMValue}>Add New Pincode</strong></Link>
                                            }
                                            <Form.Control
                                                type="text"
                                                name="new_oem_pincode"
                                                value={UsersCreate.new_oem_pincode}
                                                onChange={handleFormInput}
                                                id="new_oem_pincode"
                                                className="form-control" placeholder="Enter oem Pincode" />      
                                        </div> : '' }
                                        { oemOtherSelected == 666666 ?
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">Select OEM Location :<span className="required-mark">*</span> </label> 
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="new_oem_loc"
                                                options={districtOptions}
                                                defaultValue={oemPincodeSelected}
                                                key={oemPincodeSelected}
                                                onChange={handleOEMLocInput}
                                            />
                                        </div> :  ''}
                                        { oemOtherSelected == 666666 ?
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <Form.Label>Oem Industry Vertical :<span className="required-mark">*</span></Form.Label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="oem_industry_verticle"
                                                options={IndustryVertOptions}
                                                onChange={handleOEMIndVerticle} />
                                        </div> : 
                                        <div className="col-md-4" style={{marginTop: '5px'}}>
                                            <Form.Label>Oem Industry Vertical :<span className="required-mark">*</span></Form.Label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="oem_industry_verticle"
                                                options={IndustryVertOptions}
                                                key={oemData.oem_industry_verticle}
                                                defaultValue={oemData.oem_industry_verticle}
                                                onChange={handleOEMIndVerticle} />
                                        </div>
                                        }
                                      </>
                                    }
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Label>Lead Source :<span className="required-mark">*</span></Form.Label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="lead_source"
                                            options={leadsourceOptions}
                                            onChange={handleLeadSourceInput}
                                        />
                                    </div> 
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Label>First Contact Date :<span className="required-mark">*</span></Form.Label>
                                        <Form.Control
                                        type="date"
                                        name="date"
                                        //value={UsersCreate.date}
                                        onChange={handleFormInput}
                                        id="date"
                                        //value={UsersCreate.toISOString().split("T")[0]}
                                        defaultValue={defaultValue}
                                        className="form-control"  />
                                    </div>
                                    <div className="col-md-4"  style={{marginTop: '5px'}}>
                                        <Form.Label>Lead Description :</Form.Label>
                                        <Form.Control 
                                            className="form-control"
                                            as="textarea"
                                            rows={1}
                                            placeholder="Enter Description"
                                            name="lead_description"
                                            value={UsersCreate.lead_description}
                                            onChange={handleFormInput}
                                            autoComplete='off'
                                        />   
                                    </div>
                                </div>
                            }
                            { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && (selected != "" || lsSelected != "")) &&
                                <>  
                                <div id="accordion" style={{marginTop:'10px'}}>
                                    <div className="card">
                                        {UsersCreate.poc_data.map((input, index) => {
                                            return (
                                            <>
                                                <div className="card-header row" id={"heading"+index} data-toggle="collapse" data-target={"#collapse"+index} aria-expanded="true" aria-controls={"collapse"+index} style={{backgroundColor:'#46B653'}}>
                                                    <div className="col-md-6" >
                                                        <h3 className="mb-0" >
                                                            <span className="btn btn collapsed" style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                                                             POC DATA
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    <div className="col-md-6">
                                                        {(UsersCreate.poc_data.length!==1)? <i className="btn btn-danger" style={{float: 'right', marginLeft:'10px'}} onClick={(event)=>removeInputFields(index,event)}>Remove</i>:''}
                                                        {UsersCreate.poc_data.length - 1 === index &&
                                                        <i className="btn btn-primary" onClick={addFields} style={{float: 'right'}}>Add More</i> }
                                                    </div>
                                                </div>
                                                <div id={"collapse"+index} className={index === 0 ? "collapse show" : "collapse" } aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="card-body row">
                                                        <div className="col-md-4">
                                                            <Form.Label>Customer POC Name : <span className="required-mark">*</span></Form.Label>
                                                            <Form.Control
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Enter Customer name"
                                                                name="poc_name"
                                                                value={input.poc_name}
                                                                // defaultValue={pocData.poc_name}
                                                                onChange={(event)=>handleChange(index, event)}
                                                                autoComplete='off'
                                                            />
                                                        </div>
                                                        <div className="col-md-4" >
                                                            <Form.Label>Customer POC Number : <span className="required-mark">*</span></Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="poc_number"
                                                                value={input.poc_number}
                                                                // defaultValue={pocData.poc_number}
                                                                onChange={(event)=>handleChange(index, event)}
                                                                className="form-control" id="poc_number" placeholder="Enter Customer number" />
                                                        </div>
                                                        <div className="col-md-4" >
                                                            <Form.Label>Customer POC Email ID :</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name="poc_email_id"
                                                                value={input.poc_email_id}
                                                                // defaultValue={pocData.poc_email_id}
                                                                onChange={(event)=>handleChange(index, event)}
                                                                className="form-control" id="poc_email_id" placeholder="Enter Customer Email" />
                                                        </div> 
                                                        <div className="col-md-4"  style={{marginTop: '10px'}}>
                                                            <Form.Label>Customer POC Designation:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="designation"
                                                                value={input.designation}
                                                                // defaultValue={pocData.designation}
                                                                onChange={(event)=>handleChange(index, event)}
                                                                className="form-control" id="poc_email_id" placeholder="Enter Designation" />
                                                        </div>   
                                                    </div>
                                                </div>
                                            </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                     <div className="col-md-5" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                        <Form.Label>Upload Photographs (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                                        <Form.Control multiple type="file"  
                                        name="current_packeging_photo"
                                        onChange={handleFileInput}  />
                                     </div>
                                     {/* <div className="col-md-6 " style={{float: 'right', marginTop: '25px'}}>
                                       
                                     </div> */}
                                     <div className="col-md-12" style={{marginTop: '15px'}}>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                     </div>  
                                </> 
                            } 
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="createpincodemodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add Pincode</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <Form onSubmit={handleFormPincode} encType="multipart/form-data">
                        <div className="modal-body"> 
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>Pincode:</Form.Label>
                                <Form.Control multiple type="number"  
                                name="pincode"
                                value={createPincode.remark}
                                onChange={handlePincodeInput}
                                required
                                />
                            </div>
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>City:</Form.Label>
                                <Form.Control multiple type="text"  
                                name="city_name"
                                value={createPincode.remark}
                                onChange={handlePincodeInput}
                                required
                                />
                            </div>
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>District:</Form.Label>
                                <Form.Control multiple type="text"  
                                name="district_name"
                                value={createPincode.remark}
                                onChange={handlePincodeInput}
                                required
                                />
                            </div>
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>State:</Form.Label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="state_name"
                                    options={stateListData}
                                    onChange={handlePincodeStateInput}
                                    required
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            <button className="btn btn-primary" type='submit'  >Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </>
  )
}

export default CreateLead