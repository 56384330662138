import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import PrivacyPolicy from "./PrivacyPolicy";
import Support from "./Support";

const UnAuthPages = () => {

  const location = useLocation();
  const navigate = useNavigate();

  if(location.pathname == '/' || location.pathname == '/reset_password' || location.pathname == '/privacy_policy' || location.pathname == '/support'){

  }else{
      return (
        <div>
           <Login/>
        </div>   
    );
  }

  return (
    <React.Fragment>
    <section>
      <Routes>
          <Route path="/" element={<Login />} />   
          <Route path="/reset_password" element={<ForgotPassword />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />   
          <Route path="/support" element={<Support />} />   
      </Routes>
    </section>
  </React.Fragment>
  )

}

export default UnAuthPages