import React from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { UserPermissionList } from '../../apidata/fetchapi';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import { confirm } from "react-confirm-box";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BackendLeadView = () => {

    let base64 = require('base-64');
    const aRef = useRef(null);
   
    const navigate = useNavigate();
    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const user_id = localStorage.getItem('usrId');
    const usr_name = localStorage.getItem('name');

    const [listViewData, setListViewData] = React.useState([""]);
    const [usermessage, setMessage] = React.useState('');
    const getListViewData = async () => {
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/backend/lead_details') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;
               
                setListViewData(result)
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getListViewData();
    }, []);

    console.log(listViewData);

    const [loading, setLoading] = useState(false);


   return (
    <>
     {/* {usermessage && <div className="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{usermessage}</strong>    
        </div>} */}
    <ToastContainer />
    {loading && (
        <>
        <Loader/>
        </>
    ) }
    <div className="card" style={{overflowX:'auto'}}>
        <div className="card-body">
            <div className="card-header" id="combination">
                <h3 className="card-title">BACKEND LEAD DETAILS ( LEAD ID: {lead_id} )</h3>
                <div className='All_three_button'>
                <Link onClick={() => navigate(-1)}><strong><i title='back' className="fa fa-arrow-circle-left" style={{fontSize:'30px',color:'#0D4C7B',marginLeft: '10px'}}></i></strong></Link>
                </div>
            </div>
            <div className="invoice row">
                <div style={{float: 'left'}} className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>LOB</th>
                                <td >{listViewData != '' && listViewData.data.lob_type}</td>
                            </tr>
                            <tr>
                                <th>Product Type</th>
                                <td>{listViewData != '' && listViewData.data.inventory_type}</td>
                            </tr>
                            <tr>
                                <th>Product ID</th>
                                <td>{listViewData != '' && listViewData.data.project_id}</td>
                            </tr>
                            <tr>
                                <th>Service Type</th>
                                <td>{listViewData != '' && listViewData.data.service_type}</td>
                            </tr>
                            <tr>
                                <th>Product Name</th>
                                <td>{listViewData != '' && listViewData.data.project_name}</td>
                            </tr>
                            <tr>
                                <th>Supplier Name</th>
                                {(listViewData != '' && listViewData.data.supplier_name_id == 666666) &&
                                  <td>{listViewData.data.new_supplier_name}</td> 
                                }
                                {(listViewData != '' && listViewData.data.supplier_name_id != 666666) &&
                                  <td>{listViewData.data.supplier_name}</td>
                                }
                            </tr>
                            <tr>
                                <th>OEM Name</th>
                                {(listViewData != '' && listViewData.data.oem_name_id == 666666) &&
                                <td>{listViewData.data.new_oem_name}</td> 
                                }
                                {(listViewData != '' && listViewData.data.oem_name_id != 666666) &&
                                <td>{listViewData.data.oem_name}</td>
                                }
                            </tr> 
                            <tr>
                                <th>Component Family</th>
                                <td>{listViewData != '' && listViewData.data.component_family}</td> 
                            </tr>
                            <tr>
                                <th>Component Name</th>
                                <td>{listViewData != '' && listViewData.data.component_name}</td> 
                            </tr>
                            <tr>
                                <th>Project Description</th>
                                <td>{listViewData != '' && listViewData.data.project_desc}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
                <div className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>Proposed Outer Packaging</th>
                                <td>{listViewData != '' && listViewData.data.prop_outer_packaging_val}</td> 
                            </tr>
                            <tr>
                                <th>Solution Dimension</th>
                                <td >{listViewData != '' && listViewData.data.solution_dimension}</td>
                            </tr>
                            <tr>
                                <th>Solution Dimension Collapsed</th>
                                <td>{listViewData != '' && listViewData.data.solution_dimension_collapsed}</td>
                            </tr>
                            <tr>
                                <th>Stacking Dynamic</th>
                                <td>{listViewData != '' && listViewData.data.stacking_dynamic}</td>
                            </tr>
                            <tr>
                                <th>Stacking Static</th>
                                <td>{listViewData != '' && listViewData.data.stacking_static}</td>
                            </tr>
                            <tr>
                                <th>Weight Carrying Capacity</th>
                                <td>{listViewData != '' && listViewData.data.weight_carrying_capacity}</td>
                            </tr>
                            <tr>
                                <th>Solution Description</th>
                                <td>{listViewData != '' && listViewData.data.solution_description}</td>
                            </tr>
                            <tr>
                                <th>BD Name</th>
                                <td>{listViewData != '' && listViewData.data.bd_person_name}</td>
                            </tr> 
                            <tr>
                                <th>SD Name</th>
                                <td>{listViewData != '' && listViewData.data.sd_person_name}</td>
                            </tr> 
                            <tr>
                                <th>Lead Status</th>
                                <td> <span className="text-secondary">{listViewData != '' && listViewData.data.status}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
                <div className="table-scrollable col-md-12">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>Lead Doc</th>
                                <td>
                                    {listViewData != '' && listViewData.imgData.map((data) => (  
                                    <>
                                        <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img} ( {data.type} )</span></Link>
                                    </>
                                    ))}  
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
             
            </div>
        </div>
    </div>
    </>
  )
}

export default BackendLeadView