import React from 'react';
import { useEffect, useState } from "react";
import {  BrowserRouter as Router, Link, Route, Routes, useNavigate } from 'react-router-dom';
import Admin from '../Admin';
import { ulmsUrl } from '../Api';
import Dashboard from '../scenes/dashboard';
import ForgotPassword from './ForgotPassword';

const Login = (event)=> {

    let base64 = require('base-64');

    const [email,setEmail] = React.useState('');
    const [password,setPassword] = React.useState('');
    const [permission, setPermission] = React.useState([]);
    const [usermessage, setMessage] = React.useState(null);
    const navigate = useNavigate();
    const loginurl = '/user/login';
    const handleLogin = async () => {
       // alert(email);
        //console.warn("email,password", email, password);
        localStorage.setItem('password',base64.encode(password));
        localStorage.setItem('email',email);

        console.log(JSON.stringify({email,password}));

        let result = await fetch(ulmsUrl.concat(loginurl),{
            method:'post',
            body:JSON.stringify({email,password}),
            headers: {
            'Content-Type': 'application/json'
            }
        });

        result = await result.json();
        console.log(result)
        const { usr_token } = result.data;
        const { name } = result.data;
        const { id } = result.data;
        const { usr_role } = result.data;
        console.log(result.permission_id);

        if(result.status == true){
            localStorage.setItem('authToken', usr_token);
            localStorage.setItem('isAuthenticatedUser',"true");
            localStorage.setItem('name',name);
            localStorage.setItem('usrId',id);           
            localStorage.setItem('usrRole',usr_role);
            //localStorage.setItem('permission',permission);
            setPermission(result.permission_id);
            console.log(permission);
            navigate('/');            
    
        } else if (result.status == false) {
            setMessage(result.message)
            //alert("Please enter correct email");
        }
    }

    if(usermessage != ''){
        setTimeout(() => {
          setMessage(false);
         // sessionStorage.removeItem('createmessage');
        }, 5000);    
    }


    return (
        <>
        <div className="auth">
            <div className="form-wrapper">
                <div className="container">
                    <div className="card">
                        <div className="row g-0">
                            <div className="col">
                                <div className="row">
                                {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <strong>{usermessage}</strong>
                                        </div>}
                                    <div className="col-md-10 offset-md-1">
                                        <div className="d-block d-lg-none text-center text-lg-start">
                                            <img width="120" src="ulms_assets/images/sdmlogo.png" alt="logo" />
                                        </div>
                                        <div className="my-5 text-center text-lg-start">
                                            <h1 className="display-8">Sign In</h1>
                                            <p className="text-muted">Sign in to Holiboard to continue</p>
                                        </div>
                                        <div className="mb-5">
                                            <div className="mb-3">
                                                <input 
                                                type="email"
                                                name="email" 
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                className="form-control" 
                                                placeholder="Enter email" 
                                                autoFocus
                                                    required />
                                            </div>
                                            <div className="mb-3">
                                                <input 
                                                type="password"
                                                name="password" 
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                                className="form-control" 
                                                placeholder="Enter password"
                                                    required />
                                            </div>
                                            <div className="text-center text-lg-start">
                                                <p className="small">Can't access your account? <Link to={"https://holibook.holisollogistics.com/password/reset"} >Reset your password now</Link>.</p>
                                                <button className="btn btn-primary" type='submit' onClick={handleLogin} >Sign In</button>
                                            </div>
                                        </div>
                                        {/* <div className="social-links justify-content-center">
                                            <a href="#">
                                                <i className="ti-google bg-google"></i> Sign in with Google
                                            </a>
                                            <a href="#">
                                                <i className="ti-facebook bg-facebook"></i> Sign in with Facebook
                                            </a>
                                        </div>
                                        <p className="text-center d-block d-lg-none mt-5 mt-lg-0">
                                            Don't have an account? <a href="#">Sign up</a>.
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col d-none d-lg-flex border-start align-items-center justify-content-between flex-column text-center">
                                <div className="logo">
                                    <img width="120" src="ulms_assets/images/holiboard_logo.png" alt="logo" style={{width:'100%'}}/>
                                </div>
                                <div>
                                    <h3 className="fw-bold">Welcome to Holiboard!</h3>
                                    {/* <p className="lead my-5">If you don't have an account, would you like to register right now?</p> */}
                                    {/* <a href="#" className="btn btn-primary">Sign Up</a> */}
                                </div>
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="/privacy_policy">Privacy Policy</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="/support">Support</a>
                                    </li>
                                </ul>
                            </div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Login