import React, { useCallback, useReducer } from 'react';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { useNavigate,NavLink, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Roles = () => {

    const rolelisturl = '/role/list';
    const roledeleteurl = '/role/status_update';

    const navigate = useNavigate();
    var base64 = require('base-64');
    const usr_token = localStorage.getItem('authToken');
    const isUserLogin = localStorage.getItem('isAuthenticatedUser');
    let message = sessionStorage.getItem("rolemessage");

    const [RolesList, setRolesList]  = React.useState([]);
    const [search ,setSearch]  = React.useState("");
    const [filteredRole ,setFilteredRole]  = React.useState([]);
    const [ isAlertVisible, setIsAlertVisible ] = React.useState(true);
    const [loading, setLoading] = useState(true);

    if(message){
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: 'success1',
        });
    }
   
    const addRole = async () => {    
        navigate("/role_create");
    }

    const getRolesData = async () => {
        setLoading(true);
              
        try {
            fetch(ulmsUrl.concat(rolelisturl) , {
                method: 'POST',
                body:JSON.stringify({usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                setLoading(false);
                let result = response.data;
                setRolesList(result)
                setFilteredRole(result);
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() =>{
        getRolesData();
    }, []);

    const confirmdialog = (data) => {

        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure you want to change the status?",
            buttons: [
              {
                label: "Yes",
                onClick: () => deleteRole(data)
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const deleteRole = async (id) => {
       
        fetch(ulmsUrl.concat(roledeleteurl), {
            method: 'POST',
            body: JSON.stringify({id,usr_token}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
                });
                getRolesData();
        
            } else if (result.status == false) {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {

        })
        
    }

    useEffect(() => {
        const filterResult = RolesList.filter(data => {
             return (data.name?.toLowerCase().match(search?.toLowerCase()) || data.status?.toLowerCase().match(search?.toLowerCase()));  
        });
   
        setFilteredRole(filterResult);
    }, [search]); 

    const columns = [
        { 
          name: "ID",
          selector : (row) => row.id,
          width : '200px',
          sortable :  true},
        {
          name: "Name",
          width : '300px',
          sortable :  true,    
          selector : (row) => row.name,     
        },
        {
            name: "Status",
            width : '300px',
            sortable :  true,  
            selector : (row) => <span className={row.status=='Inactive'?"badge bg-danger":row.status=='Active'?"badge bg-success":"text-n"}>{row.status}</span>,
        },
        {
            name: "Action",
            width : '150px',
            cell : (row) => 
            {
                return(
                  <div>
                    <Link to={`/role_update/${base64.encode(row.id)}`} ><span className='badge bg-primary' >EDIT</span></Link> &nbsp;&nbsp;&nbsp;
                    <>
                    {row.status == 'Active' &&
                        <Link onClick={()=>confirmdialog(row.id)} title="Click to Active" ><span className='badge bg-danger' >Inactive</span></Link> 
                    }
                    {row.status == 'Inactive' && 
                        <Link onClick={()=>confirmdialog(row.id)} title="Click to Inactive" ><span className='badge bg-primary' >Active</span></Link> 
                    }
                    </>
                  </div>
                )
            }
        }, 
        
    ];

    useEffect(() => {
        if(message != ''){
            setTimeout(() => {
            setIsAlertVisible(false);
            sessionStorage.removeItem('rolemessage');
            }, 8000);    
        }
    }, []); 


  return (
        <>
            <ToastContainer />
            {loading && (
                <>
                <Loader/>
                </>
            )}
            <div className="row">
                <div className="order-2 order-lg-1 col-lg-12 bd-content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title" style={{float:'left'}}>ROLE LIST</h3>    
                            <button style={{font_size: '12px', float: 'right'}} type="button" onClick={addRole} className="btn btn-primary btn-sm  me-2"><strong> Add Role</strong></button>
                            <CSVLink style={{font_size: '12px', float: 'right'}} className="btn btn-success btn-sm  me-2" 
                            data={filteredRole}
                            filename={"Roles.csv"}
                            >Export</CSVLink>
                        </div>
                        
                        <div className="card-body">         
                            <DataTable 
                                id="table-to-xls"
                                data={filteredRole} 
                                columns={columns} 
                                pagination
                                fixedHeader
                                highlightOnHover
                                subHeader
                                subHeaderComponent = {
                                <div className="col-md-3" style={{float:'right'}}>
                                <input type="text" 
                                placeholder='Search here' 
                                className='w-22 form-control'
                                value={search}
                                onChange={(e)=> setSearch(e.target.value)}
                                />
                                </div>
                                }
                            />
                            
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Roles