import React from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { LocationData, ComponentFamily, SupplierOEMData, VehicleTypeList, VehicleLoadTypeList, VehicleTypeULList } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { confirmAlert } from "react-confirm-alert";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TransportForm = ({lead_id}) => {

    const { vehicleTypeData }      = VehicleTypeList();
    const { vehicleLoadTypeData }  = VehicleLoadTypeList();
    const { vehicleTypeULData }    = VehicleTypeULList();
    const { supplierOEMDropdown }  = SupplierOEMData();
    let sum = 0;

    const dRef = useRef(null);

    const navigate = useNavigate();

    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id   = localStorage.getItem('usrId');
    const user_role  = localStorage.getItem('usrRole');

    let base64 = require('base-64');

    const [selectedTransFile, setTransSelectedFile] = React.useState([]);
    const [loading, setLoading]  = React.useState(false);
    const [transInputFields, setTransInputFields] = useState(
        [{  
            lead_id : lead_id,
            unit_load_wise: 0,
            transport_title:'',
            origin: "", 
            destination: '', 
            vehicle_type: '', 
            vehicle_Load_type: '',
            vehicle_ul_type: '',
            compData: [{
                comp_id: '',
                comp_name : '',
                first_mile_cost: '',
                mid_mile_cost: '',
                last_mile_cost: '',
                moq: '',
                total_cost: '',
                remarks: '',
            }],
        }]
    );

   const handleForwardInput = async(parentIndex, event) => {

        if (event && (event.target.checked)) {

            const list = [...transInputFields];
            list[parentIndex]["unit_load_wise"] = 1;
            setTransInputFields(list);

            const unit_load_wise = 1;
            getForwardata(parentIndex, unit_load_wise);

        }else{

            const list = [...transInputFields];
            list[parentIndex]["unit_load_wise"] = 0;
            setTransInputFields(list);

            const unit_load_wise = 0;
            getForwardata(parentIndex, unit_load_wise);
            
        }

   }
    
    const getTranspData = async () => {
        
        const unit_load_wise = 0;
        setLoading(true);
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/wip/sol_transport') , {
                method: 'POST',
                body: JSON.stringify({lead_id, unit_load_wise, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                setLoading(false);
                let result = response.data;  
                console.log('datatrans',result); 
                if(result != ''){
                    setTransInputFields(result)
                }
                
            });
        } catch (error) {
            console.log(error);
        }
    }


    const getForwardata = async (parentIndex, unit_load_wise) => {

        const list = [...transInputFields];
        
        setLoading(true);
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/wip/sol_transport') , {
                method: 'POST',
                body: JSON.stringify({lead_id, unit_load_wise, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                setLoading(false);
                let result = response.data[1].compData;  

                console.log('ttttttss',result)
                list[parentIndex].compData = result;
                setTransInputFields(list)
                
                
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getTranspData();
    }, []);   


    const handleFormInput = (parentIndex, childIndex, event) => {
         const { name, value } = event.target;
         const updatedData = [...transInputFields];
         updatedData[parentIndex].compData[childIndex][name] = value;
         setTransInputFields(updatedData);

    };

    const handleOriginInput = (parentIndex, event) => {

        const updatedData = [...transInputFields];
        updatedData[parentIndex]["origin"] = event;
        setTransInputFields(updatedData);

    }

    const handleDestinationInput = (parentIndex, event) => {

        const updatedData = [...transInputFields];
        updatedData[parentIndex]["destination"] = event;
        setTransInputFields(updatedData);
    }

    const handleVehicleTypeInput = (parentIndex, event) => {

        const updatedData = [...transInputFields];
        updatedData[parentIndex]["vehicle_type"] = event;
        setTransInputFields(updatedData);
    }

    const handleVehicleLoadInput = (parentIndex, event) => {

        const updatedData = [...transInputFields];
        updatedData[parentIndex]["vehicle_Load_type"] = event;
        setTransInputFields(updatedData);

    }

    const handleVehicleULTypeInput = (parentIndex, event) => {

        const updatedData = [...transInputFields];
        updatedData[parentIndex]["vehicle_ul_type"] = event;
        setTransInputFields(updatedData);
    }

    const handleTransFileInput = (e) => {
        console.log(e.target.files); 
        const selectedFile = e.target.files[0];
        setTransSelectedFile(selectedFile)
       
      };

    const handleTransFormSubmit = async (e) => { 
        e.preventDefault();

        const transportdata = {
            data: transInputFields,
            img_data_app: [], 
            solution_creater_id: usr_id,
            solution_creater_name: usr_name,
            usr_token: usr_token,
        }
         console.log(JSON.stringify(transportdata))

        const formData = new FormData();
        formData.append('transport_file', selectedTransFile);
        
        formData.append('data', JSON.stringify(transportdata));
        console.log(formData);  

        setLoading(true);
        fetch(ulmsUrl.concat('/wip/sol_transport_store'), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
                });
                getTranspData();
                setTransInputFields(transInputFields);
                window.location.reload(true)
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setTransInputFields(transInputFields);
                toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

      
  return (
    <>
     {/* <ToastContainer /> */}
        {loading && (
            <>
            <Loader/>
            </>
        ) }
        <div className="card"  style={{marginTop:'15px', borderRadius:'1px'}}>
            <div className="card-header" style={{backgroundColor:'rgb(61 176 213)', borderRadius:'5px'}}>
                <h3 className="mb-0">
                    <span className="btn btn collapsed"  style={{fontWeight: 'bold', fontSize:'16px', color:'white'}} type="button" >
                        Transportation Details
                    </span>
                </h3>
            </div>
           
            <div className="accordion" id="accordiontransform">
                <form className="" encType="multipart/form-data" onSubmit={handleTransFormSubmit}>

                {transInputFields.map((data, parentIndex) => {
                    
                    return (
                  
                    <div className="card"  style={{margin:'10px', borderRadius:'1px'}} >
                        {data.tab_show == 1 && 
                        <>
                        <div className="card-header row" style={{backgroundColor:'#46B653', borderRadius:'5px'}}>   
                            <div className='col-md-10'>
                                <h3 className="mb-0">
                                    <span className="btn btn collapsed"  style={{fontWeight: 'bold', fontSize:'16px', color:'white'}} type="button" >
                                    {data.transport_title}
                                    </span>
                                </h3>
                            </div>
                            {(parentIndex == 1) &&
                            <div className='col-md-2' style={{marginTop:'10px'}}>
                               <Form.Check
                                    inline
                                    label="Unit Load Wise"
                                    name="unit_load_wise"
                                    value="1"
                                    type= "checkbox" 
                                    key={data.unit_load_wise}
                                    onChange={(event)=>handleForwardInput(parentIndex,event)}
                                    defaultChecked = {(data.unit_load_wise) ? true : false}
                                    style={{fontSize: '18px', fontWeight:'bold', color:'white'}}
                                />
                            </div> }
                        </div>
                        <div id="collapseReversetab" className="show" >
                            <div className="card-body row">
                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label"> Pick-up Location :<span className="required-mark">*</span> </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="origin"
                                        options={supplierOEMDropdown}
                                        defaultValue={data.origin}
                                        onChange={(event)=>handleOriginInput(parentIndex, event)}
                                        key={data.origin}
                                    />
                                </div>
                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label"> Delivery Location :<span className="required-mark">*</span> </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="destination"
                                        options={supplierOEMDropdown}
                                        defaultValue={data.destination}
                                        onChange={(event)=>handleDestinationInput(parentIndex, event)}
                                        key={data.destination}
                                    />
                                </div>
                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Vehicle Type: </label>
                                        <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="vehicle_type"
                                        options={vehicleTypeData}
                                        defaultValue={data.vehicle_type}
                                        onChange={(event)=>handleVehicleTypeInput(parentIndex, event)}
                                        key={data.vehicle_type}
                                    />   
                                </div>
                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Load Type: </label>
                                        <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="vehicle_Load_type"
                                        options={vehicleLoadTypeData}
                                        defaultValue={data.vehicle_Load_type}
                                        onChange={(event)=>handleVehicleLoadInput(parentIndex, event)}
                                        key={data.vehicle_Load_type}
                                    />   
                                </div>
                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Unit: </label>
                                        <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="vehicle_ul_type"
                                        options={vehicleTypeULData}
                                        defaultValue={data.vehicle_ul_type}
                                        onChange={(event)=>handleVehicleULTypeInput(parentIndex, event)}
                                        key={data.vehicle_ul_type}
                                    />   
                                </div>
                                <div id="accordiontrans" style={{marginTop:"12px"}}>

                                {data.compData.map((input, childIndex) => {

                                    console.log('input',input)

                                    const list = [...transInputFields];

                                    console.log('list',list)

                                    if(list[parentIndex].compData[childIndex].first_mile_cost != '' && list[parentIndex].compData[childIndex].mid_mile_cost != '' && list[parentIndex].compData[childIndex].last_mile_cost != ''){
                                        list[parentIndex].compData[childIndex].total_cost = Number(list[parentIndex].compData[childIndex].first_mile_cost) + Number(list[parentIndex].compData[childIndex].mid_mile_cost) + Number(list[parentIndex].compData[childIndex].last_mile_cost);
                                    }

                                    list[parentIndex].compData[childIndex]["total_cost"] = list[parentIndex].compData[childIndex].total_cost;
                                    console.log(list);
                                    if(list > -1){
                                        setTransInputFields(list); 
                                    }

                                    console.log('afterinput',input)

                                    return (
                                  
                                    <div className="card border-dark" >
                                        <div className="card-header" id={"heading"+input.unique_id} data-toggle="collapse" data-target={"#collapsetrans"+input.unique_id} aria-expanded="true" aria-controls={"collapsetrans"+input.unique_id} style={{backgroundColor:'#0D4C7B'}}>
                                            <h3 className="mb-0">
                                                <span className="btn btn collapsed"  style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                                                Component Name: {input.comp_name}
                                                </span>
                                            </h3>
                                        </div>
                                        <div id={"collapsetrans"+input.unique_id} className={"collapse show"} aria-labelledby={"heading"+input.unique_id}data-parent="#accordiontrans">
                                            <div className="card-body">
                                            <div className='row' key={childIndex}>
                                                
                                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">First Mile Cost (Per Unit Load): <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="number"
                                                        name="first_mile_cost"
                                                        value={input.first_mile_cost}
                                                        onChange={(event)=>handleFormInput(parentIndex, childIndex, event)}
                                                        id="first_mile_cost"
                                                        className="form-control" />      
                                                </div>
                                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Mid Mile Cost (Per Unit Load): <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="number"
                                                        name="mid_mile_cost"
                                                        value={input.mid_mile_cost}
                                                        onChange={(event)=>handleFormInput(parentIndex, childIndex, event)}
                                                        id="mid_mile_cost"
                                                        className="form-control" />      
                                                </div>
                                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Last Mile Cost (Per Unit Load): <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="number"
                                                        name="last_mile_cost"
                                                        value={input.last_mile_cost}
                                                        onChange={(event)=>handleFormInput(parentIndex, childIndex, event)}
                                                        id="last_mile_cost"
                                                        className="form-control" />      
                                                </div>
                                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">MOQ: <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="number"
                                                        name="moq"
                                                        value={input.moq}
                                                        onChange={(event)=>handleFormInput(parentIndex, childIndex, event)}
                                                        id="moq"
                                                        className="form-control" />      
                                                </div>
                                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Total Cost: <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="number"
                                                        name="total_cost"
                                                        value={input.total_cost}
                                                        onChange={(event)=>handleFormInput(parentIndex, childIndex, event)}
                                                        id="total_cost"
                                                        className="form-control" disabled/>      
                                                </div>
                                                <div className="col-md-2" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Remarks: <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="text"
                                                        name="remarks"
                                                        value={input.remarks}
                                                        onChange={(event)=>handleFormInput(parentIndex, childIndex, event)}
                                                        id="remarks"
                                                        className="form-control" />      
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div> )
                                    })}
                                    
                                </div>
                            </div>
                        </div>
                        </>
                        }
                    </div>
                        )
                    })}

                    <div className="col-md-4" controlid="formFileMultiple" style={{margin: '15px'}}>
                        <Form.Label>Upload Files (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                        <Form.Control type="file"  
                        name="transport_file"
                        onChange={handleTransFileInput}
                        />
                    </div>

                    <div className="col-md-12" style={{margin: '20px'}}>
                         <button type="submit" className="btn btn-primary">Submit</button>
                    </div> 
                </form>
            </div>
                
        </div>

    </>
  );
};

export default TransportForm;
