import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Monitor = () => {

    const usr_token = localStorage.getItem('authToken');
    const navigate = useNavigate();

    const initialData = {
        "lead_id": "",
        "usr_token": usr_token,
    }
    const [leadSend, setLeadSend] = React.useState(initialData);
    const [oppSend, setOppSend] = React.useState(initialData);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (e) =>{
        if (e && e.target) {
            const { name, value } = e.target;
    
            setLeadSend(oldValue => ({
                ...oldValue,
                "lead_id": value,
            }))
        }
    }

    const handleOppChange = (e) =>{
        if (e && e.target) {
            const { name, value } = e.target;
    
            setOppSend(oldValue => ({
                ...oldValue,
                "lead_id": value,
            }))
        }
    }

    const handleFormLead = async (e) => {
        e.preventDefault();
     
        console.log(JSON.stringify(leadSend));
 
        fetch(ulmsUrl.concat('/monitor/lead_resend'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:  JSON.stringify(leadSend),
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLeadSend(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
            
                setLoading(false);
                // navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setLeadSend(leadSend);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const handleFormOpp = async (e) => {
        e.preventDefault();
     
        console.log(JSON.stringify(oppSend));
 
        fetch(ulmsUrl.concat('/monitor/opp_resend'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:  JSON.stringify(oppSend),
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setOppSend(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
            
                setLoading(false);
                // navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setOppSend(oppSend);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const updateUserMasterHoliBook = async (e) => {
        e.preventDefault();
     
       fetch(ulmsUrl.concat('/master_update/user_update_from_holibook'), {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json'
           },
           body:  JSON.stringify({usr_token}),
       })
       .then((result) =>
           result.json()
       )
       .then((result) => {

           if (result.status == true) {
               setLeadSend(initialData);
               toast.success(result.message, {
                   position: toast.POSITION.TOP_RIGHT,
                   toastId: 'success',
               });
           
               setLoading(false);
               // navigate("/all_leads", { replace: true });

           } else if (result.status == false) {
               setLoading(false);
               setLeadSend(leadSend);
               toast.error(result.message, {
                   position: toast.POSITION.TOP_RIGHT,
                   toastId: 'success',
               });
              
           }
       })
       .catch((form_err) => {
           //console.log(form_err)
       })
   }

    const updateCusMasterHoliBook = async (e) => {
        e.preventDefault();
        
        fetch(ulmsUrl.concat('/master_update/client_update_from_holibook'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:  JSON.stringify({usr_token}),
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {

            if (result.status == true) {
                setLeadSend(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
            
                setLoading(false);
                // navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setLeadSend(leadSend);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const updateLocMasterHoliBook = async (e) => {
        e.preventDefault();
        
        fetch(ulmsUrl.concat('/master_update/location_update_from_holibook'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:  JSON.stringify({usr_token}),
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {

            if (result.status == true) {
                setLeadSend(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
            
                setLoading(false);
                // navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setLeadSend(leadSend);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const updatePincMasterHoliBook = async (e) => {
        e.preventDefault();
        
        fetch(ulmsUrl.concat('/master_update/pincode_update_from_holibook'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:  JSON.stringify({usr_token}),
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {

            if (result.status == true) {
                setLeadSend(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
            
                setLoading(false);
                // navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setLeadSend(leadSend);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const updateSupLocMasterHoliBook = async (e) => {
        e.preventDefault();
        
        fetch(ulmsUrl.concat('/master_update/supp_loc_update_from_holibook'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:  JSON.stringify({usr_token}),
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {

            if (result.status == true) {
                setLeadSend(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
            
                setLoading(false);
                // navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setLeadSend(leadSend);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const updateAllMasterHoliBook = async (e) => {
        e.preventDefault();
        
        fetch(ulmsUrl.concat('/master_update/all_master_update_from_holibook'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:  JSON.stringify({usr_token}),
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {

            if (result.status == true) {
                setLeadSend(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
            
                setLoading(false);
                // navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setLeadSend(leadSend);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

  return (
    <>
      <ToastContainer />
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Monitor</h3>
                    </div>
                    <div className='row'>
                        <div className='card col-md-3' style={{margin:'10px'}}>
                            <div className="card-header">
                                <h3 className="card-title">Lead resent to holisight</h3>
                            </div>
                            <form className="row g-3" encType="multipart/form-data" >
                                <div className="col-md-6" style={{marginLeft: '20px'}}>
                                    <label htmlFor="inputState" className="form-label">Enter Lead Id:<span className="required-mark">*</span> </label>
                                    <Form.Control
                                        type="number"
                                        name="lead_id"
                                        value={leadSend.lead_id}
                                        // onChange={(event)=>handleChange(index, event)}
                                        onChange={handleChange}
                                        id="lead_id"
                                        className="form-control" placeholder="Enter lead Id" />      
                                </div>
                                <div className="col-md-8" style={{margin: '20px'}}>
                                    <button type="submit" onClick={handleFormLead} className="btn btn-primary">Submit</button>
                                </div> 
                            </form>
                        </div>
                        <div className='card col-md-3' style={{margin:'10px'}}>
                            <div className="card-header">
                                <h3 className="card-title">Opportunity resent to holisight</h3>
                            </div>
                            <form className="row g-3" encType="multipart/form-data" >
                                <div className="col-md-6" style={{marginLeft: '20px'}}>
                                    <label htmlFor="inputState" className="form-label">Enter Lead Id:<span className="required-mark">*</span> </label>
                                    <Form.Control
                                        type="number"
                                        name="lead_id"
                                        value={oppSend.lead_id}
                                        // onChange={(event)=>handleChange(index, event)}
                                        onChange={handleOppChange}
                                        id="lead_id"
                                        className="form-control" placeholder="Enter lead Id" />      
                                </div>
                                <div className="col-md-8" style={{margin: '20px'}}>
                                    <button type="submit" onClick={handleFormOpp} className="btn btn-primary">Submit</button>
                                </div> 
                            </form>
                        </div>
                        <div className='card col-md-3' style={{margin:'10px'}}>
                            <div className="card-header">
                                <h3 className="card-title">User Master update from holibook</h3>
                            </div>
                            <form className="row g-3" encType="multipart/form-data" >
                                <div className="col-md-8" style={{margin: '20px'}}>
                                    <button type="submit" onClick={updateUserMasterHoliBook} className="btn btn-primary">Update</button>
                                </div> 
                            </form>
                        </div>
                        <div className='card col-md-3' style={{margin:'10px'}}>
                            <div className="card-header">
                                <h3 className="card-title">Customer Master update from holibook</h3>
                            </div>
                            <form className="row g-3" encType="multipart/form-data" >
                                <div className="col-md-8" style={{margin: '20px'}}>
                                    <button type="submit" onClick={updateCusMasterHoliBook} className="btn btn-primary">Update</button>
                                </div> 
                            </form>
                        </div>
                        <div className='card col-md-3' style={{margin:'10px'}}>
                            <div className="card-header">
                                <h3 className="card-title">Location Master update from holibook</h3>
                            </div>
                            <form className="row g-3" encType="multipart/form-data" >
                                <div className="col-md-8" style={{margin: '20px'}}>
                                    <button type="submit" onClick={updateLocMasterHoliBook} className="btn btn-primary">Update</button>
                                </div> 
                            </form>
                        </div>
                        <div className='card col-md-3' style={{margin:'10px'}}>
                            <div className="card-header">
                                <h3 className="card-title">Pincode Master update from holibook</h3>
                            </div>
                            <form className="row g-3" encType="multipart/form-data" >
                                <div className="col-md-8" style={{margin: '20px'}}>
                                    <button type="submit" onClick={updatePincMasterHoliBook} className="btn btn-primary">Update</button>
                                </div> 
                            </form>
                        </div>
                        <div className='card col-md-3' style={{margin:'10px'}}>
                            <div className="card-header">
                                <h3 className="card-title">Supplier Location Master update from holibook</h3>
                            </div>
                            <form className="row g-3" encType="multipart/form-data" >
                                <div className="col-md-8" style={{margin: '20px'}}>
                                    <button type="submit" onClick={updateSupLocMasterHoliBook} className="btn btn-primary">Update</button>
                                </div> 
                            </form>
                        </div>
                        <div className='card col-md-3' style={{margin:'10px'}}>
                            <div className="card-header">
                                <h3 className="card-title">All Master update from holibook on single click</h3>
                            </div>
                            <form className="row g-3" encType="multipart/form-data" >
                                <div className="col-md-8" style={{margin: '20px'}}>
                                    <button type="submit" onClick={updateAllMasterHoliBook} className="btn btn-primary">Update</button>
                                </div> 
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Monitor