import React, { useCallback, useReducer } from 'react';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { useNavigate,NavLink, Link, useParams } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateRole = () => {

    const navigate = useNavigate();
    const rolecreateurl = '/role/create';
    const usr_token = localStorage.getItem('authToken');
    const isUserLogin = localStorage.getItem('isAuthenticatedUser');

    const initialRoleData = {
        "name": "",
        "usr_token": usr_token,
    }

    const [roleCreate, setRolecreate] = React.useState(initialRoleData);
    const [formErrors, setFromErrors] = React.useState({});
    const [options, setOptions] = React.useState([""]);

    if (isUserLogin !== 'true') {
        navigate('/');
    }

    const handleFormInput = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setRolecreate(oldValue => ({
                ...oldValue,
                [name]: value
            }))
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log(roleCreate);

        fetch(ulmsUrl.concat(rolecreateurl), {
            method: 'POST',
            body: JSON.stringify(roleCreate),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setRolecreate(initialRoleData)
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                sessionStorage.setItem("rolemessage", result.message);
                navigate("/roles")

            } else if (result.status == false) {
                setRolecreate(roleCreate)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });

            }
        })
        .catch((form_err) => {
            console.log(form_err)
        })
    };



  return (
   <>
        <ToastContainer />
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">CREATE ROLE</h3>
                    </div>
                    <div className="card-body">
                        <form className="row g-3" onSubmit={handleFormSubmit}>
                            <div className="col-md-4">
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter role name"
                                    name="name"
                                    value={roleCreate.name}
                                    onChange={handleFormInput}
                                    autoComplete='off'
                                    
                                />
                            </div>
                            <div className='row' style={{marginTop: '20px'}}>
                                <div className="col-md-2">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                                <div className="col-md-2">
                                    <Link to="/roles"><span className="btn btn-primary">Cancel</span></Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CreateRole