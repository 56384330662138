import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Thankyou = () => {

  const navigate = useNavigate();

  return (
    <>
       <ToastContainer />

        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">SOLUTION</h3>
                        <div className='All_three_button'>
                            <Link to={'/solution_gallery'} ><button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right', marginLeft: '3px'}} ><strong>Upload Backend Solution</strong></button> </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div className="card-body">
            <div className="main-content">
                <header className="site-header" id="header" style={{textAlign:'center'}}>
                    <h1 className="site-header__title" data-lead-id="site-header-title">THANK YOU!</h1>
                </header>
            </div>
        </div>

	

    </>
  )
}

export default Thankyou