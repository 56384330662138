import React, { useCallback, useReducer } from 'react';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { useNavigate,NavLink, Link, useParams } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RoleUpdate = () => {

    var base64 = require('base-64');
    const navigate = useNavigate();
    const roleupdate = '/role/update';
    const roledataurl = '/role/role_info';
    const usr_token = localStorage.getItem('authToken');
    const isUserLogin = localStorage.getItem('isAuthenticatedUser');
  
    const initialUserData = {
        "name": "",
        "usr_token": usr_token,
    }
  
    const [rolesUpdate, setRolesupdate] = useState(initialUserData);
    const [usermessage, setMessage] = useState('');
    const [formErrors, setFromErrors] = useState({});
    const [options, setOptions] = useState([""]);
    
    const [id, setroleId] = useState(base64.decode(useParams().id));
  
    if (isUserLogin !== 'true') {
        navigate('/');
    }

    useEffect(() => {
        const Editroledata = async () => {
          const roledata = await fetch(ulmsUrl.concat(roledataurl), {
            method: 'POST',
            body: JSON.stringify({id,usr_token}),
            headers: {
                'Content-Type': 'application/json'
            }
          })
          .then((roledata) =>
              roledata.json()
          )
          .then((roledata) => {
            console.log(roledata)    
            setRolesupdate(roledata.data[0])
            //console.log(userdata.data[0])
          })
          .catch((form_err) => {
              
          })
            
        }
        Editroledata();
    },[]);

    const handleFormInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setRolesupdate(oldValue => ({
                ...oldValue,  
                [name]: value,
                ['usr_token'] : usr_token,
                
            }))
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log(rolesUpdate);

        fetch(ulmsUrl.concat(roleupdate), {
            method: 'POST',
            body: JSON.stringify(rolesUpdate),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setRolesupdate(initialUserData)
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                sessionStorage.setItem("rolemessage", result.message);
                navigate("/roles");
                        
            } else if (result.status == false) {
                setRolesupdate(rolesUpdate)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            console.log(form_err)
        })
    };
    


  return (
    <>
        {usermessage && <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{usermessage}</strong>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>}
        <ToastContainer />
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">UPDATE ROLE</h3>
                    </div>
                    <div className="card-body">
                        <form className="row g-3" onSubmit={handleFormSubmit}>
                            <div className="col-md-4">
                                <Form.Label>Role Name:</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter name"
                                    name="name"
                                    value={rolesUpdate.name}
                                    onChange={handleFormInput}
                                    autoComplete='off'
                                />
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default RoleUpdate