import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { CustomerData, PortList, ComponentFamily, DistrictData, SupplierOEMData } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import DateFormat from '../dateformate/DateFormat';
import 'react-toastify/dist/ReactToastify.css';

const Opportunity = () => {

    const { districtOptions }  = DistrictData();
    const { comFamOptions }    = ComponentFamily();
    const { portListData }     = PortList();
    const { options }          = CustomerData();
    const { supplierOEMDropdown } = SupplierOEMData();

    let base64 = require('base-64');
    const opportcreateurl = "/opportunity/create";
    const partialsaveurl  = "/opportunity/save";
    const navigate = useNavigate();
    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id = localStorage.getItem('usrId');

    const radioData = [
        { id: 1, value: '1', label: 'Yes' },
        {id: 2, value: '0', label: 'NO' },
      ];

    const initialData = {
        "lead_id": lead_id,
        "service_for": "",
        "sd_head_name": "",
        "origin": "",
        "destination": "",
        "port_origin": "",
        "port_destination": "",
        "component_family_name": "",
        "expected_closure_date": "",
        "business_tenure": "",
        "potential_rev": "",
        "potential_capex": "",
        "potential_ebitda": "",
        "potential_ebitda_per": "",
        "reference_url": "",
        "opportunity_desc": "",
        "opportunity_creater_id": usr_id,
        "opportunity_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const [opportunityCreate, setOpportunitycreate] = React.useState(initialData);
    const [leadViewData, setLeadViewData] = React.useState([""]);
    const [usermessage, setMessage] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [componentFamChild, setcomponentFamChild] = React.useState([""]);
    const [locationSelected, setLocationSelected] = useState('');
    const [customerData, setCustomerData] = React.useState([""]);
    const [oppDocSelected, setOppDocSelected] = useState('');
    const [loading, setLoading] = useState(false);
    const [sdHeadData, setSdHeadData] = React.useState([""]);
    const [inputFields, setInputFields] = useState([
        {  
           component_name: "", 
           monthly_volume_min: '', 
           monthly_volume_max: '', 
           monthly_volume_avg: '',
           forward_transportation: 0,
        }
    ]);

    const getOpportunityData = async () => {
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/wip/opp_temp_details') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;
                console.log('www',result);
                // result?.map((data) => {
                //     return arr.push({ value: data.menu_name, label: data.use_for_web });
                // });
                setInputFields(result.multi_data)
                setOpportunitycreate(result.data)
                handleComFamInput(result.data.component_family_name)
                handleOriginInput(result.data.origin);
                setOppDocSelected(result);
                setSdHeadData(result.sdhead_list);
            });
        } catch (error) {
            console.log(error);
        }
    }


    const getListViewData = async () => {
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/sdm_lead/view') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;
                // result?.map((data) => {
                //     return arr.push({ value: data.menu_name, label: data.use_for_web });
                // });
                
                setLeadViewData(result)
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getOpportunityData();
        getListViewData();
    }, []);

    //console.log(leadViewData);

    const handleComFamInput = (value) => { 
        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "component_family_name": value,
        }))  

       let component_family = value;

        const arr = [];
        try {
            fetch(ulmsUrl.concat('/family/name_list') , {
                method: 'POST',
                body: JSON.stringify({component_family, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                result?.map((data) => {
                    return arr.push({ value: data.id, label: data.name });
                });
                setcomponentFamChild(arr)
            });
        } catch (error) {
            console.log(error);
        }

    };

    const handleCompNameInput = (index,event) => { 
        const list = [...inputFields];
        list[index]["component_name"] = event;

        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "multi_data": list,
        }))  
    };

    const addFields = () => {
        let newfield = { 
           component_name: "", 
           monthly_volume_min: '', 
           monthly_volume_max: '', 
           monthly_volume_avg: '',
           forward_transportation: 0,
        }
        setInputFields([...inputFields, newfield])
    }

    const handleChange = (index, event) => {
       const { name, value } = event.target;
       const list = [...inputFields];
       list[index][name] = value;
       setOpportunitycreate(oldValue => ({
        ...oldValue,
        "multi_data": list,
      }))  

    }

    const removeInputFields = (index, event) => {
        // setInputFields(inputFields.filter((v, i) => i !== index));
         const rows = [...inputFields];
         rows.splice(index, 1);
         setInputFields(rows);
    }


    const handleFileInput = (e) => {
        console.log(e.target.files); 
        setSelectedFile(e.target.files)
        console.log(selectedFile);
    };

    let average = 0;
    if(opportunityCreate.monthly_volume_min != '' && opportunityCreate.monthly_volume_min != null && opportunityCreate.monthly_volume_max != '' && opportunityCreate.monthly_volume_max != null){
      let sum = Number(opportunityCreate.monthly_volume_min) + Number(opportunityCreate.monthly_volume_max);
      average = sum/2;
    }

    // let ebidtaperc = 0;
    // if(opportunityCreate.exp_monthly_revenue != '' && opportunityCreate.exp_monthly_revenue != null && opportunityCreate.exp_monthly_expense != '' && opportunityCreate.exp_monthly_expense != null){
    //   let revenue = Number(opportunityCreate.exp_monthly_revenue);
    //   let expense = Number(opportunityCreate.exp_monthly_expense);
    //   let ebitadata = (revenue - expense) / revenue;
    //   ebidtaperc = ebitadata * 100;
    // }

    let ebitadata = 0;
    if(opportunityCreate.potential_rev != '' && opportunityCreate.potential_rev != null && opportunityCreate.potential_ebitda_per != '' && opportunityCreate.potential_ebitda_per != null){
        let potentialrev = Number(opportunityCreate.potential_rev);
        let ebidta = Number(opportunityCreate.potential_ebitda_per);
        ebitadata = (ebidta / 100 ) * potentialrev;
    }

    const handleFormInput = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setOpportunitycreate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }

        if(ebitadata != ""){
         setOpportunitycreate(oldValue => ({
            ...oldValue,
            "potential_ebitda": ebitadata,
          }))
        }
    };

    const handleOriginInput = (data) => {
        setCustomerData([""]);

        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "origin": data,
        })) 
        
        let client_id = data.value;
        console.log(client_id)

        if(client_id != ""){
            console.log(JSON.stringify({ client_id }))

            fetch(ulmsUrl.concat('/holibook/supp_oem_info'), {
                method: 'POST',
                body: JSON.stringify({ client_id }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                console.log('supp',result)
                setCustomerData(response.data)
            });
        } 
    }

    console.log(customerData);

    const handleDestinationInput = (data) => {
        const test = data.label;
        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "destination": data,
        }))  
    }

    const handlePortOriginInput = (data) => {
        const test = data.label;
        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "port_origin": data,
        }))  
    }

    const handlePortDestinationInput = (data) => {
        const test = data.label;
        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "port_destination": data,
        }))  
    }

    const handleTransport = (index, e) => {
        
        const { name, value } = e.target;
       
        if (e && (e.target.checked)) {

            let removedChkbox = (value=='1') ? '0' : '1';

            document.getElementById(`chbk_${removedChkbox}`).checked = false;

            const list = [...inputFields];
            list[index][name] = value;
            setOpportunitycreate(oldValue => ({
                ...oldValue,
                "multi_data": list,
            }))  

        }

    }

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const createdata = {
            lead_id : lead_id,
            service_for :  opportunityCreate.service_for,
            sd_head_id : opportunityCreate.sd_head_id,
            origin :  opportunityCreate.origin,
            destination :  opportunityCreate.destination,
            port_origin :  opportunityCreate.port_origin,
            port_destination :  opportunityCreate.port_destination,
            component_family_name :  opportunityCreate.component_family_name,
            potential_rev :  opportunityCreate.potential_rev,
            potential_capex :  opportunityCreate.potential_capex,
            potential_ebitda_per : opportunityCreate.potential_ebitda_per,
            potential_ebitda :  ebitadata,
            business_tenure :  opportunityCreate.business_tenure,
            expected_closure_date :  opportunityCreate.expected_closure_date,
            reference_url :  opportunityCreate.reference_url,
            opportunity_desc :  opportunityCreate.opportunity_desc,
            multi_data :  inputFields,
            opportunity_creater_id : usr_id,
            opportunity_creater_name : usr_name,
            usr_token : usr_token
        }

        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`opportunity_photo_web`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(createdata));
        console.log(JSON.stringify(createdata));


        fetch(ulmsUrl.concat(opportcreateurl), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setOpportunitycreate(initialData);
                // toast.success(result.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     toastId: 'success1',
                // });
                sessionStorage.setItem("createmessage", result.message);
                setLoading(false);
                navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setOpportunitycreate(opportunityCreate);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                setLoading(false);
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const handlePartialSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const savedata = {
            lead_id : lead_id,
            service_for :  opportunityCreate.service_for,
            sd_head_id : opportunityCreate.sd_head_id,
            origin :  opportunityCreate.origin,
            destination :  opportunityCreate.destination,
            port_origin :  opportunityCreate.port_origin,
            port_destination :  opportunityCreate.port_destination,
            component_family_name :  opportunityCreate.component_family_name,
            business_tenure :  opportunityCreate.business_tenure,
            potential_rev :  opportunityCreate.potential_rev,
            potential_capex :  opportunityCreate.potential_capex,
            potential_ebitda_per : opportunityCreate.potential_ebitda_per,
            potential_ebitda :  ebitadata,
            expected_closure_date :  opportunityCreate.expected_closure_date,
            reference_url :  opportunityCreate.reference_url,
            opportunity_desc :  opportunityCreate.opportunity_desc,
            multi_data :  inputFields,
            opportunity_creater_id : usr_id,
            opportunity_creater_name : usr_name,
            usr_token : usr_token
        }

        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`opportunity_photo_web`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(savedata));
        console.log(JSON.stringify(savedata));

        fetch(ulmsUrl.concat(partialsaveurl), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setOpportunitycreate(initialData);
                // toast.success(result.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     toastId: 'success1',
                // });
                sessionStorage.setItem("createmessage", result.message);
                setLoading(false);
                navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setOpportunitycreate(opportunityCreate);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    useEffect(() => {
        if(usermessage != ''){
          setTimeout(() => {
            setMessage(false);     
          }, 8000);    
        }
    }, []); 

  return (
    <>
    {/* {usermessage != false && <div className="alert alert-danger alert-dismissible fade show" role="alert">
           <strong>{usermessage}</strong>    
       </div>} */}
       <ToastContainer />
       {loading && (
          <>
            <Loader/>
          </>
        ) }
       <div className="row">
           <div className="order-2 order-lg-1 col-lg-12 bd-content">
               <div className="card">
                   <div className="card-header">
                       <h3 className="card-title">CREATE OPPORTUNITY ( LEAD ID: {lead_id} )</h3>
                       <div className='All_three_button'>
                            <Link onClick={() => navigate(-1)}><strong><i title='back' className="fa fa-arrow-circle-left" style={{fontSize:'30px',color:'#0D4C7B',marginLeft: '10px'}}></i></strong></Link>
                       </div>
                   </div>
                   <div className="card-body">
                       <div className="invoice row">
                            <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>LOB</th>
                                            <td >{leadViewData != '' && leadViewData.data.project_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Product Type</th>
                                            <td>{leadViewData != '' && leadViewData.data.products}</td>
                                        </tr>
                                        <tr>
                                            <th>Service Type</th>
                                            <td>{leadViewData != '' && leadViewData.data.service_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Supplier Name</th>
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id == 666666) &&
                                            <td>{leadViewData.data.new_supplier_name}</td> 
                                            }
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id != 666666) &&
                                            <td>{leadViewData.data.supplier_name}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th >Entity Name</th>
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id == 666666) &&
                                            <td>{leadViewData.data.new_supplier_name}</td> 
                                            }
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id != 666666) &&
                                            <td>{leadViewData != '' && leadViewData.data.entity_name}</td>
                                            }
                                        </tr>  
                                        <tr>
                                            <th>Supplier Location</th>
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id == 666666) &&    
                                            <td>{leadViewData.data.new_supplier_loc}</td> 
                                            }
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id != 666666) &&
                                            <td>{leadViewData != '' && leadViewData.data.supplier_loc}</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>LS Services:</th>
                                            {leadViewData != '' && leadViewData.data.multi_ls.length > 0 ?
                                                <td>
                                                {leadViewData.data.multi_ls.map((data) => (  
                                                    <>{data}  </> 
                                                ))} 
                                                </td> :
                                                <td>-</td> 
                                            }  
                                        </tr>
                                        <tr>
                                            <th>Industry Vertical</th>
                                            <td>{leadViewData != '' && leadViewData.data.cust_industry_verticle}</td>
                                        </tr>
                                        <tr>
                                            <th>First Contact Date</th>
                                            <td >{leadViewData != '' && DateFormat(leadViewData.data.start_date)}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Description</th>
                                            <td >{leadViewData != '' && leadViewData.data.lead_desc}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Requester Name</th>
                                            <td >{leadViewData != '' && leadViewData.data.lead_req_name}</td>
                                        </tr> 
                                        <tr>
                                            <th>Lead Owner Name</th>
                                            {(leadViewData != '' && leadViewData.data.lead_owner_name != null) ?
                                            <td>{leadViewData.data.lead_owner_name}</td> :
                                            <td>-</td>
                                            }
                                        </tr> 
                                        <tr>
                                            <th>Lead Status</th>
                                            <td>
                                                {leadViewData != '' &&  leadViewData.data.status == 2 &&
                                                <span className="text-secondary">Lead Pending at BD Head </span>
                                                }
                                                {leadViewData != '' &&  leadViewData.data.status == 3 &&
                                                <span className="text-warning">Lead Assigned ({leadViewData.data.lead_owner_name}) </span>
                                                }
                                           </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-12">
                                {/* <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Location</th>
                                            <td>{leadViewData != '' && leadViewData.data.supplier_loc}</td>
                                        </tr>
                                    
                                    </tbody>
                                </table> */}
                            </div> 
                        </div>
                       <form className="row g-3" encType="multipart/form-data"  style={{marginTop: '5px'}}>
                          <div className='row' style={{marginTop: '5px'}}>
                                <div className="col-md-12">  
                                    <Form.Label style={{marginRight: '15px'}} >Select Solution Manager :<span className="required-mark">*</span></Form.Label>
                                        {sdHeadData != '' && sdHeadData.map((value, index) => (
                                            <Form.Check
                                                inline
                                                key={value.value}
                                                label={value.label}
                                                name="sd_head_id"
                                                style={{marginTop: '5px'}}
                                                value={value.value}
                                                type= "radio"
                                                // onClick={()=>setSelected(value.name)}
                                                onChange={handleFormInput}
                                                checked={opportunityCreate.sd_head_id == value.value ? true : false}
                                            />
                                        ))}
                                </div> 
                                <div className="col-md-3" style={{marginTop: '5px'}}>  
                                    <Form.Label style={{marginRight: '15px'}} >Service For :<span className="required-mark">*</span></Form.Label>
                                    <Form.Check
                                        inline
                                        label="Domestic"
                                        name="service_for"
                                        value="Domestic"
                                        type= "radio"
                                        onClick={()=>setLocationSelected("Domestic")}
                                        onChange={handleFormInput}
                                        checked = {opportunityCreate.service_for === "Domestic" ? true : false}
                                        id={`inline-radio-2`}
                                    />
                                    <Form.Check
                                        inline
                                        label="International"
                                        name="service_for"
                                        value="International"
                                        type= "radio"
                                        onClick={()=>setLocationSelected("International")}
                                        onChange={handleFormInput}
                                        checked = {opportunityCreate.service_for === "International" ? true : false}
                                        id={`inline-radio-1`}
                                    />
                                </div>

                                {(customerData != '' && customerData != null) ?
                                <div className="col-md-9" style={{backgroundColor: '#ADD8E6'}}>
                                    <h8>{customerData != '' && <><strong> Supplier Name : </strong> {customerData.client_name}, </> }
                                    {customerData != '' &&  <> <strong> Entity Name : </strong> {customerData.entity_name}, <strong> Pincode : </strong> {customerData.pin_code}</> }</h8><br/>
                                    <h8>{customerData != '' && <> <strong> Location : </strong> {customerData.address}</> } </h8>
                                </div> :
                                <div className="col-md-9">

                                </div>}

                                {leadViewData != '' && leadViewData.data.service_type == 'NRP' && opportunityCreate.service_for == "Domestic" &&
                                <>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label"> Origin :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="origin"
                                            options={supplierOEMDropdown}
                                            defaultValue={opportunityCreate.origin}
                                            onChange={handleOriginInput}
                                            key={opportunityCreate.origin}
                                        />
                                    </div>
                                </>}
                                {opportunityCreate.service_for == "International" &&
                                <>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label"> Origin :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="port_origin"
                                            options={portListData}
                                            defaultValue={opportunityCreate.port_origin}
                                            onChange={handlePortOriginInput}
                                            key={opportunityCreate.port_origin}
                                        />
                                    </div>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label"> Destination :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="port_destination"
                                            options={portListData}
                                            defaultValue={opportunityCreate.port_destination}
                                            onChange={handlePortDestinationInput}
                                            key={opportunityCreate.port_destination}
                                        />
                                    </div>
                                </>}
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Component Family:<span className="required-mark">*</span> </Form.Label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="component_family_name"
                                            options={comFamOptions}
                                            defaultValue={opportunityCreate.component_family_name}
                                            onChange={handleComFamInput}
                                            key={opportunityCreate.component_family_name}
                                        />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Business Tenure (In months) :<span className="required-mark">*</span> </label>
                                    <Form.Control
                                        type="text"
                                        name="business_tenure"
                                        value={opportunityCreate.business_tenure}
                                        onChange={handleFormInput}
                                        id="business_tenure"
                                        className="form-control" placeholder="Enter Business Tenure" />      
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Potential Revenue :<span className="required-mark">*</span> </label>
                                    <Form.Control
                                        type="text"
                                        name="potential_rev"
                                        value={opportunityCreate.potential_rev}
                                        onChange={handleFormInput}
                                        id="potential_rev"
                                        className="form-control" placeholder="Enter Potential Revenue" />      
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Potential Capex :<span className="required-mark">*</span> </label>
                                    <Form.Control
                                        type="text"
                                        name="potential_capex"
                                        value={opportunityCreate.potential_capex}
                                        onChange={handleFormInput}
                                        id="potential_capex"
                                        className="form-control" placeholder="Enter Potential Capex" />      
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Potential EBIDTA (%) :<span className="required-mark">*</span> </label>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <Form.Control
                                            type="number"
                                            name="potential_ebitda_per"
                                            value={opportunityCreate.potential_ebitda_per}
                                            onChange={handleFormInput}
                                            id="potential_ebitda_per"
                                            className="form-control" placeholder="Enter Potential Ebitda" />  
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control
                                            type="text"
                                            name="potential_ebitda"
                                            value={ebitadata}
                                            onChange={handleFormInput}
                                            id="potential_ebitda"
                                            className="form-control" placeholder="Potential Ebitda percentage" disabled />    
                                        </div>
                                    </div>  
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Label>Expected Closure Date:<span className="required-mark">*</span></Form.Label>
                                        <Form.Control
                                        type="date"
                                        name="expected_closure_date"
                                        value={opportunityCreate.expected_closure_date}
                                        onChange={handleFormInput}
                                        id="expected_closure_date"
                                        className="form-control"  />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Reference url</Form.Label>
                                    <Form.Control
                                        type="url"
                                        name="reference_url"
                                        value={opportunityCreate.reference_url}
                                        onChange={handleFormInput}
                                        id="reference_url"
                                        className="form-control"  />
                                </div>
                                <div className="col-md-4"  style={{marginTop: '5px'}}>
                                    <Form.Label>Opportunity Description : <span className="required-mark">*</span></Form.Label>
                                    <Form.Control 
                                        className="form-control"
                                        as="textarea"
                                        rows={1}
                                        placeholder="Enter Description"
                                        name="opportunity_desc"
                                        value={opportunityCreate.opportunity_desc}
                                        onChange={handleFormInput}
                                        autoComplete='off'
                                    />   
                                </div>
                                <div id="accordion" style={{marginTop:'10px'}}>
                                    <div className="card">
                                    {inputFields.map((input, index) => {

                                        if(input.monthly_volume_min != '' && input.monthly_volume_max != ''){
                                            let sum = Number(input.monthly_volume_min) + Number(input.monthly_volume_max);
                                            average = sum/2;
                                        }

                                        const list = [...inputFields];
                                        list[index]["monthly_volume_avg"] = average;
                                        console.log(list);
                                        if(list > -1){
                                            setOpportunitycreate(oldValue => ({
                                                oldValue,
                                                "multi_data": list,
                                            })) 
                                        }

                                    return (
                                    <div className="row">
                                        <div className="card-header row" id={"heading"+index} data-toggle="collapse" data-target={"#collapse"+index} aria-expanded="true" aria-controls={"collapse"+index} style={{backgroundColor:'#46B653'}}>
                                            <div className="col-md-6" >
                                                <h3 className="mb-0" >
                                                    <span className="btn btn collapsed" style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                                                    Component Name: {input.component_name}
                                                    </span>
                                                </h3>
                                            </div>
                                            <div className="col-md-6 " style={{marginTop: '20px', float:'right'}}>
                                            {(inputFields.length!==1)? <i className="btn btn-danger" style={{float: 'right', marginLeft:'8px'}} onClick={(event)=>removeInputFields(index,event)}>Remove</i>:''}
                                            {inputFields.length - 1 === index &&
                                            <i className="btn btn-primary" onClick={addFields} style={{float: 'right'}}>Add More</i> }
                                            </div>
                                        </div>
                                        <div id={"collapse"+index} className={index === 0 ? "collapse show" : "collapse" } aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body row">
                                                {/* <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Component Name: </label>
                                                        <Select
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            name="component_name"
                                                            options={componentFamChild}
                                                            key={input.component_name}
                                                            defaultValue={input.component_name}
                                                            onChange={(event)=>handleCompNameInput(index,event)}
                                                        />
                                                </div> */}
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Component Name: <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="text"
                                                        name="component_name"
                                                        defaultValue={input.component_name}
                                                        onChange={(event)=>handleChange(index, event)}
                                                        id="component_name"
                                                        className="form-control" placeholder="Enter Component Name" />      
                                                </div>
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Minimum Monthly Volume (nos.): <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="text"
                                                        name="monthly_volume_min"
                                                        defaultValue={input.monthly_volume_min}
                                                        onChange={(event)=>handleChange(index, event)}
                                                        id="monthly_volume_min"
                                                        className="form-control" placeholder="Enter Monthly Volume (min)" />      
                                                </div>
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Maximum Monthly Volume (nos.):<span className="required-mark">*</span> </label>
                                                    <Form.Control
                                                        type="text"
                                                        name="monthly_volume_max"
                                                        defaultValue={input.monthly_volume_max}
                                                        onChange={(event)=>handleChange(index, event)}
                                                        id="monthly_volume_max"
                                                        className="form-control" placeholder="Enter Monthly Volume (max)" />      
                                                </div>
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Average (nos.): <span className="required-mark">*</span></label>
                                                    <Form.Control
                                                        type="text"
                                                        name="monthly_volume_avg"
                                                        value={average}
                                                        onChange={(event)=>handleChange(index, event)}
                                                        id="monthly_volume_avg"
                                                        className="form-control" placeholder="Average" disabled />      
                                                </div>
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <div className='row'> 
                                                        <div className="col-md-7" style={{marginTop: '25px'}}>
                                                        <label htmlFor="inputState" className="form-label">Include Forward Transportation: <span className="required-mark">*</span></label>
                                                        </div>
                                                        <div className="col-md-5" style={{marginTop: '25px'}}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name="forward_transportation"
                                                                value="1"
                                                                type= "checkbox"
                                                                onChange={(event)=>handleTransport(index, event)}
                                                                // onChange={(event)=>handleChange(index, event)}
                                                                checked = {input.forward_transportation == '1' ? true : false}
                                                                id={'chbk_'+1}
                                                            /> 
                                                            <Form.Check
                                                                inline
                                                                label="NO"
                                                                name="forward_transportation"
                                                                value="0"
                                                                type= "checkbox"
                                                                onChange={(event)=>handleTransport(index, event)}
                                                                // onChange={(event)=>handleChange(index, event)}
                                                                checked = {input.forward_transportation == '0' ? true : false}
                                                                id={'chbk_'+0}
                                                            /> 
                                                        </div>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                    })}
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '10px'}}>
                                   <Form.Label>Lead Files : </Form.Label>
                                   {oppDocSelected != '' &&  oppDocSelected.packingImg.map((data) => (  
                                     <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                    ))}  
                                </div>
                                <div className="col-md-12" style={{marginTop: '3px'}}>
                                   <Form.Label>Opportunity Files : </Form.Label>
                                   {oppDocSelected != '' && oppDocSelected.OpportunityImgPath.map((data) => (  
                                     <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                    ))}  
                                </div>
                                <div className="col-md-4" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                        <Form.Label>Upload Photographs / Files (File name should be in alphanumeric , - , _ , . ) : <span className="required-mark">*</span></Form.Label>
                                        <Form.Control multiple type="file"  
                                        name="opportunity_photo_web"
                                        onChange={handleFileInput}
                                        />
                                </div>
                                <div className='combination'>
                                    <div className="col-md-10"  style={{marginTop: '15px'}}>
                                        <button type="submit"id="save" onClick={handlePartialSubmit} className="btn btn-primary">Save Details</button>
                                    </div> 
                                    <div className="col-md-2"  style={{marginTop: '15px'}}>
                                        <button type="submit" id="create" onClick={handleFormSubmit} style={{float: 'right'}} className="btn btn-primary">Create Opportunity</button>
                                    </div>
                                </div>
                            </div>
                       </form>
                   </div>
               </div>
           </div>
       </div>

   </>
  )
}

export default Opportunity