import React from 'react'

function Footer() {
    return (
        <>
            <footer className="content-footer">
                <div>2023 &copy; holisollogistics.com </div>
                <div>
                    {/* <nav className="nav gap-4">
                        <a href="https://themeforest.net/licenses/standard" className="nav-link">Licenses</a>
                        <a href="#" className="nav-link">Change Log</a>
                        <a href="#" className="nav-link">Get Help</a>
                    </nav> */}
                </div>
            </footer>
        </>
    )
}

export default Footer