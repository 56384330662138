import React from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { UserPermissionList } from '../../apidata/fetchapi';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import { confirm } from "react-confirm-box";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeadView = () => {
    let base64 = require('base-64');
    const aRef = useRef(null);
    const queryreateurl   = "/opportunity/additional_query";
    const leadallocateurl = '/sdm_lead/reassign_to_bdteam';
    const leadassignurl   = '/sdm_lead/assignment_to_bdteam';
    const navigate = useNavigate();
    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const user_id = localStorage.getItem('usrId');
    const usr_name = localStorage.getItem('name');

    const [listViewData, setListViewData] = React.useState([""]);
    const [usermessage, setMessage] = React.useState('');
    const getListViewData = async () => {
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/sdm_lead/view') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;
               
                setListViewData(result)
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getListViewData();
    }, []);

    console.log(listViewData);

    const initialQueryData = {
        //"lead_id": "",
        "query": "",
        "usr_token": usr_token,
    }

    const initialData   = {
        "lead_id": "",
        "lead_owner": "",
        "usr_token": usr_token,
    }

    const initialRefData = {
        "stage":"1",
        "lead_id": lead_id,
        "solution_creater_id": user_id,
        "solution_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const initialdeleteData = {
        "lead_id": lead_id,
        "file_id": "",
        "remark": "",
        "usr_token": usr_token,
    }

    const initialrejectData = {
        "lead_id": lead_id,
        "status": "22",
        "remark": "",
        "usr_token": usr_token,
    }

    const initialdeleteLead = {
        "lead_id": "",
        "remark": "",
        "usr_token": usr_token,
    }

    const [queryCreate, setQuerycreate] = React.useState(initialQueryData);
    const [bdUserListData, setBDUserListData]       = React.useState([""]);
    const [assignLeads, setAssignLead]              = React.useState(initialData);
    const [refUploadData, setRefUploadData]         = React.useState(initialRefData);
    const [show, setShow] = React.useState(false);
    const [loading, setLoading]                     = useState(false);
    const [selectedFile, setSelectedFile]           = React.useState([]);
    const [delDocFileId, setDelDocFileId]           = React.useState('');
    const [deleteLeadFile, setDeleteLeadFile]       = React.useState(initialdeleteData);
    const [rejectLeadData, setRejectLeadData]       = React.useState(initialrejectData);
    const [rejectRemark, setRejectRemark]           = React.useState('');
    const [deleteLead, setDeleteLead]               = React.useState(initialdeleteLead);

    const handleFormInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setQuerycreate(oldValue => ({
                ...oldValue,
                [name]: value,
                "lead_id": listViewData.data.id, 
            }))
        }
    };

    const handleRemarkInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setDeleteLeadFile(oldValue => ({
                ...oldValue,
                [name]: value,
                "file_id": delDocFileId
            }))
        }
    };    

    const handleLeadRemarkInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setDeleteLead(oldValue => ({
                ...oldValue,
                [name]: value,
                "lead_id": listViewData.data.id,
            }))
        }
    };   


    const handleLeadDelForm = async (e) => {
        setLoading(true);
        e.preventDefault();
    
        console.log(JSON.stringify(deleteLead))
    
        fetch(ulmsUrl.concat('/sdm_lead/delete'), {
            method: 'POST',
            body: JSON.stringify(deleteLead),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setDeleteLead('')
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getListViewData();
                navigate("/all_leads", { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setDeleteLead(deleteLead)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
      
    }

    const handleDocDeleteForm = async (e) => {
        setLoading(true);
        e.preventDefault();
    
       console.log(JSON.stringify(deleteLeadFile))
    
        fetch(ulmsUrl.concat('/files/del_ref_file'), {
            method: 'POST',
            body: JSON.stringify(deleteLeadFile),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setDeleteLeadFile('')
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getListViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/lead_view/${base64.encode(listViewData.data.id)}`, { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setDeleteLeadFile(deleteLeadFile)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        
    }

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
    
       console.log(JSON.stringify(queryCreate))
    
        fetch(ulmsUrl.concat(queryreateurl), {
            method: 'POST',
            body: JSON.stringify(queryCreate),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setQuerycreate(initialQueryData)
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getListViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/lead_view/${base64.encode(listViewData.data.id)}`, { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setQuerycreate(queryCreate)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        
      }

    const leadAssign = async(id,project_type,products,service_type) => {

        setShow(true);
       
        setAssignLead(oldValue => ({
          ...oldValue,
          "lead_id": id,
        }))
  
        console.log(JSON.stringify({service_type,project_type,products,usr_token}));
        const arr = [];
        try { 
            fetch(ulmsUrl.concat('/user/bd_user_dropdown') , {
                method: 'POST',
                body: JSON.stringify({service_type,project_type,products,usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                result?.map((data) => {
                    return arr.push({ value: data.id, label: data.value });
                });
                setBDUserListData(arr)
            });
        } catch (error) {
            console.log(error);
        }
          
    }

    const confirmCopy = (data) => {

        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure you want to Copy this lead?",
            buttons: [
              {
                label: "Yes",
                onClick: () => leadCopy(data)
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const leadCopy = async(id) => {

        let lead_id = id;

        try { 
            fetch(ulmsUrl.concat('/leads/copy') , {
                method: 'POST',
                body: JSON.stringify({lead_id,usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                } 
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {  
                if (result.status == true) {
                    sessionStorage.setItem("createmessage", result.message);
                    navigate("/all_leads", { replace: true })
    
                } else if (result.status == false) {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: 'success1',
                    });
                
                }
                
            });
        } catch (error) {
            console.log(error);
        }
        
    }

    const confirmdialog = (data, status) => {

        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure you want to reject this lead?",
            buttons: [
              {
                label: "Yes",
                // onClick: () => rejectLead(data, status)
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const handleRejectInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setRejectLeadData(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    };

    const rejectLeadApi = async(e) => {   
        e.preventDefault();
        console.log(rejectLeadData);

        try { 
            fetch(ulmsUrl.concat('/sdm_lead/leads_action') , {
                method: 'POST',
                body: JSON.stringify(rejectLeadData),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {  
                if (result.status == true) {
                    sessionStorage.setItem("createmessage", result.message);
                    navigate("/all_leads", { replace: true })
    
                } else if (result.status == false) {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: 'success1',
                    });
                
                }
                
            });
        } catch (error) {
            console.log(error);
        }

    }
    
    console.log(show);
    useEffect(() => {
      leadAssign();
  
    }, []);

    const handleLeadSourceInput = (value) => { 
        setAssignLead(oldValue => ({
            ...oldValue,
            "lead_owner": value,
        }))  
      };

    const handleReAssignForm = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        fetch(ulmsUrl.concat(leadallocateurl), {
            method: 'POST',
            body: JSON.stringify(assignLeads),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setAssignLead(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getListViewData();
                setShow(false);
                setLoading(false);
                //sessionStorage.setItem("createmessage", result.message);
               navigate(`/lead_view/${base64.encode(listViewData.data.id)}`, { replace: true })
  
            } else if (result.status == false) {
                setLoading(false);
                setAssignLead(assignLeads)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const handleAssignForm = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        fetch(ulmsUrl.concat(leadassignurl), {
            method: 'POST',
            body: JSON.stringify(assignLeads),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setLoading(false);
                setAssignLead(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getListViewData();
                setShow(false);
                //sessionStorage.setItem("createmessage", result.message);
               navigate(`/lead_view/${base64.encode(listViewData.data.id)}`, { replace: true })
  
            } else if (result.status == false) {
                setLoading(false);
                setAssignLead(assignLeads)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const handleFileInput = (e) => {
        console.log(e.target.files); 
        setSelectedFile(e.target.files)
        console.log(selectedFile);
    };

    const handleRefFileSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        console.log(JSON.stringify(refUploadData))
  
        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`fileTest`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(refUploadData));
        console.log(formData);  
  
        fetch(ulmsUrl.concat("/wip/ref_file"), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                aRef.current.value = null;
                setRefUploadData(refUploadData);
                toast.success(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
                getListViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/lead_view/${base64.encode(lead_id)}`, { replace: true });
  
            } else if (result.status == false) {
                setLoading(false);
                setRefUploadData(refUploadData);
                toast.error(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
  
    }

    function handleCloseModal(){            
        document.getElementById("exampleModal").classList.remove("show", "d-block");
        document.getElementById("leadassignModal").classList.remove("show", "d-block");
        document.getElementById("deleteremarkModal").classList.remove("show", "d-block");
        document.getElementById("rejectremarkModal").classList.remove("show", "d-block");
        document.getElementById("deleteleadremarkModal").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop")
        .forEach(el => el.classList.remove("modal-backdrop"));
        document.body.style.overflow = 'unset';
     }

      const myComponent = {
       
        height: '500px',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: '20px'
    };

   
    if(usermessage != ''){
        setTimeout(() => {
          setMessage('');
        }, 8000);    
    }
   return (
    <>
     {/* {usermessage && <div className="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{usermessage}</strong>    
        </div>} */}
    <ToastContainer />
    {loading && (
        <>
        <Loader/>
        </>
    ) }
    <div className="card" style={{overflowX:'auto'}}>
        <div className="card-body">
            <div className="card-header" id="combination">
                <h3 className="card-title">LEAD DETAILS ( LEAD ID: {lead_id} )</h3>
                <div className='All_three_button'>
                {listViewData != '' && (listViewData.data.button == 2) &&
                    <button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right'}} onClick={()=>leadAssign(listViewData.data.id,listViewData.data.project_type,listViewData.data.products,listViewData.data.service_type)}  data-bs-toggle="modal" data-bs-target="#leadassignModal" >Lead Assign To BD</button>  
                }
                {listViewData != '' && (listViewData.data.reassign == 2) &&
                    <button className="btn btn-primary btn-sm  me-2" id="re-assign" 
                    style={{font_size: '12px', float: 'right'}} 
                    onClick={()=>leadAssign(listViewData.data.id,listViewData.data.project_type,listViewData.data.products,listViewData.data.service_type)} 
                     data-bs-toggle="modal" data-bs-target="#exampleModal"><strong> Lead Re-assign To BD</strong></button>  
                }
              
                {(listViewData != '' && listViewData.data.status == 3 && listViewData.data.lead_owner_id == user_id && listViewData.data.project_type == 'AUTO' && 
                listViewData.data.service_type == 'NRP' &&  (listViewData.data.products == 'RM' || listViewData.data.products == 'FG') ) &&
                    <Link to={`/create_opportunity/${base64.encode(listViewData.data.id)}`} ><button style={{font_size: '6px', float: 'right'}}
                     type="button" className="btn btn-primary btn-sm  me-2"><strong>Create Opportunity</strong></button></Link>
                }
                {(listViewData != '' && listViewData.data.status == 3 && listViewData.data.lead_owner_id == user_id && listViewData.data.project_type == 'AUTO' && 
                (listViewData.data.service_type == 'RP' || listViewData.data.service_type == 'LS') 
                && (listViewData.data.products == 'RM' || listViewData.data.products == 'FG')) &&
                    <Link to={`/create_rpopportunity/${base64.encode(listViewData.data.id)}`} ><button style={{font_size: '6px', float: 'right'}} 
                    type="button" className="btn btn-primary btn-sm  me-2"><strong>Create Opportunity</strong></button></Link>
                }
                {(listViewData != '' && listViewData.data.status == 3 && listViewData.data.lead_owner_id == user_id && listViewData.data.project_type == 'RETAIL') &&
                    <Link to={`/create_reopportunity/${base64.encode(listViewData.data.id)}`} ><button style={{font_size: '6px', float: 'right'}} type="button" className="btn btn-primary btn-sm  me-2"><strong>Create Opportunity</strong></button></Link>
                }
                {listViewData != '' && (listViewData.data.rejbutton == 2) &&  
                    <button className="btn btn-primary btn-sm  me-2" style={{font_size: '12px', float: 'right'}}  data-bs-toggle="modal" data-bs-target="#rejectremarkModal" ><strong>Reject</strong></button>   
                }
                {listViewData != '' && (listViewData.data.LeadEditButtonShow == 1) &&
                   <Link to={`/leads_edit/${base64.encode(listViewData.data.id)}`}  ><button className='btn btn-primary btn-sm  me-2'><strong>Edit Lead</strong></button></Link> 
                }
                {listViewData != '' && (listViewData.data.status > 2 && listViewData.data.lead_owner_id == user_id) &&  
                    <strong><i title='copy lead' id='copy_lead_btn' onClick={()=>confirmCopy(listViewData.data.id)} className="fa fa-copy" style={{fontSize:'30px',color:'#0D4C7B'}}></i></strong>
                }
                {listViewData != '' && (listViewData.data.LeadDelButton == 1) &&
                    <Link onClick={()=>setLeadId(listViewData.data.id)} style={{marginLeft: '10px'}} data-bs-toggle="modal" data-bs-target="#deleteleadremarkModal" ><span title='Delete lead' ><i className="fa fa-trash-o" style={{fontSize:'30px',color:'red'}}></i></span></Link> 
                }
                <Link onClick={() => navigate(-1)}><strong><i title='back' className="fa fa-arrow-circle-left" style={{fontSize:'30px',color:'#0D4C7B',marginLeft: '10px'}}></i></strong></Link>
                </div>
            </div>
            <div className="invoice row">
                <div style={{float: 'left'}} className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                                <th>LOB</th>
                                <td >{listViewData != '' && listViewData.data.project_type}</td>
                            </tr>
                            <tr>
                                <th>Product Type</th>
                                <td>{listViewData != '' && listViewData.data.products}</td>
                            </tr>
                            <tr>
                                <th>Service Type</th>
                                <td>{listViewData != '' && listViewData.data.service_type}</td>
                            </tr>
                            <tr>
                                <th>Supplier Name</th>
                                {/* <td >{listViewData != '' && listViewData.data.supplier_name}</td> */}
                                {(listViewData != '' && listViewData.data.supplier_name_id == 666666) &&
                                  <td>{listViewData.data.new_supplier_name}</td> 
                                }
                                {(listViewData != '' && listViewData.data.supplier_name_id != 666666) &&
                                  <td>{listViewData.data.supplier_name}</td>
                                }
                            </tr>
                            <tr>
                                <th >Entity Name</th>
                                {(listViewData != '' && listViewData.data.supplier_name_id == 666666) &&
                                  <td>{listViewData.data.new_supplier_name}</td> 
                                }
                                {(listViewData != '' && listViewData.data.supplier_name_id != 666666) &&
                                  <td>{listViewData != '' && listViewData.data.entity_name}</td>
                                }
                            </tr>  
                            <tr>
                                <th>Supplier Location</th>
                                {(listViewData != '' && listViewData.data.supplier_name_id == 666666) &&    
                                  <td>{listViewData.data.new_supplier_loc}</td> 
                                }
                                {(listViewData != '' && listViewData.data.supplier_name_id != 666666) &&
                                  <td>{listViewData != '' && listViewData.data.supplier_loc}</td>
                                }
                            </tr>
                            <tr>
                                <th>LS Services:</th>
                                {listViewData != '' && listViewData.data.multi_ls.length > 0 ?
                                    <td>
                                    {listViewData.data.multi_ls.map((data) => (  
                                        <>{data}  </> 
                                    ))} 
                                    </td> :
                                    <td>-</td> 
                                }  
                            </tr>
                            {listViewData != '' && listViewData.data.service_type == 'RP' &&
                            <tr>
                                <th>OEM Name</th>
                                {/* <td >{listViewData != '' && listViewData.data.supplier_name}</td> */}
                                {(listViewData != '' && listViewData.data.oem_name_id == 666666) &&
                                <td>{listViewData.data.new_oem_name}</td> 
                                }
                                {(listViewData != '' && listViewData.data.oem_name_id != 666666) &&
                                <td>{listViewData.data.oem_name}</td>
                                }
                            </tr>
                            }
                                    
                        </tbody>
                    </table>
                </div> 
                <div className="table-scrollable col-md-6">
                    <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            {listViewData != '' && listViewData.data.service_type == 'RP' &&
                            <tr>
                                <th >OEM Entity Name</th>
                                {(listViewData != '' && listViewData.data.oem_name_id == 666666) &&
                                <td>{listViewData.data.new_oem_name}</td> 
                                }
                                {(listViewData != '' && listViewData.data.oem_name_id != 666666) &&
                                <td>{listViewData != '' && listViewData.data.oem_entity_name}</td>
                                }
                            </tr> 
                            }     
                            {listViewData != '' && listViewData.data.service_type == 'RP' &&
                            <tr>
                                <th>OEM Location</th>
                                {(listViewData != '' && listViewData.data.oem_name_id == 666666) &&    
                                  <td>{listViewData.data.new_oem_loc}</td> 
                                }
                                {(listViewData != '' && listViewData.data.oem_name_id != 666666) &&
                                  <td>{listViewData != '' && listViewData.data.oem_loc}</td>
                                }
                            </tr>
                            }
                            <tr>
                                <th>Industry Vertical</th>
                                <td>{listViewData != '' && listViewData.data.cust_industry_verticle}</td>
                            </tr> 
                            {/* <tr>
                                <th>Start Date</th>
                                <td >{listViewData != '' && listViewData.data.start_date}</td>
                            </tr> */}
                            <tr>
                                <th>Lead Description</th>
                                <td >{listViewData != '' && listViewData.data.lead_desc}</td>
                            </tr>
                            <tr>
                                <th>Lead Requester Name</th>
                                <td >{listViewData != '' && listViewData.data.lead_req_name}</td>
                            </tr> 
                            <tr>
                                <th>Lead Owner Name</th>
                                {(listViewData != '' && listViewData.data.lead_owner_name != null) ?
                                  <td>{listViewData.data.lead_owner_name}</td> :
                                  <td>-</td>
                                }
                            </tr> 
                            <tr>
                                <th>Lead Status</th>
                                <td>
                                {listViewData != '' &&  listViewData.data.status == 2 &&
                                  <span className="text-secondary">Lead Pending at BD Head ({listViewData.data.bd_head_name}) </span>
                                }
                                {listViewData != '' &&  listViewData.data.status == 3 &&
                                  <span className="text-warning">Lead Assigned ({listViewData.data.lead_owner_name}) </span>
                                }
                               </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
                <div style={{float: 'left'}} className="table-scrollable col-md-6">
                     <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                        <thead className="thead-light">
                        </thead>
                        <tbody>
                            <tr>
                               <th>Lead Doc</th>
                                <td>
                                {listViewData != '' && listViewData.packingImg.map((data) => (  
                                    <>
                                        <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'-2px'}} >{data.img}</span></Link>
                                        { (listViewData.data.lead_req_id == user_id || listViewData.data.lead_owner_id == user_id) &&
                                        <img
                                            className='cross-icon'
                                            src='../../ulms_assets/images/cross_icon.png'
                                            alt="close icon"
                                            data-bs-toggle="modal" data-bs-target="#deleteremarkModal"
                                            title='Delete ref file'
                                            onClick={()=>setDelDocFileId(data.file_id)}
                                        /> }
                                    </>
                                    ))}  
                                </td>
                            </tr>
                          
                        </tbody>
                    </table>
                </div> 
            
                <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                    <table id="mainTable" className="table padded-table">
                    <thead>
                    </thead>
                        <tbody>                               
                            <tr>
                                <th>Customer Contact Name</th>
                                <th>Customer Contact Number</th>
                                <th>Customer Contact Email ID</th>
                                <th>Designation</th>
                            </tr>
                            {listViewData != '' && listViewData.PocInfo.map((pocdata, index) => (  
                                <tr key={index}>  
                                    <td>{pocdata.PocName}</td>  
                                    <td>{pocdata.PocNo}</td>  
                                    <td>{pocdata.PocEmail}</td>  
                                    <td>{pocdata.Designation}</td>
                                </tr>  
                            ))}  
                        </tbody>
                    </table>
                </div>
                <div className=' col-md-6'>
                    <div className='row'>
                    <form className="row g-3" encType="multipart/form-data" onSubmit={handleRefFileSubmit} >
                        <div className="col-md-9" controlid="formFileMultiple" >
                            <Form.Label>Lead Related Ref. File (File name should be in alphanumeric , - , _ , . ):</Form.Label>
                            <Form.Control multiple type="file" 
                                ref={aRef}  
                                name="opportunity_photo_web"
                                onChange={handleFileInput}
                            />
                        </div>
                        <div className="col-md-2" style={{marginTop: '40px'}}>
                            <button type="submit" className="btn btn-primary">Upload</button>
                        </div>  
                    </form>
                    </div>
                {/* {listViewData != '' && (listViewData.data.reassign == 2 || listViewData.data.reassign == 24) &&
                    <Link onClick={()=>leadAssign(listViewData.data.id,listViewData.data.project_type,listViewData.data.products,listViewData.data.service_type)} ><span className='badge bg-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Reassign To BD</span></Link> 
                }
                 */}
                </div>
                
                <div className="col-md-6" style={myComponent} >
                    <div className="card-title">
                        <p>Chat</p>
                    </div>

                    {/* begin::messages  */}
                    {listViewData != '' && listViewData.AdditionInfo.map((chatdata) => (  
                    <div>
                        {chatdata.UserId != user_id  &&
                        <div className="messages" style={{float: 'left'}}>
                            <div className="message-item col-md-12">
                                <span className="time small text-muted font-italic" >{chatdata.UserName}</span>
                                <div className='row'>
                                 <div className="alert alert-primary col-md-10" >{chatdata.LeadQuery}</div>
                                 {/* <span className="time small text-muted font-italic col-md-2">02:30 PM </span> */}
                                </div>
                            </div>
                        </div>
                        }
                        {chatdata.UserId == user_id  &&
                        <div className="messages" style={{ }}>
                            <div className="message-item col-md-12" style={{ whiteSpace: 'pre-wrap'}}>
                                <span className="time small text-muted font-italic " style={{ width:'100%', textAlign:'right'}}>{chatdata.UserName}</span>
                                <div className='row'>
                                <div className="alert alert-success col-md-10" style={{ whiteSpace: 'pre-wrap'}}>{chatdata.LeadQuery}</div>
                                {/* <span className="time small text-muted font-italic col-md-2">02:30 PM </span> */}
                                </div>
                            </div>
                        </div>
                        
                        }                            
                    </div>
                    ))}    
                    {/* end::messages  */}

                    {/* begin::chat footer  */}
                    <div className="chat-footer">
                        <form className="d-flex col-md-12" onSubmit={handleFormSubmit} >
                            <button className="btn btn-primary btn-rounded flex-shrink-0" type="button" style={{marginRight:'5px', marginBottom:'5px'}}>
                                {/* <i className="bi bi-three-dots"></i> */}
                            </button>
                            {/* <div className="dropdown-menu">
                                <a href="#" className="dropdown-item">Add Emoji</a>
                                <a href="#" className="dropdown-item">Attach files</a>
                            </div> */}
                            
                            <Form.Control 
                            type="text" 
                            className="form-control" autoFocus 
                            placeholder="Write message..."
                            name='query'
                            value={queryCreate.query}
                            onChange={handleFormInput}
                            />
                            <button className="btn btn-primary btn-rounded flex-shrink-0 ms-3" type="submit" >Send</button>
                        </form>
                    </div>
                    {/* end::chat footer  */}

                </div>
            </div>
        </div>
    </div>
        { show == true &&
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>{usermessage}</strong>
                </div>} */}
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"> Allocate Lead </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form className="row g-3" onSubmit={handleReAssignForm}>
                <div className="modal-body">
                    <div className="col-md-6">
                        <label htmlFor="inputState" className="form-label"> Select BD User : </label> 
                        <Select
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="bd_user"
                            options={bdUserListData}
                            onChange={handleLeadSourceInput}
                        />
                    </div> 
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                </div>
            </form>
            </div>
        </div>
        </div>
        }
        { show == true &&
        <div className="modal fade" id="leadassignModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>{usermessage}</strong>
                </div>} */}
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"> Allocate Lead </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form className="row g-3" onSubmit={handleAssignForm}>
                <div className="modal-body">
                    <div className="col-md-6">
                        <label htmlFor="inputState" className="form-label"> Select BD User : </label> 
                        <Select
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="bd_user"
                            options={bdUserListData}
                            onChange={handleLeadSourceInput}
                        />
                    </div> 
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                </div>
            </form>
            </div>
        </div>
        </div>
        }
        <div className="modal fade" id="deleteremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Reference File Remark</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <Form onSubmit={handleDocDeleteForm}>
                        <div className="modal-body">
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>Enter Delete Remark:</Form.Label>
                                <Form.Control multiple type="text"  
                                name="remark"
                                value={deleteLeadFile.remark}
                                onChange={handleRemarkInput}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <div className="modal fade" id="rejectremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Lead Reject Remark</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <Form onSubmit={rejectLeadApi}>
                        <div className="modal-body"> 
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>Enter Reject Remark:</Form.Label>
                                <Form.Control multiple type="text"  
                                name="remark"
                                value={rejectLeadData.remark}
                                onChange={handleRejectInput}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <div className="modal fade" id="deleteleadremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Lead Delete Remark</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <Form onSubmit={handleLeadDelForm}>
                        <div className="modal-body"> 
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>Enter Delete Remark:</Form.Label>
                                <Form.Control multiple type="text"  
                                name="remark"
                                value={deleteLead.remark}
                                onChange={handleLeadRemarkInput}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </>
  )
}

export default LeadView