import logo from './logo.svg';
import { BrowserRouter, Route, Routes, Router, useNavigate } from "react-router-dom";
import './App.css';
import Admin from './Admin';
import Login from './auth/Login';
import UnAuthPages from './auth/UnAuthPages';


function App() {
  const isUserLogin = localStorage.getItem('isAuthenticatedUser');
  const navigate = useNavigate();
  if(isUserLogin == 'true'){
    return ( 
      <div>
          <Admin/>
      </div>   
  );

  }

  return (
  
      <div>
          <UnAuthPages/>
      </div>     
  
  );
}

export default App;
