import React, { useCallback, useReducer } from 'react';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { useNavigate,NavLink, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';

const Client = () => {

    const clientlisturl = '/mst/client_list';

    const navigate = useNavigate();
    var base64 = require('base-64');
    const usr_token = localStorage.getItem('authToken');
    const isUserLogin = localStorage.getItem('isAuthenticatedUser');
    let message = sessionStorage.getItem("rolemessage");

    const [ClientList, setClientList]  = React.useState([]);
    const [search ,setSearch]  = React.useState("");
    const [filteredClient ,setFilteredClient]  = React.useState([]);
    const [ isAlertVisible, setIsAlertVisible ] = React.useState(true);
    const [isusermessage, issetMessage] = React.useState(null);
    const [errormessage, isseterrorMessage] = React.useState(null);
    const [loading, setLoading] = useState(true);


    const getClientData = async () => {
        setLoading(true);
              
        try {
            fetch(ulmsUrl.concat(clientlisturl) , {
                method: 'POST',
                body:JSON.stringify({usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                setLoading(false);
                let result = response.data;
                setClientList(result)
                setFilteredClient(result);
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() =>{
        getClientData();
    }, []);

    console.log(ClientList);

    useEffect(() => {
        const filterResult = ClientList.filter(data => {
             return (data.client_name?.toLowerCase().match(search?.toLowerCase()) || data.client_code?.toLowerCase().match(search?.toLowerCase()) || data.clientStatus?.toLowerCase().match(search?.toLowerCase()));  
        });
   
        setFilteredClient(filterResult);
    }, [search]); 

    const columns = [
        { 
          name: "ID",
          selector : (row) => row.id,
          width : '180px',
          sortable :  true},
        {
          name: "Client Code",
          width : '200px',
          sortable :  true,    
          selector : (row) => row.client_code,     
        },
        {
          name: "Client Name",
          width : '350px',
          sortable :  true,    
          selector : (row) => 
          {
            return(
                <Link to={`/client_view/${base64.encode(row.id)}`} ><span className='text-info' >{row.client_name}</span></Link>
            )
          }     
        },
        {
          name: "State Name",
          width : '250px',
          sortable :  true,    
          selector : (row) => row.state_name,     
        },
        {
            name: "Contact Person",
            width : '200px',
            cell : (row) => row.ctc_person,     
        }, 
        {
          name: "Status",
          width : '200px',
          sortable :  true,  
          selector : (row) => 
          {
            if(row.clientStatus == 'Pending'){
              return(
                  <span className="badge bg-info">{row.clientStatus}</span>
              )
            }else if(row.clientStatus == 'Active'){
                return(
                  <span className="badge bg-success">{row.clientStatus}</span>
                )
            }else if(row.clientStatus == 'Deleted'){
              return(
                <span className="badge bg-danger">{row.clientStatus} </span>
              )
            }
          }
        },
        
    ];

  return (
    <>
        {isusermessage &&  <div className="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{isusermessage}</strong>
        </div>}
        {loading && (
            <>
            <Loader/>
            </>
        )}
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title" style={{float:'left'}}>CLIENT LIST</h3>    
                        <CSVLink style={{font_size: '12px', float: 'right'}} className="btn btn-success btn-sm  me-2" 
                        data={filteredClient}
                        filename={"Clients.csv"}
                        >Export</CSVLink>
                    </div>
                    
                    <div className="card-body">         
                        <DataTable 
                            id="table-to-xls"
                            data={filteredClient} 
                            columns={columns} 
                            pagination
                            fixedHeader
                            highlightOnHover
                            subHeader
                            subHeaderComponent = {
                            <div className="col-md-3" style={{float:'right'}}>
                            <input type="text" 
                            placeholder='Search here' 
                            className='w-22 form-control'
                            value={search}
                            onChange={(e)=> setSearch(e.target.value)}
                            />
                            </div>
                            }
                        />
                        
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Client