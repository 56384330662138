import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { LocationData, ComponentFamily, OperationTypeList, DistrictData, PortList} from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RetailOpportunity = () => {

    const { locationOptions }  = LocationData();
    const { comFamOptions }    = ComponentFamily();
    const { operatTypeListData }    = OperationTypeList();
    const { districtOptions }  = DistrictData();
    const { portListData }     = PortList();

    let base64 = require('base-64');
    const opportcreateurl = "/opportunity/create";
    const partialsaveurl  = "/opportunity/save";
    const navigate = useNavigate();
    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id = localStorage.getItem('usrId');

    const initialData = {
        "lead_id": lead_id,
        "service_for": "",
        "origin": "",
        "destination": "",
        "port_origin": "",
        "port_destination": "",
        "business_tenure": "",
        "potential_rev": "",
        "potential_capex": "",
        "potential_ebitda": "",
        "expected_closure_date": "",
        "reference_url": "",
        "opportunity_desc": "",
        "opportunity_creater_id": usr_id,
        "opportunity_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const optionarr = [
        { value: '4', label: 'Test1' },
        { value: '5', label: 'Test2' },
        { value: '2', label: 'Test3' },
        { value: '666666', label: 'Other' }
    ]

    const [opportunityCreate, setOpportunitycreate] = React.useState(initialData);
    const [leadViewData, setLeadViewData] = React.useState([""]);
    const [usermessage, setMessage] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [otherselected, setOtherSelected] = React.useState(optionarr[0]);
    const [locationSelected, setLocationSelected] = useState('');
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState([
        {  
           operation_type: "", 
           area_required: '', 
           inventory_days: '', 
           avg_daily_inbound: '',
           avg_daily_outbound: '',
        }
    ]);

    const getOpportunityData = async () => {
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/wip/opp_temp_details') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;
                console.log(result);
                setOpportunitycreate(result.data)
                setInputFields(result.multi_data);
            });
        } catch (error) {
            console.log(error);
        }
    }

    console.log(opportunityCreate);

    const getListViewData = async () => {
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/sdm_lead/view') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;
                setLeadViewData(result)
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getListViewData();
        getOpportunityData();
    }, []);

    //console.log(leadViewData);

    const handleFormInput = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setOpportunitycreate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
        if(ebidtaperc != 0){
         setOpportunitycreate(oldValue => ({
            ...oldValue,
            "ebidta_perc": ebidtaperc,
          }))
        }
    };

    const handleOriginInput = (data) => {
        const test = data.label;
        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "origin": data,
        }))  
    }

    const handlePortOriginInput = (data) => {
        const test = data.label;
        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "port_origin": data,
        }))  
    }

    const handleDestinationInput = (data) => {
        const test = data.label;
        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "destination": data,
        }))  
    }

    const handlePortDestinationInput = (data) => {
        const test = data.label;
        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "port_destination": data,
        }))  
    }

    const handleOperTypeInput = (index,event) => { 
        const list = [...inputFields];
        list[index]["operation_type"] = event;

        setOpportunitycreate(oldValue => ({
            ...oldValue,
            "multi_data": list,
        }))  
    };

    const addFields = () => {
        let newfield = { 
           operation_type: "", 
           area_required: '', 
           inventory_days: '', 
           avg_daily_inbound: '',
           avg_daily_outbound: '',
        }
        setInputFields([...inputFields, newfield])
    }

    const handleChange = (index, event) => {
       const { name, value } = event.target;
       const list = [...inputFields];
       list[index][name] = value;
       setOpportunitycreate(oldValue => ({
        ...oldValue,
        "multi_data": list,
      }))  

    }

    const removeInputFields = (index, event) => {
        // setInputFields(inputFields.filter((v, i) => i !== index));
         const rows = [...inputFields];
         rows.splice(index, 1);
         setInputFields(rows);
    }

    let ebidtaperc = 0;
    if(opportunityCreate.exp_monthly_revenue != '' && opportunityCreate.exp_monthly_revenue != null && opportunityCreate.exp_monthly_expense != ''  && opportunityCreate.exp_monthly_expense != null){
      let revenue = Number(opportunityCreate.exp_monthly_revenue);
      let expense = Number(opportunityCreate.exp_monthly_expense);
      let ebitadata = (revenue - expense) / revenue;
      ebidtaperc = ebitadata * 100;
    }


    const handleFileInput = (e) => {
        console.log(e.target.files); 
        setSelectedFile(e.target.files)
        console.log(selectedFile);
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const createdata = {
            lead_id : lead_id,
            service_for: opportunityCreate.service_for,
            origin :  opportunityCreate.origin,
            destination :  opportunityCreate.destination,
            port_origin :  opportunityCreate.port_origin,
            port_destination :  opportunityCreate.port_destination,
            business_tenure :  opportunityCreate.business_tenure,
            potential_rev :  opportunityCreate.potential_rev,
            potential_capex :  opportunityCreate.potential_capex,
            potential_ebitda :  opportunityCreate.potential_ebitda,
            expected_closure_date :  opportunityCreate.expected_closure_date,
            multi_data :  opportunityCreate.multi_data,
            reference_url :  opportunityCreate.reference_url,
            opportunity_desc :  opportunityCreate.opportunity_desc,
            multi_data: inputFields,
            opportunity_creater_id : usr_id,
            opportunity_creater_name : usr_name,
            usr_token : usr_token
        }

        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`opportunity_photo_web`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(createdata));
        console.log(JSON.stringify(createdata));

        fetch(ulmsUrl.concat(opportcreateurl), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setOpportunitycreate(initialData);
                // toast.success(result.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     toastId: 'success1',
                // });
                sessionStorage.setItem("createmessage", result.message);
                setLoading(false);
                navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setOpportunitycreate(opportunityCreate);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
         })
    }

    const handlePartialSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const savedata = {
            lead_id : lead_id,
            service_for: opportunityCreate.service_for,
            origin :  opportunityCreate.origin,
            destination :  opportunityCreate.destination,
            port_origin :  opportunityCreate.port_origin,
            port_destination :  opportunityCreate.port_destination,
            business_tenure :  opportunityCreate.business_tenure,
            potential_rev :  opportunityCreate.potential_rev,
            potential_capex :  opportunityCreate.potential_capex,
            potential_ebitda :  opportunityCreate.potential_ebitda,
            expected_closure_date :  opportunityCreate.expected_closure_date,
            reference_url :  opportunityCreate.reference_url,
            opportunity_desc :  opportunityCreate.opportunity_desc,
            multi_data: inputFields,
            opportunity_creater_id : usr_id,
            opportunity_creater_name : usr_name,
            usr_token : usr_token
        }

        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`opportunity_photo_web`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(savedata));
        console.log(JSON.stringify(savedata));

        fetch(ulmsUrl.concat(partialsaveurl), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setOpportunitycreate(initialData);
                setLoading(false);
                // toast.success(result.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     toastId: 'success1',
                // });
                sessionStorage.setItem("createmessage", result.message);
                navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setOpportunitycreate(opportunityCreate);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

  return (
    <>
  {/* {usermessage && <div className="alert alert-danger alert-dismissible fade show" role="alert">
           <strong>{usermessage}</strong>    
       </div>} */}
       <ToastContainer />
       {loading && (
          <>
            <Loader/>
          </>
        ) }
       <div className="row">
           <div className="order-2 order-lg-1 col-lg-12 bd-content">
               <div className="card">
                   <div className="card-header">
                       <h3 className="card-title">CREATE OPPORTUNITY ( LEAD ID: {lead_id} )</h3>
                   </div>
                   <div className="card-body">
                       <div className="invoice row">
                            <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>LOB</th>
                                            <td >{leadViewData != '' && leadViewData.data.project_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Product Type</th>
                                            <td>{leadViewData != '' && leadViewData.data.products}</td>
                                        </tr>
                                        <tr>
                                            <th>Service Type</th>
                                            <td>{leadViewData != '' && leadViewData.data.service_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Supplier Name</th>
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id == 666666) &&
                                            <td>{leadViewData.data.new_supplier_name}</td> 
                                            }
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id != 666666) &&
                                            <td>{leadViewData.data.supplier_name}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th >Entity Name</th>
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id == 666666) &&
                                            <td>{leadViewData.data.new_supplier_name}</td> 
                                            }
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id != 666666) &&
                                            <td>{leadViewData != '' && leadViewData.data.entity_name}</td>
                                            }
                                        </tr>  
                                        <tr>
                                            <th>Supplier Location</th>
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id == 666666) &&    
                                            <td>{leadViewData.data.new_supplier_loc}</td> 
                                            }
                                            {(leadViewData != '' && leadViewData.data.supplier_name_id != 666666) &&
                                            <td>{leadViewData != '' && leadViewData.data.supplier_loc}</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>LS Services:</th>
                                            {leadViewData != '' && leadViewData.data.multi_ls.length > 0 ?
                                                <td>
                                                {leadViewData.data.multi_ls.map((data) => (  
                                                    <>{data}  </> 
                                                ))} 
                                                </td> :
                                                <td>-</td> 
                                            }  
                                        </tr>
                                        <tr>
                                            <th>Industry Vertical</th>
                                            <td>{leadViewData != '' && leadViewData.data.cust_industry_verticle}</td>
                                        </tr>
                                        <tr>
                                            <th>First Contact Date</th>
                                            <td >{leadViewData != '' && leadViewData.data.start_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Description</th>
                                            <td >{leadViewData != '' && leadViewData.data.lead_desc}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Requester Name</th>
                                            <td >{leadViewData != '' && leadViewData.data.lead_req_name}</td>
                                        </tr> 
                                        <tr>
                                            <th>Lead Owner Name</th>
                                            {(leadViewData != '' && leadViewData.data.lead_owner_name != null) ?
                                            <td>{leadViewData.data.lead_owner_name}</td> :
                                            <td>-</td>
                                            }
                                        </tr> 
                                        <tr>
                                            <th>Lead Status</th>
                                            <td>
                                                {leadViewData != '' &&  leadViewData.data.status == 2 &&
                                                <span className="text-secondary">Lead Pending at BD Head </span>
                                                }
                                                {leadViewData != '' &&  leadViewData.data.status == 3 &&
                                                <span className="text-warning">Lead Assigned ({leadViewData.data.lead_owner_name}) </span>
                                                }
                                           </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-12">
                                {/* <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Location</th>
                                            <td>{leadViewData != '' && leadViewData.data.supplier_loc}</td>
                                        </tr>
                                    
                                    </tbody>
                                </table> */}
                            </div> 
                        </div>
                       <form className="row g-3" encType="multipart/form-data" style={{marginTop: '5px'}}>
                            <div className="col-md-12">  
                                <Form.Label style={{marginRight: '15px'}} >Service For :<span className="required-mark">*</span></Form.Label>
                                <Form.Check
                                    inline
                                    label="Domestic"
                                    name="service_for"
                                    value="Domestic"
                                    type= "radio"
                                    onClick={()=>setLocationSelected("Domestic")}
                                    onChange={handleFormInput}
                                    checked = {opportunityCreate.service_for === "Domestic" ? true : false}
                                    id={`inline-radio-2`}
                                />
                                <Form.Check
                                    inline
                                    label="International"
                                    name="service_for"
                                    value="International"
                                    type= "radio"
                                    onClick={()=>setLocationSelected("International")}
                                    onChange={handleFormInput}
                                    checked = {opportunityCreate.service_for === "International" ? true : false}
                                    id={`inline-radio-1`}
                                />
                            </div>
                            {(opportunityCreate.service_for == "Domestic") &&
                            <>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label"> Origin :<span className="required-mark">*</span> </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="origin"
                                        options={districtOptions}
                                        defaultValue={opportunityCreate.origin}
                                        onChange={handleOriginInput}
                                        key={opportunityCreate.origin}
                                    />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label"> Destination :<span className="required-mark">*</span> </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="destination"
                                        options={districtOptions}
                                        defaultValue={opportunityCreate.destination}
                                        onChange={handleDestinationInput}
                                        key={opportunityCreate.destination}
                                    />
                                </div>
                            </>}
                            {opportunityCreate.service_for == "International" &&
                            <>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label"> Origin :<span className="required-mark">*</span> </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="port_origin"
                                        options={portListData}
                                        defaultValue={opportunityCreate.port_origin}
                                        onChange={handlePortOriginInput}
                                        key={opportunityCreate.port_origin}
                                    />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label"> Destination :<span className="required-mark">*</span> </label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="port_destination"
                                        options={portListData}
                                        defaultValue={opportunityCreate.port_destination}
                                        onChange={handlePortDestinationInput}
                                        key={opportunityCreate.port_destination}
                                    />
                                </div>
                            </>}
                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                <label htmlFor="inputState" className="form-label">Business Tenure (In months) :<span className="required-mark">*</span> </label>
                                <Form.Control
                                    type="number"
                                    name="business_tenure"
                                    value={opportunityCreate.business_tenure}
                                    onChange={handleFormInput}
                                    id="business_tenure"
                                    className="form-control" placeholder="Enter Business Tenure" />      
                            </div>
                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                <label htmlFor="inputState" className="form-label">Potential Revenue :<span className="required-mark">*</span> </label>
                                <Form.Control
                                    type="number"
                                    name="potential_rev"
                                    value={opportunityCreate.potential_rev}
                                    onChange={handleFormInput}
                                    id="potential_rev"
                                    className="form-control" placeholder="Enter Potential Revenue" />      
                            </div>
                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                <label htmlFor="inputState" className="form-label">Potential Capex :<span className="required-mark">*</span> </label>
                                <Form.Control
                                    type="number"
                                    name="potential_capex"
                                    value={opportunityCreate.potential_capex}
                                    onChange={handleFormInput}
                                    id="potential_capex"
                                    className="form-control" placeholder="Enter Potential Capex" />      
                            </div>
                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                <label htmlFor="inputState" className="form-label">Potential Ebitda :<span className="required-mark">*</span> </label>
                                <Form.Control
                                    type="number"
                                    name="potential_ebitda"
                                    value={opportunityCreate.potential_ebitda}
                                    onChange={handleFormInput}
                                    id="potential_ebitda"
                                    className="form-control" placeholder="Enter Potential Ebitda" />      
                            </div>
                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Expected Closure Date:<span className="required-mark">*</span></Form.Label>
                                    <Form.Control
                                    type="date"
                                    name="expected_closure_date"
                                    value={opportunityCreate.expected_closure_date}
                                    onChange={handleFormInput}
                                    id="expected_closure_date"
                                    className="form-control"  />
                            </div>
                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                <Form.Label>Reference url :</Form.Label>
                                <Form.Control
                                    type="url"
                                    name="reference_url"
                                    value={opportunityCreate.reference_url}
                                    onChange={handleFormInput}
                                    id="reference_url"
                                    className="form-control"  />
                            </div>
                            <div className="col-md-4"  style={{marginTop: '5px'}}>
                                <Form.Label>Opportunity Description :</Form.Label>
                                    <Form.Control 
                                        className="form-control"
                                        as="textarea"
                                        rows={1}
                                        placeholder="Enter Description"
                                        name="opportunity_desc"
                                        value={opportunityCreate.opportunity_desc}
                                        onChange={handleFormInput}
                                        autoComplete='off'
                                    />   
                            </div>
                            {inputFields.map((input, index) => {
                              return (
                                <div className="row">
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Operation Type :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="operation_type"
                                            options={operatTypeListData}
                                            onChange={(event)=>handleOperTypeInput(index,event)}
                                            defaultValue={input.operation_type}
                                            key={input.operation_type }
                                        />
                                    </div>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Area Required (Sq.ft.): <span className="required-mark">*</span></label>
                                        <Form.Control
                                            type="number"
                                            name="area_required"
                                            value={input.area_required}
                                            onChange={(event)=>handleChange(index, event)}
                                            id="area_required"
                                            className="form-control" placeholder="Enter area in sq. ft." 
                                            />      
                                    </div>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Inventory Days:<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="number"
                                            name="inventory_days"
                                            value={input.inventory_days}
                                            onChange={(event)=>handleChange(index, event)}
                                            id="inventory_days"
                                            className="form-control" placeholder="Enter inventory days" 
                                            />      
                                    </div>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Avg. Daily Inbound:<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="number"
                                            name="avg_daily_inbound"
                                            value={input.avg_daily_inbound}
                                            onChange={(event)=>handleChange(index, event)}
                                            id="avg_daily_inbound"
                                            className="form-control" placeholder="Enter daily avg. inbound" 
                                            />      
                                    </div>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Avg. Daily Outbound:<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="number"
                                            name="avg_daily_outbound"
                                            value={input.avg_daily_outbound}
                                            onChange={(event)=>handleChange(index, event)}
                                            id="avg_daily_outbound"
                                            className="form-control" placeholder="Enter daily avg. outbound" 
                                            />      
                                    </div>
                                    <div className="col-md-4" style={{marginTop: '25px'}} >
                                        {(inputFields.length!==1)? <i className="btn btn-danger" style={{float: 'right', marginLeft:'8px'}} onClick={(event)=>removeInputFields(index,event)}>Remove</i>:''}
                                        <i className="btn btn-primary" onClick={addFields} style={{float: 'right'}}>Add More</i>
                                    </div>
                                </div>
                                )
                            })}
                            <div className="col-md-4" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                <Form.Label>Upload Photographs / Files (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                                    <Form.Control multiple type="file"  
                                      name="opportunity_photo_web"
                                      onChange={handleFileInput}
                                    />
                            </div>
                            <div className='combination'>
                                <div className="col-md-10"  style={{marginTop: '15px'}}>
                                    <button type="submit"id="save" onClick={handlePartialSubmit} className="btn btn-primary">Save Details</button>
                                </div> 
                                <div className="col-md-2"  style={{marginTop: '15px'}}>
                                    <button type="submit" id="create" onClick={handleFormSubmit} style={{float: 'right'}} className="btn btn-primary">Create Opportunity</button>
                                </div>
                            </div>
                       </form>
                   </div>
               </div>
           </div>
       </div>
    </>
  )
}

export default RetailOpportunity