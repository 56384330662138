import React, { useCallback, useReducer } from 'react';
import { Form } from 'react-bootstrap';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { UserPermissionList, LOBList, ProductList, ServicesList, BDList, SDList} from '../../apidata/fetchapi';
import { useNavigate,NavLink, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import { password, username } from '../../Api';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import DateFormat from '../dateformate/DateFormat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';

const AllLeads = () => {

  const { permissionList }   = UserPermissionList();
  const { lobListData }      = LOBList();
  const { productListData }  = ProductList();
  const { servicesListData } = ServicesList();
  const { bdListData }       = BDList();
  const { sdListData }       = SDList();

  let base64          = require('base-64');
  const navigate      = useNavigate();
  const usr_token     = localStorage.getItem('authToken');
  const user_id       = localStorage.getItem('usrId');
  const isUserLogin   = localStorage.getItem('isAuthenticatedUser');
  let message         = sessionStorage.getItem("createmessage");
  let lead_status     = sessionStorage.getItem('leadStatus');

  let lobname = sessionStorage.getItem("lobnamemydash");
  let lobnamemydash = JSON.parse(lobname);

  let productname = sessionStorage.getItem("productnamemydash");
  let productnamemydash = JSON.parse(productname);

  let servicename = sessionStorage.getItem("servicenamemydash");
  let servicenamemydash = JSON.parse(servicename);

  let bdname = sessionStorage.getItem("bdnamemydash");
  let bdnamemydash = JSON.parse(bdname);

  let sdname = sessionStorage.getItem("sdnamemydash");
  let sdnamemydash = JSON.parse(sdname);

  let status = sessionStorage.getItem("statusmydash");
  let statusmydash = JSON.parse(status);

  let dateselected = sessionStorage.getItem("datemydash");
  let datemydashs = JSON.parse(dateselected);

  let datemydash = '';
  if(datemydashs != '' && datemydashs != null){
     datemydash = [new Date(datemydashs[0]), new Date(datemydashs[1])]
     datemydashs = {from : datemydashs[0], to : datemydashs[1]}
  }

  console.log(datemydash);

  const optionarr = [
    { value: "4", label: "Test1" },
    { value: "5", label: "Test2" },
    { value: "2", label: "Test3" },
    { value: "666666", label: "Other" },
  ];

  const statusarr = [
    { value: "2", label: "Lead Pending at BD Head" },
    { value: "3", label: "Lead Assigned" },
    { value: "4", label: "Opportunity Pending at SD Head" },
    { value: "5", label: "WIP" },
    { value: "22", label: "Rejected" },
    { value: "21", label: "Deleted" },
    { value: "23", label: "Dormant" },
    { value: '24', label: 'Lost' },
  ];

  const initialData = {
    lob_name: lobnamemydash,
    product_name: productnamemydash,
    service_name: servicenamemydash,
    bd_name: bdnamemydash,
    sd_name: sdnamemydash,
    status : statusmydash,
    date : datemydashs ,
    usr_token: usr_token,
    lead_status: lead_status,
  };

  const initialBDData   = {
    "lead_id": "",
    "lead_owner": "",
    "usr_token": usr_token,
  }

  const initialSDData   = {
    "lead_id": "",
    "opportunity_owner": "",
    "expected_close_date": "",
    "usr_token": usr_token,
  }

  const initialdeleteData = {
    "lead_id": "",
    "remark": "",
    "usr_token": usr_token,
}

  const leaddataurl                               = '/sdm_lead/requester_leads';
  const leadallocateurl                           = '/sdm_lead/assignment_to_bdteam';
  const leadallocatesdurl                         = '/sdm_lead/assignment_to_designteam';
  const [leadId, setLeadId]                       = React.useState('');
  const [assignLeads, setAssignLead]              = React.useState(initialBDData);
  const [assignSDTeam, setAssignSDTeam]           = React.useState(initialSDData);
  const [usermessage, setMessage]                 = React.useState(null);
  const [RefreshData ,setRefreshData]             = useReducer(x => x+1,0);
  const [search ,setSearch]                       = React.useState("");
  const [filteredLeads ,setFilteredLeads]         = React.useState([]);
  const [ isAlertVisible, setIsAlertVisible]      = React.useState(true);
  const [isusermessage, issetMessage]             = React.useState(null);
  const [dashboardLeadData, setDashboardLeadData] = React.useState([""]);
  const [reportLeadData, setReportLeadData]       = React.useState([]);
  const [bdUserListData, setBDUserListData]       = React.useState([""]);
  const [sdUserListData, setSDUserListData]       = React.useState([""]);
  const [show, setShow]                           = React.useState(false);
  const [loading, setLoading]                     = useState(true);
  const [dropdownData, setDropdownData]           = useState(initialData);
  const [deleteLeadFile, setDeleteLeadFile]       = React.useState(initialdeleteData);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRemarkInput = (e) => {

    if (e && e.target) {
        const { name, value } = e.target;
        setDeleteLeadFile(oldValue => ({
            ...oldValue,
            [name]: value,
            "lead_id": leadId,
        }))
    }
  };    

  const addLead = async () => {     
    navigate("/create_leads");
  }


  const  handleDateSelect = (ranges) =>{
    console.log('d',ranges);

    sessionStorage.removeItem("datemydash");
    sessionStorage.setItem("datemydash", JSON.stringify(ranges));

    if (ranges != '' && ranges != null){

      setDropdownData((oldValue) => ({
        ...oldValue,
        date: {from : ranges[0], to : ranges[1]},
      }));

    }else{

      setDropdownData((oldValue) => ({
        ...oldValue,
        date: ranges,
      }));

    }

  } 

  const handleLOBNameInput = (data) => {
    sessionStorage.removeItem("lobnamemydash");
    sessionStorage.setItem("lobnamemydash", JSON.stringify(data));

    setDropdownData((oldValue) => ({
        ...oldValue,
        lob_name: data,
    }));

    // getDashBoardLeadsData();

  };

  const handleProductInput = (data) => {
    sessionStorage.removeItem("productnamemydash");
    sessionStorage.setItem("productnamemydash", JSON.stringify(data));

    setDropdownData((oldValue) => ({
        ...oldValue,
        product_name: data,
    }));

    // getDashBoardLeadsData();
  };

  const handleServicesInput = (data) => {
      sessionStorage.removeItem("servicenamemydash");
      sessionStorage.setItem("servicenamemydash", JSON.stringify(data));

      setDropdownData((oldValue) => ({
          ...oldValue,
          service_name: data,
      }));

      // getDashBoardLeadsData();
  };

  const handleBDNameInput = (data) => {
    sessionStorage.removeItem("bdnamemydash");
    sessionStorage.setItem("bdnamemydash", JSON.stringify(data));

    setDropdownData((oldValue) => ({
        ...oldValue,
        bd_name: data,
    }));

    // getDashBoardLeadsData();
    
  }

  const handleSDNameInput = (data) => {
      sessionStorage.removeItem("sdnamemydash");
      sessionStorage.setItem("sdnamemydash", JSON.stringify(data));

      setDropdownData((oldValue) => ({
          ...oldValue,
          sd_name: data,
      }));
      // getDashBoardLeadsData();
  };

  const handleStatusInput = (data) => {

    sessionStorage.removeItem("statusmydash");
    sessionStorage.setItem("statusmydash", JSON.stringify(data));

    setDropdownData((oldValue) => ({
        ...oldValue,
        status: data,
    }));
    // getDashBoardLeadsData();

  }

  const clearFilter = () =>{
    sessionStorage.removeItem("lobnamemydash");
    sessionStorage.removeItem("productnamemydash");
    sessionStorage.removeItem("servicenamemydash");
    sessionStorage.removeItem("bdnamemydash");
    sessionStorage.removeItem("sdnamemydash");
    sessionStorage.removeItem("statusmydash");
    sessionStorage.removeItem("datemydash");

    setDropdownData({
        lob_name: "",
        product_name: "",
        service_name: "",
        bd_name: "",
        sd_name: "",
        status: "",
        date: "",
        lead_status: lead_status,
        usr_token: usr_token,
    })
    getDashBoardLeadsData();
  }

  const leadAssign = async(id,project_type,products,service_type) => {

      setShow(true);
     
      setAssignLead(oldValue => ({
        ...oldValue,
        "lead_id": id,
      }))

      console.log(JSON.stringify({service_type,project_type,products,usr_token}));
      const arr = [];
      try { 
          fetch(ulmsUrl.concat('/user/bd_user_dropdown') , {
              method: 'POST',
              body: JSON.stringify({service_type,project_type,products,usr_token}),
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then((response) =>
              response.json()
          )
          .then((response) => {  
              let result = response.data;
              result?.map((data) => {
                  return arr.push({ value: data.id, label: data.value });
              });
              setBDUserListData(arr)
          });
      } catch (error) {
          console.log(error);
      }
        
  }

  const leadAssignSd = async(id,project_type,products,service_type) => {

    setShow(true);
   
    setAssignSDTeam(oldValue => ({
      ...oldValue,
      "lead_id": id,
    }))

    console.log(JSON.stringify({service_type,project_type,products,usr_token}));
    const arr = [];
    try { 
        fetch(ulmsUrl.concat('/user/sd_user_dropdown') , {
            method: 'POST',
            body: JSON.stringify({service_type,project_type,products,usr_token}),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) =>
            response.json()
        )
        .then((response) => {  
            let result = response.data;
            result?.map((data) => {
                return arr.push({ value: data.id, label: data.value });
            });
            setSDUserListData(arr)
        });
    } catch (error) {
        console.log(error);
    }
      
  }

  console.log(show);
    useEffect(() => {
      leadAssignSd();
      leadAssign();

  }, []);

function handleCloseModal(e){      

  document.getElementById("exampleModal").classList.remove("show", "d-block");
  document.getElementById("assigntosdmodal").classList.remove("show", "d-block");
  document.getElementById("deleteremarkModal").classList.remove("show", "d-block");
  document.querySelectorAll(".modal-backdrop")
  .forEach(el => el.classList.remove("modal-backdrop"));
  document.body.style.overflow = 'unset';
}
  const handleLeadDelForm = async (e) => {
    setLoading(true);
    e.preventDefault();

    console.log(JSON.stringify(deleteLeadFile))

    fetch(ulmsUrl.concat('/sdm_lead/delete'), {
        method: 'POST',
        body: JSON.stringify(deleteLeadFile),
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then((result) =>
        result.json()
    )
    .then((result) => {
        console.log(result)
        if (result.status == true) {
            setLoading(false);
            setDeleteLeadFile('')
            toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
            getDashBoardLeadsData();
            navigate("/all_leads", { replace: true })

        } else if (result.status == false) {
            setLoading(false);
            setDeleteLeadFile(deleteLeadFile)
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
            
        }
    })
    .catch((form_err) => {
        //console.log(form_err)
    })
  
  }
  const handleLeadSourceInput = (value) => { 
    setAssignLead(oldValue => ({
        ...oldValue,
        "lead_owner": value,
    }))  
  };

  const handleSDUserInput = (value) => {
    setAssignSDTeam(oldValue => ({
        ...oldValue,
        "opportunity_owner": value,
    }))  
  }

  const handleFormInput = (e) => {
    if (e && e.target) {
        const { name, value } = e.target;
        setAssignSDTeam(oldValue => ({
            ...oldValue,
            [name]: value,
        }))
    }
};

  console.log(JSON.stringify(assignLeads));

  const handleFormSubmit = async (e) => {
      setLoading(true);
      e.preventDefault();

      fetch(ulmsUrl.concat(leadallocateurl), {
          method: 'POST',
          body: JSON.stringify(assignLeads),
          headers: {
            'Content-Type': 'application/json',
          }
      })
      .then((result) =>
          result.json()
      )
      .then((result) => {
          if (result.status == true) {
              setLoading(false);
              setAssignLead(initialBDData);
              setShow(false);
              toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
              });
              getDashBoardLeadsData();
             // sessionStorage.setItem("message", result.message);
              navigate("/all_leads", { replace: true })

          } else if (result.status == false) {
              setLoading(false);
              setAssignLead(assignLeads)
              toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
              });
          }
      })
      .catch((form_err) => {
          //console.log(form_err)
      })
  }

  console.log(JSON.stringify(assignSDTeam))

  const handleSDFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(JSON.stringify(assignSDTeam))

    fetch(ulmsUrl.concat(leadallocatesdurl), {
        method: 'POST',
        body: JSON.stringify(assignSDTeam),
        headers: {
          'Content-Type': 'application/json',
        }
    })
    .then((result) =>
        result.json()
    )
    .then((result) => {
        if (result.status == true) {
            setLoading(false);
            setAssignSDTeam(initialSDData);
            setShow(false);
            toast.success(result.message, {
              position: toast.POSITION.TOP_RIGHT,
              toastId: 'success1',
            });
            getDashBoardLeadsData();
           // sessionStorage.setItem("message", result.message);
            navigate("/all_leads", { replace: true })

        } else if (result.status == false) {
            setLoading(false);
            setAssignSDTeam(assignSDTeam)
            toast.error(result.message, {
              position: toast.POSITION.TOP_RIGHT,
              toastId: 'success1',
            });
        }
    })
    .catch((form_err) => {
        //console.log(form_err)
    })
  }

 

  const getDashBoardLeadsData = async () => {
    console.log('dropdown',JSON.stringify(dropdownData));
    setLoading(true);
          
    try {
        fetch(ulmsUrl.concat(leaddataurl) , {
            method: 'POST',
            body: JSON.stringify(dropdownData),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) =>
            response.json()
        )
        .then((response) => {  
            setLoading(false);
            let result = response.data;
            console.log('filter',result);
            setDashboardLeadData(result)
            setFilteredLeads(response.data);
        });
    } catch (error) {
        console.log(error);
    }
  }

  const getReportLeadsData = async () => {
    console.log('dropdown',JSON.stringify(dropdownData));
    setLoading(true);
          
    try {
        fetch(ulmsUrl.concat('/report/lead_opp_data') , {
            method: 'POST',
            body: JSON.stringify(dropdownData),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) =>
            response.json()
        )
        .then((response) => {  
            setLoading(false);
            let result = response.data;
            console.log('report',result);
            setReportLeadData(response.data)
            // setFilteredLeads(response.data);
        });
      } catch (error) {
        console.log(error);
    }
  }

  useEffect(() => {
      getDashBoardLeadsData();
      getReportLeadsData();
  }, [dropdownData]);

  console.log(filteredLeads);
  console.log(dashboardLeadData);

  useEffect(() => {

    const isNumber = !isNaN(search);
    const searchLower = search?.toLowerCase();

    const filterResult = dashboardLeadData.filter(data => {
         return (isNumber && data.id === Number(search) || data.project_type?.toLowerCase().match(searchLower) || data.products?.toLowerCase().match(searchLower) || data.service_type?.toLowerCase().match(searchLower) || data.supplier_name?.toLowerCase().match(searchLower) || data.oem_name?.toLowerCase().match(searchLower) || data.cust_industry_verticle?.toLowerCase().match(searchLower) || data.lead_req_name?.toLowerCase().match(searchLower) || "");  
    });

    setReportLeadData(filterResult);
    setFilteredLeads(filterResult);
   }, [search]); 

   const columns = [
    { 
      name: "Lead Id",
      selector : (row) => row.id ,
      width : '89px',
      sortable :  true 
    },
    {
      name: "LOB",
      sortable :  true,  
      width : '80px',
      selector : (row) => row.project_type,
    },
    {
      name: "Product Type",
      sortable :  true,  
      width : '125px',
      selector : (row) => row.products,
    },
    {
      name: "Services",
      sortable :  true,  
      width : '95px',
      selector : (row) => row.service_type,
    },
    {
      name: "Supplier Name",
      sortable :  true,  
      width : '220px',
      selector : (row) => {
        if(row.supplier_name != null && row.supplier_name_id != 666666){
          return(
              <div>{row.supplier_name + ' (' +row.supplier_loc + ')' }</div>
          )
        }else if(row.new_supplier_name != null && row.supplier_name_id == 666666){
          return(
              <div>{row.new_supplier_name + ' (' +row.new_supplier_loc + ')' }</div>
          )
        }else{
          return(
            <div>-</div>
          )
        }
      },
      wrap: true
    },
    {
      name: "OEM Name",
      sortable :  true,  
      width : '220px',
      selector : (row) => 
      {
        if(row.service_type == 'RP' && row.oem_name != null && row.oem_name_id != 666666){
          return(
              <div>{row.oem_name + ' (' +row.oem_loc + ')' }</div>
          )
        }else if(row.service_type == 'RP' && row.new_oem_name != null && row.oem_name_id == 666666){
          return(
              <div>{row.new_oem_name + ' (' +row.new_oem_loc + ')' }</div>
          )
        }else{
          return(
            <div>-</div>
          )
        }
      },
      wrap: true
    },
    {
      name: "Industry Vertical",
      sortable :  true,   
      width : '160px', 
      selector : (row) => row.cust_industry_verticle, 
      wrap: true    
    },
    {
      name: "Lead Referred By",
      sortable :  true,
      width : '150px',
      selector : (row) => row.lead_req_name,
      wrap: true  
    },
    {
      name: "Lead Owner name",
      sortable :  true,
      width : '150px',
      selector : (row) => row.lead_owner_name,
      wrap: true  
    },
    {
      name: "Created At",
      sortable :  true,
      width : '115px',
      selector : (row) => DateFormat(row.created_at)
    },
    {
      name: "Status",
      sortable :  true,  
      width : '150px',
      selector : (row) =>
      {
        if(row.status == 2){
          return(
              <span className="text-secondary">Lead Pending at {row.BdName} </span>
          )
        }else if(row.status == 3){
            return(
              <span className="text-warning">Lead Assigned ({row.lead_owner_name}) </span>
            )
        }else if(row.status == 4){
          return(
            <span className="text-info">Opportunity Pending at {row.SdName} </span>
          )
        }else if(row.status == 5){
          return(
            <span className="text-success">{row.status_name} </span>
          )
        }else if(row.status == 22){
          return(
            <span className="text-danger">Rejected</span>
          )
        }else if(row.status == 21){
          return(
            <span className="text-danger">Deleted</span>
          )
        }else if(row.status == 23){
          return(
            <span className="text-danger">Dormant</span>
          )
        }else if(row.status == 24){
          return(
            <span className="text-danger">Lost</span>
          )
        }
      },
      wrap: true    
    },
    {
      name: "Remark",
      sortable :  true,
      width : '100px',
      selector : (row) => row.del_remark,
      wrap:true
    },
    
    {
      name: "Action",
      width : '150px',
      cell : (row) =>
      {
        if(row.button == 2){
          return(
            <>
            <Link onClick={()=>leadAssign(row.id,row.project_type,row.products,row.service_type)} ><span className='badge bg-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Assign To BD</span></Link>
            {/* {(row.lead_req_id == user_id) &&
            <Link to={`/leads_edit/${base64.encode(row.id)}`} style={{marginLeft: '20px'}} ><span className='badge bg-primary'>Edit Lead</span></Link> 
            } */}
          </>

          )
        }else if(row.button == 4){
          return(
            <Link onClick={()=>leadAssignSd(row.id,row.project_type,row.products,row.service_type)} ><span className='badge bg-primary' data-bs-toggle="modal" data-bs-target="#assigntosdmodal">Assign To SD</span></Link> 
            )
        }
        if(row.status == 3 && row.lead_owner_id == user_id && row.project_type == 'AUTO' && row.service_type == 'NRP' &&  (row.products == 'RM' || row.products == 'FG') ){
          return(
            <Link to={`/create_opportunity/${base64.encode(row.id)}`}><span className='badge bg-primary' > Create Opportunity</span></Link> 
           )
        }else if(row.status == 3 && row.lead_owner_id == user_id && row.project_type == 'AUTO' && (row.service_type == 'RP' || row.service_type == 'LS') && (row.products == 'RM' || row.products == 'FG')){
          return(
            <Link to={`/create_rpopportunity/${base64.encode(row.id)}`}><span className='badge bg-primary' > Create Opportunity</span></Link> 
           )
        }else if(row.status == 3 && row.lead_owner_id == user_id && row.project_type == 'RETAIL'){
          return(
            <Link to={`/create_reopportunity/${base64.encode(row.id)}`}><span className='badge bg-primary' > Create Opportunity</span></Link> 
           )
        }


        if(row.status > 3 && row.lead_owner_id == user_id && row.status != 21 && row.status != 22  && row.status != 23 && row.status != 24 && row.project_type == 'AUTO' && row.service_type == 'NRP' &&  (row.products == 'RM' || row.products == 'FG')){
          return(
            <Link to={`/update_opportunity/${base64.encode(row.id)}`} ><span className='badge bg-primary' >Edit</span></Link> 
          )
        }else if(row.status > 3 && row.lead_owner_id == user_id && row.status != 21 && row.status != 22  && row.status != 23 && row.status != 24 &&  row.project_type == 'AUTO' && (row.service_type == 'RP' || row.service_type == 'LS') && (row.products == 'RM' || row.products == 'FG')){
          return(
            <Link to={`/update_rpopportunity/${base64.encode(row.id)}`} ><span className='badge bg-primary' >Edit</span></Link> 
          )
        }else if(row.status > 3 && row.lead_owner_id == user_id && row.status != 21 && row.status != 22 && row.status != 23 && row.status != 24 && row.project_type == 'RETAIL'){
          return(
            <Link to={`/update_reopportunity/${base64.encode(row.id)}`} ><span className='badge bg-primary' >Edit</span></Link> 
          )
        }
        
      },
      wrap: true,
    }, 
    {
      name: "View",
      width : '150px',
      cell : (row) => 
      {
        if(row.status < 4){
          return(
            <>
              <Link to={`/lead_view/${base64.encode(row.id)}`} ><span className='badge bg-primary' >Lead View</span></Link> 
              {(row.LeadDelButton == 1) &&
               <Link onClick={()=>setLeadId(row.id)} style={{marginLeft: '45px'}} data-bs-toggle="modal" data-bs-target="#deleteremarkModal" ><span title='Delete lead' ><img style={{width: '30px',  borderRadius:'50px'}} src='../../ulms_assets/images/trash1.gif' alt="logo" /></span></Link>
              }
            </>
          )
        }else if(row.status > 3 && row.status != 22 && row.status != 21){
          return(
            <>
             <Link to={`/solution_development/${base64.encode(row.id)}`} ><span className='badge bg-primary' >Opportunity View</span></Link> 
              {(row.LeadDelButton == 1) &&
               <Link onClick={()=>setLeadId(row.id)} style={{marginLeft: '35px'}} data-bs-toggle="modal" data-bs-target="#deleteremarkModal" ><span title='Delete lead' ><img style={{width: '30px',  borderRadius:'50px'}} src='../../ulms_assets/images/trash1.gif' alt="logo" /></span></Link>
              }
             </>
           )
        }
      
      }
    }, 
    {
      name: "Holisight Id",
      sortable :  true,  
       width : '120px',   
      selector : (row) =>
      {
        if(row.holisight_lead_id != null){
          return(
             <span><strong>{row.holisight_lead_id}</strong></span>
          )
        }else{
            return(
              <i className="fa fa-close" style={{fontSize:'20px',color:'red'}}></i>
            )
        }
      },
      wrap: true    
    },
  ];

 
    if(usermessage != ''){
      setTimeout(() => {
        setMessage(false);
       // sessionStorage.removeItem('createmessage');
      }, 8000);    
    }

    useEffect(() => {
      if(message != ''){
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'success1',
        });
        setTimeout(() => {
          setMessage(false);
          sessionStorage.removeItem('createmessage');
        }, 8000);    
      }
      }, []); 

  return (
    <>
        {/* {isAlertVisible && message && <div className="alert alert-success alert-dismissible fade show" role="alert">
                <strong>{message}</strong>
                
            </div>} */}
            {/* {usermessage &&  <div className="alert alert-success alert-dismissible fade show" role="alert">
                <strong>{usermessage}</strong>
            </div>} */}
            <ToastContainer />
            {loading && (
              <>
                <Loader/>
              </>
            ) }
            <div className="row">
                <div className="order-2 order-lg-1 col-lg-12 bd-content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title" style={{float:'left'}}> {lead_status == 0 && 'ALL LEADS'} {lead_status == 2 && 'LEADS (Un-Allocated)'} {lead_status == 3 && 'LEADS (Request Mapping)'} {lead_status == 4 && 'LEADS (Pending @ SD Head)'} {lead_status == 5 && 'LEADS (WIP)'} {lead_status == 6 && 'LEADS (Technical Submitted)'}  {lead_status == 7 && 'LEADS (Commercial Shared)'} {lead_status == 8 && 'LEADS (Dropped)'}</h3> 
                            <button style={{font_size: '12px', float: 'right'}} type="button" onClick={clearFilter} className="btn btn-danger btn-sm  me-2"><strong>Clear Filter</strong></button>   
                            {/* <button style={{font_size: '12px', float: 'right'}} type="button" onClick={addLead} className="btn btn-primary btn-sm  me-2"><strong>Create Lead</strong></button>     */}
                            <CSVLink style={{font_size: '12px', float: 'right'}} className="btn btn-success btn-sm  me-2" 
                            data={reportLeadData}
                            filename={"leads.csv"}
                            ><strong>Export</strong></CSVLink>
                        </div>
                        <div style={{marginLeft:'10px', marginRight:'10px'}}>
                          <form className="row"  encType="multipart/form-data">  
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Date :{" "}
                                  </label>
                                  <DateRangePicker 
                                  block 
                                  style={{ width: 230, borderColor: '#cccccc', borderRadius : '4px', borderStyle:'solid', borderWidth:'1px', boxSizing: 'border box'}} 
                                  name="date" 
                                  onChange={handleDateSelect}
                                  defaultValue={datemydash}
                                  key={datemydash}
                                   />
                              </div>                        
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      LOB Name :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="supplier_name"
                                      options={lobListData}
                                      defaultValue={lobnamemydash}
                                      key={lobnamemydash}
                                      onChange={handleLOBNameInput}
                                  />
                              </div>
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Product :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="supplier_name"
                                      options={productListData}
                                      defaultValue={productnamemydash}
                                      key={productnamemydash}
                                      onChange={handleProductInput}
                                  />
                              </div>
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Services :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="supplier_name"
                                      options={servicesListData}
                                      defaultValue={servicenamemydash}
                                      key={servicenamemydash}
                                      onChange={handleServicesInput}
                                  />
                              </div>
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      BD Name :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="supplier_name"
                                      options={bdListData}
                                      defaultValue={bdnamemydash}
                                      key={bdnamemydash}
                                      onChange={handleBDNameInput}
                                      // onChange={handleSuppNameInput}
                                  />
                              </div>
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Sol. Design Exe. :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="supplier_name"
                                      options={sdListData}
                                      defaultValue={sdnamemydash}
                                      key={sdnamemydash}
                                      onChange={handleSDNameInput}
                                      // onChange={handleSuppNameInput}
                                  />
                              </div>
                              <div className="col-md-2" style={{ marginTop: "15px" }}>
                                  <label htmlFor="inputState" className="form-label">
                                      Status :{" "}
                                  </label>
                                  <Select
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      name="status"
                                      options={statusarr}
                                      defaultValue={statusmydash}
                                      key={statusmydash}
                                      onChange={handleStatusInput}
                                      // onChange={handleSuppNameInput}
                                  />
                              </div>
                          </form>
                        </div>
                        <div className="card-body">         
                               <DataTable 
                               id="table-to-xls"
                               paginationRowsPerPageOptions={[10,25,50,100,1000]}
                               data={filteredLeads} 
                               columns={columns} 
                               pagination
                               //fixedHeader
                               //fixedHeaderScrollHeight='500px'
                               highlightOnHover
                               subHeader
                               subHeaderComponent = {
                                <div className="col-md-3" style={{float:'right'}}>
                                <input type="text" 
                                  placeholder='Search here' 
                                  className='w-22 form-control'
                                  value={search || ''}
                                  onChange={(e)=> setSearch(e.target.value)}
                                />
                                </div>
                               }
                               />
                          
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                    </div>
                </div>
            </div>
            { show == true &&
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{usermessage}</strong>
                    </div>}
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"> Allocate Lead </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={handleFormSubmit}>
                    <div className="modal-body">
                        <div className="col-md-6">
                            <label htmlFor="inputState" className="form-label"> Select BD User : </label> 
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="bd_user"
                                options={bdUserListData}
                                onChange={handleLeadSourceInput}
                            />
                        </div> 
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            }

            { show == true &&
            <div className="modal fade" id="assigntosdmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{usermessage}</strong>
                    </div>}
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Lead Allocate To SD Team</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={handleSDFormSubmit}>
                    <div className="modal-body">
                        <div className="col-md-6">
                            <label htmlFor="inputState" className="form-label"> Select SD User : </label> 
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="bd_user"
                                options={sdUserListData}
                                onChange={handleSDUserInput}
                            />
                        </div> 
                        <div className="col-md-4" style={{marginTop: '5px'}}>
                            <Form.Label>Expected Closure Date:</Form.Label>
                            <Form.Control
                            type="date"
                            name="expected_close_date"
                            value={assignSDTeam.expected_close_date}
                            onChange={handleFormInput}
                            id="expected_close_date"
                            className="form-control"  />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            }
            <div className="modal fade" id="deleteremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">Lead Delete Remark</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <Form onSubmit={handleLeadDelForm}>
                          <div className="modal-body"> 
                              <div className="col-md-8" controlid="formFileMultiple" >
                                  <Form.Label>Enter Delete Remark:</Form.Label>
                                  <Form.Control multiple type="text"  
                                  name="remark"
                                  value={deleteLeadFile.remark}
                                  onChange={handleRemarkInput}
                                  />
                              </div>
                          </div>
                          <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                          <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                          </div>
                      </Form>
                  </div>
              </div>
            </div>
    </>
  )
}

export default AllLeads