import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { UserList, UserPermissionList } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserRolePermission = () => {

  const { userData }       =  UserList();
  const { permissionList } = UserPermissionList();

  const navigate = useNavigate();
  const userrolecreateurl    = "/config/user_role_update";
  const getUserRoleurl       = "/img/get_user_role";
  const usr_token = localStorage.getItem('authToken');
  const usr_name = localStorage.getItem('name');
  const usr_id = localStorage.getItem('usrId');
  const isUserLogin = localStorage.getItem('isAuthenticatedUser');

  const initialData = {
    "role_id": "",
    "user_name": "",
    "usr_token": usr_token,
  }

  const [permissionData, setPermissisonData] = React.useState(['']);
  const [permissionelected, setPermissionSelected] = React.useState([""]);
  const [checkboxSelected, setCheckboxSelected] = React.useState(initialData);
  const [userRoleSelected, setUserRoleSelected] = React.useState([""]);
  const [loading, setLoading] = useState(false);

  if(isUserLogin != "true"){
    navigate("/");
  }

  let rolemenuconfig = 0;
  { permissionList.map(permissionList =>
    {
      if(permissionList.menuid == 19 && permissionList.web == 1){
        rolemenuconfig = 1;
      }
    }
  )}

    async function getPermissionData() {
        const arr = [];
        try {
            await fetch(ulmsUrl.concat('/img/get_role_list') , {
                method: 'POST',
                body: JSON.stringify({ usr_token }),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                // result?.map((data) => {
                //     return arr.push({ value: data.id, label: data.name });
                // });
                setPermissisonData(result)
            })
        } catch (error) {
            console.log(error);
        }
        
    }


    useEffect(() => {
        getPermissionData();
    }, []);

    console.log(permissionData);
    const arr = [];
    const handlePermissInput = (e) => {        
        if (e && e.target) {
            const { name, value } = e.target;
            setCheckboxSelected(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    }

    const handleUserNameInput = (data) => {

        setCheckboxSelected(oldValue => ({
            ...oldValue,
            "user_name": data
        }))  

        let user_name = data;

        console.log(JSON.stringify({user_name,usr_token}));

        fetch(ulmsUrl.concat(getUserRoleurl), {
            method: 'POST',
            body: JSON.stringify({user_name,usr_token}),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) =>
            response.json()
        )
        .then((response) => {
            if (response.status == true) {
                let result = response.data;
                result?.map((data, index) => {
                   // return arr.push(data.value);
                    return arr[data.value] = [data.value];
                });
                setUserRoleSelected(arr);

            } else if (response.status == false) {      
                //setMessage(response.message);
                setUserRoleSelected([''])
                getPermissionData();
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    console.log(userRoleSelected);
    console.log(JSON.stringify(checkboxSelected));

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    
    fetch(ulmsUrl.concat(userrolecreateurl), {
        method: 'POST',
        body: JSON.stringify(checkboxSelected),
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then((result) =>
        result.json()
    )
    .then((result) => {
        if (result.status == true) {
            setLoading(false);
            setCheckboxSelected(checkboxSelected);
            toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });

        } else if (result.status == false) {
            setLoading(false);
            setCheckboxSelected(checkboxSelected);
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
           
        }
    })
    .catch((form_err) => {
        //console.log(form_err)
    })
    
  }



  return (
    <>
        <ToastContainer />
        {loading && (
              <>
                <Loader/>
              </>
        ) }
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">ADD USER ROLE PERMISSION</h3>
                    </div>
                    <div className="card-body">
                        <form className="row g-3" onSubmit={handleFormSubmit} encType="multipart/form-data">
                            <div className='row'>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label"> Select User : </label> 
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="user_name"
                                        options={userData}
                                        key={userData}
                                        onChange={handleUserNameInput}
                                    />
                                </div> 
                            </div>
                          
                            <label htmlFor="inputState" className="form-label"> Select Role : </label>
                            {permissionData != "" && permissionData.map((data, index) => {
                                return (
                                  <div className='row'>
                                      <div className="col-md-12" style={{marginTop: '10px'}}>
                                      
                                          <Form.Check
                                              inline
                                              label={data.value}
                                              name="role_id"
                                              value={data.id}
                                              type= "radio"
                                              key={userRoleSelected[data.id]}
                                              onChange={handlePermissInput}
                                              defaultChecked = {userRoleSelected[data.id] && true }
                                             //onClick={()=>handlePermissInput(data.id)}
                                          />
                                          <br/>
                                      </div> 
                                  </div>
                                  )
                              })}

                                <div className="col-md-12" style={{marginTop: '15px'}}>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>      
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default UserRolePermission