import React, { useCallback, useReducer } from 'react';
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { useNavigate,NavLink, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';

const User = () => {

    const usrlisturl = '/user/list';
    const [UsersList, setUsersList]  = React.useState([]);
    const [search ,setSearch]  = React.useState("");
    const [filteredUser ,setFilteredUser]  = React.useState([]);
    const [ isAlertVisible, setIsAlertVisible ] = React.useState(true);
    const [isusermessage, issetMessage] = React.useState(null);
    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();
    const usr_token = localStorage.getItem('authToken');
    const isUserLogin = localStorage.getItem('isAuthenticatedUser');
    let message = sessionStorage.getItem("message");
    
    var base64 = require('base-64');

      if(isAlertVisible == false){
        sessionStorage.removeItem("message");
      }
      
      const getUsersData = async () => {
        setLoading(true);
              
        try {
            fetch(ulmsUrl.concat(usrlisturl) , {
                method: 'POST',
                body:JSON.stringify({usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                setLoading(false);
                let result = response.data;
                setUsersList(result)
                setFilteredUser(response.data);
            });
        } catch (error) {
            console.log(error);
        }
      }

      useEffect(() =>{
        getUsersData();
      }, []);
      
    useEffect(() => {
     const filterResult = UsersList.filter(data => {
          return (data.name?.toLowerCase().match(search?.toLowerCase()) || data.email?.toLowerCase().match(search?.toLowerCase()) || data.contact_number?.toLowerCase().match(search?.toLowerCase()) || data.emp_id?.toLowerCase().match(search?.toLowerCase()) || data.RoleName?.toLowerCase().match(search?.toLowerCase()) || data.status?.toLowerCase().match(search?.toLowerCase()));  
     });

     setFilteredUser(filterResult);
    }, [search]); 


    const columns = [
        { 
          name: "ID",
          selector : (row) => row.id,
          width : '130px',
          sortable :  true},
        {
          name: "Name",
          width : '200px',
          sortable :  true,    
          selector : (row) => row.name,     
        },
        {
          name: "Emp Id",
          width : '200px',
          sortable :  true,  
          selector : (row) => row.emp_id
        },
        {
          name: "Role",
          width : '200px',
          sortable :  true,  
          selector : (row) => row.RoleName
        },
        {
          name: "Contact number",
          width : '200px',
          sortable :  true,
          selector : (row) => row.contact_number
        },
        {
          name: "Email",
          width : '300px',
          sortable :  true,  
          selector : (row) => row.email,
          
        },
        {
            name: "Status",
            width : '130px',
            sortable :  true,  
            selector : (row) => <span className={row.status=='Inactive'?"badge bg-danger":row.status=='Active'?"badge bg-success":"text-n"}>{row.status}</span>,
        },
        
      ];

      useEffect(() => {
        
        if(message != ''){
          setTimeout(() => {
            setIsAlertVisible(false);
          }, 8000);    
        }
        }, []); 

        useEffect(() => {
          if(isusermessage != ''){
            setTimeout(() => {
              issetMessage(false);
            }, 8000);    
          }
          }, []); 
      
 
    return (
        <>
          {isAlertVisible && message && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{message}</strong>
                
            </div>}
            {isusermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{isusermessage}</strong>
            </div>}
            {loading && (
              <>
                <Loader/>
              </>
            )}
            <div className="row">
                <div className="order-2 order-lg-1 col-lg-12 bd-content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title" style={{float:'left'}}>USER LIST</h3>    
                            <CSVLink style={{font_size: '12px', float: 'right'}} className="btn btn-success btn-sm  me-2" 
                            data={filteredUser}
                            filename={"users.csv"}
                            >Export</CSVLink>
                        </div>
                        
                        <div className="card-body">         
                               <DataTable 
                               id="table-to-xls"
                               data={filteredUser} 
                               columns={columns} 
                               pagination
                               fixedHeader
                               highlightOnHover
                               subHeader
                               subHeaderComponent = {
                                <div className="col-md-3" style={{float:'right'}}>
                                <input type="text" 
                                  placeholder='Search here' 
                                  className='w-22 form-control'
                                  value={search}
                                  onChange={(e)=> setSearch(e.target.value)}
                                />
                                </div>
                               }
                               />
                          
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
                              
}

export default User