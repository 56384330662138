import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { UserList, UserPermissionList } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreatePermission = () => {

  const { userData }       =  UserList();
  const { permissionList } = UserPermissionList();

  const navigate = useNavigate();
  const userpermisscreateurl = "/img/add_user_permission";
  const userpermissionurl = "/img/get_user_permission";
  const usr_token = localStorage.getItem('authToken');
  const usr_name = localStorage.getItem('name');
  const usr_id = localStorage.getItem('usrId');
  const isUserLogin = localStorage.getItem('isAuthenticatedUser');

  const userdata = {
    "user_name": "",
  }
  const [permissionData, setPermissisonData] = React.useState(['']);
  const [permissionelected, setPermissionSelected] = React.useState(userdata);
  const [checkboxSelected, setCheckboxSelected] = React.useState([]);
  const [userCheckboxSelected, setUserCheckboxSelected] = useState([""]);
  const [loading, setLoading] = useState(false);


  if(isUserLogin != "true"){
    navigate("/");
  }

  let usermenuconfig = 0;
  { permissionList.map(permissionList =>
    {
      if(permissionList.menuid == 53 && permissionList.web == 1){
        usermenuconfig = 1;
      }
    }
  )}

//   if(usermenuconfig == 0){
//     sessionStorage.setItem("permissionmessage", "Not authorized!");
//     navigate("/");
//   }

    async function getPermissionData() {
        const arr = [];
        try {
            await fetch(ulmsUrl.concat('/img/configs') , {
                method: 'POST',
                body: JSON.stringify({ usr_token }),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                // result?.map((data) => {
                //     return arr.push({ value: data.id, label: data.name });
                // });
                setPermissisonData(result)
            })
        } catch (error) {
           //console.log(error);
        }
        
    }

    useEffect(() => {
        getPermissionData();
    }, []);


    const handleUserNameInput = async (data) => {
   
        setPermissionSelected(oldValue => ({
            ...oldValue,
            "user_name": data
        }))  

        let user_name = data;

        let arr = [];
        let checkarr = [];
        fetch(ulmsUrl.concat(userpermissionurl), {
            method: 'POST',
            body: JSON.stringify({user_name,usr_token}),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) =>
            response.json()
        )
        .then((response) => {
            if (response.status == true) {
                let result = response.data;
                result?.map((data, index) => {
                   // return arr.push(data.value);
                    return arr[data.value] = [data.value];
                });
                result?.map((data, index) => {
                     return checkarr[index] = [data.value];
                 });
                setUserCheckboxSelected(arr)
                setCheckboxSelected(checkarr);
                
            } else if (response.status == false) {      
                if(response.message != ''){
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: 'success1',
                    });
                }
                setUserCheckboxSelected([''])
                getPermissionData();
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }


    const arr = [];
    const handlePermissInput = (e) => {  
        const { name, value } = e.target;
      
        if (e && (e.target.checked)) {
            setCheckboxSelected(oldValue => ([
                ...oldValue,
                [Number(value)],
            ]))
        }
        if(e.target.checked==false){

           let selectedPermissions =[];
            checkboxSelected?.map((item) => {
                if(e.target.value!=item[0] && item!=null && item!=undefined){
                    return  selectedPermissions.push(item);
                }
            });
        
           setCheckboxSelected(selectedPermissions);
        }
        
    }

    const handleAllCheckbox =(e) =>{

        let array_temp = [];
        if(e.target.checked){
            permissionData.map(item=>{
                if(!array_temp[item.id]){
                    array_temp[item.id] = item.id
                }
               
                item.child.map(super_child=>{
                    if(!array_temp[super_child.id]){
                        array_temp[super_child.id] = super_child.id
                    }

                    super_child.child.map(sub_child=>{
                       if(!array_temp[sub_child.id]){
                        array_temp[sub_child.id] = sub_child.id
                    }
                    })
                })
                
            })
            
            setUserCheckboxSelected(array_temp)

            array_temp.map(data=>{
                setCheckboxSelected(oldval => [
                    ...oldval,
                    [Number(data)],
                ])
            }) 
            
        }else{
            setUserCheckboxSelected(array_temp)
            setCheckboxSelected(array_temp)
        }
        
    } 

    const handleMasterCheckbox = (e) =>{
  
        let indexOfMaster = permissionData.findIndex(item=>item.name==e.target.name);

        let selected_permision_data = permissionData[indexOfMaster];
        let user_permissions = [...userCheckboxSelected];
       
        let temp_permissions =[];
        temp_permissions[selected_permision_data.id] = selected_permision_data.id
       
        selected_permision_data.child.map(item=>{
            if(!temp_permissions[item.id]){
                temp_permissions[item.id] = item.id;
            }
            item.child.map(subchild=>{
                if(!temp_permissions[subchild.id]){
                    temp_permissions[subchild.id] = subchild.id;
                }
            })
        })
    
        if(e.target.checked){
            user_permissions.forEach((item,key)=>{
        
                if(!temp_permissions[item]){
                    temp_permissions[item] = item;
                }
            })
            
            setUserCheckboxSelected(temp_permissions)

            temp_permissions.map(data=>{
                setCheckboxSelected(oldval => [
                    ...oldval,
                    [Number(data)],
                ])
            }) 
           
        }else{
          
            temp_permissions.map((item,id)=>{
                if(user_permissions[id]!==undefined ){
                    delete user_permissions[id];
                }
            })

            setUserCheckboxSelected(user_permissions)

            setCheckboxSelected([]);
        }
       
    }

    const handleSubChildCheckbox = (e) =>{
  
        let user_permissions = [...userCheckboxSelected];
        let subchild = [];
        permissionData.forEach((item,key)=>{
            let subChildIndex = item.child.findIndex(subItem => subItem.name==e.target.name);
          
            if(subChildIndex>=0){
                subchild =permissionData[key].child[subChildIndex];
            }
        })

       let temp_permissions =[];
       
       subchild.child.map(item=>{
            if(!temp_permissions[item.id]){
                temp_permissions[item.id] =item.id;
            }
        })
        if(e.target.checked){
            user_permissions.forEach((item,key)=>{
        
                if(!temp_permissions[item]){
                    temp_permissions[item] = item;
                }
            })
            setUserCheckboxSelected(temp_permissions)

            temp_permissions.map(data=>{
                setCheckboxSelected(oldval => [
                    ...oldval,
                    [Number(data)],
                ])
            }) 

        }else{
            temp_permissions.map((item,id)=>{
                if(user_permissions[id]!==undefined ){
                    delete user_permissions[id];
                }
            })
            setUserCheckboxSelected(user_permissions)
            setCheckboxSelected([]);
        }
    }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    const arr = [];
    for (var key in checkboxSelected) {
        var value = checkboxSelected[key];
        arr.push({ id: key, value: value[0] });
    }

    let myJson = {
      "permissions" : arr,
      "selected_user" : permissionelected,
      "usr_token" : usr_token
    }
    console.log('Permissionns',permissionelected);
    console.log('UserData',JSON.stringify(myJson))

    setLoading(true);
    fetch(ulmsUrl.concat(userpermisscreateurl), {
        method: 'POST',
        body: JSON.stringify(myJson),
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then((result) =>
        result.json()
    )
    .then((result) => {
        if (result.status == true) {
            setLoading(false);
            setCheckboxSelected(checkboxSelected);
            setPermissionSelected(permissionelected);
            toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });

        } else if (result.status == false) {
            setLoading(false);
            setCheckboxSelected(checkboxSelected);
            setPermissionSelected(permissionelected);
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
           
        }
    })
    .catch((form_err) => {
        //console.log(form_err)
   })
    
  }

  return (
    <>
        <ToastContainer />
        {loading && (
              <>
                <Loader/>
              </>
        ) }
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">ADD USER PERMISSION</h3>
                    </div>
                    <div className="card-body">
                        <form className="row g-3" onSubmit={handleFormSubmit} encType="multipart/form-data">
                            <div className='row'>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label"> Select User : </label> 
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="user"
                                        options={userData}
                                        onChange={handleUserNameInput}
                                    />
                                </div> 
                            </div>
                            <div className='row'>
                                <div className="col-md-4" style={{marginTop: '10px'}}>
                                    <Form.Check
                                        inline
                                        label="Select All"
                                        name="project_type"
                                        value="select_all"
                                        type= "checkbox"
                                        id={`inline-radio-2`}
                                        onChange={handleAllCheckbox}
                                    />
                                </div> 
                            </div>
                        
                            {permissionData != "" && permissionData.map((data, index) => {
                                return (
                                  <div className='row'>
                                      <div className="col-md-12 parent" style={{marginTop: '10px'}}>
                                          <Form.Check
                                              inline
                                              label={data.name}
                                              name={data.name}
                                              value={data.id}
                                              type= "checkbox"
                                              key={userCheckboxSelected[data.id]}
                                              onChange={handleMasterCheckbox}
                                              defaultChecked = { userCheckboxSelected[data.id] ? true : false }
                                          />

                                          <br/>
                                      </div> 
                                      {data.child.map((type, index) => {
                                        return (
                                        <p className='row'>
                                            <div className="col-md-4 supchild" id="master" style={{marginTop: '10px'}}>
                                                <Form.Check
                                                    inline
                                                    label={type.name}
                                                    name={type.name}
                                                    value={type.id}
                                                    type= "checkbox"
                                                    key={userCheckboxSelected[type.id]}
                                                    onChange={handleSubChildCheckbox}
                                                    defaultChecked = {userCheckboxSelected[type.id] ? true : false }
                                                />
                                            </div>
                                            <div></div>
                                            {type.child.map((typechild, index) => {
                                               // //console.log('isexists'+typechild.id,userCheckboxSelected[typechild.id])
                                                return (
                                                    <div className="col-md-2 subchild" id="master" style={{marginTop: '10px'}}>
                                                        <Form.Check
                                                            inline
                                                            label={typechild.name}
                                                            name={typechild.name}
                                                            value={typechild.id}
                                                            type= "checkbox" 
                                                            key={userCheckboxSelected[typechild.id]}
                                                            onChange={handlePermissInput}
                                                            defaultChecked = { (userCheckboxSelected[typechild.id]) ? true : false  }
                                                        />
                                                    </div>                                             
                                                )
                                            })}    
                                         </p>                                    
                                        )

                                    })}
                                  </div>
                                  )
                              })}

                                <div className="col-md-12" style={{marginTop: '15px'}}>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>      
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CreatePermission