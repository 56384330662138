import { useEffect, useState , useRef } from "react";
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './Commercial.css';
import Loader from '../../loader/Loader';
import Select from 'react-select';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { LocationData, ComponentFamily, UnitLoadList, VehicleTypeList, VehicleLoadTypeList } from '../../apidata/fetchapi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { roundToTwo } from "../helper/Helpers";


const NRPSolutionForm = () => {

    const { locationOptions }  = LocationData();
    const { comFamOptions }    = ComponentFamily();
    const { outerULData, innerULData }     = UnitLoadList();
    const { vehicleTypeData }      = VehicleTypeList();
    const { vehicleLoadTypeData }  = VehicleLoadTypeList();

    let base64 = require('base-64');

    const navigate = useNavigate();
    const [lead_id, setLeadId]   = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id   = localStorage.getItem('usrId');
    let total = 0;
    let solutionCost = 0;

    const aRef = useRef(null);
    const bRef = useRef(null);
    const cRef = useRef(null);
    const dRef = useRef(null);
    const eRef = useRef(null);
    const fRef = useRef(null);

    const wipuploadurl = "/wip/upload";
   
    const [loading, setLoading]  = useState(false);
    const [solFile1, setSolFile] = useState(null);
    const [bomFile, setBomFile]  = useState(null);
    const [refFile, setRefFile]  = useState(null);
    const [trialFile, setTrialFile]     = useState(null);
    const [inputFields, setInputFields] = useState([
        {  
            component_name: "",
            monthly_volume_avg: "",
            prop_box_dimension_l: "",
            prop_box_dimension_w: "",
            prop_box_dimension_h: "",
            prop_box_dimension_wt: "",
            prop_ul_rate: "",
        
        }
    ]);

    const getCompUpdateData = async () => {
        const arr = [];
        const innrarr = [];
        try {
            fetch(ulmsUrl.concat('/wip/sol_component') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;   
                console.log('resulsst',result)
                setInputFields(result.data);
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getCompUpdateData();
    }, []);

    const handleOpportFormInput = (parentIndex, event) => {

        const { name, value } = event.target;
        const updatedData = [...inputFields];
        updatedData[parentIndex][name] = value;
        setInputFields(updatedData);

    };

    const handleSolFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setSolFile(selectedFile);
    };
    
    const handleBomFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setBomFile(selectedFile);
    };

    const handleRefFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setRefFile(selectedFile);
    };

    const handleTrialFileChange = (event) => {  
        const selectedFile = event.target.files[0];
        setTrialFile(selectedFile);
    } 

    const handleFormSubmit = async (e) => {

        e.preventDefault();

        const createdata = {
            lead_id : lead_id,
            data: inputFields,
            img_data_app: [], 
            solution_creater_id: usr_id,
            solution_creater_name: usr_name,
            usr_token: usr_token,
        }

        console.log(JSON.stringify(createdata))

        if(solFile1 || bomFile || refFile)
        {
            setLoading(true);
            
            const formData = new FormData();
            formData.append('opportunity_photo_web', solFile1);
            formData.append('opportunity_photo_bom', bomFile);
            formData.append('opportunity_photo_ref', refFile);
            formData.append('opportunity_photo_trial', trialFile);
            
            formData.append('data', JSON.stringify(createdata));
            console.log(formData);  
    
            fetch(ulmsUrl.concat(wipuploadurl), {
                method: 'POST',
                body: formData,
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {
                console.log(result)
                if (result.status == true) {
                    setLoading(false);
                    bRef.current.value = null;
                    setSolFile('');
                    setBomFile('');
                    setRefFile('');
                    toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                    getCompUpdateData();
                    //sessionStorage.setItem("message", result.message);
                    navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
    
                } else if (result.status == false) {
                    setLoading(false);
                    toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                
                }
            })
            .catch((form_err) => {
                //console.log(form_err)
            })


        }else{

            toast.error("Please upload at least one file.", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
        }

    }


  return (
    <>

    <div id="accordion" style={{marginTop:'10px'}}>

        <form className="row g-3" encType="multipart/form-data" onSubmit={handleFormSubmit}>
        <div className="card">
            {inputFields != "" && inputFields.map((value, parentIndex) => {
              
                const updatedData = [...inputFields];

                if(value.prop_ul_rate != ''){
                    updatedData[parentIndex]["solution_cost"] = 1 * Number(updatedData[parentIndex].prop_ul_rate);
                
                }

                if(updatedData > -1){
                    setInputFields(updatedData)
                }
            
        return(
            <>
            <div className="card-header row" id={"heading"+parentIndex} data-toggle="collapse" data-target={"#collapse"+parentIndex} aria-expanded="true" aria-controls={"collapse"+parentIndex} style={{backgroundColor:'#46B653'}}>
               <div className="col-md-10" >
                    <h3 className="mb-0" >
                        <span className="btn btn collapsed" style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                        Component Name: {value.component_name}
                        </span>
                    </h3>
                </div>
                <div className="col-md-2" >
                    <label htmlFor="inputState" className="form-label">Solution Cost : </label>
                    <Form.Control
                        type="number"
                        name="solution_cost"
                        value={value.solution_cost}
                        // onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                        id="solution_cost"
                        className="form-control" disabled />      
                </div>
            </div>
            <div id={"collapse"+parentIndex} className={parentIndex === 0 ? "collapse show" : "collapse" } aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body row">
                    <div className="col-md-3" style={{marginTop: '5px'}}>
                        <label htmlFor="inputState" className="form-label">Component Name:<span className="required-mark">*</span> </label>
                        <Form.Control
                            type="text"
                            name="component_name"
                            defaultValue={value.component_name}
                            onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                            id="component_name"
                            className="form-control" />      
                    </div>
                    <div className="col-md-3" style={{marginTop: '5px'}}>
                        <label htmlFor="inputState" className="form-label">Monthly Volume (nos.):<span className="required-mark">*</span> </label>
                        <Form.Control
                            type="number"
                            name="monthly_volume_avg"
                            value={value.monthly_volume_avg}
                            onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                            id="monthly_volume_avg"
                            className="form-control" placeholder="Average" />      
                    </div>
                    <div className="col-md-3" style={{marginTop: '5px'}}>
                        <label htmlFor="inputState" className="form-label">Prop UL Dim. (L:W:H: in mm / Wt in Kg): </label>
                        <div className='row'>
                            <div className="col-md-3" >
                                <Form.Control
                                    type="text"
                                    name="prop_box_dimension_l"
                                    defaultValue={value.prop_box_dimension_l}
                                    onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                    id="prop_box_dimension_l"
                                    className="form-control" placeholder='L' />
                            </div>    
                            <div className="col-md-3" >
                                <Form.Control
                                    type="text"
                                    name="prop_box_dimension_w"
                                    defaultValue={value.prop_box_dimension_w}
                                    onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                    id="prop_box_dimension_w"
                                    className="form-control" placeholder='W' />     
                            </div>    
                            <div className="col-md-3" >
                                <Form.Control
                                    type="text"
                                    name="prop_box_dimension_h"
                                    defaultValue={value.prop_box_dimension_h}
                                    onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                    id="prop_box_dimension_h"
                                    className="form-control" placeholder='H' />       
                            </div>   
                            <div className="col-md-3" >
                                <Form.Control
                                    type="text"
                                    name="prop_box_dimension_wt"
                                    defaultValue={value.prop_box_dimension_wt}
                                    onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                    id="prop_box_dimension_wt"
                                    className="form-control" placeholder='WT' />      
                            </div>    
                        </div>  
                    </div>
                    <div className="col-md-3" style={{marginTop: '5px'}}>
                        <label htmlFor="inputState" className="form-label">Proposed UL Rate : </label>
                        <Form.Control
                            type="number"
                            name="prop_ul_rate"
                            defaultValue={value.prop_ul_rate}
                            onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                            id="prop_ul_rate"
                            className="form-control" />      
                    </div>
                </div>
            </div>
            </>) })}
            </div> 
            <div className="row" style={{marginTop: '20px'}}> 
                <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                    <Form.Label style={{fontWeight:'bold'}}>Upload Solution File :</Form.Label>
                    <Form.Control type="file"
                    ref={bRef}    
                    name="opportunity_photo_web"
                    onChange={handleSolFileChange}
                    />
                </div>
                <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                    <Form.Label style={{fontWeight:'bold'}}>Upload BOM File :</Form.Label>
                    <Form.Control type="file"
                    ref={bRef}    
                    name="opportunity_photo_bom"
                    onChange={handleBomFileChange}
                    />
                </div>
                <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                    <Form.Label style={{fontWeight:'bold'}}>Upload Related Ref File :</Form.Label>
                    <Form.Control type="file"
                    ref={bRef}    
                    name="opportunity_photo_ref"
                    onChange={handleRefFileChange}
                    />
                </div>
                <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                    <Form.Label style={{fontWeight:'bold'}}>Upload Trial File :</Form.Label>
                    <Form.Control type="file"
                    ref={bRef}    
                    name="opportunity_photo_trial"
                    onChange={handleTrialFileChange}
                    />
                </div>
            </div>
            <div className="col-md-2" style={{marginTop: '30px'}}>
                <button type="submit" className="btn btn-primary">Upload</button>
            </div>  
        </form>

    </div>

    </>
  )
}

export default NRPSolutionForm