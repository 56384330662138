import React from 'react';
import { useState } from "react";
import Dashboard from './scenes/dashboard';
import { Routes, Route } from "react-router-dom";
import Footer from './layout/Footer';
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import Switcher from './layout/Switcher';
import NavPage from './layout/NavPage';


function Admin() {
  const [isSidebar, setIsSidebar] = useState(false);
  return (
    <>
     <Switcher/>
      <Sidebar  />    
      <div className="layout-wrapper">
      { isSidebar == true &&
      <div class="preloader">
          <img src="https://vetra.laborasyon.com/assets/images/logo.svg" alt="logo" />
          <div class="preloader-icon"></div>
      </div> }
        <Header />
        <div className="content ">
           <NavPage />
        </div>
        <Footer />
      </div>
      
    </>
  )
}

export default Admin