import React, { useCallback, useReducer } from 'react';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { useNavigate,NavLink, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import DataTable from 'react-data-table-component';

const Leads = () => {

    const leadlisturl = '/sdm_lead/requester_leads';
    const leaddeleteurl = '/user/status_update';
    const [LeadList, setLeadList]  = React.useState([]);
    const [RefreshData ,setRefreshData]  = useReducer(x => x+1,0);
    const [search ,setSearch]  = React.useState("");
    const [filteredUser ,setFilteredUser]  = React.useState([]);
    const [ isAlertVisible, setIsAlertVisible ] = React.useState(true);
    const [isusermessage, issetMessage] = React.useState(null);
  
    const navigate = useNavigate();
    const usr_token = localStorage.getItem('authToken');
    const isUserLogin = localStorage.getItem('isAuthenticatedUser');
    let message = sessionStorage.getItem("message");
    
    var base64 = require('base-64');

      if(isAlertVisible == false){
        sessionStorage.removeItem("message");
      }
      
      const addLead = async () => {    
        navigate("/leads_create");
      }

    function leadList() {

        if(isUserLogin!=='true')
        {
            navigate('/');
        }
        fetch(ulmsUrl.concat(leadlisturl),{
          method:"POST",
          body:JSON.stringify({usr_token}),
          headers: {
          'Content-Type': 'application/json'
          },
          mode: 'cors',  
        })
       
        .then((response) =>
            response.json()
        )
        .then((response) => {   
            console.log(response.data);
            setLeadList(response.data);
            setFilteredUser(response.data);
        })
        .catch((err) => {
        })
        setRefreshData();
    }

    useEffect(() =>{
        leadList();
    }, []);

    useEffect(() => {
        const filterResult = LeadList.filter(data => {
             return (data.poc_name?.toLowerCase().match(search?.toLowerCase()) || data.poc_number?.toLowerCase().match(search?.toLowerCase()) || data.poc_email_id?.toLowerCase().match(search?.toLowerCase()) || data.service_type?.toLowerCase().match(search?.toLowerCase()) || data.lead_req_name?.toLowerCase().match(search?.toLowerCase()));  
        });
   
        setFilteredUser(filterResult);
       }, [search]); 

    const deleteUser = async (id) => {
      
        //  console.log(id);
         const confirmdelete  = await confirm('Are you sure you want to change the status?');
         console.log(confirmdelete);
         if(confirmdelete == true)
         {
            // fetch(ulmsUrl.concat(leaddeleteurl), {
            //     method: 'POST',
            //     body: JSON.stringify({id,usr_token}),
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // })
            // .then((result) =>
            //     result.json()
            // )
            // .then((result) => {
            //     console.log(result)
            //     if (result.status == true) {
            //       issetMessage(result.message);
            //       //sessionStorage.setItem("usrdeletemsg", result.message);
            //         leadList();
  
                              
            //     } else if (result.status == false) {
            //       issetMessage(result.message);
            //       //sessionStorage.setItem("usrdeletemsg", result.message);
            //     }
            // })
            // .catch((form_err) => {
  
            // })
         } 
       }

    const columns = [
        { 
          name: "ID",
          selector : (row, index) => index + 1 ,
          width : '80px',
          sortable :  true
        },
        {
          name: "Service Type",
          sortable :  true,  
          selector : (row) => row.service_type,
        },
        {
          name: "POC Name",
          sortable :  true,    
          selector : (row) => row.poc_name,     
        },
        {
          name: "POC Number",
          sortable :  true,  
          selector : (row) => row.poc_number
        },
        {
          name: "POC Email Id",
          sortable :  true,
          selector : (row) => row.poc_email_id
        },
        {
          name: "Requester Name",
          sortable :  true,
          selector : (row) => row.lead_req_name
        },
        {
          name: "Status",
          sortable :  true,  
          selector : (row) => <span className={row.status == 2?"text-danger":row.status == 3?"text-success":"text-n"}>{
            (row.status == 2) ? 'Lead Pending' : 'Lead Assigned'
    
            }</span>,
        },
        {
          name: "Edit",
          cell : (row) =><Link ><span className='badge bg-primary' >Edit</span></Link>,
        },
        {
          name: "Delete",
          cell : (row) => <Link onClick={()=>deleteUser(row.id)} ><span className='badge bg-danger' >Delete</span></Link>
        },
        
      ];

      useEffect(() => {
        if(message != ''){
          setTimeout(() => {
            setIsAlertVisible(false);
          }, 8000);    
        }
        }, []); 

        useEffect(() => {
          if(isusermessage != ''){
            setTimeout(() => {
              issetMessage(false);
            }, 8000);    
          }
          }, []); 

  return (
    <>
          {isAlertVisible && message && <div className="alert alert-success alert-dismissible fade show" role="alert">
                <strong>{message}</strong>
                
            </div>}
            {isusermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>{isusermessage}</strong>
            </div>}
            <div className="row">
                <div className="order-2 order-lg-1 col-lg-12 bd-content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">All Leads</h3>    
                            <button style={{font_size: '12px', float: 'right'}} type="button" onClick={addLead} className="btn btn-primary btn-sm  me-2"><strong>Create Lead</strong></button>    
                            <CSVLink style={{font_size: '12px', float: 'right'}} className="btn btn-success btn-sm  me-2" 
                            data={filteredUser}
                            filename={"users.csv"}
                            >Export</CSVLink>
                        </div>
                        
                        <div className="card-body">         
                               <DataTable 
                               id="table-to-xls"
                               data={filteredUser} 
                               columns={columns} 
                               pagination
                               fixedHeader
                               fixedHeaderScrollHeight='500px'
                               highlightOnHover
                               subHeader
                               subHeaderComponent = {
                                <input type="text" 
                                placeholder='Search here' 
                                className='w-25 form-control'
                                value={search}
                                onChange={(e)=> setSearch(e.target.value)}
                                 />
                               }
                               />
                          
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>

                        </div>
                    </div>
                </div>
            </div>

        </>
  )
}

export default Leads