import React, { useCallback, useReducer } from 'react';
import { Form } from 'react-bootstrap';
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import { UserPermissionList, LOBList, ProductList, ServicesList, BDList, SDList} from '../../apidata/fetchapi';
import { useNavigate,NavLink, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import { password, username } from '../../Api';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import Loader from '../../loader/Loader';
import DateFormat from '../dateformate/DateFormat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';


const BackendLead = () => {

    const { permissionList }   = UserPermissionList();
    const { lobListData }      = LOBList();
    const { productListData }  = ProductList();
    const { servicesListData } = ServicesList();
    const { bdListData }       = BDList();
    const { sdListData }       = SDList();

    let base64          = require('base-64');
    const navigate      = useNavigate();
    const usr_token     = localStorage.getItem('authToken');
    const user_id       = localStorage.getItem('usrId');
    const isUserLogin   = localStorage.getItem('isAuthenticatedUser');
    let message         = sessionStorage.getItem("createmessage");

    const initialData = {
        usr_token: usr_token,
        lead_status: 0,
    };

    const leaddataurl                               = '/backend/data_list';
    const [leadId, setLeadId]                       = React.useState('');
    const [search ,setSearch]                       = React.useState("");
    const [filteredLeads ,setFilteredLeads]         = React.useState([]);
    const [ isAlertVisible, setIsAlertVisible]      = React.useState(true);
    const [isusermessage, issetMessage]             = React.useState(null);
    const [dashboardLeadData, setDashboardLeadData] = React.useState([""]);
    const [reportLeadData, setReportLeadData]       = React.useState([]);
    const [loading, setLoading]                     = useState(true);
    const [dropdownData, setDropdownData]           = useState(initialData);


    const getDashBoardLeadsData = async () => {
        console.log('dropdown',JSON.stringify(dropdownData));
        setLoading(true);
              
        try {
            fetch(ulmsUrl.concat(leaddataurl) , {
                method: 'POST',
                body: JSON.stringify(dropdownData),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                setLoading(false);
                let result = response.data;
                console.log('filter',result);
                setDashboardLeadData(result)
                setFilteredLeads(response.data);
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDashBoardLeadsData();
    }, [dropdownData]);
  
    console.log(filteredLeads);
    console.log(dashboardLeadData);
  
    useEffect(() => {

      const isNumber = !isNaN(search);
      const searchLower = search?.toLowerCase();

      const filterResult = dashboardLeadData.filter(data => {
           return (isNumber && data.id === Number(search) || data.project_type?.toLowerCase().match(searchLower) || data.products?.toLowerCase().match(searchLower) || data.service_type?.toLowerCase().match(searchLower) || data.supplier_name?.toLowerCase().match(searchLower) || data.oem_name?.toLowerCase().match(searchLower) || data.cust_industry_verticle?.toLowerCase().match(searchLower) || data.lead_req_name?.toLowerCase().match(searchLower) || "");  
      });
  
      setFilteredLeads(filterResult);
    }, [search]); 


     const columns = [
        { 
          name: "Lead Id",
          selector : (row) => row.id ,
          width : '89px',
          sortable :  true 
        },
        {
          name: "LOB",
          sortable :  true,  
          width : '80px',
          selector : (row) => row.lob_type,
        },
        {
          name: "Product Type",
          sortable :  true,  
          width : '125px',
          selector : (row) => row.inventory_type,
        },
        {
          name: "Project ID",
          sortable :  true,  
          width : '140px',
          selector : (row) => row.project_id,
        },
        {
          name: "Services",
          sortable :  true,  
          width : '95px',
          selector : (row) => row.service_type,
        },
        {
          name: "Supplier Name",
          sortable :  true,  
          width : '220px',
          selector : (row) => {
            if(row.supplier_name != null && row.supplier_name_id != 666666){
              return(
                  <div>{row.supplier_name}</div>
              )
            }else if(row.new_supplier_name != null && row.supplier_name_id == 666666){
              return(
                  <div>{row.new_supplier_name}</div>
              )
            }else{
              return(
                <div>-</div>
              )
            }
          },
          wrap: true
        },
        {
          name: "OEM Name",
          sortable :  true,  
          width : '220px',
          selector : (row) => 
          {
            if(row.service_type == 'RP' && row.oem_name != null && row.oem_name_id != 666666){
              return(
                  <div>{row.oem_name }</div>
              )
            }else if(row.service_type == 'RP' && row.new_oem_name != null && row.oem_name_id == 666666){
              return(
                  <div>{row.new_oem_name}</div>
              )
            }else{
              return(
                <div>-</div>
              )
            }
          },
          wrap: true
        },
        {
          name: "Component Name",
          sortable :  true,
          width : '150px',
          selector : (row) => row.component_name,
          wrap: true  
        },
        {
          name: "BD Name",
          sortable :  true,
          width : '110px',
          selector : (row) => row.bd_person_name,
          wrap: true  
        },
        {
            name: "SD Name",
            sortable :  true,
            width : '110px',
            selector : (row) => row.sd_person_name,
            wrap: true  
        },
        {
          name: "Created At",
          sortable :  true,
          width : '115px',
          selector : (row) => DateFormat(row.created_at)
        },
        {
          name: "Status",
          sortable :  true,  
          width : '90px',
          selector : (row) =>
          {
            return(
                <span className="text-success">{row.status}</span>
            )
          },
          wrap: true    
        },
        {
          name: "View",
          width : '100px',
          cell : (row) => 
          {
            return(
                <>
                    <Link to={`/backend_leadview/${base64.encode(row.id)}`} ><span className='badge bg-primary' >Lead View</span></Link> 
                </>
            )
          }
        }, 
    ];

    useEffect(() => {
      if(message != ''){
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'success1',
        });  
      }
      setTimeout(() => {
        sessionStorage.removeItem('createmessage');
      }, 8000); 
    }, []); 


  return (
    <>
        <ToastContainer />
        {loading && (
            <>
            <Loader/>
            </>
        ) }
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title" style={{float:'left'}}> BACKEND LEADS</h3> 
                        {/* <button style={{font_size: '12px', float: 'right'}} type="button" onClick={clearFilter} className="btn btn-danger btn-sm  me-2"><strong>Clear Filter</strong></button>    */}
                        {/* <button style={{font_size: '12px', float: 'right'}} type="button" onClick={addLead} className="btn btn-primary btn-sm  me-2"><strong>Create Lead</strong></button>     */}
                        <CSVLink style={{font_size: '12px', float: 'right'}} className="btn btn-success btn-sm  me-2" 
                        data={filteredLeads}
                        filename={"backend_leads.csv"}
                        ><strong>Export</strong></CSVLink>
                    </div>
                    {/* <div style={{marginLeft:'10px', marginRight:'10px'}}>
                        <form className="row"  encType="multipart/form-data">  
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    Date :{" "}
                                </label>
                                <DateRangePicker 
                                block 
                                style={{ width: 230, borderColor: '#cccccc', borderRadius : '4px', borderStyle:'solid', borderWidth:'1px', boxSizing: 'border box'}} 
                                name="date" 
                                onChange={handleDateSelect}
                                defaultValue={datemydash}
                                key={datemydash}
                                />
                            </div>                        
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    LOB Name :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={lobListData}
                                    defaultValue={lobnamemydash}
                                    key={lobnamemydash}
                                    onChange={handleLOBNameInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    Product :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={productListData}
                                    defaultValue={productnamemydash}
                                    key={productnamemydash}
                                    onChange={handleProductInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    Services :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={servicesListData}
                                    defaultValue={servicenamemydash}
                                    key={servicenamemydash}
                                    onChange={handleServicesInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    BD Name :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={bdListData}
                                    defaultValue={bdnamemydash}
                                    key={bdnamemydash}
                                    onChange={handleBDNameInput}
                                    // onChange={handleSuppNameInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    Sol. Design Exe. :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="supplier_name"
                                    options={sdListData}
                                    defaultValue={sdnamemydash}
                                    key={sdnamemydash}
                                    onChange={handleSDNameInput}
                                    // onChange={handleSuppNameInput}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                <label htmlFor="inputState" className="form-label">
                                    Status :{" "}
                                </label>
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="status"
                                    options={statusarr}
                                    defaultValue={statusmydash}
                                    key={statusmydash}
                                    onChange={handleStatusInput}
                                    // onChange={handleSuppNameInput}
                                />
                            </div>
                        </form>
                    </div> */}
                    <div className="card-body">         
                            <DataTable 
                            id="table-to-xls"
                            paginationRowsPerPageOptions={[10,25,50,100,1000]}
                            data={filteredLeads} 
                            columns={columns} 
                            pagination
                            //fixedHeader
                            //fixedHeaderScrollHeight='500px'
                            highlightOnHover
                            subHeader
                            subHeaderComponent = {
                            <div className="col-md-3" style={{float:'right'}}>
                            <input type="text" 
                                placeholder='Search here' 
                                className='w-22 form-control'
                                value={search || ''}
                                onChange={(e)=> setSearch(e.target.value)}
                            />
                            </div>
                            }
                            />
                        
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
            
    </>
  )
}

export default BackendLead