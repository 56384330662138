import React from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { LocationData, ComponentFamily, UnitLoadList, VehicleTypeList, VehicleLoadTypeList } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { confirmAlert } from "react-confirm-alert";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';


const CommercialLeadDetail = () => {

    let base64 = require('base-64');

    const usr_token = localStorage.getItem('authToken');
    const usr_id    = localStorage.getItem('usrId');

    const [lead_id, setLeadId]                = useState(base64.decode(useParams().id));
    const [opportViewData, setOpportViewData] = React.useState([""]);
    const [loading, setLoading]               = React.useState(false);


    const getOpportViewData = async () => {
      setLoading(true);
      const arr = [];

      try {
          fetch(ulmsUrl.concat('/wip/lead_details') , {
              method: 'POST',
              body: JSON.stringify({lead_id, usr_token}),
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then((response) =>
              response.json()
          )
          .then((response) => {  
              setLoading(false);
              let result = response;  
              console.log('data',result); 
              setOpportViewData(result)
          });
        } catch (error) {
          console.log(error);
        }
    }


    useEffect(() => {
        getOpportViewData();
      }, []);


  return (
    <>
       
        <div className="accordion" id="accordionExample">
            <div className="card">
                <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{backgroundColor:'#46B653', borderRadius:'5px'}}  >
                    <h3 className="mb-0">
                        <span className="btn btn collapsed"  style={{fontWeight: 'bold', fontSize:'16px', color:'white'}} type="button" >
                            Leads Details
                        </span>
                    </h3>
                </div>
                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                        <div className="invoice row">
                            <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>LOB</th>
                                            <td >{opportViewData != '' && opportViewData.data.project_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Product Type</th>
                                            <td>{opportViewData != '' && opportViewData.data.products}</td>
                                        </tr>
                                        <tr>
                                            <th>Service Type</th>
                                            <td>{opportViewData != '' && opportViewData.data.service_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Start Date</th>
                                            <td >{opportViewData != '' && opportViewData.data.start_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Supplier Name</th>
                                            {/* <td>{opportViewData != '' && opportViewData.data.supplier_name}</td> */}
                                            {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&
                                                <td>{opportViewData.data.new_supplier_name}</td> 
                                            }
                                            {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                                <td>{opportViewData.data.supplier_name}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th >Entity Name</th>
                                            {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&
                                            <td>{opportViewData.data.new_supplier_name}</td> 
                                            }
                                            {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                            <td>{opportViewData != '' && opportViewData.data.entity_name}</td>
                                            }
                                        </tr>  
                                        <tr>
                                            <th>LS Services:</th>
                                            {opportViewData != '' && opportViewData.data.multi_ls.length > 0 ?
                                            <td>
                                                {opportViewData.data.multi_ls.map((data) => (  
                                                    <>{data}  </> 
                                                ))} 
                                            </td> :
                                            <td>-</td> 
                                            }  
                                        </tr> 
                                        <tr>
                                            <th>Supplier Location</th>
                                            {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&    
                                            <td>{opportViewData.data.new_supplier_loc}</td> 
                                            }
                                            {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                            <td>{opportViewData != '' && opportViewData.data.supplier_loc}</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Supplier Industry Vertical</th>
                                            <td>{opportViewData != '' && opportViewData.data.cust_industry_verticle}</td>
                                        </tr> 
                                            {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                            <tr>
                                                <th>OEM Name</th>
                                                {/* <td >{listViewData != '' && listViewData.data.supplier_name}</td> */}
                                                {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&
                                                <td>{opportViewData.data.new_oem_name}</td> 
                                                }
                                                {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                <td>{opportViewData.data.oem_name}</td>
                                                }
                                            </tr>
                                            }
                                            {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                            <tr>
                                                <th >OEM Entity Name</th>
                                                {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&
                                                <td>{opportViewData.data.new_oem_name}</td> 
                                                }
                                                {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                <td>{opportViewData != '' && opportViewData.data.oem_entity_name}</td>
                                                }
                                            </tr> 
                                            }    
                                            {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                            <tr>
                                                <th>OEM Location</th>
                                                {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&    
                                                <td>{opportViewData.data.new_oem_loc}</td> 
                                                }
                                                {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                <td>{opportViewData != '' && opportViewData.data.oem_loc}</td>
                                                }
                                            </tr>
                                            }
                                        <tr>
                                            <th>Lead Requester Name</th>
                                            <td >{opportViewData != '' && opportViewData.data.lead_req_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Owner Name</th>
                                            <td >{opportViewData != '' && opportViewData.data.lead_owner_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Opportunity Owner Name</th>
                                            {/* <td >{opportViewData != '' && opportViewData.data.opportunity_owner_name}</td> */}
                                            {(opportViewData != '' && opportViewData.data.opportunity_owner_name != null) ?
                                                <td>{opportViewData.data.opportunity_owner_name}</td> :
                                                <td>-</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th>Lead Status</th>
                                            {(opportViewData != '' && opportViewData.data.LeadStatus != 'Dormant' && opportViewData.data.LeadStatus != 'Lost') &&
                                            <td>
                                                <span className="text-success"> {opportViewData != '' && opportViewData.data.LeadStatus} </span>
                                            </td>}
                                            {(opportViewData != '' && (opportViewData.data.LeadStatus === 'Dormant' || opportViewData.data.LeadStatus === 'Lost') ) &&
                                            <td>
                                                <span className="text-danger"> {opportViewData != '' && opportViewData.data.LeadStatus} </span>
                                            </td>}
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-12">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th style={{width:'150px'}}>Lead Doc</th>
                                            <td>
                                            {opportViewData != '' && opportViewData.packingImg.map((data) => (  
                                                <>
                                                    <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                                </>
                                            ))}  
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{width:'150px'}}>Opportunity Doc</th>
                                            <td>
                                            {opportViewData != '' && opportViewData.OpportunityImgPath.map((data) => (  
                                                <>
                                                    <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                                </>
                                            ))}  
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div> 
                            <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                <table id="mainTable" className="table padded-table">
                                    <thead>
                                    </thead>
                                    <tbody>                               
                                        <tr>
                                            <th>Customer POC Name</th>
                                            <th>Customer POC Number</th>
                                            <th>Customer POC Email ID</th>
                                            <th>Customer POC Designation</th>
                                            
                                        </tr>
                                        {opportViewData != '' && opportViewData.PocInfo.map((data, index) => (  
                                            <tr key={index}>  
                                                <td>{data.PocName}</td>  
                                                <td>{data.PocNo}</td>  
                                                <td>{data.PocEmail}</td>  
                                                <td>{data.Designation}</td>
                                                
                                            </tr>  
                                        ))}  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{backgroundColor:'#46B653', borderRadius:'5px'}}>
                    <h3 className="mb-0">
                        <span className="btn btn collapsed"  style={{fontWeight: 'bold', fontSize:'16px', color:'white'}} type="button" >
                            Opportunity Details
                        </span>
                    </h3>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                    {opportViewData != '' && opportViewData.data.project_type == 'AUTO' && (opportViewData.data.service_type == 'RP' || opportViewData.data.service_type == 'LS') && (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG') &&
                        <div className="invoice row col-md-12">
                            <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Service For</th>
                                            <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                        </tr>
                                        <tr>
                                            <th>Origin</th>
                                            <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                        </tr>
                                        <tr>
                                            <th>Destination</th>
                                            <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                        </tr>
                                        <tr>
                                            <th>Business Tenure (In Months)</th>
                                            <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                        </tr>
                                        <tr>
                                            <th>Potential Revenue</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                        </tr>
                                        <tr>
                                            <th>Potential Capex</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-6">
                                <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Potential Ebitda (%)</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                        </tr>
                                        <tr>
                                            <th>Component Family</th>
                                            <td>{opportViewData != '' && opportViewData.data.component_family_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Expected Closure Date</th>
                                            <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Reference URL</th>
                                            <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Description</th>
                                            <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                <table id="mainTable" className="table padded-table">
                                <thead>
                                </thead>
                                    <tbody>                               
                                        <tr>
                                            <th>Component Name</th>
                                            <th>Minimum Monthly Volume</th>
                                            <th>Maximum Monthly Volume</th>
                                            <th>Monthly Volume</th>
                                            <th>Current Packaging</th>
                                            <th>Current Packaging (Qty./Box)</th>
                                            <th>Cycle Time</th>
                                            <th>Component Dimensions Length (mm)</th>
                                            <th>Component Dimensions Width (mm)</th>
                                            <th>Component Dimensions Height (mm)</th>
                                            <th>Component Dimensions Wt. (gm)</th>
                                          
                                        </tr>
                                        {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                            <tr key={index}>  
                                                <td>{data.component_name}</td>  
                                                <td>{data.monthly_volume_min}</td>  
                                                <td>{data.monthly_volume_max}</td>  
                                                <td>{data.monthly_volume_avg}</td>
                                                <td>{data.current_packaging}</td>
                                                <td>{data.curr_qty_per_box}</td>
                                                <td>{data.cycle_time}</td>
                                                <td>{data.component_dimension_l}</td>
                                                <td>{data.component_dimension_w}</td>
                                                <td>{data.component_dimension_h}</td>
                                                <td>{data.component_dimension_wt}</td>
                                               
                                            </tr>  
                                        ))}  
                                    </tbody>
                                </table>
                            </div>
                        </div> } 
                        {opportViewData != '' && opportViewData.data.project_type == 'RETAIL' &&
                        <div className="invoice row col-md-12">
                            <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Service For</th>
                                            <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                        </tr>
                                        <tr>
                                            <th>Origin</th>
                                            <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                        </tr>
                                        <tr>
                                            <th>Destination</th>
                                            <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                        </tr>
                                        <tr>
                                            <th>Business Tenure (In Months)</th>
                                            <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                        </tr>
                                        <tr>
                                            <th>Potential Revenue</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                        </tr>
                                        <tr>
                                            <th>Potential Capex</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-6">
                                <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Potential Ebitda (%)</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                        </tr>
                                        <tr>
                                            <th>Expected Closure Date</th>
                                            <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Reference URL</th>
                                            <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Description</th>
                                            <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                <table id="mainTable" className="table padded-table">
                                <thead>opportunity_view
                                </thead>    
                                    <tbody>                               
                                        <tr>
                                            <th>Operation Type</th>
                                            <th>Area Required (Sq.ft.)</th> 
                                            <th>Inventory Days</th>
                                            <th>Avg. Daily Inbound</th>
                                            <th>Avg. Daily Outbound</th>
                                          
                                        </tr>
                                        {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                            <tr key={index}>  
                                                <td>{data.operation_type.label}</td>  
                                                <td>{data.area_required}</td>  
                                                <td>{data.inventory_days}</td>  
                                                <td>{data.avg_daily_inbound}</td>  
                                                <td>{data.avg_daily_outbound}</td>
                                               
                                            </tr>  
                                        ))}  
                                    </tbody>
                                </table>
                            </div> 
                        </div> }
                        { ( opportViewData != '' && opportViewData.data.project_type == 'AUTO' && opportViewData.data.service_type == 'NRP' &&  (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG') ) ?
                        <div className="invoice row col-md-12">
                            <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Service For</th>
                                            <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                        </tr>
                                        <tr>
                                            <th>Origin</th>
                                            <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                        </tr>
                                        <tr>
                                            <th>Destination</th>
                                            <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                        </tr>
                                        <tr>
                                            <th>Business Tenure (In Months)</th>
                                            <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                        </tr>
                                        <tr>
                                            <th>Potential Revenue</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                        </tr>
                                        <tr>
                                            <th>Potential Capex</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-6">
                                <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Potential Ebitda (%)</th>
                                            <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                        </tr>
                                        <tr>
                                            <th>Component Family</th>
                                            <td>{opportViewData != '' && opportViewData.data.component_family_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Expected Closure Date</th>
                                            <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Reference URL</th>
                                            <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Description</th>
                                            <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                <table id="mainTable" className="table padded-table">
                                    <thead>
                                    </thead>
                                    <tbody>                               
                                        <tr>
                                            <th>Component Name</th>
                                            <th>Minimum Monthly Volume</th>
                                            <th>Maximum Monthly Volume</th>
                                            <th>Monthly Volume</th>
                                          
                                        </tr>
                                        {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                            <tr key={index}>  
                                                <td>{data.component_name}</td>  
                                                <td>{data.monthly_volume_min}</td>  
                                                <td>{data.monthly_volume_max}</td>  
                                                <td>{data.monthly_volume_avg}</td>
                                              
                                            </tr>  
                                        ))}  
                                    </tbody>
                                </table>
                            </div>
                        </div> : null }

                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{backgroundColor:'#46B653', borderRadius:'5px'}}>
                    <h3 className="mb-0">
                        <span className="btn btn collapsed"  style={{fontWeight: 'bold', fontSize:'16px', color:'white'}} type="button" >
                            Solution Details
                        </span>
                    </h3>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body">
                    {opportViewData != '' && opportViewData.SolCompData.map((data,index) => {
                        return(
                        <>
                        <div className="card-header" id="headingThreess" data-toggle="collapse" data-target={"#collapseThrees"+index} aria-expanded="false" aria-controls={"collapseThrees"+index} style={{backgroundColor:'#0D4C7B', borderRadius:'5px'}}>
                            <h3 className="mb-0">
                                <span className="btn btn collapsed"  style={{fontWeight: 'bold', fontSize:'16px', color:'white'}} type="button" >
                                    Component Name : {data.component_name}
                                </span>
                            </h3>
                        </div>
                        <div id={"collapseThrees"+index} className="collapse" aria-labelledby="headingThreess" data-parent="#accordion">
                            <div className="card-body">
                                <div className="invoice row">
                                
                                    <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                        <table style={{marginTop: '10px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>Component Name</th>
                                                    <td >{data.component_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Monthly Volume (nos.)</th>
                                                    <td>{data.monthly_volume_avg}</td>
                                                </tr>
                                                <tr>
                                                    <th>Cycle Time</th>
                                                    <td>{data.cycle_time}</td>
                                                </tr>
                                                <tr>
                                                    <th>Component Dimensions Length (mm)</th>
                                                    <td>{data.component_dimension_l}</td>
                                                </tr>
                                                <tr>
                                                    <th>Component Dimensions Width (mm)</th>
                                                    <td>{data.component_dimension_w}</td>
                                                </tr>
                                                <tr>
                                                    <th>Component Dimensions Height (mm)</th>
                                                    <td>{data.component_dimension_h}</td>
                                                </tr>
                                                <tr>
                                                    <th>Component Dimensions Weight (Kg)</th>
                                                    <td>{data.component_dimension_wt}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed Comp. Qty./UL</th>
                                                    <td>{data.prop_comp_ul_qty}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Packaging</th>
                                                    <td >{data.prop_outer_packaging_val}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Dimension Length (mm)</th>
                                                    <td>{data.prop_box_dimension_l}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Dimension Width (mm)</th>
                                                    <td>{data.prop_box_dimension_w}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div className="table-scrollable col-md-6">
                                        <table style={{marginTop: '10px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                            
                                                <tr>
                                                    <th>Proposed UL Dimension Height (mm)</th>
                                                    <td>{data.prop_box_dimension_h}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Dimension Weight (Kg)</th>
                                                    <td>{data.prop_box_dimension_wt}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Collapsible Dimension Length (mm)</th>
                                                    <td>{data.prop_collaps_dimension_l}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Collapsible Dimension Width (mm)</th>
                                                    <td>{data.prop_collaps_dimension_w}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Collapsible Dimension Height (mm)</th>
                                                    <td>{data.prop_collaps_dimension_h}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Collapsible Dimension Weight (Kg)</th>
                                                    <td>{data.prop_collaps_dimension_wt}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proposed UL Rate</th>
                                                    <td>{data.prop_ul_rate}</td>
                                                </tr>
                                                <tr>
                                                    <th>Outer Cost</th>
                                                    <td>{data.outer_cost}</td>
                                                </tr>
                                                <tr>
                                                    <th>Inner Cost</th>
                                                    <td>{data.inner_cost}</td>
                                                </tr>
                                                <tr>
                                                    <th>Solution Cost </th>
                                                    <td>{data.solution_cost}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div className="table-scrollable col-md-12">
                                        <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th style={{width:'150px'}}>Solution Doc</th>
                                                    <td>
                                                    {opportViewData != '' && opportViewData.LastSolution[0].SolutionImg.map((data, index) => {  
                                                        return(
                                                        <>
                                                            <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px', float:'left'}} >{data.file_type}</span></Link>
                                                        
                                                        </>)
                                                    })}  
                                                    </td>
                                                    </tr>
                                            
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div style={{float:'left', overflowX:'auto'}} className=" col-md-12">
                                        <table id="mainTable" className="table padded-table">
                                            <thead>
                                            </thead>
                                            <tbody>                               
                                                <tr>
                                                    <th>Proposed Inner Packaging</th>
                                                    <th>Inner Dimension Length</th>
                                                    <th>Inner Dimension Width</th>
                                                    <th>Inner Dimension Height</th>
                                                    <th>Inner Dimension Weight</th>
                                                    <th>Inner Collapsible Dim. Length</th>
                                                    <th>Inner Collapsible Dim. Width</th>
                                                    <th>Inner Collapsible Dim. Height</th>
                                                    <th>Inner Collapsible Dim. Weight</th>
                                                    <th>Inner Qty</th>
                                                    <th>Inner Rate</th>
                                                    
                                                </tr>
                                                {data.SolCompInner.map((data, index) => (  
                                                    <tr key={index}>  
                                                        <td>{data.prop_inner_packaging_val}</td>  
                                                        <td>{data.inner_dimension_l}</td>  
                                                        <td>{data.inner_dimension_w}</td>  
                                                        <td>{data.inner_dimension_h}</td>
                                                        <td>{data.inner_dimension_wt}</td>
                                                        <td>{data.inner_collaps_dimension_l}</td>  
                                                        <td>{data.inner_collaps_dimension_w}</td>  
                                                        <td>{data.inner_collaps_dimension_h}</td>
                                                        <td>{data.inner_collaps_dimension_wt}</td>
                                                        <td>{data.inner_qty}</td>
                                                        <td>{data.inner_rate}</td>                                                
                                                    </tr>  
                                                ))}  
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        </>
                        )
                        })}
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{backgroundColor:'#46B653', borderRadius:'5px'}}>
                    <h3 className="mb-0">
                        <span className="btn btn collapsed"  style={{fontWeight: 'bold', fontSize:'16px', color:'white'}} type="button" >
                            Transport Details
                        </span>
                    </h3>
                </div>
                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div className="card-body">
                        <div className="invoice row">
                            <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Pick-up Location</th>
                                            <td >{opportViewData != '' && opportViewData.TransportData.origin}</td>
                                        </tr>
                                        <tr>
                                            <th>Delivery Location</th>
                                            <td>{opportViewData != '' && opportViewData.TransportData.destination}</td>
                                        </tr>
                                        <tr>
                                            <th>Vehicle Type</th>
                                            <td>{opportViewData != '' && opportViewData.TransportData.vehicle_type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Load Type</th>
                                            <td >{opportViewData != '' && opportViewData.TransportData.vehicle_Load_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Unit</th>
                                            <td>{opportViewData != '' && opportViewData.TransportData.vehicle_ul_type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-12">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th style={{width:'150px'}}>Transport Doc</th>
                                            <td>
                                            {opportViewData != '' && opportViewData.transport.map((data) => (  
                                                <>
                                                    <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                                </>
                                            ))}  
                                            </td>
                                        </tr>            
                                    </tbody>
                                </table>
                            </div> 
                            <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                <table id="mainTable" className="table padded-table">
                                    <thead>
                                    </thead>
                                    <tbody>                               
                                        <tr>
                                            <th>Component Id</th>
                                            <th>Component Name</th>
                                            <th>First Mile Cost (Per Unit Load)</th>
                                            <th>Mid Mile Cost (Per Unit Load)</th>
                                            <th>Last Mile Cost (Per Unit Load)</th>
                                            <th>Total Cost</th>
                                            <th>Remarks</th>
                                            
                                        </tr>
                                        {opportViewData != '' && opportViewData.TransportData.TransportInner.map((data, index) => (  
                                            <tr key={index}>  
                                                <td>{data.comp_id}</td>  
                                                <td>{data.comp_name}</td>  
                                                <td>{data.first_mile_cost}</td>  
                                                <td>{data.mid_mile_cost}</td>
                                                <td>{data.last_mile_cost}</td>
                                                <td>{data.total_cost}</td>
                                                <td>{data.remarks}</td>                                                
                                            </tr>  
                                        ))}  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default CommercialLeadDetail