import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { CustomerData, PortList, ComponentFamily, DistrictData, SupplierOEMData } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateNRPOpportunity = () => {

    const { districtOptions }  = DistrictData();
    const { comFamOptions }    = ComponentFamily();
    const { portListData }     = PortList();
    const { options }          = CustomerData();
    const { supplierOEMDropdown } = SupplierOEMData();

    let base64 = require('base-64');
    const opportupdateurl = "/opportunity/update";
    const navigate = useNavigate();
    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id = localStorage.getItem('usrId');

    const initialData = {
        "lead_id": lead_id,
        "service_for": "",
        "origin": "",
        "destination": "",
        "port_origin": "",
        "port_destination": "",
        "component_family_name": "",
        "potential_rev": "",
        "potential_capex": "",
        "potential_ebitda": "",
        "potential_ebitda_per": "",
        "expected_closure_date": "",
        "business_tenure": "",
        "reference_url": "",
        "opportunity_desc": "",
        "opportunity_creater_id": usr_id,
        "opportunity_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const [opportunityUpdate, setOpportunityUpdate] = React.useState([""]);
    const [opportDocData, setOpportDocData] = React.useState('');
    const [leadViewData, setLeadViewData] = React.useState([""]);
    const [usermessage, setMessage] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [componentFamChild, setcomponentFamChild] = React.useState([""]);
    const [locationSelected, setLocationSelected] = useState('');
    const [customerData, setCustomerData] = React.useState([""]);
    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState([
        {  
           component_name: "", 
           monthly_volume_min: '', 
           monthly_volume_max: '', 
           monthly_volume_avg: '',
           forward_transportation: 0,
        }
    ]);

    const getOpportViewData = async () => {
        const arr = [];
        try {
            fetch(ulmsUrl.concat('/wip/opp_details') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;   
                console.log(result);
                setOpportDocData(result);
                setOpportunityUpdate(result.data)
                setInputFields(result.multi_data)
                handleComFamInput(result.data.component_family_name)
            });
        } catch (error) {
            console.log(error);
        }
      }
  
      useEffect(() => {
        getOpportViewData();
      }, []);


      const handleFormInput = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setOpportunityUpdate(oldValue => ({
                ...oldValue,
                [name]: value,
                "usr_token": usr_token,
            }))
        }
        if(average != 0 || ebidtaperc != 0){
            setOpportunityUpdate(oldValue => ({
            ...oldValue,
            "monthly_volume_avg": average,
            "ebidta_perc": ebidtaperc,
          }))
        }
    };

    const handleFileInput = (e) => {
        console.log(e.target.files); 
        setSelectedFile(e.target.files)
        console.log(selectedFile);
    };

    const handleOriginInput = (data) => {

        setCustomerData([""]);

        setOpportunityUpdate(oldValue => ({
            ...oldValue,
            "origin": data,
        }))  

        let client_id = data.value;
        console.log(client_id)

        if(client_id != ""){
            console.log(JSON.stringify({ client_id }))

            fetch(ulmsUrl.concat('/holibook/supp_oem_info'), {
                method: 'POST',
                body: JSON.stringify({ client_id }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + base64.encode(username + ":" + password)
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                console.log('supp',result)
                setCustomerData(response.data)
            });
        } 

    }

    const handleDestinationInput = (data) => {
        const test = data.label;
        setOpportunityUpdate(oldValue => ({
            ...oldValue,
            "destination": data,
        }))  
    }

    const handlePortOriginInput = (data) => {
        const test = data.label;
        setOpportunityUpdate(oldValue => ({
            ...oldValue,
            "port_origin": data,
        }))  
    }

    const handlePortDestinationInput = (data) => {
        const test = data.label;
        setOpportunityUpdate(oldValue => ({
            ...oldValue,
            "port_destination": data,
        }))  
    }

    const handleComFamInput = (value) => { 
        setOpportunityUpdate(oldValue => ({
            ...oldValue,
            "component_family_name": value,
        }))  

        let component_family = value;

        // const arr = [];
        // try {
        //     fetch(ulmsUrl.concat('/family/name_list') , {
        //         method: 'POST',
        //         body: JSON.stringify({component_family, usr_token}),
        //         headers: {
        //             'Content-Type': 'application/json',
        //         }
        //     })
        //     .then((response) =>
        //         response.json()
        //     )
        //     .then((response) => {  
        //         let result = response.data;
        //         result?.map((data) => {
        //             return arr.push({ value: data.id, label: data.name });
        //         });
        //         setcomponentFamChild(arr)
        //     });
        // } catch (error) {
        //     console.log(error);
        // }
    };

    const handleCompNameInput = (index,event) => { 
        const list = [...inputFields];
        list[index]["component_name"] = event;

        setOpportunityUpdate(oldValue => ({
            ...oldValue,
            "multi_data": list,
        }))  
    };

    const addFields = () => {
        let newfield = { 
           component_name: "", 
           monthly_volume_min: '', 
           monthly_volume_max: '', 
           monthly_volume_avg: '',
           forward_transportation: 0,
        }
        setInputFields([...inputFields, newfield])
    }

    const handleChange = (index, event) => {
       const { name, value } = event.target;
       const list = [...inputFields];
       list[index][name] = value;
       setOpportunityUpdate(oldValue => ({
        ...oldValue,
        "multi_data": list,
      }))  

    }

    const removeInputFields = (index, event) => {
        // setInputFields(inputFields.filter((v, i) => i !== index));
         const rows = [...inputFields];
         rows.splice(index, 1);
         setInputFields(rows);
    }


    let average = 0;
    if(opportunityUpdate.monthly_volume_min != '' && opportunityUpdate.monthly_volume_max != ''){
      let sum = Number(opportunityUpdate.monthly_volume_min) + Number(opportunityUpdate.monthly_volume_max);
      average = sum/2;
    }

    let ebidtaperc = 0;
    if(opportunityUpdate.exp_monthly_revenue != '' && opportunityUpdate.exp_monthly_expense){
      let revenue = Number(opportunityUpdate.exp_monthly_revenue);
      let expense = Number(opportunityUpdate.exp_monthly_expense);
      let ebitadata = (revenue - expense) / revenue;
      ebidtaperc = ebitadata * 100;
    }

    let ebitadata = 0;
    if(opportunityUpdate.potential_rev != '' && opportunityUpdate.potential_rev != null && opportunityUpdate.potential_ebitda_per != '' && opportunityUpdate.potential_ebitda_per != null){
        let potentialrev = Number(opportunityUpdate.potential_rev);
        let ebidta = Number(opportunityUpdate.potential_ebitda_per);
        ebitadata = (ebidta / 100 ) * potentialrev;
    }

    const handleTransport = (index, e) => {
        
        const { name, value } = e.target;
       
        if (e && (e.target.checked)) {

            let removedChkbox = (value=='1') ? '0' : '1';

            document.getElementById(`chbk_${removedChkbox}`).checked = false;

            const list = [...inputFields];
            list[index][name] = value;
            setOpportunityUpdate(oldValue => ({
                ...oldValue,
                "multi_data": list,
            }))  

        }else{

           
        }
    }

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const createdata = {
            lead_id : lead_id,
            service_for :  opportunityUpdate.service_for,
            origin :  opportunityUpdate.origin,
            destination :  opportunityUpdate.destination,
            port_origin :  opportunityUpdate.port_origin,
            port_destination :  opportunityUpdate.port_destination,
            component_family_name :  opportunityUpdate.component_family_name,
            business_tenure :  opportunityUpdate.business_tenure,
            potential_rev :  opportunityUpdate.potential_rev,
            potential_capex :  opportunityUpdate.potential_capex,
            potential_ebitda_per : opportunityUpdate.potential_ebitda_per,
            potential_ebitda :  ebitadata,
            expected_closure_date :  opportunityUpdate.expected_closure_date,
            reference_url :  opportunityUpdate.reference_url,
            opportunity_desc :  opportunityUpdate.opportunity_desc,
            multi_data :  inputFields,
            opportunity_creater_id : usr_id,
            opportunity_creater_name : usr_name,
            usr_token : usr_token
        }

        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`opportunity_photo_web`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(createdata));
        console.log(JSON.stringify(createdata));
      
        fetch(ulmsUrl.concat(opportupdateurl), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setOpportunityUpdate(initialData);
                // toast.success(result.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     toastId: 'success1',
                // });
                sessionStorage.setItem("createmessage", result.message);
                setLoading(false);
                navigate("/all_leads", { replace: true });

            } else if (result.status == false) {
                setLoading(false);
                setOpportunityUpdate(opportunityUpdate);
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

  return (
    <>
     {/* {usermessage && <div className="alert alert-danger alert-dismissible fade show" role="alert">
           <strong>{usermessage}</strong>    
       </div>} */}
       <ToastContainer />
       {loading && (
          <>
            <Loader/>
          </>
        ) }
       <div className="row">
           <div className="order-2 order-lg-1 col-lg-12 bd-content">
               <div className="card">
                   <div className="card-header">
                       <h3 className="card-title">UPDATE OPPORTUNITY ( LEAD ID: {lead_id} )</h3>
                       <div className='All_three_button'>
                        <Link onClick={() => navigate(-1)}><strong><i title='back' className="fa fa-arrow-circle-left" style={{fontSize:'30px',color:'#0D4C7B',marginLeft: '10px'}}></i></strong></Link>
                       </div>
                   </div>
                   <div className="card-body">
                       <div className="invoice row">
                            <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>LOB</th>
                                            <td >{opportunityUpdate != '' && opportunityUpdate.project_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Product Type</th>
                                            <td>{opportunityUpdate != '' && opportunityUpdate.products}</td>
                                        </tr>
                                        <tr>
                                            <th>Service Type</th>
                                            <td>{opportunityUpdate != '' && opportunityUpdate.service_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Supplier Name</th>
                                            {(opportunityUpdate != '' && opportunityUpdate.supplier_name_id == 666666) &&
                                            <td>{opportunityUpdate.new_supplier_name}</td> 
                                            }
                                            {(opportunityUpdate != '' && opportunityUpdate.supplier_name_id != 666666) &&
                                            <td>{opportunityUpdate.supplier_name}</td>
                                            }
                                        </tr>
                                        <tr>
                                            <th >Entity Name</th>
                                            {(opportunityUpdate != '' && opportunityUpdate.supplier_name_id == 666666) &&
                                            <td>{opportunityUpdate.new_supplier_name}</td> 
                                            }
                                            {(opportunityUpdate != '' && opportunityUpdate.supplier_name_id != 666666) &&
                                            <td>{opportunityUpdate != '' && opportunityUpdate.entity_name}</td>
                                            }
                                        </tr>  
                                        <tr>
                                            <th>Supplier Location</th>
                                            {(opportunityUpdate != '' && opportunityUpdate.supplier_name_id == 666666) &&    
                                            <td>{opportunityUpdate.new_supplier_loc}</td> 
                                            }
                                            {(opportunityUpdate != '' && opportunityUpdate.supplier_name_id != 666666) &&
                                            <td>{opportunityUpdate != '' && opportunityUpdate.supplier_loc}</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-6">
                                <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>LS Services:</th>
                                            {opportunityUpdate != '' && opportunityUpdate.multi_ls.length > 0 ?
                                                <td>
                                                {opportunityUpdate.multi_ls.map((data) => (  
                                                    <>{data}  </> 
                                                ))} 
                                                </td> :
                                                <td>-</td> 
                                            }  
                                        </tr>
                                        <tr>
                                            <th>Industry Vertical</th>
                                            <td>{opportunityUpdate != '' && opportunityUpdate.cust_industry_verticle}</td>
                                        </tr>
                                        <tr>
                                            <th>First Contact Date</th>
                                            <td >{opportunityUpdate != '' && opportunityUpdate.start_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Description</th>
                                            <td >{opportunityUpdate != '' && opportunityUpdate.lead_desc}</td>
                                        </tr>
                                        <tr>
                                            <th>Lead Requester Name</th>
                                            <td >{opportunityUpdate != '' && opportunityUpdate.lead_req_name}</td>
                                        </tr> 
                                        <tr>
                                            <th>Lead Owner Name</th>
                                            {(opportunityUpdate != '' && opportunityUpdate.lead_owner_name != null) ?
                                            <td>{opportunityUpdate.lead_owner_name}</td> :
                                            <td>-</td>
                                            }
                                        </tr> 
                                        <tr>
                                            <th>Lead Status</th>
                                            <td>
                                                {opportunityUpdate != '' &&  opportunityUpdate.status == 2 &&
                                                <span className="text-secondary">Lead Pending at BD Head </span>
                                                }
                                                {opportunityUpdate != '' &&  opportunityUpdate.status == 3 &&
                                                <span className="text-warning">Lead Assigned ({opportunityUpdate.lead_owner_name}) </span>
                                                }
                                                 {opportunityUpdate != '' &&  opportunityUpdate.status == 4 &&
                                                <span className="text-info">Opportunity Pending at SD Head </span>
                                                }
                                                {opportunityUpdate != '' &&  opportunityUpdate.status == 5 &&
                                                <span className="text-success">WIP ({opportunityUpdate.opportunity_owner_name}) </span>
                                                }
                                                {opportunityUpdate != '' &&  opportunityUpdate.status == 22 &&
                                                <span className="text-danger">Rejected</span>
                                                }
                                                {opportunityUpdate != '' &&  opportunityUpdate.status == 21 &&
                                                 <span className="text-danger">Deleted</span>
                                                }
                                           </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                            <div className="table-scrollable col-md-12">
                                {/* <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                    <thead className="thead-light">
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Location</th>
                                            <td>{opportunityUpdate != '' && opportunityUpdate.supplier_loc}</td>
                                        </tr>
                                    
                                    </tbody>
                                </table> */}
                            </div> 
                        </div>
                       <form className="row g-3" encType="multipart/form-data" onSubmit={handleFormSubmit} style={{marginTop: '5px'}}>
                          <div className='row' style={{marginTop: '5px'}}>
                                <div className="col-md-3">  
                                    <Form.Label style={{marginRight: '15px'}} >Service For :<span className="required-mark">*</span></Form.Label>
                                    <Form.Check
                                        inline
                                        label="Domestic"
                                        name="service_for"
                                        value="Domestic"
                                        type= "radio"
                                        onClick={()=>setLocationSelected("Domestic")}
                                        onChange={handleFormInput}
                                        checked = {opportunityUpdate.service_for === "Domestic" ? true : false}
                                        id={`inline-radio-2`}
                                    />
                                    <Form.Check
                                        inline
                                        label="International"
                                        name="service_for"
                                        value="International"
                                        type= "radio"
                                        onClick={()=>setLocationSelected("International")}
                                        onChange={handleFormInput}
                                        checked = {opportunityUpdate.service_for === "International" ? true : false}
                                        id={`inline-radio-1`}
                                    />
                                </div>

                                {(customerData != '' && customerData != null) ?
                                <div className="col-md-9" style={{backgroundColor: '#ADD8E6'}}>
                                    <h8>{customerData != '' && <><strong> Supplier Name : </strong> {customerData.client_name}, </> }
                                    {customerData != '' &&  <> <strong> Entity Name : </strong> {customerData.entity_name}, <strong> Pincode : </strong> {customerData.pin_code}</> }</h8><br/>
                                    <h8>{customerData != '' && <> <strong> Location : </strong> {customerData.address}</> } </h8>
                                </div> :
                                <div className="col-md-9">

                                </div>}

                                {opportunityUpdate != '' && opportunityUpdate.service_type == 'NRP' && opportunityUpdate.service_for == "Domestic" &&
                                <>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label"> Origin :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="origin"
                                            options={supplierOEMDropdown}
                                            defaultValue={opportunityUpdate.origin}
                                            onChange={handleOriginInput}
                                            key={opportunityUpdate.origin}
                                        />
                                    </div>
                                </>}
                                {opportunityUpdate.service_for == "International" &&
                                <>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label"> Origin :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="port_origin"
                                            options={portListData}
                                            defaultValue={opportunityUpdate.port_origin}
                                            onChange={handlePortOriginInput}
                                            key={opportunityUpdate.port_origin}
                                        />
                                    </div>
                                    <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label"> Destination :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="port_destination"
                                            options={portListData}
                                            defaultValue={opportunityUpdate.port_destination}
                                            onChange={handlePortDestinationInput}
                                            key={opportunityUpdate.port_destination}
                                        />
                                    </div>
                                </>}
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Business Tenure (In months) :<span className="required-mark">*</span> </label>
                                    <Form.Control
                                        type="text"
                                        name="business_tenure"
                                        value={opportunityUpdate.business_tenure}
                                        onChange={handleFormInput}
                                        id="business_tenure"
                                        className="form-control" placeholder="Enter Business Tenure" />      
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Potential Revenue :<span className="required-mark">*</span> </label>
                                    <Form.Control
                                        type="text"
                                        name="potential_rev"
                                        value={opportunityUpdate.potential_rev}
                                        onChange={handleFormInput}
                                        id="potential_rev"
                                        className="form-control" placeholder="Enter Potential Revenue" />      
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Potential Capex :<span className="required-mark">*</span> </label>
                                    <Form.Control
                                        type="text"
                                        name="potential_capex"
                                        value={opportunityUpdate.potential_capex}
                                        onChange={handleFormInput}
                                        id="potential_capex"
                                        className="form-control" placeholder="Enter Potential Capex" />      
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <label htmlFor="inputState" className="form-label">Potential Ebitda (%) :<span className="required-mark">*</span> </label>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <Form.Control
                                                type="number"
                                                name="potential_ebitda_per"
                                                value={opportunityUpdate.potential_ebitda_per}
                                                onChange={handleFormInput}
                                                id="potential_ebitda_per"
                                                className="form-control" placeholder="Enter Potential Ebitda" />  
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Control
                                                type="text"
                                                name="potential_ebitda"
                                                value={ebitadata}
                                                onChange={handleFormInput}
                                                id="potential_ebitda"
                                                className="form-control" placeholder="Potential Ebitda" disabled />  
                                        </div>
                                    </div>    
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Component Family:<span className="required-mark">*</span> </Form.Label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="component_family_name"
                                            options={comFamOptions}
                                            defaultValue={opportunityUpdate.component_family_name}
                                            onChange={handleComFamInput}
                                            key={opportunityUpdate.component_family_name}
                                        />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                        <Form.Label>Expected Closure Date:<span className="required-mark">*</span></Form.Label>
                                        <Form.Control
                                        type="date"
                                        name="expected_closure_date"
                                        value={opportunityUpdate.expected_closure_date}
                                        onChange={handleFormInput}
                                        id="expected_closure_date"
                                        className="form-control"  />
                                </div>
                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                    <Form.Label>Reference url</Form.Label>
                                    <Form.Control
                                        type="url"
                                        name="reference_url"
                                        value={opportunityUpdate.reference_url}
                                        onChange={handleFormInput}
                                        id="reference_url"
                                        className="form-control"  />
                                </div>
                                <div className="col-md-4"  style={{marginTop: '5px'}}>
                                        <Form.Label>Opportunity Description :</Form.Label>
                                        <Form.Control 
                                            className="form-control"
                                            as="textarea"
                                            rows={1}
                                            placeholder="Enter Description"
                                            name="opportunity_desc"
                                            value={opportunityUpdate.opportunity_desc}
                                            onChange={handleFormInput}
                                            autoComplete='off'
                                        />   
                                </div>
                                <div id="accordion" style={{marginTop:'10px'}}>
                                    <div className="card">
                                    {inputFields.map((input, index) => {

                                        if(input.monthly_volume_min != '' && input.monthly_volume_max != ''){
                                            let sum = Number(input.monthly_volume_min) + Number(input.monthly_volume_max);
                                            average = sum/2;
                                        }

                                        const list = [...inputFields];
                                        list[index]["monthly_volume_avg"] = average;
                                        console.log(list);
                                        if(list > -1){
                                            setOpportunityUpdate(oldValue => ({
                                                oldValue,
                                                "multi_data": list,
                                            })) 
                                        }

                                    return (
                                        <>
                                        <div className="card-header row" id={"heading"+index} data-toggle="collapse" data-target={"#collapse"+index} aria-expanded="true" aria-controls={"collapse"+index} style={{backgroundColor:'#46B653'}}>
                                            <div className="col-md-6" >
                                                <h3 className="mb-0" >
                                                    <span className="btn btn collapsed" style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                                                    Component Name: {input.component_name}
                                                    </span>
                                                </h3>
                                            </div>
                                            <div className="col-md-6 " style={{marginTop: '20px', float:'right'}}>
                                                {(inputFields.length!==1)? <i className="btn btn-danger" style={{float: 'right', marginLeft:'8px'}} onClick={(event)=>removeInputFields(index,event)}>Remove</i>:''}
                                                {inputFields.length - 1 === index &&
                                                <i className="btn btn-primary" onClick={addFields} style={{float: 'right'}}>Add More</i>}
                                            </div>
                                        </div>
                                        <div id={"collapse"+index} className={index === 0 ? "collapse show" : "collapse" } aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body row">
                                                {/* <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Component Name: </label>
                                                        <Select
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            name="component_name"
                                                            options={componentFamChild}
                                                            key={input.component_name}
                                                            defaultValue={input.component_name}
                                                            onChange={handleCompNameInput}
                                                        />
                                                </div> */}
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Component Name:<span className="required-mark">*</span> </label>
                                                    <Form.Control
                                                        type="text"
                                                        name="component_name"
                                                        value={input.component_name}
                                                        onChange={(event)=>handleChange(index, event)}
                                                        id="component_name"
                                                        className="form-control" placeholder="Enter Component Name" />      
                                                </div>
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Minimum Monthly Volume (nos.):<span className="required-mark">*</span> </label>
                                                    <Form.Control
                                                        type="text"
                                                        name="monthly_volume_min"
                                                        value={input.monthly_volume_min}
                                                        onChange={(event)=>handleChange(index, event)}
                                                        id="monthly_volume_min"
                                                        className="form-control" placeholder="Enter Monthly Volume (min)" />      
                                                </div>
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Maximum Monthly Volume (nos.):<span className="required-mark">*</span> </label>
                                                    <Form.Control
                                                        type="text"
                                                        name="monthly_volume_max"
                                                        value={input.monthly_volume_max}
                                                        onChange={(event)=>handleChange(index, event)}
                                                        id="monthly_volume_max"
                                                        className="form-control" placeholder="Enter Monthly Volume (max)" />      
                                                </div>
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <label htmlFor="inputState" className="form-label">Average (nos.):<span className="required-mark">*</span> </label>
                                                    <Form.Control
                                                        type="text"
                                                        name="monthly_volume_avg"
                                                        value={average}
                                                        onChange={(event)=>handleChange(index, event)}
                                                        id="monthly_volume_avg"
                                                        className="form-control" placeholder="Average" disabled />      
                                                </div>
                                                <div className="col-md-4" style={{marginTop: '5px'}}>
                                                    <div className='row'> 
                                                        <div className="col-md-7" style={{marginTop: '25px'}}>
                                                            <label htmlFor="inputState" className="form-label">Include Forward Transportation: <span className="required-mark">*</span></label>
                                                        </div>
                                                        <div className="col-md-5" style={{marginTop: '25px'}}>
                                                            <Form.Check
                                                                inline
                                                                label="Yes"
                                                                name="forward_transportation"
                                                                value="1"
                                                                type= "checkbox"
                                                                onChange={(event)=>handleTransport(index, event)}
                                                                // onChange={(event)=>handleChange(index, event)}
                                                                checked = {input.forward_transportation == '1' ? true : false}
                                                                id={'chbk_'+1}
                                                            /> 
                                                            <Form.Check
                                                                inline
                                                                label="NO"
                                                                name="forward_transportation"
                                                                value="0"
                                                                type= "checkbox"
                                                                onChange={(event)=>handleTransport(index, event)}
                                                                // onChange={(event)=>handleChange(index, event)}
                                                                checked = {input.forward_transportation == '0' ? true : false}
                                                                id={'chbk_'+0}
                                                            /> 
                                                        </div>
                                                    </div>      
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    )
                                    })}
                                    </div>
                                </div>
                                <div className="col-md-12" style={{marginTop: '10px'}}>
                                   <Form.Label>Lead Files : </Form.Label>
                                   {opportDocData != '' &&  opportDocData.packingImg.map((data) => (  
                                     <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                    ))}  
                                </div>
                                <div className="col-md-12" style={{marginTop: '3px'}}>
                                   <Form.Label>Opportunity Files : </Form.Label>
                                   {opportDocData != '' && opportDocData.OpportunityImgPath.map((data) => (  
                                     <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                    ))}  
                                </div>
                                <div className="col-md-4" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                        <Form.Label>Upload Photographs (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                                        <Form.Control multiple type="file"  
                                        name="opportunity_photo_web"
                                        onChange={handleFileInput}
                                        />
                                </div>
                                <div className="col-md-12" style={{marginTop: '15px'}}>
                                     <button type="submit" className="btn btn-primary">Submit</button>
                                </div>  
                            </div>
                       </form>
                   </div>
               </div>
           </div>
       </div>
    </>
  )
}

export default UpdateNRPOpportunity