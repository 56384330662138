import React from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { LocationData, ComponentFamily, UnitLoadList, VehicleTypeList, VehicleLoadTypeList } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { confirmAlert } from "react-confirm-alert";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TransportForm from './TransportForm';
import Commercial from './Commercial';
import CommercialLeadDetail from './CommercialLeadDetail';
import TransportLeadDetail from './TransportLeadDetail';
import NRPSolutionForm from './NRPSolutionForm';
import CommercialUpload from './CommercialUpload';


const OpportunityView = () => {

    const { locationOptions }  = LocationData();
    const { comFamOptions }    = ComponentFamily();
    const { outerULData, innerULData }     = UnitLoadList();
    const { vehicleTypeData }      = VehicleTypeList();
    const { vehicleLoadTypeData }  = VehicleLoadTypeList();

    let base64 = require('base-64');

    const aRef = useRef(null);
    const bRef = useRef(null);
    const cRef = useRef(null);
    const dRef = useRef(null);
    const eRef = useRef(null);
    const fRef = useRef(null);

    const wipuploadurl = "/wip/upload";
    const solmarkfinalurl = "/wip/mark_final";
    const querycreateurl   = "/opportunity/additional_query";
    const leadallocatesdurl = '/sdm_lead/assignment_to_designteam';
    const leadreallocatesdurl = '/sdm_lead/reassign_to_solutionteam';
    const leadallocateurl = '/sdm_lead/reassign_to_bdteam';

    const navigate = useNavigate();
    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id   = localStorage.getItem('usrId');
    const user_role  = localStorage.getItem('usrRole');
    let average = 0; 
    let outerCost = 0;
    let total = 0;
    let solutionCost = 0;

    const initialData = {
        "lead_id": lead_id,
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const leadAssignData   = {
        "lead_id": "",
        "lead_owner": "",
        "usr_token": usr_token,
    }

    const initialQueryData = {
        //"lead_id": "",
        "query": "",
        "usr_token": usr_token,
    }

    const initialSDData   = {
        "lead_id": "",
        "opportunity_owner": "",
        "expected_close_date": "",
        "usr_token": usr_token,
    }

    const initialRefData = {
        "stage":"2",
        "lead_id": lead_id,
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const initialDraftData = {
        "lead_id": lead_id,
        "draft_id" : "",
        "file_type": "1",
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "img_data_app": [], 
        "usr_token": usr_token,
    }

    const initialCompData = {
        "lead_id": lead_id,
        "comp_id" : "",
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "img_data_app": [], 
        "usr_token": usr_token,
    }

    const initialrejectedDraftData = {
        "draft_id" : "",
        "lead_id": lead_id,
        "status": 3,
        "reject_remark": "",
        "usr_token": usr_token,
    }

    const initialdeleteData = {
        "lead_id": lead_id,
        "file_id": "",
        "remark": "",
        "usr_token": usr_token,
    }

    const initialOppCloseData = {
        "lead_id": lead_id,
        "status" : "",
        "opp_close_remark": "",
        "other_close_remark": "",
        "usr_token": usr_token,
    }
    
    const initialdeleteLead = {
        "lead_id": "",
        "remark": "",
        "usr_token": usr_token,
    }

    const initialOppClosureData ={
        "lead_id": lead_id,
        "expected_closure_date": "",
        "usr_token": usr_token,
    }

    const statusArr = [
        { value: '23', label: 'Dormant' },
        { value: '24', label: 'Lost' },
    ];

    const reasonArr = [
        { value: '1', label: 'Target Price not feasible' },
        { value: '2', label: 'Project on Hold' },
        { value: '3', label: 'Lost to Competition' },
        { value: '4', label: 'Poor Followup' },
        { value: '5', label: 'Others' },
    ];

    const [queryCreate, setQuerycreate]         = React.useState(initialQueryData);
    const [opportViewData, setOpportViewData]   = React.useState([""]);
    const [usermessage, setMessage]             = React.useState(null);
    const [chatmessage, setchatMessage]         = React.useState('');
    const [selectedFile, setSelectedFile]       = React.useState([]);
    const [expandselected, setExpandSelected]   = React.useState(0);
    const [isFinalImg, setIsFinal]              = React.useState(0);
    const [show, setShow]                       = React.useState(false);
    const [assignSDTeam, setAssignSDTeam]       = React.useState(initialSDData);
    const [sdUserListData, setSDUserListData]   = React.useState([""]);
    const [refUploadData, setRefUploadData]     = React.useState(initialRefData);
    const [draftUploadData, setDraftUploadData] = React.useState(initialDraftData);
    const [compUploadData, setCompUploadData]   = React.useState(initialCompData);
    const [compImageData, setCompImageData]     = React.useState("");
    const [draftIdData, setDraftIdData]         = React.useState("");
    const [rejectedDraftData, setRejectedDraftData]   = React.useState(initialrejectedDraftData);
    const [loading, setLoading]                 = React.useState(false);
    const [solFile1, setSolFile]                = useState(null);
    const [bomFile, setBomFile]                 = useState(null);
    const [refFile, setRefFile]                 = useState([]);
    const [trialFile, setTrialFile]             = useState([]);
    const [solFileCheck, setSolFileCheck]       = useState(null);
    const [bomFileCheck, setBomFileCheck]       = useState(null);
    const [delDocFileId, setDelDocFileId]       = React.useState('');
    const [deleteLeadFile, setDeleteLeadFile]   = React.useState(initialdeleteData);
    const [oppClose, setOppClose]               = React.useState(initialOppCloseData);
    const [oppClosureDate, setOppClosureDate]   = React.useState(initialOppClosureData);
    const [bdUserListData, setBDUserListData]   = React.useState([""]);
    const [assignLeads, setAssignLead]          = React.useState(leadAssignData);
    const [outer, setOuter]                     = React.useState([]);
    const [inner, setInner]                     = React.useState([]);
    const [deleteLead, setDeleteLead]           = React.useState(initialdeleteLead);
    const [otherReason, setOtherReason]         = React.useState("");

    const [inputFields, setInputFields] = useState([
        {  
            component_name: "",
            monthly_volume_avg: "",
            cycle_time: "",
            component_dimension_l: "",
            component_dimension_w: "",
            component_dimension_h: "",
            component_dimension_wt: "",
            prop_comp_ul_qty: "",
            prop_outer_packaging: "",
            prop_box_dimension_l: "",
            prop_box_dimension_w: "",
            prop_box_dimension_h: "",
            prop_box_dimension_wt: "",
            prop_collaps_dimension_l: "",
            prop_collaps_dimension_w: "",
            prop_collaps_dimension_h: "",
            prop_collaps_dimension_wt: "",
            prop_ul_rate: "",
            innerData: [{
                id: 1,
                prop_inner_packaging: "", 
                inner_dimension_l: '', 
                inner_dimension_w: '', 
                inner_dimension_h: '',
                inner_dimension_wt: '',
                inner_collaps_dimension_l: '',
                inner_collaps_dimension_w: '',
                inner_collaps_dimension_h: '',
                inner_collaps_dimension_wt: '',
                inner_qty: '',
                inner_rate: '',
            }],
        
        }
    ]);

    // console.log(inputFields);

    const getOpportViewData = async () => {
        setLoading(true);
      const arr = [];
      try {
          fetch(ulmsUrl.concat('/wip/lead_details') , {
              method: 'POST',
              body: JSON.stringify({lead_id, usr_token}),
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then((response) =>
              response.json()
          )
          .then((response) => {  
              setLoading(false);
              let result = response;  
              console.log('data',result); 
              setOpportViewData(result)
          });
      } catch (error) {
          console.log(error);
      }
    }

    const getCompUpdateData = async () => {
        const arr = [];
        const innrarr = [];
        try {
            fetch(ulmsUrl.concat('/wip/sol_component') , {
                method: 'POST',
                body: JSON.stringify({lead_id, usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response;   
                console.log('result',result)
                setInputFields(result.data);
                // result.data?.map((data) => {
                //     return arr.push(data.outer);
                // });
                // result.data?.map((data) => {
                //     return innrarr.push(data.inner);
                // });
                // setOuter(result.data);
                // setInner(result.data);
                
            });
        } catch (error) {
            console.log(error);
        }
    }

    const addFields = (parentIndex, childIndex, event) => {
        // console.log('child',childIndex);
        // console.log(inputFields)
       
        setInputFields(prevState => {
            const updatedData = [...prevState];
            const lastId = updatedData[parentIndex].innerData.length > 0 ? updatedData[parentIndex].innerData[updatedData[parentIndex].innerData.length - 1].id : 0;

            let newfield = { 
                id: lastId + 1,
                prop_inner_packaging: "", 
                inner_dimension_l: '', 
                inner_dimension_w: '', 
                inner_dimension_h: '',
                inner_dimension_wt: '',
                inner_collaps_dimension_l: '',
                inner_collaps_dimension_w: '',
                inner_collaps_dimension_h: '',
                inner_collaps_dimension_wt: '',
                inner_qty: 0,
                inner_rate: 0,
                innerCost: 0,
            }

            updatedData[parentIndex].innerData.push(newfield);
            return updatedData;
        });
    }

    const confirmDeleteLine = async(parentIndex, childIndex, e) => {

        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure you want to delete this line?",
            buttons: [
              {
                label: "Yes",
                onClick: () => removeInputFields(parentIndex, childIndex, e)
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const removeInputFields = async (parentIndex, childIndex, e) => {

        let rows = [...inputFields];
        
        rows[parentIndex].innerData = rows[parentIndex].innerData.filter((_, index) => index !== childIndex);
        if(childIndex > -1){
            setInputFields(rows);
        }   
    }

    console.log('mod',inputFields);

    const handleOpportFormInput = (parentIndex, event) => {

        const { name, value } = event.target;
        const updatedData = [...inputFields];
        updatedData[parentIndex][name] = value;
        setInputFields(updatedData);

    };
   
    const handleInnerFormInput = (parentIndex, childIndex, event) => {
        
        const { name, value } = event.target;
        const updatedData = [...inputFields];
        updatedData[parentIndex].innerData[childIndex][name] = value;
        setInputFields(updatedData);
    };

     
     console.log(total)

    const getCompImageData = async (data) => {

        let comp_id = data;

        if(comp_id != ''){
            try {
                fetch(ulmsUrl.concat('/wip/view_component_file') , {
                    method: 'POST',
                    body: JSON.stringify({lead_id, comp_id, usr_token}),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response;   
                    setCompImageData(result.data)
                });
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
      getOpportViewData();
      getCompImageData();
      getCompUpdateData();
    }, []);


    const expand = async () => {    
        
        console.log('expend',expandselected);
        if(expandselected == 1){
            setExpandSelected(0);
        }else{
            setExpandSelected(1);
        }
    }

    const handleFileInput = (e) => {
      console.log(e.target.files); 
      setSelectedFile(e.target.files)
      console.log(selectedFile);
    };

    const handleOuterInput = (parentIndex,event) => {
   
        if (event && (event.target.checked)) {

            const list = [...inputFields];
            list[parentIndex]["outer"] = 1;
            setInputFields(list);

        }else{

            const list = [...inputFields];
            list[parentIndex]["outer"] = 0;
            setInputFields(list);
        }
    }


    const handleInnerInput = (parentIndex,event) => {

        if (event && (event.target.checked)) {

            const list = [...inputFields];
            list[parentIndex]["inner"] = 1;
            setInputFields(list);

        }else {

            const list = [...inputFields];
            list[parentIndex]["inner"] = 0;
            setInputFields(list);
        }
    }

    console.log('che',inner)

    const handlePropPackInput = (parentIndex, event) => {

        const updatedData = [...inputFields];
        updatedData[parentIndex]["prop_outer_packaging"] = event;
        setInputFields(updatedData);
    }

    const handleInnerPackInput = (parentIndex, childIndex, event) => {

        const updatedData = [...inputFields];
        updatedData[parentIndex].innerData[childIndex]["prop_inner_packaging"] = event;
        setInputFields(updatedData);
    }

    const handleSolFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setSolFile(selectedFile);
    };
    
    const handleBomFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setBomFile(selectedFile);
    };

    const handleRefFileChange = (event) => {
        const selectedFile = event.target.files;
        setRefFile(selectedFile);
    };

    const handleTrialFileChange = (event) => {
        const selectedFile = event.target.files;
        setTrialFile(selectedFile);
    }

    const handleFormSubmit = async (e) => {

        e.preventDefault();

        const createdata = {
            lead_id : lead_id,
            data: inputFields,
            img_data_app: [], 
            solution_creater_id: usr_id,
            solution_creater_name: usr_name,
            usr_token: usr_token,
        }

        console.log(JSON.stringify(createdata))

        if(solFile1 || bomFile || refFile)
        {
            setLoading(true);
            
            const formData = new FormData();

            for(let i = 0; i < refFile.length; i++){
                formData.append('opportunity_photo_ref', refFile[i]);
            }
            for(let i = 0; i < trialFile.length; i++){
                formData.append('opportunity_photo_trial', trialFile[i]);
            }

            formData.append('opportunity_photo_web', solFile1);
            formData.append('opportunity_photo_bom', bomFile);
            // formData.append('opportunity_photo_ref', refFile);
            // formData.append('opportunity_photo_trial', trialFile);
            
            formData.append('data', JSON.stringify(createdata));
            console.log(formData);  
    
            fetch(ulmsUrl.concat(wipuploadurl), {
                method: 'POST',
                body: formData,
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {
                console.log(result)
                if (result.status == true) {
                    setLoading(false);
                    bRef.current.value = null;
                    setSolFile('');
                    setBomFile('');
                    setRefFile('');
                    toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                    getOpportViewData();
                    //sessionStorage.setItem("message", result.message);
                    navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
    
                } else if (result.status == false) {
                    setLoading(false);
                    toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                
                }
            })
            .catch((form_err) => {
                //console.log(form_err)
            })


        }else{

            toast.error("Please upload at least one file.", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
        }

    }

    const handledraftId = (draftid) => {

        setRejectedDraftData(oldValue => ({
            ...oldValue,
            "draft_id": draftid,
        }))
    };

    const RejectedRemarkDraft = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setRejectedDraftData(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    };

    const handleRejectedRemark = async() => {

        console.log('rejected',JSON.stringify(rejectedDraftData));
        setLoading(true);
        
          fetch(ulmsUrl.concat(solmarkfinalurl), {
          method: 'POST',
          body: JSON.stringify(rejectedDraftData),
          headers: {
              'Content-Type': 'application/json',
          }
          })
          .then((result) =>
              result.json()
          )
          .then((result) => {
              console.log(result)
              if (result.status == true) {
                  toast.success(result.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      toastId: 'success1',
                  });
                  getOpportViewData();
                  setLoading(false);
                  //sessionStorage.setItem("message", result.message);
                  navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
  
              } else if (result.status == false) {
                  toast.error(result.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      toastId: 'success1',
                  });
              
              }
          })
          .catch((form_err) => {
              //console.log(form_err)
          })
    };

    const confirmdialog = (data, status) => {

        let msg = '';
        if(status == 1){
            msg = "Are you sure you want to final this draft?";
        }else if(status == 3){
            msg = "Are you sure you reject this draft?";
        }

        confirmAlert({
            title: "Confirm to submit",
            message: msg,
            buttons: [
              {
                label: "Yes",
                onClick: () => handleMarkFinlClick(data, status)
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const handleMarkFinlClick = async(data, status) => {

      console.log(status);
      
        let draft_id = data;
        let reject_remark = '';

        console.log(JSON.stringify({ draft_id,lead_id, status, reject_remark, usr_token }));

        fetch(ulmsUrl.concat(solmarkfinalurl), {
        method: 'POST',
        body: JSON.stringify({ draft_id, lead_id, status, reject_remark, usr_token }),
        headers: {
            'Content-Type': 'application/json',
        }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });

            } else if (result.status == false) {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        

    };

    const handleRemarkInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setDeleteLeadFile(oldValue => ({
                ...oldValue,
                [name]: value,
                "file_id": delDocFileId
            }))
        }
    }; 

    const handleDocDeleteForm = async (e) => {
        setLoading(true);
        e.preventDefault();
    
       console.log(JSON.stringify(deleteLeadFile))
    
        fetch(ulmsUrl.concat('/files/del_ref_file'), {
            method: 'POST',
            body: JSON.stringify(deleteLeadFile),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setDeleteLeadFile('')
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setDeleteLeadFile(deleteLeadFile)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        
    }

    const handleFormInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setQuerycreate(oldValue => ({
                ...oldValue,
                [name]: value,
                "lead_id": opportViewData.data.id, 
            }))
        }
    };

    const handleChatSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
    
       console.log(JSON.stringify(queryCreate))
    
        fetch(ulmsUrl.concat(querycreateurl), {
            method: 'POST',
            body: JSON.stringify(queryCreate),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setQuerycreate(initialQueryData)
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
    
            } else if (result.status == false) {
                setLoading(false);
                setQuerycreate(queryCreate)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        
    }

    const confirmcopy = (data) => {

        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure you want to copy this lead ?",
            buttons: [
              {
                label: "Yes",
                onClick: () => leadCopy(data)
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const leadCopy = async(id) => {

        let lead_id = id;

        try { 
            fetch(ulmsUrl.concat('/leads/copy') , {
                method: 'POST',
                body: JSON.stringify({lead_id,usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {  
                if (result.status == true) {
                    sessionStorage.setItem("createmessage", result.message);
                    navigate("/all_leads", { replace: true })
    
                } else if (result.status == false) {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: 'success1',
                    });
                
                }
                
            });
        } catch (error) {
            console.log(error);
        }
        
    }

    const handleLeadSourceInput = (value) => { 
        setAssignLead(oldValue => ({
            ...oldValue,
            "lead_owner": value,
        }))  
    };

    const leadAssign = async(id,project_type,products,service_type) => {

        setShow(true);
       
        setAssignLead(oldValue => ({
          ...oldValue,
          "lead_id": id,
        }))
  
        console.log(JSON.stringify({service_type,project_type,products,usr_token}));
        const arr = [];
        try { 
            fetch(ulmsUrl.concat('/user/bd_user_dropdown') , {
                method: 'POST',
                body: JSON.stringify({service_type,project_type,products,usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                result?.map((data) => {
                    return arr.push({ value: data.id, label: data.value });
                });
                setBDUserListData(arr)
            });
        } catch (error) {
            console.log(error);
        }
          
    }

    const leadAssignSd = async(id,project_type,products,service_type) => {
      
        setShow(true);
       
        setAssignSDTeam(oldValue => ({
          ...oldValue,
          "lead_id": id,
        }))
    
        console.log(JSON.stringify({service_type,project_type,products,usr_token}));
        const arr = [];
        try { 
            fetch(ulmsUrl.concat('/user/sd_user_dropdown') , {
                method: 'POST',
                body: JSON.stringify({service_type,project_type,products,usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                result?.map((data) => {
                    return arr.push({ value: data.id, label: data.value });
                });
                setSDUserListData(arr)
            });
        } catch (error) {
            console.log(error);
        }
          
    }

    const handleLeadReAssignForm = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        fetch(ulmsUrl.concat(leadallocateurl), {
            method: 'POST',
            body: JSON.stringify(assignLeads),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setAssignLead(leadAssignData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                setShow(false);
                setLoading(false);
                //sessionStorage.setItem("createmessage", result.message);
  
            } else if (result.status == false) {
                setLoading(false);
                setAssignLead(assignLeads)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    useEffect(() => {
        leadAssign();
        leadAssignSd();  
    }, []);

    const handleSDUserInput = (value) => {
        setAssignSDTeam(oldValue => ({
            ...oldValue,
            "opportunity_owner": value,
        }))  
    }

    const handleSDFormInput = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setAssignSDTeam(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    };

    console.log(JSON.stringify(assignSDTeam))

    const handleSDFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        console.log(JSON.stringify(assignSDTeam))
    
        fetch(ulmsUrl.concat(leadallocatesdurl), {
            method: 'POST',
            body: JSON.stringify(assignSDTeam),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setLoading(false);
                setAssignSDTeam(initialSDData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                setShow(false);
                getOpportViewData();
                //sessionStorage.setItem("createmessage", result.message);
                //navigate("/all_leads", { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setAssignSDTeam(assignSDTeam)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const handleReassignSDSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        console.log(JSON.stringify(assignSDTeam))
    
        fetch(ulmsUrl.concat(leadreallocatesdurl), {
            method: 'POST',
            body: JSON.stringify(assignSDTeam),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setLoading(false);
                setAssignSDTeam(initialSDData);
                toast.success(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: 'success1',
                });
                setShow(false);
                getOpportViewData();
                //sessionStorage.setItem("createmessage", result.message);
                //navigate("/all_leads", { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setAssignSDTeam(assignSDTeam)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const handleRefFileSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        console.log(JSON.stringify(refUploadData))
  
        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`fileTest`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(refUploadData));
        console.log(formData);  
  
        fetch(ulmsUrl.concat("/wip/ref_file"), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                aRef.current.value = null;
                setRefUploadData(refUploadData);
                toast.success(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
  
            } else if (result.status == false) {
                setLoading(false);
                setRefUploadData(refUploadData);
                toast.error(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const CompDataUpload = (data) => {
       
        setCompUploadData(oldValue => ({
            ...oldValue,
            "comp_id" : data
        }))
    }

    const componentFileSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        console.log(JSON.stringify(compUploadData))
  
        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`fileTest`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(compUploadData));
        console.log(formData);  
  
        fetch(ulmsUrl.concat("/wip/component_file"), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setLoading(false);
                dRef.current.value = null;
                setCompUploadData(compUploadData);
                toast.success(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
  
            } else if (result.status == false) {
                setLoading(false);
                setCompUploadData(compUploadData);
                toast.error(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const DraftDataUpload = (data) => {
        console.log(data);
        setDraftIdData(data.draft);
        setSolFileCheck(data.sol);
        setBomFileCheck(data.bom);

        setDraftUploadData(oldValue => ({
            ...oldValue,
            "draft_id" : data.draft_id
        }))
    }

    const getFormData = async (e) => {
        
        if (e && e.target) {
            const { name, value } = e.target;
            setDraftUploadData(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    }

    const handleExtraFileSubmit = async (e) => {
        e.preventDefault();
  
        console.log(JSON.stringify(draftUploadData))

        if(solFile1 || bomFile || refFile || trialFile)
        {         
            setLoading(true);

            const formData = new FormData();

            for(let i = 0; i < refFile.length; i++){
                formData.append('opportunity_photo_ref', refFile[i]);
            }
            for(let i = 0; i < trialFile.length; i++){
                formData.append('opportunity_photo_trial', trialFile[i]);
            }

            formData.append('opportunity_photo_web', solFile1);
            formData.append('opportunity_photo_bom', bomFile);
            // formData.append('opportunity_photo_ref', refFile);
            // formData.append('opportunity_photo_trial', trialFile);
            
            formData.append('data', JSON.stringify(draftUploadData));
            console.log(formData);  
    
            fetch(ulmsUrl.concat("/wip/ref_sol_file"), {
                method: 'POST',
                body: formData,
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {
                console.log(result)
                if (result.status == true) {
                    setLoading(false);
                    cRef.current.value = null;
                    setSolFile('');
                    setBomFile('');
                    setRefFile('');
                    toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                    getOpportViewData();
                    //sessionStorage.setItem("message", result.message);
                    navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
    
                } else if (result.status == false) {
                    setLoading(false);
                    setDraftUploadData(draftUploadData);
                    toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                
                }
            })
            .catch((form_err) => {
                //console.log(form_err)
            })

        }else{

            toast.error("Please upload at least one file.", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
        }
    }

    const handleOppStatusInput = (data) => {

        setOppClose(oldValue => ({
            ...oldValue,
            "status" : data.value
        }))
    }
    const handleReasonInput = (data) => {

        setOtherReason(data.label);

        setOppClose(oldValue => ({
            ...oldValue,
            "opp_close_remark" : data.label
        }))
    }

    const handleCloseRemark = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setOppClose(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    }; 

    const handleOppCloseForm = async (e) => {
        setLoading(true);
        e.preventDefault();
    
       console.log(JSON.stringify(oppClose))
    
        fetch(ulmsUrl.concat('/opportunity/opp_status'), {
            method: 'POST',
            body: JSON.stringify(oppClose),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setOppClose(initialOppCloseData)
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setOppClose(oppClose)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        
    }

    const handleClosureDateInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setOppClosureDate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    }; 

    const handleOppClosureDateForm = async (e) => {

        // setLoading(true);
        e.preventDefault();
    
       console.log(JSON.stringify(oppClosureDate))
    
        fetch(ulmsUrl.concat('/opportunity/extend_closure_date'), {
            method: 'POST',
            body: JSON.stringify(oppClosureDate),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setOppClosureDate(initialOppClosureData)
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setOppClosureDate(oppClosureDate)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        
    }

    function handleCloseModal(){            
        document.getElementById("leadReallocateModal").classList.remove("show", "d-block");
        document.getElementById("fileUploadModal").classList.remove("show", "d-block");
        document.getElementById("assigntosdmodal").classList.remove("show", "d-block");
        document.getElementById("CompUploadModal").classList.remove("show", "d-block");
        document.getElementById("draftrejectedModal").classList.remove("show", "d-block");
        document.getElementById("deleteremarkModal").classList.remove("show", "d-block");
        document.getElementById("LeadAssignModal").classList.remove("show", "d-block");
        document.getElementById("closeOpportunitymodal").classList.remove("show", "d-block");
        document.getElementById("deleteleadremarkModal").classList.remove("show", "d-block");
        document.getElementById("extendClosuredatemodal").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop")
        .forEach(el => el.classList.remove("modal-backdrop"));
        document.body.style.overflow = 'unset';
    }

    const handleLeadRemarkInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setDeleteLead(oldValue => ({
                ...oldValue,
                [name]: value,
                "lead_id": lead_id,
            }))
        }
    };   

    const handleLeadDelForm = async (e) => {
        setLoading(true);
        e.preventDefault();
    
        console.log(JSON.stringify(deleteLead))
    
        fetch(ulmsUrl.concat('/sdm_lead/delete'), {
            method: 'POST',
            body: JSON.stringify(deleteLead),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setDeleteLead('')
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
    
            } else if (result.status == false) {
                setLoading(false);
                setDeleteLead(deleteLead)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
      
    }

    const confirmReactiveButton = () => {

        let msg = "Are you sure you want to re-active this lead ?";

        confirmAlert({
            title: "Confirm to submit",
            message: msg,
            buttons: [
              {
                label: "Yes",
                onClick: () => handReactiveLead()
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const handReactiveLead = async() => {

        console.log(JSON.stringify({ lead_id, usr_token }));
    
        fetch(ulmsUrl.concat('/leads/lead_reactivate'), {
            method: 'POST',
            body: JSON.stringify({ lead_id, usr_token }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });

            } else if (result.status == false) {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
          
    };

    const myComponent = {
        height: '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
    };

  
    if(usermessage != '' || chatmessage != ''){
        setTimeout(() => {
        setMessage(null);
        setchatMessage(null);
        }, 800);    
    }

    function getExtension(filename) {
        return filename.split('.').pop()
    }



  return (
    <>
     <ToastContainer />
        {loading && (
            <>
            <Loader/>
            </>
        ) }
            <div className="row" style={{marginTop:'-20px'}}>
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">SOLUTION DEVELOPMENT ( LEAD ID : {lead_id} ) (HOLISIGHT ID: {opportViewData != '' && opportViewData.data.holisight_lead_id})</h3>
                        <div className='All_three_button'>
                        {opportViewData != '' && (opportViewData.data.leadreassignbutton == 2  && opportViewData.data.status != 23 && opportViewData.data.status != 24) &&
                            <button className="btn btn-primary btn-sm  me-2" id="re-assign" 
                            style={{font_size: '6px', float: 'right'}}
                            onClick={()=>leadAssign(opportViewData.data.id,opportViewData.data.project_type,opportViewData.data.products,opportViewData.data.service_type)} 
                            data-bs-toggle="modal" data-bs-target="#LeadAssignModal"  ><strong>Lead Re-assign To BD</strong></button> 
                        }
                        {opportViewData != '' && (opportViewData.data.button == 4 && opportViewData.data.status != 23 && opportViewData.data.status != 24) &&
                            <button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right'}} onClick={()=>leadAssignSd(opportViewData.data.id,opportViewData.data.project_type,opportViewData.data.products,opportViewData.data.service_type)}  data-bs-toggle="modal" data-bs-target="#assigntosdmodal" ><strong>Opp Assign To SD</strong></button>  
                        }
                        {opportViewData != '' && (opportViewData.data.reassign == 4 && opportViewData.data.status != 23 && opportViewData.data.status != 24) &&
                            <button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right'}} onClick={()=>leadAssignSd(opportViewData.data.id,opportViewData.data.project_type,opportViewData.data.products,opportViewData.data.service_type)}  data-bs-toggle="modal" data-bs-target="#leadReallocateModal" ><strong>Opp Re-assign To SD</strong></button>  
                        }
                     
                        {opportViewData != '' && (opportViewData.data.status > 3 && opportViewData.data.status != 23 && opportViewData.data.status != 24 && opportViewData.data.lead_owner_id == usr_id &&  opportViewData.data.project_type == 'AUTO' && opportViewData.data.service_type == 'NRP' &&  (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG')) &&
                       
                            <Link to={`/update_opportunity/${base64.encode(lead_id)}`} ><button className="btn btn-primary btn-sm"  ><strong>Edit Opportunity</strong></button></Link> 
                        }
                        {opportViewData != '' && (opportViewData.data.status > 3 && opportViewData.data.status != 23 && opportViewData.data.status != 24 && opportViewData.data.lead_owner_id == usr_id && opportViewData.data.project_type == 'AUTO' && (opportViewData.data.service_type == 'RP' || opportViewData.data.service_type == 'LS') && (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG')) &&
                       
                            <Link to={`/update_rpopportunity/${base64.encode(lead_id)}`} ><button className="btn btn-primary btn-sm"><strong>Edit Opportunity</strong></button></Link> 
                        }
                        {opportViewData != '' && (opportViewData.data.status > 3 && opportViewData.data.status != 23 && opportViewData.data.status != 24 && opportViewData.data.lead_owner_id == usr_id && opportViewData.data.project_type == 'RETAIL')  &&
                       
                            <Link to={`/update_reopportunity/${base64.encode(lead_id)}`} ><button className="btn btn-primary btn-sm" ><strong>Edit Opportunity</strong></button></Link> 
                        
                        }
                        {opportViewData != '' && (opportViewData.data.LeadEditButtonShow == 1 && opportViewData.data.status != 23 && opportViewData.data.status != 24) &&
                            <Link to={`/leads_edit/${base64.encode(opportViewData.data.id)}`}  ><button className='btn btn-primary btn-sm  me-2' style={{marginLeft: '10px'}}><strong>Edit Lead</strong></button></Link> 
                        }
                        {opportViewData != '' && (opportViewData.data.status > 3 && opportViewData.data.lead_owner_id == usr_id) && 
                            <button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right', marginLeft: '3px'}} data-bs-toggle="modal" data-bs-target="#extendClosuredatemodal" ><strong>Extend Closure Date</strong></button>  
                        }
                        {opportViewData != '' && (opportViewData.data.status == 23) && 
                            <button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right', marginLeft: '3px'}} onClick={()=>confirmReactiveButton()} ><strong>Re - activate</strong></button>  
                        }
                        {opportViewData != '' && (opportViewData.data.OppStatusButton == 1) && 
                            <button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right', marginLeft: '3px'}} data-bs-toggle="modal" data-bs-target="#closeOpportunitymodal" ><strong>Update Status</strong></button>  
                        }

                        {opportViewData != '' && (opportViewData.data.status > 2 && opportViewData.data.lead_owner_id == usr_id) && 
                           <strong><i title='copy lead' id='copy_lead_btn' onClick={()=>confirmcopy(opportViewData.data.id)}  className="fa fa-copy" style={{fontSize:'30px',color:'#0D4C7B',marginLeft: '10px'}}></i></strong> 
                        }
                        {opportViewData != '' && (opportViewData.data.LeadDelButton == 1) &&
                            <Link onClick={()=>setLeadId(opportViewData.data.id)} style={{marginLeft: '10px'}} data-bs-toggle="modal" data-bs-target="#deleteleadremarkModal" ><span title='Delete lead' ><i className="fa fa-trash-o" style={{fontSize:'30px',color:'red'}}></i></span></Link> 
                        }
                        <Link onClick={() => navigate(-1)}><strong><i title='back' className="fa fa-arrow-circle-left" style={{fontSize:'30px',color:'#0D4C7B',marginLeft: '10px'}}></i></strong></Link>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card-body">
                            <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation" >
                                    <a className="nav-link active" id="opportunity-tab" data-bs-toggle="tab" href="#opportunity" role="tab"
                                    aria-controls="opportunity" aria-selected="true" style={{fontSize: '16px', fontWeight:'bold'}} >Opportunity</a>
                                </li>
                                <li className="nav-item" role="presentation" >
                                    <a className="nav-link" id="solution-tab" data-bs-toggle="tab" href="#solution" role="tab"
                                    aria-controls="solution" aria-selected="false"  style={{fontSize: '16px', fontWeight:'bold'}}>Solution</a>
                                </li>
                                {opportViewData != '' && opportViewData.data.TransportTab == 1 &&
                                <li className="nav-item" role="presentation" >
                                    <a className="nav-link" id="transportation-tab" data-bs-toggle="tab" href="#transportation" role="tab"
                                    aria-controls="transportation" aria-selected="false"  style={{fontSize: '16px', fontWeight:'bold'}}>Transportation</a>
                                </li>}
                                {opportViewData != '' && opportViewData.data.CommercialTab == 1 &&
                                <li className="nav-item" role="presentation" >
                                    <a className="nav-link" id="commercial-tab" data-bs-toggle="tab" href="#commercial" role="tab"
                                    aria-controls="commercial" aria-selected="false"  style={{fontSize: '16px', fontWeight:'bold'}}>Commercial</a>
                                </li>}
                            </ul>
                            <div className="tab-content" id="myTabContent" >
                                <div className="tab-pane fade show active" id="opportunity" role="tabpanel" aria-labelledby="opportunity-tab" >
                                  <div className="invoice row">
                                    <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                        <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>LOB</th>
                                                    <td >{opportViewData != '' && opportViewData.data.project_type}</td>
                                                </tr>
                                                <tr>
                                                    <th>Product Type</th>
                                                    <td>{opportViewData != '' && opportViewData.data.products}</td>
                                                </tr>
                                                <tr>
                                                    <th>Service Type</th>
                                                    <td>{opportViewData != '' && opportViewData.data.service_type}</td>
                                                </tr>
                                                <tr>
                                                    <th>Start Date</th>
                                                    <td >{opportViewData != '' && opportViewData.data.start_date}</td>
                                                </tr>
                                                <tr>
                                                    <th>Supplier Name</th>
                                                    {/* <td>{opportViewData != '' && opportViewData.data.supplier_name}</td> */}
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&
                                                        <td>{opportViewData.data.new_supplier_name}</td> 
                                                    }
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                                        <td>{opportViewData.data.supplier_name}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th >Entity Name</th>
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&
                                                    <td>{opportViewData.data.new_supplier_name}</td> 
                                                    }
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                                    <td>{opportViewData != '' && opportViewData.data.entity_name}</td>
                                                    }
                                                </tr>  
                                                <tr>
                                                    <th>LS Services:</th>
                                                    {opportViewData != '' && opportViewData.data.multi_ls.length > 0 ?
                                                    <td>
                                                        {opportViewData.data.multi_ls.map((data) => (  
                                                            <>{data}  </> 
                                                        ))} 
                                                    </td> :
                                                    <td>-</td> 
                                                    }  
                                                </tr> 
                                                <tr>
                                                    <th>Supplier Location</th>
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&    
                                                    <td>{opportViewData.data.new_supplier_loc}</td> 
                                                    }
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                                    <td>{opportViewData != '' && opportViewData.data.supplier_loc}</td>
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div className="table-scrollable col-md-6">
                                        <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>Supplier Industry Vertical</th>
                                                    <td>{opportViewData != '' && opportViewData.data.cust_industry_verticle}</td>
                                                </tr> 
                                                {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                                    <tr>
                                                        <th>OEM Name</th>
                                                        {/* <td >{listViewData != '' && listViewData.data.supplier_name}</td> */}
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&
                                                        <td>{opportViewData.data.new_oem_name}</td> 
                                                        }
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                        <td>{opportViewData.data.oem_name}</td>
                                                        }
                                                    </tr>
                                                    }
                                                    {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                                    <tr>
                                                        <th >OEM Entity Name</th>
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&
                                                        <td>{opportViewData.data.new_oem_name}</td> 
                                                        }
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                        <td>{opportViewData != '' && opportViewData.data.oem_entity_name}</td>
                                                        }
                                                    </tr> 
                                                    }    
                                                    {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                                    <tr>
                                                        <th>OEM Location</th>
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&    
                                                        <td>{opportViewData.data.new_oem_loc}</td> 
                                                        }
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                        <td>{opportViewData != '' && opportViewData.data.oem_loc}</td>
                                                        }
                                                    </tr>
                                                    }
                                                <tr>
                                                    <th>Lead Requester Name</th>
                                                    <td >{opportViewData != '' && opportViewData.data.lead_req_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Lead Owner Name</th>
                                                    <td >{opportViewData != '' && opportViewData.data.lead_owner_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Opportunity Owner Name</th>
                                                    {/* <td >{opportViewData != '' && opportViewData.data.opportunity_owner_name}</td> */}
                                                    {(opportViewData != '' && opportViewData.data.opportunity_owner_name != null) ?
                                                        <td>{opportViewData.data.opportunity_owner_name}</td> :
                                                        <td>-</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>Lead Status</th>
                                                    {(opportViewData != '' && opportViewData.data.LeadStatus != 'Dormant' && opportViewData.data.LeadStatus != 'Lost') &&
                                                    <td>
                                                        <span className="text-success"> {opportViewData != '' && opportViewData.data.LeadStatus} </span>
                                                    </td>}
                                                    {(opportViewData != '' && (opportViewData.data.LeadStatus === 'Dormant' || opportViewData.data.LeadStatus === 'Lost') ) &&
                                                    <td>
                                                        <span className="text-danger"> {opportViewData != '' && opportViewData.data.LeadStatus} </span>
                                                    </td>}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div className="table-scrollable col-md-12">
                                        <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th style={{width:'150px'}}>Lead Doc</th>
                                                    <td>
                                                    {opportViewData != '' && opportViewData.packingImg.map((data) => (  
                                                        <>
                                                            <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                                            { (opportViewData.data.lead_req_id == usr_id || opportViewData.data.lead_owner_id == usr_id) &&
                                                                <img
                                                                    className='cross-icon'
                                                                    src='../../ulms_assets/images/cross_icon.png'
                                                                    alt="close icon"
                                                                    data-bs-toggle="modal" data-bs-target="#deleteremarkModal"
                                                                    title='Delete ref file'
                                                                    onClick={()=>setDelDocFileId(data.file_id)}
                                                                /> }
                                                        </>
                                                    ))}  
                                                    </td>
                                                    </tr>
                                                <tr>
                                                    <th style={{width:'150px'}}>Opportunity Doc</th>
                                                    <td>
                                                    {opportViewData != '' && opportViewData.OpportunityImgPath.map((data) => (  
                                                        <>
                                                        <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                                        { (opportViewData.data.opportunity_owner_id == usr_id || opportViewData.data.lead_owner_id == usr_id) &&
                                                            <img
                                                                className='cross-icon'
                                                                src='../../ulms_assets/images/cross_icon.png'
                                                                alt="close icon"
                                                                data-bs-toggle="modal" data-bs-target="#deleteremarkModal"
                                                                title='Delete ref file'
                                                                onClick={()=>setDelDocFileId(data.file_id)}
                                                            /> }
                                                        </>
                                                    ))}  
                                                    </td>
                                                </tr>
                                            
                                            </tbody>
                                        </table>
                                    </div> 
                                  </div>
                                    <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                        <table id="mainTable" className="table padded-table">
                                            <thead>
                                            </thead>
                                            <tbody>                               
                                                <tr>
                                                    <th>Customer POC Name</th>
                                                    <th>Customer POC Number</th>
                                                    <th>Customer POC Email ID</th>
                                                    <th>Customer POC Designation</th>
                                                    
                                                </tr>
                                                {opportViewData != '' && opportViewData.PocInfo.map((data, index) => (  
                                                    <tr key={index}>  
                                                        <td>{data.PocName}</td>  
                                                        <td>{data.PocNo}</td>  
                                                        <td>{data.PocEmail}</td>  
                                                        <td>{data.Designation}</td>
                                                        
                                                    </tr>  
                                                ))}  
                                            </tbody>
                                        </table>
                                    </div>
                                  <td><div className='col-md-12'>
                                    <button style={{font_size: '12px', float: 'right', marginTop: '12px'}} type="button" onClick={expand}  className="btn btn-primary btn-sm  me-2"><strong>{ expandselected == 1 ? 'HIDE OPPORTUNITY DETAILS' : 'SHOW OPPORTUNITY DETAILS' }</strong></button> 
                                    </div></td>

                                    { expandselected == 1 && opportViewData.data.project_type == 'AUTO' && (opportViewData.data.service_type == 'RP' || opportViewData.data.service_type == 'LS') && (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG') &&
                                    <div className="invoice row col-md-12">
                                        <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Service For</th>
                                                        <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Origin</th>
                                                        <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Destination</th>
                                                        <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Business Tenure (In Months)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Revenue</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Capex</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Potential Ebitda (%)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Component Family</th>
                                                        <td>{opportViewData != '' && opportViewData.data.component_family_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Expected Closure Date</th>
                                                        <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Reference URL</th>
                                                        <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Lead Description</th>
                                                        <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                            <table id="mainTable" className="table padded-table">
                                            <thead>
                                            </thead>
                                                <tbody>                               
                                                    <tr>
                                                        <th>Component Name</th>
                                                        <th>Minimum Monthly Volume</th>
                                                        <th>Maximum Monthly Volume</th>
                                                        <th>Monthly Volume</th>
                                                        <th>Current Packaging</th>
                                                        <th>Current Packaging (Qty./Box)</th>
                                                        <th>Cycle Time</th>
                                                        <th>Component Dimensions Length (mm)</th>
                                                        <th>Component Dimensions Width (mm)</th>
                                                        <th>Component Dimensions Height (mm)</th>
                                                        <th>Component Dimensions Wt. (gm)</th>
                                                        {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <th>Back Up File</th>
                                                        }
                                                    </tr>
                                                    {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                                        <tr key={index}>  
                                                            <td>{data.component_name}</td>  
                                                            <td>{data.monthly_volume_min}</td>  
                                                            <td>{data.monthly_volume_max}</td>  
                                                            <td>{data.monthly_volume_avg}</td>
                                                            <td>{data.current_packaging}</td>
                                                            <td>{data.curr_qty_per_box}</td>
                                                            <td>{data.cycle_time}</td>
                                                            <td>{data.component_dimension_l}</td>
                                                            <td>{data.component_dimension_w}</td>
                                                            <td>{data.component_dimension_h}</td>
                                                            <td>{data.component_dimension_wt}</td>
                                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                            <td style={{width:'10%'}}><span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                            </td>
                                                            }
                                                        </tr>  
                                                    ))}  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> } 
                                    { expandselected  === 1 && opportViewData.data.project_type == 'RETAIL' &&
                                    <div className="invoice row col-md-12">
                                        <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Service For</th>
                                                        <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Origin</th>
                                                        <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Destination</th>
                                                        <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Business Tenure (In Months)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Revenue</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Capex</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Potential Ebitda (%)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Expected Closure Date</th>
                                                        <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Reference URL</th>
                                                        <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Lead Description</th>
                                                        <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                            <table id="mainTable" className="table padded-table">
                                            <thead>opportunity_view
                                            </thead>    
                                                <tbody>                               
                                                    <tr>
                                                        <th>Operation Type</th>
                                                        <th>Area Required (Sq.ft.)</th> 
                                                        <th>Inventory Days</th>
                                                        <th>Avg. Daily Inbound</th>
                                                        <th>Avg. Daily Outbound</th>
                                                        {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <th>Back Up File</th>
                                                        }
                                                    </tr>
                                                    {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                                        <tr key={index}>  
                                                            <td>{data.operation_type.label}</td>  
                                                            <td>{data.area_required}</td>  
                                                            <td>{data.inventory_days}</td>  
                                                            <td>{data.avg_daily_inbound}</td>  
                                                            <td>{data.avg_daily_outbound}</td>
                                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                            <td> <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                            </td>
                                                            }
                                                        </tr>  
                                                    ))}  
                                                </tbody>
                                            </table>
                                        </div> 
                                    </div> }
                                    { (expandselected  === 1 && opportViewData.data.project_type == 'AUTO' && opportViewData.data.service_type == 'NRP' &&  (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG') ) ?
                                    <div className="invoice row col-md-12">
                                        <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Service For</th>
                                                        <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Origin</th>
                                                        <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Destination</th>
                                                        <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Business Tenure (In Months)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Revenue</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Capex</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Potential Ebitda (%)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Component Family</th>
                                                        <td>{opportViewData != '' && opportViewData.data.component_family_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Expected Closure Date</th>
                                                        <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Reference URL</th>
                                                        <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Lead Description</th>
                                                        <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                            <table id="mainTable" className="table padded-table">
                                                <thead>
                                                </thead>
                                                <tbody>                               
                                                    <tr>
                                                        <th>Component Name</th>
                                                        <th>Minimum Monthly Volume</th>
                                                        <th>Maximum Monthly Volume</th>
                                                        <th>Monthly Volume</th>
                                                        {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <th>Back Up File</th>
                                                        }
                                                    </tr>
                                                    {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                                        <tr key={index}>  
                                                            <td>{data.component_name}</td>  
                                                            <td>{data.monthly_volume_min}</td>  
                                                            <td>{data.monthly_volume_max}</td>  
                                                            <td>{data.monthly_volume_avg}</td>
                                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                                <td> <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                                <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                                </td>
                                                            }
                                                        </tr>  
                                                    ))}  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : null }
                                    <div className='row' style={{marginTop: '15px'}}>
                                        <div className="col-md-5" controlid="formFileMultiple" >
                                            <Form.Label>Opportunity Related Reference File (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                                            <Form.Control multiple type="file"  
                                            ref={aRef}  
                                            name="opportunity_photo_web"
                                            onChange={handleFileInput}
                                            />
                                        </div>
                                        <div className="col-md-2" style={{marginTop: '26px'}}>
                                            <button type="submit" className="btn btn-primary" onClick={handleRefFileSubmit} >Upload</button>
                                        </div>  
                                        <div className="col-md-5" style={{float:'right'}}>
                                            <a className="nav-link nav-link-notify" data-count={opportViewData != '' && opportViewData.data.QueryCount} ><img src="/ulms_assets/images/chat_icon.png" alt="icon" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'70px', width:'75px', float:'right'}} /></a>
                                        </div>
                                    </div>
                                </div>

                                {/* Solution tab */}

                                <div className="tab-pane fade" id="solution" role="tabpanel" aria-labelledby="solution-tab">
                                  <div className="invoice row">
                                    <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                        <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>LOB</th>
                                                    <td >{opportViewData != '' && opportViewData.data.project_type}</td>
                                                </tr>
                                                <tr>
                                                    <th>Product Type</th>
                                                    <td>{opportViewData != '' && opportViewData.data.products}</td>
                                                </tr>
                                                <tr>
                                                    <th>Service Type</th>
                                                    <td>{opportViewData != '' && opportViewData.data.service_type}</td>
                                                </tr>
                                                <tr>
                                                    <th>Start Date</th>
                                                    <td >{opportViewData != '' && opportViewData.data.start_date}</td>
                                                </tr>
                                                <tr>
                                                    <th>Supplier Name</th>
                                                    {/* <td>{opportViewData != '' && opportViewData.data.supplier_name}</td> */}
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&
                                                        <td>{opportViewData.data.new_supplier_name}</td> 
                                                    }
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                                        <td>{opportViewData.data.supplier_name}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th >Entity Name</th>
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&
                                                    <td>{opportViewData.data.new_supplier_name}</td> 
                                                    }
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                                    <td>{opportViewData != '' && opportViewData.data.entity_name}</td>
                                                    }
                                                </tr>  
                                                <tr>
                                                    <th>LS Services:</th>
                                                    {opportViewData != '' && opportViewData.data.multi_ls.length > 0 ?
                                                    <td>
                                                        {opportViewData.data.multi_ls.map((data) => (  
                                                            <>{data}  </> 
                                                        ))} 
                                                    </td> :
                                                    <td>-</td> 
                                                    }  
                                                </tr> 
                                                <tr>
                                                    <th>Supplier Location</th>
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&    
                                                    <td>{opportViewData.data.new_supplier_loc}</td> 
                                                    }
                                                    {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                                    <td>{opportViewData != '' && opportViewData.data.supplier_loc}</td>
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div className="table-scrollable col-md-6">
                                        <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>Supplier Industry Vertical</th>
                                                    <td>{opportViewData != '' && opportViewData.data.cust_industry_verticle}</td>
                                                </tr> 
                                                {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                                    <tr>
                                                        <th>OEM Name</th>
                                                        {/* <td >{listViewData != '' && listViewData.data.supplier_name}</td> */}
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&
                                                        <td>{opportViewData.data.new_oem_name}</td> 
                                                        }
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                        <td>{opportViewData.data.oem_name}</td>
                                                        }
                                                    </tr>
                                                    }
                                                    {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                                    <tr>
                                                        <th >OEM Entity Name</th>
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&
                                                        <td>{opportViewData.data.new_oem_name}</td> 
                                                        }
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                        <td>{opportViewData != '' && opportViewData.data.oem_entity_name}</td>
                                                        }
                                                    </tr> 
                                                    }    
                                                    {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                                    <tr>
                                                        <th>OEM Location</th>
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&    
                                                        <td>{opportViewData.data.new_oem_loc}</td> 
                                                        }
                                                        {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                                        <td>{opportViewData != '' && opportViewData.data.oem_loc}</td>
                                                        }
                                                    </tr>
                                                    }
                                                <tr>
                                                    <th>Lead Requester Name</th>
                                                    <td >{opportViewData != '' && opportViewData.data.lead_req_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Lead Owner Name</th>
                                                    <td >{opportViewData != '' && opportViewData.data.lead_owner_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Opportunity Owner Name</th>
                                                    {/* <td >{opportViewData != '' && opportViewData.data.opportunity_owner_name}</td> */}
                                                    {(opportViewData != '' && opportViewData.data.opportunity_owner_name != null) ?
                                                        <td>{opportViewData.data.opportunity_owner_name}</td> :
                                                        <td>-</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>Lead Status</th>
                                                    {(opportViewData != '' && opportViewData.data.LeadStatus != 'Dormant' && opportViewData.data.LeadStatus != 'Lost') &&
                                                    <td>
                                                        <span className="text-success"> {opportViewData != '' && opportViewData.data.LeadStatus} </span>
                                                    </td>}
                                                    {(opportViewData != '' && (opportViewData.data.LeadStatus === 'Dormant' || opportViewData.data.LeadStatus === 'Lost') ) &&
                                                    <td>
                                                        <span className="text-danger"> {opportViewData != '' && opportViewData.data.LeadStatus} </span>
                                                    </td>}
                                                  
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div className="table-scrollable col-md-12">
                                        <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                            <thead className="thead-light">
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th style={{width:'150px'}}>Lead Doc</th>
                                                    <td>
                                                    {opportViewData != '' && opportViewData.packingImg.map((data) => (  
                                                        <>
                                                            <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                                            { (opportViewData.data.lead_req_id == usr_id || opportViewData.data.lead_owner_id == usr_id) &&
                                                                <img
                                                                    className='cross-icon'
                                                                    src='../../ulms_assets/images/cross_icon.png'
                                                                    alt="close icon"
                                                                    data-bs-toggle="modal" data-bs-target="#deleteremarkModal"
                                                                    title='Delete ref file'
                                                                    onClick={()=>setDelDocFileId(data.file_id)}
                                                                /> }
                                                        </>
                                                    ))}  
                                                    </td>
                                                    </tr>
                                                <tr>
                                                    <th style={{width:'150px'}}>Opportunity Doc</th>
                                                    <td>
                                                    {opportViewData != '' && opportViewData.OpportunityImgPath.map((data) => (  
                                                        <>
                                                        <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                                        { (opportViewData.data.opportunity_owner_id == usr_id || opportViewData.data.lead_owner_id == usr_id) &&
                                                            <img
                                                                className='cross-icon'
                                                                src='../../ulms_assets/images/cross_icon.png'
                                                                alt="close icon"
                                                                data-bs-toggle="modal" data-bs-target="#deleteremarkModal"
                                                                title='Delete ref file'
                                                                onClick={()=>setDelDocFileId(data.file_id)}
                                                            /> }
                                                        </>
                                                    ))}  
                                                    </td>
                                                </tr>
                                            
                                            </tbody>
                                        </table>
                                    </div> 
                                  </div>
                                    <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                        <table id="mainTable" className="table padded-table">
                                        <thead>
                                        </thead>
                                            <tbody>                               
                                                <tr>
                                                    <th>Customer POC Name</th>
                                                    <th>Customer POC Number</th>
                                                    <th>Customer POC Email ID</th>
                                                    <th>Customer POC Designation</th>
                                                    
                                                </tr>
                                                {opportViewData != '' && opportViewData.PocInfo.map((data, index) => (  
                                                    <tr key={index}>  
                                                        <td>{data.PocName}</td>  
                                                        <td>{data.PocNo}</td>  
                                                        <td>{data.PocEmail}</td>  
                                                        <td>{data.Designation}</td>
                                                        
                                                    </tr>  
                                                ))}  
                                            </tbody>
                                        </table>
                                    </div>
                                  <td><div className='col-md-12'>
                                    <button style={{font_size: '12px', float: 'right', marginTop: '12px'}} type="button" onClick={expand}  className="btn btn-primary btn-sm  me-2"><strong>{ expandselected == 1 ? 'HIDE OPPORTUNITY DETAILS' : 'SHOW OPPORTUNITY DETAILS' }</strong></button> 
                                    </div></td>

                                    { expandselected == 1 && opportViewData.data.project_type == 'AUTO' && (opportViewData.data.service_type == 'RP' || opportViewData.data.service_type == 'LS') && (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG') &&
                                    <div className="invoice row col-md-12">
                                        <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Service For</th>
                                                        <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Origin</th>
                                                        <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Destination</th>
                                                        <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Business Tenure (In Months)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Revenue</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Capex</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Potential Ebitda (%)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Component Family</th>
                                                        <td>{opportViewData != '' && opportViewData.data.component_family_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Expected Closure Date</th>
                                                        <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Reference URL</th>
                                                        <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Lead Description</th>
                                                        <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                            <table id="mainTable" className="table padded-table">
                                            <thead>
                                            </thead>
                                                <tbody>                               
                                                    <tr>
                                                        <th>Component Name</th>
                                                        <th>Minimum Monthly Volume</th>
                                                        <th>Maximum Monthly Volume</th>
                                                        <th>Monthly Volume</th>
                                                        <th>Current Packaging</th>
                                                        <th>Current Packaging (Qty./Box)</th>
                                                        <th>Cycle Time</th>
                                                        <th>Component Dimensions Length (mm)</th>
                                                        <th>Component Dimensions Width (mm)</th>
                                                        <th>Component Dimensions Height (mm)</th>
                                                        <th>Component Dimensions Wt. (gm)</th>
                                                        {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <th>Back Up File</th>
                                                        }
                                                    </tr>
                                                    {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                                        <tr key={index}>  
                                                            <td>{data.component_name}</td>  
                                                            <td>{data.monthly_volume_min}</td>  
                                                            <td>{data.monthly_volume_max}</td>  
                                                            <td>{data.monthly_volume_avg}</td>
                                                            <td>{data.current_packaging}</td>
                                                            <td>{data.curr_qty_per_box}</td>
                                                            <td>{data.cycle_time}</td>
                                                            <td>{data.component_dimension_l}</td>
                                                            <td>{data.component_dimension_w}</td>
                                                            <td>{data.component_dimension_h}</td>
                                                            <td>{data.component_dimension_wt}</td>
                                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                            <td style={{width:'10%'}}><span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                            </td>
                                                            }
                                                        </tr>  
                                                    ))}  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> } 
                                    { expandselected  === 1 && opportViewData.data.project_type == 'RETAIL' &&
                                    <div className="invoice row col-md-12">
                                        <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Service For</th>
                                                        <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Origin</th>
                                                        <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Destination</th>
                                                        <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Business Tenure (In Months)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Revenue</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Capex</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Potential Ebitda (%)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Expected Closure Date</th>
                                                        <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Reference URL</th>
                                                        <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Lead Description</th>
                                                        <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                            <table id="mainTable" className="table padded-table">
                                            <thead>
                                            </thead>    
                                                <tbody>                               
                                                    <tr>
                                                        <th>Operation Type</th>
                                                        <th>Area Required (Sq.ft.)</th> 
                                                        <th>Inventory Days</th>
                                                        <th>Avg. Daily Inbound</th>
                                                        <th>Avg. Daily Outbound</th>
                                                        {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <th>Back Up File</th>
                                                        }
                                                    </tr>
                                                    {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                                        <tr key={index}>  
                                                            <td>{data.operation_type.label}</td>  
                                                            <td>{data.area_required}</td>  
                                                            <td>{data.inventory_days}</td>  
                                                            <td>{data.avg_daily_inbound}</td>  
                                                            <td>{data.avg_daily_outbound}</td>
                                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                            <td> <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                            </td>
                                                            }
                                                        </tr>  
                                                    ))}  
                                                </tbody>
                                            </table>
                                        </div> 
                                    </div> }
                                    { (expandselected  === 1 && opportViewData.data.project_type == 'AUTO' && opportViewData.data.service_type == 'NRP' &&  (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG') ) ?
                                    <div className="invoice row col-md-12">
                                        <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Service For</th>
                                                        <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Origin</th>
                                                        <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Destination</th>
                                                        <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Business Tenure (In Months)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Revenue</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_rev}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Potential Capex</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_capex}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div className="table-scrollable col-md-6">
                                            <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                                <thead className="thead-light">
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>Potential Ebitda (%)</th>
                                                        <td>{opportViewData != '' && opportViewData.data.potential_ebitda} ({opportViewData != '' && opportViewData.data.potential_ebitda_per}%)</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Component Family</th>
                                                        <td>{opportViewData != '' && opportViewData.data.component_family_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Expected Closure Date</th>
                                                        <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Reference URL</th>
                                                        <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Lead Description</th>
                                                        <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                        <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                            <table id="mainTable" className="table padded-table">
                                                <thead>
                                                </thead>
                                                <tbody>                               
                                                    <tr>
                                                        <th>Component Name</th>
                                                        <th>Minimum Monthly Volume</th>
                                                        <th>Maximum Monthly Volume</th>
                                                        <th>Monthly Volume</th>
                                                        {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <th>Back Up File</th>
                                                        }
                                                    </tr>
                                                    {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                                        <tr key={index}>  
                                                            <td>{data.component_name}</td>  
                                                            <td>{data.monthly_volume_min}</td>  
                                                            <td>{data.monthly_volume_max}</td>  
                                                            <td>{data.monthly_volume_avg}</td>
                                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                                <td> <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                                <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                                </td>
                                                            }
                                                        </tr>  
                                                    ))}  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : null }
                                    { opportViewData != '' && ((opportViewData.data.opportunity_owner_id == usr_id) || (opportViewData.data.solutionUploadBySdHead == 1 && user_role == 'SD Head' && opportViewData.data.opportunity_owner_id != null)) &&
                                    <>
                                    { opportViewData.data.SentToClientsCount != 1 &&  opportViewData.data.service_type == 'RP' &&

                                        <div id="accordion" style={{marginTop:'10px'}}>

                                            <form className="row g-3" encType="multipart/form-data" onSubmit={handleFormSubmit}>
                                             <div className="card">
                                                {inputFields != "" && inputFields.map((value, parentIndex) => {
                                                    console.log('outer',value.outer);

                                                    const updatedData = [...inputFields];

                                                    if(value.prop_ul_rate != ''){
                                                        updatedData[parentIndex].outer_cost = 1 * Number(updatedData[parentIndex].prop_ul_rate);
                                                    
                                                    }

                                                    {value.innerData.map((data, chldIndex)=>{
                                                        if(value.inner_qty != '' || value.inner_rate != ''){
                                                            updatedData[parentIndex].innerData[chldIndex].innerCost = updatedData[parentIndex].innerData[chldIndex].inner_qty * updatedData[parentIndex].innerData[chldIndex].inner_rate; 
                                                        }
                                                    })}

                                                    total = value.innerData.reduce((sum, item) => sum + item.innerCost, 0);
                                                    solutionCost =  updatedData[parentIndex].outer_cost + total;

                                                    updatedData[parentIndex]["outer_cost"] = value.prop_ul_rate;
                                                    updatedData[parentIndex]["inner_cost"] = total;
                                                    updatedData[parentIndex]["solution_cost"] = solutionCost;

                                                    if(updatedData > -1){
                                                        setInputFields(updatedData)
                                                    }
                                                   
                                              return(
                                                <>
                                                <div className="card-header row" id={"heading"+parentIndex} data-toggle="collapse" data-target={"#collapse"+parentIndex} aria-expanded="true" aria-controls={"collapse"+parentIndex} style={{backgroundColor:'#46B653'}}>
                                                   <div className="col-md-6" >
                                                        <h3 className="mb-0" >
                                                            <span className="btn btn collapsed" style={{fontWeight: 'bold', fontSize:'16px', color:'white'}}>
                                                            Component Name: {value.component_name}
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    <div className="col-md-2" >
                                                        <label htmlFor="inputState" className="form-label">Outer Cost : </label>
                                                        <Form.Control
                                                            type="number"
                                                            name="outer_cost"
                                                            value={value.prop_ul_rate}
                                                            // onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                            id="outer_cost"
                                                            className="form-control" disabled />      
                                                    </div>
                                                    <div className="col-md-2" >
                                                        <label htmlFor="inputState" className="form-label">Inner Cost : </label>
                                                        <Form.Control
                                                            type="number"
                                                            name="inner_cost"
                                                            value={total}
                                                            // onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                            id="inner_cost"
                                                            className="form-control" disabled />      
                                                    </div>
                                                    <div className="col-md-2" >
                                                        <label htmlFor="inputState" className="form-label">Solution Cost : </label>
                                                        <Form.Control
                                                            type="number"
                                                            name="solution_cost"
                                                            value={solutionCost}
                                                            // onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                            id="solution_cost"
                                                            className="form-control" disabled />      
                                                    </div>
                                                </div>
                                                <div id={"collapse"+parentIndex} className={parentIndex === 0 ? "collapse show" : "collapse" } aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="card-body row">
                                                        <div className='col-md-10'  style={{backgroundColor:'#D3D3D3', marginBottom:'10px'}}>
                                                            <div className='row'>
                                                            <div className="col-md-3" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label">Component Name:<span className="required-mark">*</span> </label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="component_name"
                                                                    defaultValue={value.component_name}
                                                                    onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                    id="component_name"
                                                                    className="form-control" />      
                                                            </div>
                                                            <div className="col-md-3" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label">Monthly Volume (nos.):<span className="required-mark">*</span> </label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="monthly_volume_avg"
                                                                    value={value.monthly_volume_avg}
                                                                    onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                    id="monthly_volume_avg"
                                                                    className="form-control" placeholder="Average" />      
                                                            </div>
                                                            <div className="col-md-2" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label">Cycle Time: <span className="required-mark">*</span></label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="cycle_time"
                                                                    defaultValue={value.cycle_time}
                                                                    onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                    id="cycle_time"
                                                                    className="form-control" />      
                                                            </div>
                                                            <div className="col-md-4" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label">Component Dimensions (L:W:H: in mm / Wt in Kg): </label>
                                                                <div className='row'>
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="component_dimension_l"
                                                                            defaultValue={value.component_dimension_l}
                                                                            onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                            id="component_dimension_l"
                                                                            className="form-control" placeholder='L' />     
                                                                    </div> 
                                                                    <div className="col-md-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="component_dimension_w"
                                                                            defaultValue={value.component_dimension_w}
                                                                            onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                            id="component_dimension_w"
                                                                            className="form-control" placeholder='W' />      
                                                                    </div> 
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="component_dimension_h"
                                                                            defaultValue={value.component_dimension_h}
                                                                            onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                            id="component_dimension_h"
                                                                            className="form-control" placeholder='H' />  
                                                                    </div>
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="component_dimension_wt"
                                                                            defaultValue={value.component_dimension_wt}
                                                                            onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                            id="component_dimension_wt"
                                                                            className="form-control" placeholder='WT' />   
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                            <div style={{marginTop: '20px'}}></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2" >
                                                            <label htmlFor="inputState" className="form-label">Proposed Comp. Qty./UL : </label>
                                                            <Form.Control
                                                                type="number"
                                                                name="prop_comp_ul_qty"
                                                                defaultValue={value.prop_comp_ul_qty}
                                                                onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                id="prop_comp_ul_qty"
                                                                className="form-control" />      
                                                        </div>
                                                        <div className="col-md-12" style={{ marginTop:'10px'}}>
                                                            <Form.Check
                                                                inline
                                                                label="Outer"
                                                                name="Outer"
                                                                value="1"
                                                                type= "checkbox" 
                                                                key={value.outer}
                                                                onChange={(event)=>handleOuterInput(parentIndex, event)}
                                                                defaultChecked = {(value.outer) ? true : false  }

                                                                style={{fontSize: '18px', fontWeight:'bold'}}
                                                            />
                                                        </div>  
                                                        {inputFields != '' && inputFields[parentIndex].outer != 0 && 
                                                        <>
                                                        <div className="col-md-2" style={{marginTop: '5px'}}>
                                                            <label htmlFor="inputState" className="form-label">Proposed UL Packaging: </label>
                                                             <Select
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                name="prop_outer_packaging"
                                                                options={outerULData}
                                                                defaultValue={value.prop_outer_packaging}
                                                                onChange={(event)=>handlePropPackInput(parentIndex, event)}
                                                                key={value.prop_outer_packaging}
                                                            />   
                                                        </div>
                                                        <div className="col-md-3" style={{marginTop: '5px'}}>
                                                            <label htmlFor="inputState" className="form-label">Prop UL Dim. (L:W:H: in mm / Wt in Kg): </label>
                                                            <div className='row'>
                                                                <div className="col-md-3" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prop_box_dimension_l"
                                                                        defaultValue={value.prop_box_dimension_l}
                                                                        onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                        id="prop_box_dimension_l"
                                                                        className="form-control" placeholder='L' />
                                                                </div>    
                                                                <div className="col-md-3" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prop_box_dimension_w"
                                                                        defaultValue={value.prop_box_dimension_w}
                                                                        onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                        id="prop_box_dimension_w"
                                                                        className="form-control" placeholder='W' />     
                                                                </div>    
                                                                <div className="col-md-3" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prop_box_dimension_h"
                                                                        defaultValue={value.prop_box_dimension_h}
                                                                        onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                        id="prop_box_dimension_h"
                                                                        className="form-control" placeholder='H' />       
                                                                </div>   
                                                                <div className="col-md-3" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prop_box_dimension_wt"
                                                                        defaultValue={value.prop_box_dimension_wt}
                                                                        onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                        id="prop_box_dimension_wt"
                                                                        className="form-control" placeholder='WT' />      
                                                                </div>    
                                                            </div>  
                                                        </div>
                                                        <div className="col-md-3" style={{marginTop: '5px'}}>
                                                            <label htmlFor="inputState" className="form-label">Prop. UL Collaps Dim.(L:W:H: in mm / Wt in Kg): </label>
                                                            <div className='row'>
                                                                <div className="col-md-3" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prop_collaps_dimension_l"
                                                                        defaultValue={value.prop_collaps_dimension_l}
                                                                        onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                        id="prop_collaps_dimension_l"
                                                                        className="form-control" placeholder='L' />
                                                                </div>    
                                                                <div className="col-md-3" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prop_collaps_dimension_w"
                                                                        defaultValue={value.prop_collaps_dimension_w}
                                                                        onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                        id="prop_collaps_dimension_w"
                                                                        className="form-control" placeholder='W' />     
                                                                </div>    
                                                                <div className="col-md-3" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prop_collaps_dimension_h"
                                                                        defaultValue={value.prop_collaps_dimension_h}
                                                                        onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                        id="prop_collaps_dimension_h"
                                                                        className="form-control" placeholder='H' />       
                                                                </div>   
                                                                <div className="col-md-3" >
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="prop_collaps_dimension_wt"
                                                                        defaultValue={value.prop_collaps_dimension_wt}
                                                                        onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                        id="prop_collaps_dimension_wt"
                                                                        className="form-control" placeholder='WT' />      
                                                                </div>    
                                                            </div>  
                                                        </div>
                                                        <div className="col-md-3" style={{marginTop: '5px'}}>
                                                            <label htmlFor="inputState" className="form-label">Proposed UL Rate : </label>
                                                            <Form.Control
                                                                type="number"
                                                                name="prop_ul_rate"
                                                                defaultValue={value.prop_ul_rate}
                                                                onChange={(event)=>handleOpportFormInput(parentIndex, event)}
                                                                id="prop_ul_rate"
                                                                className="form-control" />      
                                                        </div>
                                                        </> }
                                                       
                                                        <div className="col-md-12" style={{ marginTop:'10px', font:'20px'}}>
                                                            <Form.Check
                                                                inline
                                                                label="Inner"
                                                                name="inner"
                                                                value="1"
                                                                type= "checkbox" 
                                                                key={value.inner}
                                                                onChange={(event)=>handleInnerInput(parentIndex, event)}
                                                                defaultChecked = {(value.inner) ? true : false}
                                                                style={{fontSize: '18px', fontWeight:'bold'}}
                                                            />
                                                        </div>  
                                                      
                                                        {inputFields != '' && inputFields[parentIndex].inner != 0 && 

                                                        <>
                                                        {value.innerData.map((input, childIndex) => {

                                                        return (

                                                          <div className = 'row' key={input.id}>
                                                            <div className="col-md-2" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label">Proposed Inner Packaging: </label>
                                                                <Select
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    name="prop_inner_packaging"
                                                                    options={innerULData}
                                                                    defaultValue={input.prop_inner_packaging}
                                                                    onChange={(event)=>handleInnerPackInput(parentIndex, childIndex, event)}
                                                                    // key={value.prop_inner_packaging}
                                                                />   
                                                            </div>
                                                            <div className="col-md-3" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label">Dimension (L:W:H: in mm / Wt in Kg): </label>
                                                                <div className='row'>
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="inner_dimension_l"
                                                                            defaultValue={input.inner_dimension_l}
                                                                            onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                            id="inner_dimension_l"
                                                                            className="form-control" placeholder='L' />
                                                                    </div>    
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="inner_dimension_w"
                                                                            defaultValue={input.inner_dimension_w}
                                                                            onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                            id="inner_dimension_w"
                                                                            className="form-control" placeholder='W' />     
                                                                    </div>    
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="inner_dimension_h"
                                                                            defaultValue={input.inner_dimension_h}
                                                                            onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                            id="inner_dimension_h"
                                                                            className="form-control" placeholder='H' />       
                                                                    </div>   
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="inner_dimension_wt"
                                                                            defaultValue={input.inner_dimension_wt}
                                                                            onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                            id="inner_dimension_wt"
                                                                            className="form-control" placeholder='WT' />      
                                                                    </div>    
                                                                </div>  
                                                            </div> 
                                                            <div className="col-md-3" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label">Collapsible Dim.(L:W:H: in mm / Wt in Kg): </label>
                                                                <div className='row'>
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="inner_collaps_dimension_l"
                                                                            defaultValue={input.inner_collaps_dimension_l}
                                                                            onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                            id="inner_collaps_dimension_l"
                                                                            className="form-control" placeholder='L' />
                                                                    </div>    
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="inner_collaps_dimension_w"
                                                                            defaultValue={input.inner_collaps_dimension_w}
                                                                            onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                            id="inner_collaps_dimension_w"
                                                                            className="form-control" placeholder='W' />     
                                                                    </div>    
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="inner_collaps_dimension_h"
                                                                            defaultValue={input.inner_collaps_dimension_h}
                                                                            onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                            id="inner_collaps_dimension_h"
                                                                            className="form-control" placeholder='H' />       
                                                                    </div>   
                                                                    <div className="col-md-3" >
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="inner_collaps_dimension_wt"
                                                                            defaultValue={input.inner_collaps_dimension_wt}
                                                                            onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                            id="inner_collaps_dimension_wt"
                                                                            className="form-control" placeholder='WT' />      
                                                                    </div>    
                                                                </div>  
                                                            </div> 
                                                            <div className="col-md-1" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label"> Qty : </label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="inner_qty"
                                                                    defaultValue={input.inner_qty}
                                                                    onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                    id="inner_qty"
                                                                    className="form-control" />      
                                                            </div>
                                                            <div className="col-md-2" style={{marginTop: '5px'}}>
                                                                <label htmlFor="inputState" className="form-label"> Rate : </label>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="inner_rate"
                                                                    defaultValue={input.inner_rate}
                                                                    onChange={(event)=>handleInnerFormInput(parentIndex, childIndex, event)}
                                                                    id="inner_rate"
                                                                    className="form-control" />      
                                                            </div>
                                                            <div className="col-md-1 " style={{marginTop: '40px', float:'right'}}>
                                                            {(value.innerData.length!==1)? <i className="fa fa-minus-circle" aria-hidden="true" style={{float: 'right', marginLeft:'10px', color:'red', fontSize: '2em'}} onClick={(e)=>confirmDeleteLine(parentIndex, childIndex, e)}></i>:''}
                                                            {value.innerData.length - 1 === childIndex &&
                                                            <i className="fa fa-plus-square" aria-hidden="true" onClick={(event)=>addFields(parentIndex, childIndex, event)} style={{float: 'right', color:'#13467B', fontSize: '2em'}}></i> }
                                                            </div>
                                                        </div> )
                                                         })}
                                                        </> }
                                                    </div>
                                                </div>
                                                </>) })}
                                                </div> 
                                                <div className="row" style={{marginTop: '20px'}}> 
                                                    <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                                        <Form.Label style={{fontWeight:'bold'}}>Upload Solution File :</Form.Label>
                                                        <Form.Control type="file"
                                                        ref={bRef}    
                                                        name="opportunity_photo_web"
                                                        onChange={handleSolFileChange}
                                                        />
                                                    </div>
                                                    <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                                        <Form.Label style={{fontWeight:'bold'}}>Upload BOM File :</Form.Label>
                                                        <Form.Control type="file"
                                                        ref={bRef}    
                                                        name="opportunity_photo_bom"
                                                        onChange={handleBomFileChange}
                                                        />
                                                    </div>
                                                    <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                                        <Form.Label style={{fontWeight:'bold'}}>Upload Related Ref File :</Form.Label>
                                                        <Form.Control type="file"
                                                        ref={bRef}    
                                                        multiple
                                                        name="opportunity_photo_ref"
                                                        onChange={handleRefFileChange}
                                                        />
                                                    </div>
                                                    <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                                        <Form.Label style={{fontWeight:'bold'}}>Upload Trial File :</Form.Label>
                                                        <Form.Control type="file"
                                                        ref={bRef}    
                                                        multiple
                                                        name="opportunity_photo_trial"
                                                        onChange={handleTrialFileChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2" style={{marginTop: '30px'}}>
                                                    <button type="submit" className="btn btn-primary">Upload</button>
                                                </div>  
                                            </form>
                    
                                        </div>
                                        }
                                        { opportViewData.data.SentToClientsCount != 1 &&  opportViewData.data.service_type == 'NRP' &&
                                            <NRPSolutionForm/>
                                        }
                                    </>
                                    }
                                    <div className='row'>
                                       <form className='col-md-12'>
                                          <div className="row" style={{marginTop:'15px'}}>
                                            {opportViewData != '' && opportViewData.Solution.slice(0).reverse().map((data, index) => (
                                            <div className='card'>
                                                <div className='row card-body '> 
                                                    <div className="col-md-12" >
                                                        <div className = "row">
                                                            <div className = "col-md-1">
                                                                <h5 >{data.draft}</h5>
                                                                {(data.mark_final == 0) ?
                                                                <>
                                                                    <h6 style={{marginLeft: '2px'}}><span className="badge rounded-pill bg-primary">Pending</span></h6>
                                                                    
                                                                </>
                                                                :
                                                                (data.mark_final == 1) ?
                                                                <>
                                                                    <h6 style={{marginLeft: '7px'}}><span className="badge rounded-pill bg-success">Final</span></h6> 
                                                                
                                                                </>
                                                                :
                                                                (data.mark_final == 3) ? 
                                                                <>
                                                                    <h6 style={{marginLeft: '5px'}}><span className="badge rounded-pill bg-danger">Reject</span></h6> 
                                                                </>
                                                                : ''
                                                                }
                                                            </div>
                                                            <div className="col-md-8" >
                                                            <span className="text-success"> {data.apprStatus} </span>
                                                            {data.rejectRemark != '' && <span className="text-danger">(Rejected Reason:  {data.rejectRemark})</span> }
                                                            </div> 
                                                            <div className="col-md-3" style={{marginTop:'20px', float:'right'}}>
                                                                {data.mark_final == 0  &&
                                                                    <>
                                                                        {(opportViewData != '' && user_role == 'SD User' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                                        <>
                                                                            <div className='col-md-1' >
                                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                                            </div>
                                                                        </>
                                                                        }

                                                                        {(opportViewData != '' && user_role == 'SD Head' && opportViewData.data.LPSPermissionCount == 3) &&
                                                                        <>
                                                                            <div className='col-md-1' >
                                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                                            </div>
                                                                        </>
                                                                        }

                                                                    </>
                                                                }
                                                                {data.mark_final == 1  &&
                                                                    <div className='row'>
                                                                    {(opportViewData != '' && user_role == 'SD User' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                                    <>
                                                                        <div className='col-md-2' style={{marginRight:'8px'}}>
                                                                        <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                                        </div>
                                                                    </>
                                                                    }

                                                                    {(opportViewData != '' && user_role == 'SD Head' && opportViewData.data.LPSPermissionCount == 3 ) &&
                                                                    <>
                                                                        <div className='col-md-2' style={{marginRight:'8px'}} >
                                                                        <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                                        </div>
                                                                    </>
                                                                    }


                                                                    {opportViewData != '' && opportViewData.data.ApproveButtonToUser == 1 && user_role == 'SD Head' &&
                                                                    <div className='col-md-8'>
                                                                        <span onClick={()=>confirmdialog(data.draft_id, 1)} className="btn btn-primary btn-sm me-2" >Send To BD </span>
                                                                        <span className="btn btn-danger btn-sm me-2" onClick={()=>handledraftId(data.draft_id)} data-bs-toggle="modal" data-bs-target="#draftrejectedModal"  >Reject</span>
                                                                    </div> 
                                                                    }

                                                                    {opportViewData != '' && opportViewData.data.ApproveButtonToUser == 2 && (user_role == 'BD Head' || user_role == 'BD User') &&
                                                                    <div className='col-md-8'>
                                                                        <span onClick={()=>confirmdialog(data.draft_id, 1)} className="btn btn-primary btn-sm me-2" >Approve</span>
                                                                        <span onClick={()=>handledraftId(data.draft_id)}  className="btn btn-danger btn-sm me-2" data-bs-toggle="modal" data-bs-target="#draftrejectedModal" >Reject</span>
                                                                    </div> 
                                                                    }

                                                                    {(opportViewData != '' && (user_role == 'BD User' || user_role == 'BD Head' ) && (opportViewData.data.ApproveButtonToUser == 4)) &&
                                                                    <>
                                                                        <div className='col-md-4' >
                                                                        <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>handledraftId(data.draft_id)} data-bs-toggle="modal" data-bs-target="#draftrejectedModal" >Reject</span>
                                                                        </div>
                                                                    </>
                                                                    }
                                                                    </div>
                                                                }

                                                                {data.mark_final == 3  && 
                                                                <>

                                                                {(opportViewData != '' && user_role == 'SD User' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                                    <>
                                                                        <div className='col-md-1' >
                                                                        <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                                        </div>
                                                                    </>
                                                                    }

                                                                    {(opportViewData != '' && user_role == 'SD Head' && opportViewData.data.LPSPermissionCount == 3) &&
                                                                    <>
                                                                        <div className='col-md-1' >
                                                                        <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                                        </div>
                                                                    </>
                                                                    }

                                                                </>
                                                                }
                                                            
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-md-12" style={{marginTop:'12px'}}>
                                                        <div className="row">
                                                        {data.SolutionImg.map((data, index) => {
                                                            let ext = getExtension(data.img);
                                
                                                            return(
                                                            <>
                                                            {(opportViewData != '' && (user_role == 'SD Head' || opportViewData.data.opportunity_owner_id == usr_id) && data.file_type == 'ref') && 
                                                            <div className="col-md-3">
                                                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                                                                </svg> : {data.time}</h6>
                                                                { (ext == 'png' || ext == 'jpg' || ext == 'jpeg') ?
                                                                <Link to={data.img_url} target="_blank"><img  src={data.img_url} key={data.draft_id} alt="logo" style={{height:'60px', width:'60px', marginTop:"5px"}}/></Link> :
                                                                <Link to={data.img_url} target="_blank"><img  src='../../ulms_assets/images/doc.png' key={data.draft_id} alt="logo" style={{height:'60px', width:'60px', marginTop:"5px"}}/></Link>
                                                                }
                                                                <div>{data.img}( {data.file_type} )</div> 
                                                                
                                                            </div>
                                                            }
                                                            { data.file_type != 'ref' &&
                                                            <div className="col-md-3">
                                                             <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                                                                </svg> : {data.time}</h6>
                                                            { (ext == 'png' || ext == 'jpg' || ext == 'jpeg') ?
                                                                <Link to={data.img_url} target="_blank"><img  src={data.img_url} key={data.draft_id} alt="logo" style={{height:'60px', width:'60px'}}/></Link> :
                                                                <Link to={data.img_url} target="_blank"><img  src='../../ulms_assets/images/doc.png' key={data.draft_id} alt="logo" style={{height:'60px', width:'60px', marginTop:"5px"}}/></Link>
                                                                }
                                                                <div>{data.img} ( {data.file_type} )</div>
                                                            </div>
                                                            }
                                                            </>)
                                                        })} 
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                            ))}  
                                          </div> 
                                        </form>
                                        <div className='row' style={{marginTop: '15px'}}>
                                            <div className="col-md-5" controlid="formFileMultiple" >
                                                <Form.Label>Opportunity Related Reference File (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                                                <Form.Control multiple type="file"  
                                                ref={aRef}  
                                                name="opportunity_photo_web"
                                                onChange={handleFileInput}
                                                />
                                            </div>
                                            <div className="col-md-2" style={{marginTop: '26px'}}>
                                                <button type="submit" className="btn btn-primary" onClick={handleRefFileSubmit} >Upload</button>
                                            </div>  
                                            <div className="col-md-5" style={{float:'right'}}>
                                                <a className="nav-link nav-link-notify" data-count={opportViewData != '' && opportViewData.data.QueryCount} ><img src="/ulms_assets/images/chat_icon.png" alt="icon" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'70px', width:'75px', float:'right'}} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* transportation tab */}

                                <div className="tab-pane fade" id="transportation" role="tabpanel" aria-labelledby="transportation-tab">
                                    <div className="invoice row">
                                        <TransportLeadDetail />
                                    </div>

                                    <TransportForm lead_id={lead_id}/>
                                    <div className='row' style={{marginTop: '15px'}}>
                                        <div className="col-md-5" controlid="formFileMultiple" >
                                            <Form.Label>Opportunity Related Reference File (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                                            <Form.Control multiple type="file"  
                                            ref={aRef}  
                                            name="opportunity_photo_web"
                                            onChange={handleFileInput}
                                            />
                                        </div>
                                        <div className="col-md-2" style={{marginTop: '26px'}}>
                                            <button type="submit" className="btn btn-primary" onClick={handleRefFileSubmit} >Upload</button>
                                        </div>  
                                        <div className="col-md-5" style={{float:'right'}}>
                                            <a className="nav-link nav-link-notify" data-count={opportViewData != '' && opportViewData.data.QueryCount} ><img src="/ulms_assets/images/chat_icon.png" alt="icon" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'70px', width:'75px', float:'right'}} /></a>
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* end transport tab */}

                                {/* commercial tab */}

                                <div className="tab-pane fade" id="commercial" role="tabpanel" aria-labelledby="commercial-tab">
                                    <CommercialLeadDetail/>
                                    {opportViewData != '' && opportViewData.data.products == 'RM' && opportViewData.data.service_type == 'RP' ?
                                     <Commercial lead_id={lead_id}/> :
                                     <CommercialUpload/>
                                    }
                                </div>

                                {/* commercial tab end */}
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div> 

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                {chatmessage  &&  <div className="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>{chatmessage}</strong>
                    </div>}
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"> CHAT </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="chat-content empty-chat-wrapper col-md-12" style={myComponent}>
                            <div className="card-title">
                            </div>

                            {opportViewData != '' && opportViewData.AdditionInfo.map((chatdata) => (  
                            <div>
                                {chatdata.UserId != usr_id  &&
                                <div className="messages" style={{ float:'right', width:'100%'}}>
                                    <div className="message-item col-md-12">
                                        <span className="time small text-muted font-italic" >{chatdata.UserName}</span>
                                        <div className='row'>
                                          <div className="alert alert-primary col-md-9" style={{marginLeft:'10px'}}>{chatdata.LeadQuery}</div>
                                          <span className="time small text-muted font-italic col-md-2">{chatdata.Created} </span>
                                        </div>
                                    </div>
                                </div>
                                }
                                {chatdata.UserId == usr_id  &&
                                <div className="messages" style={{ float:'left', width:'100%'}}>
                                    <div className="message-item col-md-12" style={{ whiteSpace: 'pre-wrap'}}>
                                        <span className="time small text-muted font-italic " style={{float:'right', width:'100%', textAlign:'right', marginRight:'10px'}}>{chatdata.UserName}</span>
                                        <div className="alert alert-success col-md-9" style={{float: 'right', whiteSpace: 'pre-wrap', marginRight: '10px'}}>{chatdata.LeadQuery}</div>
                                        <span className="time small text-muted font-italic col-md-2">{chatdata.Created} </span>
                                    </div>
                                </div>
                                }                            
                            </div>
                            ))}  

                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="chat-footer col-md-12">
                            <form className="d-flex col-md-12" onSubmit={handleChatSubmit} >
                                <button className="btn btn-primary btn-rounded flex-shrink-0" type="button" style={{marginRight:'5px', marginBottom:'5px'}}>
                                    {/* <i className="bi bi-three-dots"></i> */}
                                </button>
                                <Form.Control 
                                    type="text" 
                                    className="form-control" autoFocus 
                                    placeholder="Write message..."
                                    name='query'
                                    value={queryCreate.query}
                                    onChange={handleFormInput}
                                />
                                <button className="btn btn-primary btn-rounded flex-shrink-0 ms-3" type="submit" >Send</button>
                            </form>
                        </div>
                    </div>     
                </div>
            </div>
        </div>  
        { show == true &&
            <div className="modal fade" id="assigntosdmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{usermessage}</strong>
                    </div>} */}
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Lead Allocate To SD Team</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={handleSDFormSubmit}>
                    <div className="modal-body">
                        <div className="col-md-6">
                            <label htmlFor="inputState" className="form-label"> Select SD User : </label> 
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="bd_user"
                                options={sdUserListData}
                                onChange={handleSDUserInput}
                            />
                        </div> 
                        <div className="col-md-4" style={{marginTop: '5px'}}>
                            <Form.Label>Expected Closure Date:</Form.Label>
                            <Form.Control
                            type="date"
                            name="expected_close_date"
                            value={assignSDTeam.expected_close_date}
                            onChange={handleSDFormInput}
                            id="expected_close_date"
                            className="form-control"  />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        }
        { show == true &&
            <div className="modal fade" id="leadReallocateModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{usermessage}</strong>
                    </div>} */}
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Lead Re-allocate To SD Team</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={handleReassignSDSubmit}>
                    <div className="modal-body">
                        <div className="col-md-6">
                            <label htmlFor="inputState" className="form-label"> Select SD User : </label> 
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="bd_user"
                                options={sdUserListData}
                                onChange={handleSDUserInput}
                            />
                        </div> 
                        <div className="col-md-4" style={{marginTop: '5px'}}>
                            <Form.Label>Expected Closure Date:</Form.Label>
                            <Form.Control
                            type="date"
                            name="expected_close_date"
                            value={assignSDTeam.expected_close_date}
                            onChange={handleSDFormInput}
                            id="expected_close_date"
                            className="form-control"  />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        }
         { show == true &&
            <div className="modal fade" id="fileUploadModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{usermessage}</strong>
                    </div>} */}
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Upload {draftIdData} Related Files</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={handleExtraFileSubmit}>
                    <div className="modal-body">
                        <div className="col-md-12">  
                            <Form.Label style={{marginRight: '15px'}} >Select File Type : <span className="required-mark">*</span></Form.Label>
                            <Form.Check
                                inline
                                label="New Trial File"
                                name="file_type"
                                value="1"
                                type= "radio"
                                onChange={getFormData}
                                defaultChecked
                                id={`inline-radio-1`}
                            />
                            <Form.Check
                                inline
                                label="Same Trial File"
                                name="file_type"
                                value="2"
                                type= "radio"
                                onChange={getFormData}
                                id={`inline-radio-2`}
                            />
                        </div>
                        {solFileCheck == 0 && 
                        <div className="col-md-8" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight:'bold'}}>Upload Solution File :</Form.Label>
                            <Form.Control type="file"
                            ref={cRef}    
                            name="opportunity_photo_web"
                            onChange={handleSolFileChange}
                            />
                        </div> }
                        {bomFileCheck == 0 && 
                        <div className="col-md-8" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight:'bold'}}>Upload BOM File :</Form.Label>
                            <Form.Control type="file"
                            ref={cRef}    
                            name="opportunity_photo_bom"
                            onChange={handleBomFileChange}
                            />
                        </div> }
                        <div className="col-md-8" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight:'bold'}}>Upload Related Ref File :</Form.Label>
                            <Form.Control type="file"
                            ref={cRef}    
                            multiple
                            name="opportunity_photo_ref"
                            onChange={handleRefFileChange}
                            />
                        </div>
                        <div className="col-md-8" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight:'bold'}}>Upload Trial File :</Form.Label>
                            <Form.Control type="file"
                            ref={cRef}    
                            multiple
                            name="opportunity_photo_trial"
                            onChange={handleTrialFileChange}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Upload</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        }
        { show == true &&
            <div className="modal fade" id="CompUploadModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Upload Component Related Files</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={componentFileSubmit}>
                    <div className="modal-body">
                        <div className="col-md-8" controlid="formFileMultiple" >
                            <Form.Label>Component Related Solution File:</Form.Label>
                            <Form.Control multiple type="file"  
                            ref={dRef}   
                            name="opportunity_photo_web"
                            onChange={handleFileInput}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Upload</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        }
        { show == true &&
            <div className="modal fade" id="CompImageViewModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Component Related Files</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                    <div className="modal-body">
                        <div className='row'>
                            {compImageData != '' && compImageData.comp_img.map((data, index) => (
                                <div className="col-md-2">
                                <Link to={data.img_url} target="_blank"><img  src="/ulms_assets/images/thumb.webp" alt="logo" style={{height:'40px', width:'40px'}}/></Link>
                                </div>
                            ))}
                            {compImageData == '' &&
                                <div className="col-md-5">
                                    No Document Available
                                </div>
                            }   
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
              </div>
            </div>
        }
        <div className="modal fade" id="draftrejectedModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Draft Rejected Reason</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="col-md-8" controlid="formFileMultiple" >
                        <Form.Label>Enter Rejected Remark:</Form.Label>
                        <Form.Control multiple type="text"  
                        name="reject_remark"
                        value={rejectedDraftData.reject_remark}
                        onChange={RejectedRemarkDraft}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    <button className="btn btn-primary" onClick={() => { handleRejectedRemark(); handleCloseModal(); }} >Submit</button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="deleteremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Reference File Remark</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <Form onSubmit={handleDocDeleteForm}>
                        <div className="modal-body">
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>Enter Delete Remark:</Form.Label>
                                <Form.Control type="text"  
                                name="remark"
                                value={deleteLeadFile.remark}
                                onChange={handleRemarkInput}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        { show == true &&
            <div className="modal fade" id="LeadAssignModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>{usermessage}</strong>
                        </div>} */}
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> Allocate Lead </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form className="row g-3" onSubmit={handleLeadReAssignForm}>
                        <div className="modal-body">
                            <div className="col-md-6">
                                <label htmlFor="inputState" className="form-label"> Select BD User : </label> 
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="bd_user"
                                    options={bdUserListData}
                                    onChange={handleLeadSourceInput}
                                />
                            </div> 
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        }
        <div className="modal fade" id="deleteleadremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Lead Delete Remark</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleLeadDelForm}>
                        <div className="modal-body"> 
                            <div className="col-md-8" controlid="formFileMultiple" >
                                <Form.Label>Enter Delete Remark:</Form.Label>
                                <Form.Control multiple type="text"  
                                name="remark"
                                value={deleteLead.remark}
                                onChange={handleLeadRemarkInput}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="modal fade" id="closeOpportunitymodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Opportunity Status Update</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <Form onSubmit={handleOppCloseForm}>
                        <div className="modal-body">
                            <div className="col-md-6" style={{marginTop:'5px'}}>
                                <label htmlFor="inputState" className="form-label"> Select Status : </label> 
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="bd_user"
                                    options={statusArr}
                                    onChange={handleOppStatusInput}
                                />
                            </div> 
                            <div className="col-md-6" style={{marginTop:'8px'}}>
                                <label htmlFor="inputState" className="form-label"> Select Reason : </label> 
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="opp_close_remark"
                                    options={reasonArr}
                                    onChange={handleReasonInput}
                                />
                            </div> 
                            {(otherReason === 'Others' || otherReason === 'Lost to Competition') &&
                            <div className="col-md-8" style={{marginTop:'8px'}}>
                                <Form.Label>Enter Other Close Remark :</Form.Label>
                                <Form.Control type="text"  
                                name="other_close_remark"
                                value={oppClose.other_close_remark}
                                onChange={handleCloseRemark}
                                />
                            </div> }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <div className="modal fade" id="extendClosuredatemodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Extend Opportunity Closure Date</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <Form onSubmit={handleOppClosureDateForm}>
                        <div className="modal-body">
                            <div className="col-md-6" style={{marginTop:'5px'}}>
                                <label htmlFor="inputState" className="form-label"> Select Date : </label> 
                                <Form.Control
                                    type="date"
                                    name="expected_closure_date"
                                    value={oppClosureDate.expected_closure_date}
                                    onChange={handleClosureDateInput}
                                    id="expected_closure_date"
                                    className="form-control"  />
                            </div> 
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>

    </>
  )
}

export default OpportunityView