import React from 'react';
import { ulmsUrl } from '../Api';
import {  BrowserRouter as Router, Link, Route, Routes, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {

    const [email,setEmail] = React.useState('');
    const [usermessage, setMessage] = React.useState(null);

    const navigate = useNavigate();
    const resetpassurl = '/user/forgot_password';

    console.log(JSON.stringify({email}));

    const handleReset = async () => {
        // alert(email);
         console.warn("email,password", email);
         
 
         let result = await fetch(ulmsUrl.concat(resetpassurl),{
             method:'post',
             body:JSON.stringify({email}),
             headers: {
             'Content-Type': 'application/json'
             }
         });
 
         result = await result.json();
         console.log(result)
        
         if(result.status == true){
            setMessage(result.message)
            // navigate('/');            
     
         } else if (result.status == false) {
             setMessage(result.message)
             //alert("Please enter correct email");
         }
    }


  return (
    <>
        <div className="auth">
            <div className="form-wrapper">
                <div className="container">
                    <div className="card">
                        <div className="row g-0">
                            <div className="col">
                                <div className="row align-items-center">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="d-block d-lg-none text-center text-lg-start">
                                            <img width="120" src="https://vetra.laborasyon.com/assets/images/logo.svg" alt="logo" />
                                        </div>
                                        {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <strong>{usermessage}</strong>
                                        </div>}
                                        <div className="my-5 text-center text-lg-start">
                                            <h1 className="display-8 mb-3">Reset Password</h1>
                                            <p className="text-muted">Type and send the email address to reset your password.</p>
                                        </div>
                                       
                                            <div className="mb-3">
                                                <input 
                                                name="email" 
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                className="form-control" 
                                                placeholder="Enter email" 
                                                autoFocus
                                                required  />
                                            </div>
                                            <div className="text-center text-lg-start">
                                                <button className="btn btn-primary mb-4" type='submit' onClick={handleReset} >Send</button>
                                            </div>
                                      
                                        <p className="text-center d-block d-lg-none mt-4 mt-lg-0">
                                            You can now <a href="login.html">sign in</a> or <a href="#">create an
                                                account</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-none d-lg-flex border-start align-items-center justify-content-between flex-column text-center">
                                <div className="logo">
                                    <img width="120" src="ulms_assets/images/sdmlogo.png" alt="logo" style={{width:'100%'}}/>
                                </div>
                                <div>
                                    <h3 className="fw-bold">Welcome to SDMS!</h3>
                                    {/* <p className="lead my-5">If you don't have an account, would you like to register right now?</p> */}
                                    {/* <a href="#" className="btn btn-primary">Sign Up</a> */}
                                </div>
                                {/* <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="#">Privacy Policy</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#">Terms & Conditions</a>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ForgotPassword