import React from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ulmsUrl,holibookUrl, username, password } from '../../Api';
import { LocationData, ComponentFamily } from '../../apidata/fetchapi';
import { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { confirmAlert } from "react-confirm-alert";
import Select from 'react-select';
import { encode } from "base-64";
import { DatePicker } from 'antd';
import Loader from '../../loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SolutionDevelopment = () => {

    const { locationOptions }  = LocationData();
    const { comFamOptions }    = ComponentFamily();

    let base64 = require('base-64');
    const aRef = useRef(null);
    const bRef = useRef(null);
    const cRef = useRef(null);
    const dRef = useRef(null);
    const eRef = useRef(null);
    const fRef = useRef(null);

    const wipuploadurl = "/wip/upload";
    const solmarkfinalurl = "/wip/mark_final";
    const querycreateurl   = "/opportunity/additional_query";
    const leadallocatesdurl = '/sdm_lead/assignment_to_designteam';
    const leadreallocatesdurl = '/sdm_lead/reassign_to_solutionteam';
    const leadallocateurl = '/sdm_lead/reassign_to_bdteam';

    const navigate = useNavigate();
    const [lead_id, setLeadId] = useState(base64.decode(useParams().id));
    const usr_token = localStorage.getItem('authToken');
    const usr_name = localStorage.getItem('name');
    const usr_id   = localStorage.getItem('usrId');
    const user_role  = localStorage.getItem('usrRole');

    const initialData = {
        "lead_id": lead_id,
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const leadAssignData   = {
        "lead_id": "",
        "lead_owner": "",
        "usr_token": usr_token,
    }

    const initialQueryData = {
        //"lead_id": "",
        "query": "",
        "usr_token": usr_token,
    }

    const initialSDData   = {
        "lead_id": "",
        "opportunity_owner": "",
        "expected_close_date": "",
        "usr_token": usr_token,
    }

    const initialRefData = {
        "stage":"2",
        "lead_id": lead_id,
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "usr_token": usr_token,
    }

    const initialDraftData = {
        "lead_id": lead_id,
        "draft_id" : "",
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "img_data_app": [], 
        "usr_token": usr_token,
    }

    const initialCompData = {
        "lead_id": lead_id,
        "comp_id" : "",
        "solution_creater_id": usr_id,
        "solution_creater_name": usr_name,
        "img_data_app": [], 
        "usr_token": usr_token,
    }

    const initialrejectedDraftData = {
        "draft_id" : "",
        "lead_id": lead_id,
        "status": 3,
        "reject_remark": "",
        "usr_token": usr_token,
    }

    const initialdeleteData = {
        "lead_id": lead_id,
        "file_id": "",
        "remark": "",
        "usr_token": usr_token,
    }

    const [queryCreate, setQuerycreate] = React.useState(initialQueryData);
    const [opportunityCreate, setOpportunitycreate] = React.useState(initialData);
    const [opportViewData, setOpportViewData] = React.useState([""]);
    const [usermessage, setMessage] = React.useState(null);
    const [chatmessage, setchatMessage] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [expandselected, setExpandSelected] = React.useState(0);
    const [isFinalImg, setIsFinal] = React.useState(0);
    const [show, setShow] = React.useState(false);
    const [assignSDTeam, setAssignSDTeam] = React.useState(initialSDData);
    const [sdUserListData, setSDUserListData] = React.useState([""]);
    const [refUploadData, setRefUploadData]   = React.useState(initialRefData);
    const [draftUploadData, setDraftUploadData]   = React.useState(initialDraftData);
    const [compUploadData, setCompUploadData]   = React.useState(initialCompData);
    const [compImageData, setCompImageData]   = React.useState("");
    const [draftIdData, setDraftIdData]   = React.useState("");
    const [rejectedDraftData, setRejectedDraftData]   = React.useState(initialrejectedDraftData);
    const [loading, setLoading]           = React.useState(false);
    const [solFile1, setSolFile] = useState(null);
    const [bomFile, setBomFile] = useState(null);
    const [refFile, setRefFile] = useState(null);
    const [solFileCheck, setSolFileCheck] = useState(null);
    const [bomFileCheck, setBomFileCheck] = useState(null);
    const [delDocFileId, setDelDocFileId]       = React.useState('');
    const [deleteLeadFile, setDeleteLeadFile]   = React.useState(initialdeleteData);
    const [bdUserListData, setBDUserListData]   = React.useState([""]);
    const [assignLeads, setAssignLead]          = React.useState(leadAssignData);

    const getOpportViewData = async () => {
        setLoading(true);
      const arr = [];
      try {
          fetch(ulmsUrl.concat('/wip/lead_details') , {
              method: 'POST',
              body: JSON.stringify({lead_id, usr_token}),
              headers: {
                  'Content-Type': 'application/json',
              }
          })
          .then((response) =>
              response.json()
          )
          .then((response) => {  
              setLoading(false);
              let result = response;   
              setOpportViewData(result)
          });
      } catch (error) {
          console.log(error);
      }
    }

    const getCompImageData = async (data) => {

        let comp_id = data;

        if(comp_id != ''){
            try {
                fetch(ulmsUrl.concat('/wip/view_component_file') , {
                    method: 'POST',
                    body: JSON.stringify({lead_id, comp_id, usr_token}),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response;   
                    setCompImageData(result.data)
                });
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
      getOpportViewData();
      getCompImageData();
    }, []);

    console.log('CompImag',opportViewData)

    const expand = async () => {    
        
        console.log('expend',expandselected);
        if(expandselected == 1){
            setExpandSelected(0);
        }else{
            setExpandSelected(1);
        }
    }

    const handleFileInput = (e) => {
      console.log(e.target.files); 
      setSelectedFile(e.target.files)
      console.log(selectedFile);
    };

    const handleSolFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setSolFile(selectedFile);
    };
    
    const handleBomFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setBomFile(selectedFile);
    };

    const handleRefFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setRefFile(selectedFile);
    };

    const handleFormSubmit = async (e) => {
     
      e.preventDefault();

        console.log(JSON.stringify(initialData))

        if(solFile1 || bomFile || refFile)
        {
            setLoading(true);
            
            const formData = new FormData();
            formData.append('opportunity_photo_web', solFile1);
            formData.append('opportunity_photo_bom', bomFile);
            formData.append('opportunity_photo_ref', refFile);
            
            formData.append('data', JSON.stringify(initialData));
            console.log(formData);  
    
            fetch(ulmsUrl.concat(wipuploadurl), {
                method: 'POST',
                body: formData,
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {
                console.log(result)
                if (result.status == true) {
                    setLoading(false);
                    bRef.current.value = null;
                    setOpportunitycreate(initialData);
                    setSolFile('');
                    setBomFile('');
                    setRefFile('');
                    toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                    getOpportViewData();
                    //sessionStorage.setItem("message", result.message);
                    navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
    
                } else if (result.status == false) {
                    setLoading(false);
                    setOpportunitycreate(opportunityCreate);
                    toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                
                }
            })
            .catch((form_err) => {
                //console.log(form_err)
            })


        }else{

            toast.error("Please upload at least one file.", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
        }

    }


    const handledraftId = (draftid) => {

        setRejectedDraftData(oldValue => ({
            ...oldValue,
            "draft_id": draftid,
        }))
    };

    const RejectedRemarkDraft = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setRejectedDraftData(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    };

    const handleRejectedRemark = async() => {

        console.log('rejected',JSON.stringify(rejectedDraftData));
        setLoading(true);
        
          fetch(ulmsUrl.concat(solmarkfinalurl), {
          method: 'POST',
          body: JSON.stringify(rejectedDraftData),
          headers: {
              'Content-Type': 'application/json',
          }
          })
          .then((result) =>
              result.json()
          )
          .then((result) => {
              console.log(result)
              if (result.status == true) {
                  toast.success(result.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      toastId: 'success1',
                  });
                  getOpportViewData();
                  setLoading(false);
                  //sessionStorage.setItem("message", result.message);
                  navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
  
              } else if (result.status == false) {
                  toast.error(result.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      toastId: 'success1',
                  });
              
              }
          })
          .catch((form_err) => {
              //console.log(form_err)
          })
    };

    const confirmdialog = (data, status) => {

        let msg = '';
        if(status == 1){
            msg = "Are you sure you want to final this draft?";
        }else if(status == 3){
            msg = "Are you sure you reject this draft?";
        }

        confirmAlert({
            title: "Confirm to submit",
            message: msg,
            buttons: [
              {
                label: "Yes",
                onClick: () => handleMarkFinlClick(data, status)
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const handleMarkFinlClick = async(data, status) => {

      console.log(status);
      
        let draft_id = data;
        let reject_remark = '';

        console.log(JSON.stringify({ draft_id,lead_id, status, reject_remark, usr_token }));

        fetch(ulmsUrl.concat(solmarkfinalurl), {
        method: 'POST',
        body: JSON.stringify({ draft_id, lead_id, status, reject_remark, usr_token }),
        headers: {
            'Content-Type': 'application/json',
        }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });

            } else if (result.status == false) {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        

    };

    const handleRemarkInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setDeleteLeadFile(oldValue => ({
                ...oldValue,
                [name]: value,
                "file_id": delDocFileId
            }))
        }
    }; 

    const handleDocDeleteForm = async (e) => {
        setLoading(true);
        e.preventDefault();
    
       console.log(JSON.stringify(deleteLeadFile))
    
        fetch(ulmsUrl.concat('/files/del_ref_file'), {
            method: 'POST',
            body: JSON.stringify(deleteLeadFile),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setDeleteLeadFile('')
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setDeleteLeadFile(deleteLeadFile)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        
    }

    const sendToSDHead = async() => {
        setLoading(true);
        console.log(JSON.stringify({ lead_id, usr_token }));

        fetch(ulmsUrl.concat('/wip/solution_for_approval'), {
        method: 'POST',
        body: JSON.stringify({ lead_id, usr_token }),
        headers: {
            'Content-Type': 'application/json',
        }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            setLoading(false);
            console.log(result)
            if (result.status == true) {
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });

            } else if (result.status == false) {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const sendToBDHead = async() => {
        setLoading(true);
        console.log(JSON.stringify({ lead_id, usr_token }));

        fetch(ulmsUrl.concat('/wip/send_to_bd_for_approval'), {
        method: 'POST',
        body: JSON.stringify({ lead_id, usr_token }),
        headers: {
            'Content-Type': 'application/json',
        }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            setLoading(false);
            console.log(result)
            if (result.status == true) {
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });

            } else if (result.status == false) {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const sendToClient = async() => {
        setLoading(true);
        console.log(JSON.stringify({ lead_id, usr_token }));

        fetch(ulmsUrl.concat('/wip/send_to_client_for_approval'), {
        method: 'POST',
        body: JSON.stringify({ lead_id, usr_token }),
        headers: {
            'Content-Type': 'application/json',
        }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            setLoading(false);
            console.log(result)
            if (result.status == true) {
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });

            } else if (result.status == false) {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }
 

    const handleFormInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setQuerycreate(oldValue => ({
                ...oldValue,
                [name]: value,
                "lead_id": opportViewData.data.id, 
            }))
        }
    };

    const handleChatSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
    
       console.log(JSON.stringify(queryCreate))
    
        fetch(ulmsUrl.concat(querycreateurl), {
            method: 'POST',
            body: JSON.stringify(queryCreate),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                setQuerycreate(initialQueryData)
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
    
            } else if (result.status == false) {
                setLoading(false);
                setQuerycreate(queryCreate)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
        
    }

    const confirmcopy = (data) => {

        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure you want to copy this lead ?",
            buttons: [
              {
                label: "Yes",
                onClick: () => leadCopy(data)
              },
              {
                label: "No"
                // onClick: () => alert("Click No")
              }
            ]
        });
    }

    const leadCopy = async(id) => {

        let lead_id = id;

        try { 
            fetch(ulmsUrl.concat('/leads/copy') , {
                method: 'POST',
                body: JSON.stringify({lead_id,usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {  
                if (result.status == true) {
                    sessionStorage.setItem("createmessage", result.message);
                    navigate("/all_leads", { replace: true })
    
                } else if (result.status == false) {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: 'success1',
                    });
                
                }
                
            });
        } catch (error) {
            console.log(error);
        }
        
    }

    const handleLeadSourceInput = (value) => { 
        setAssignLead(oldValue => ({
            ...oldValue,
            "lead_owner": value,
        }))  
    };

    const leadAssign = async(id,project_type,products,service_type) => {

        setShow(true);
       
        setAssignLead(oldValue => ({
          ...oldValue,
          "lead_id": id,
        }))
  
        console.log(JSON.stringify({service_type,project_type,products,usr_token}));
        const arr = [];
        try { 
            fetch(ulmsUrl.concat('/user/bd_user_dropdown') , {
                method: 'POST',
                body: JSON.stringify({service_type,project_type,products,usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                result?.map((data) => {
                    return arr.push({ value: data.id, label: data.value });
                });
                setBDUserListData(arr)
            });
        } catch (error) {
            console.log(error);
        }
          
    }

    const leadAssignSd = async(id,project_type,products,service_type) => {
      
        setShow(true);
       
        setAssignSDTeam(oldValue => ({
          ...oldValue,
          "lead_id": id,
        }))
    
        console.log(JSON.stringify({service_type,project_type,products,usr_token}));
        const arr = [];
        try { 
            fetch(ulmsUrl.concat('/user/sd_user_dropdown') , {
                method: 'POST',
                body: JSON.stringify({service_type,project_type,products,usr_token}),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                result?.map((data) => {
                    return arr.push({ value: data.id, label: data.value });
                });
                setSDUserListData(arr)
            });
        } catch (error) {
            console.log(error);
        }
          
    }

    const handleLeadReAssignForm = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        fetch(ulmsUrl.concat(leadallocateurl), {
            method: 'POST',
            body: JSON.stringify(assignLeads),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setAssignLead(initialData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                getOpportViewData();
                setShow(false);
                setLoading(false);
                //sessionStorage.setItem("createmessage", result.message);
  
            } else if (result.status == false) {
                setLoading(false);
                setAssignLead(assignLeads)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    useEffect(() => {
        leadAssign();
        leadAssignSd();  
    }, []);

    const handleSDUserInput = (value) => {
        setAssignSDTeam(oldValue => ({
            ...oldValue,
            "opportunity_owner": value,
        }))  
    }

    const handleSDFormInput = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setAssignSDTeam(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }
    };

    console.log(JSON.stringify(assignSDTeam))

    const handleSDFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        console.log(JSON.stringify(assignSDTeam))
    
        fetch(ulmsUrl.concat(leadallocatesdurl), {
            method: 'POST',
            body: JSON.stringify(assignSDTeam),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setLoading(false);
                setAssignSDTeam(initialSDData);
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
                setShow(false);
                getOpportViewData();
                //sessionStorage.setItem("createmessage", result.message);
                //navigate("/all_leads", { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setAssignSDTeam(assignSDTeam)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const handleReassignSDSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        console.log(JSON.stringify(assignSDTeam))
    
        fetch(ulmsUrl.concat(leadreallocatesdurl), {
            method: 'POST',
            body: JSON.stringify(assignSDTeam),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setLoading(false);
                setAssignSDTeam(initialSDData);
                toast.success(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        toastId: 'success1',
                });
                setShow(false);
                getOpportViewData();
                //sessionStorage.setItem("createmessage", result.message);
                //navigate("/all_leads", { replace: true })
    
            } else if (result.status == false) {
                setLoading(false);
                setAssignSDTeam(assignSDTeam)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const handleRefFileSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        console.log(JSON.stringify(refUploadData))
  
        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`fileTest`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(refUploadData));
        console.log(formData);  
  
        fetch(ulmsUrl.concat("/wip/ref_file"), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLoading(false);
                aRef.current.value = null;
                setRefUploadData(refUploadData);
                toast.success(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
  
            } else if (result.status == false) {
                setLoading(false);
                setRefUploadData(refUploadData);
                toast.error(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const CompDataUpload = (data) => {
       
        setCompUploadData(oldValue => ({
            ...oldValue,
            "comp_id" : data
        }))
    }

    const componentFileSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
  
        console.log(JSON.stringify(compUploadData))
  
        const formData = new FormData();
        for(let i = 0; i < selectedFile.length; i++){
            formData.append(`fileTest`, selectedFile[i]);
        }
        
        formData.append('data', JSON.stringify(compUploadData));
        console.log(formData);  
  
        fetch(ulmsUrl.concat("/wip/component_file"), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            if (result.status == true) {
                setLoading(false);
                dRef.current.value = null;
                setCompUploadData(compUploadData);
                toast.success(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
                getOpportViewData();
                //sessionStorage.setItem("message", result.message);
                navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
  
            } else if (result.status == false) {
                setLoading(false);
                setCompUploadData(compUploadData);
                toast.error(result.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  toastId: 'success1',
                });
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })
    }

    const DraftDataUpload = (data) => {
        console.log(data);
        setDraftIdData(data.draft);
        setSolFileCheck(data.sol);
        setBomFileCheck(data.bom);

        setDraftUploadData(oldValue => ({
            ...oldValue,
            "draft_id" : data.draft_id
        }))
    }

    const handleExtraFileSubmit = async (e) => {
        e.preventDefault();
  
        console.log(JSON.stringify(draftUploadData))

        if(solFile1 || bomFile || refFile)
        {         
            setLoading(true);

            const formData = new FormData();
            formData.append('opportunity_photo_web', solFile1);
            formData.append('opportunity_photo_bom', bomFile);
            formData.append('opportunity_photo_ref', refFile);
            
            formData.append('data', JSON.stringify(draftUploadData));
            console.log(formData);  
    
            fetch(ulmsUrl.concat("/wip/ref_sol_file"), {
                method: 'POST',
                body: formData,
            })
            .then((result) =>
                result.json()
            )
            .then((result) => {
                console.log(result)
                if (result.status == true) {
                    setLoading(false);
                    cRef.current.value = null;
                    setSolFile('');
                    setBomFile('');
                    setRefFile('');
                    toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                    getOpportViewData();
                    //sessionStorage.setItem("message", result.message);
                    navigate(`/solution_development/${base64.encode(lead_id)}`, { replace: true });
    
                } else if (result.status == false) {
                    setLoading(false);
                    setDraftUploadData(draftUploadData);
                    toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                    });
                
                }
            })
            .catch((form_err) => {
                //console.log(form_err)
            })

        }else{

            toast.error("Please upload at least one file.", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: 'success1',
            });
        }
    }

    function handleCloseModal(){            
        document.getElementById("leadReallocateModal").classList.remove("show", "d-block");
        document.getElementById("fileUploadModal").classList.remove("show", "d-block");
        document.getElementById("assigntosdmodal").classList.remove("show", "d-block");
        document.getElementById("CompUploadModal").classList.remove("show", "d-block");
        document.getElementById("draftrejectedModal").classList.remove("show", "d-block");
        document.getElementById("deleteremarkModal").classList.remove("show", "d-block");
        document.getElementById("LeadAssignModal").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop")
        .forEach(el => el.classList.remove("modal-backdrop"));
        document.body.style.overflow = 'unset';
    }

    const myComponent = {
        height: '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
    };

  
    if(usermessage != '' || chatmessage != ''){
        setTimeout(() => {
        setMessage(null);
        setchatMessage(null);
        }, 800);    
    }

    function getExtension(filename) {
        return filename.split('.').pop()
    }
 
  return (
    <>
  {/* {usermessage && <div className="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{usermessage}</strong>    
        </div>} */}
        <ToastContainer />
        {loading && (
            <>
            <Loader/>
            </>
        ) }
        <div className="row">
            <div className="order-2 order-lg-1 col-lg-12 bd-content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">SOLUTION DEVELOPMENT ( LEAD ID : {lead_id} )</h3>
                        <div className='All_three_button'>
                        {opportViewData != '' && (opportViewData.data.leadreassignbutton == 2) &&
                            <button className="btn btn-primary btn-sm  me-2" id="re-assign" 
                            style={{font_size: '6px', float: 'right'}}
                            onClick={()=>leadAssign(opportViewData.data.id,opportViewData.data.project_type,opportViewData.data.products,opportViewData.data.service_type)} 
                            data-bs-toggle="modal" data-bs-target="#LeadAssignModal"  >Lead Re-assign To BD</button> 
                        }
                        {opportViewData != '' && (opportViewData.data.button == 4) &&
                            <button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right'}} onClick={()=>leadAssignSd(opportViewData.data.id,opportViewData.data.project_type,opportViewData.data.products,opportViewData.data.service_type)}  data-bs-toggle="modal" data-bs-target="#assigntosdmodal" >Opp Assign To SD</button>  
                        }
                        {opportViewData != '' && (opportViewData.data.reassign == 4) &&
                            <button className="btn btn-primary btn-sm  me-2" style={{font_size: '6px', float: 'right'}} onClick={()=>leadAssignSd(opportViewData.data.id,opportViewData.data.project_type,opportViewData.data.products,opportViewData.data.service_type)}  data-bs-toggle="modal" data-bs-target="#leadReallocateModal" >Opp Re-assign To SD</button>  
                        }
                        {opportViewData != '' && (opportViewData.data.status > 2 && opportViewData.data.lead_owner_id == usr_id) && 
                           <button style={{font_size: '6px', float: 'right'}} type="button" onClick={()=>confirmcopy(opportViewData.data.id)} className="btn btn-primary btn-sm  me-2"><strong>Copy Lead</strong></button>   
                          }
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="invoice row">
                          <div style={{float: 'left'}} className="table-scrollable col-md-6">
                              <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                  <thead className="thead-light">
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <th>LOB</th>
                                          <td >{opportViewData != '' && opportViewData.data.project_type}</td>
                                      </tr>
                                      <tr>
                                          <th>Product Type</th>
                                          <td>{opportViewData != '' && opportViewData.data.products}</td>
                                      </tr>
                                      <tr>
                                          <th>Service Type</th>
                                          <td>{opportViewData != '' && opportViewData.data.service_type}</td>
                                      </tr>
                                      <tr>
                                          <th>Start Date</th>
                                          <td >{opportViewData != '' && opportViewData.data.start_date}</td>
                                      </tr>
                                      <tr>
                                          <th>Supplier Name</th>
                                          {/* <td>{opportViewData != '' && opportViewData.data.supplier_name}</td> */}
                                          {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&
                                            <td>{opportViewData.data.new_supplier_name}</td> 
                                          }
                                          {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                            <td>{opportViewData.data.supplier_name}</td>
                                          }
                                      </tr>
                                      <tr>
                                        <th >Entity Name</th>
                                        {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&
                                        <td>{opportViewData.data.new_supplier_name}</td> 
                                        }
                                        {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                        <td>{opportViewData != '' && opportViewData.data.entity_name}</td>
                                        }
                                      </tr>  
                                      <tr>
                                        <th>LS Services:</th>
                                        {opportViewData != '' && opportViewData.data.multi_ls.length > 0 ?
                                          <td>
                                            {opportViewData.data.multi_ls.map((data) => (  
                                                <>{data}  </> 
                                            ))} 
                                          </td> :
                                          <td>-</td> 
                                        }  
                                      </tr> 
                                      <tr>
                                        <th>Supplier Location</th>
                                        {(opportViewData != '' && opportViewData.data.supplier_name_id == 666666) &&    
                                        <td>{opportViewData.data.new_supplier_loc}</td> 
                                        }
                                        {(opportViewData != '' && opportViewData.data.supplier_name_id != 666666) &&
                                        <td>{opportViewData != '' && opportViewData.data.supplier_loc}</td>
                                        }
                                      </tr>
                                  </tbody>
                              </table>
                          </div> 
                          <div className="table-scrollable col-md-6">
                              <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                  <thead className="thead-light">
                                  </thead>
                                  <tbody>
                                      <tr>
                                        <th>Supplier Industry Vertical</th>
                                        <td>{opportViewData != '' && opportViewData.data.cust_industry_verticle}</td>
                                      </tr> 
                                       {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                        <tr>
                                            <th>OEM Name</th>
                                            {/* <td >{listViewData != '' && listViewData.data.supplier_name}</td> */}
                                            {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&
                                            <td>{opportViewData.data.new_oem_name}</td> 
                                            }
                                            {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                            <td>{opportViewData.data.oem_name}</td>
                                            }
                                        </tr>
                                        }
                                        {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                        <tr>
                                            <th >OEM Entity Name</th>
                                            {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&
                                            <td>{opportViewData.data.new_oem_name}</td> 
                                            }
                                            {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                            <td>{opportViewData != '' && opportViewData.data.oem_entity_name}</td>
                                            }
                                        </tr> 
                                        }    
                                        {opportViewData != '' && opportViewData.data.service_type == 'RP' &&
                                        <tr>
                                            <th>OEM Location</th>
                                            {(opportViewData != '' && opportViewData.data.oem_name_id == 666666) &&    
                                            <td>{opportViewData.data.new_oem_loc}</td> 
                                            }
                                            {(opportViewData != '' && opportViewData.data.oem_name_id != 666666) &&
                                            <td>{opportViewData != '' && opportViewData.data.oem_loc}</td>
                                            }
                                        </tr>
                                        }
                                      <tr>
                                          <th>Lead Requester Name</th>
                                          <td >{opportViewData != '' && opportViewData.data.lead_req_name}</td>
                                      </tr>
                                      <tr>
                                          <th>Lead Owner Name</th>
                                          <td >{opportViewData != '' && opportViewData.data.lead_owner_name}</td>
                                      </tr>
                                      <tr>
                                          <th>Opportunity Owner Name</th>
                                          {/* <td >{opportViewData != '' && opportViewData.data.opportunity_owner_name}</td> */}
                                          {(opportViewData != '' && opportViewData.data.opportunity_owner_name != null) ?
                                            <td>{opportViewData.data.opportunity_owner_name}</td> :
                                            <td>-</td>
                                          }
                                      </tr>
                                      <tr>
                                          <th>Lead Status</th>
                                          <td>
                                            <span className="text-success"> {opportViewData != '' && opportViewData.data.LeadStatus} </span>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div> 
                          <div className="table-scrollable col-md-12">
                              <table style={{marginTop: '20px'}} id="mainTable" className="table table-striped">
                                  <thead className="thead-light">
                                  </thead>
                                  <tbody>
                                       <tr>
                                          <th>Lead Doc</th>
                                          <td>
                                          {opportViewData != '' && opportViewData.packingImg.map((data) => (  
                                            <>
                                                <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                                { (opportViewData.data.lead_req_id == usr_id || opportViewData.data.lead_owner_id == usr_id) &&
                                                    <img
                                                        className='cross-icon'
                                                        src='../../ulms_assets/images/cross_icon.png'
                                                        alt="close icon"
                                                        data-bs-toggle="modal" data-bs-target="#deleteremarkModal"
                                                        title='Delete ref file'
                                                        onClick={()=>setDelDocFileId(data.file_id)}
                                                    /> }
                                            </>
                                          ))}  
                                          </td>
                                        </tr>
                                      <tr>
                                          <th style={{width:'50px'}}>Opportunity Doc</th>
                                          <td>
                                          {opportViewData != '' && opportViewData.OpportunityImgPath.map((data) => (  
                                            <>
                                            <Link to={data.img_url} target="_blank"><span className="btn btn-primary btn-sm me-2" style={{margin:'2px'}} >{data.img}</span></Link>
                                            { (opportViewData.data.opportunity_owner_id == usr_id || opportViewData.data.lead_owner_id == usr_id) &&
                                                <img
                                                    className='cross-icon'
                                                    src='../../ulms_assets/images/cross_icon.png'
                                                    alt="close icon"
                                                    data-bs-toggle="modal" data-bs-target="#deleteremarkModal"
                                                    title='Delete ref file'
                                                    onClick={()=>setDelDocFileId(data.file_id)}
                                                /> }
                                            </>
                                          ))}  
                                          </td>
                                      </tr>
                                  
                                  </tbody>
                              </table>
                          </div> 
                        </div>
                        <td><div className='col-md-12'>
                          <button style={{font_size: '12px', float: 'right', marginTop: '12px'}} type="button" onClick={expand}  className="btn btn-primary btn-sm  me-2"><strong>{ expandselected == 1 ? 'HIDE' : 'SHOW' }</strong></button> 
                        </div></td>
                        { expandselected == 1 && opportViewData.data.project_type == 'AUTO' && (opportViewData.data.service_type == 'RP' || opportViewData.data.service_type == 'LS') && (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG') &&
                           <div className="invoice row col-md-12">
                              <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                  <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                      <thead className="thead-light">
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <th>Service For</th>
                                              <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                          </tr>
                                          <tr>
                                              <th>Origin</th>
                                              <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                          </tr>
                                          <tr>
                                              <th>Destination</th>
                                              <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                          </tr>
                                          <tr>
                                              <th>Business Tenure</th>
                                              <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div> 
                              <div className="table-scrollable col-md-6">
                                  <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                      <thead className="thead-light">
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <th>Component Family</th>
                                              <td>{opportViewData != '' && opportViewData.data.component_family_name}</td>
                                          </tr>
                                          <tr>
                                              <th>Expected Closure Date</th>
                                              <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                          </tr>
                                          <tr>
                                              <th>Reference URL</th>
                                              <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                          </tr>
                                          <tr>
                                              <th>Lead Description</th>
                                              <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div> 
                              <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                <table id="mainTable" className="table padded-table">
                                <thead>
                                </thead>
                                    <tbody>                               
                                        <tr>
                                            <th>Component Name</th>
                                            <th>Minimum Monthly Volume</th>
                                            <th>Maximum Monthly Volume</th>
                                            <th>Monthly Volume</th>
                                            <th>Current Packaging</th>
                                            <th>Current Packaging (Qty./Box)</th>
                                            <th>Cycle Time</th>
                                            <th>Component Dimensions Length (mm)</th>
                                            <th>Component Dimensions Width (mm)</th>
                                            <th>Component Dimensions Height (mm)</th>
                                            <th>Component Dimensions Wt. (gm)</th>
                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                              <th>Back Up File</th>
                                            }
                                        </tr>
                                        {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                            <tr key={index}>  
                                                <td>{data.component_name}</td>  
                                                <td>{data.monthly_volume_min}</td>  
                                                <td>{data.monthly_volume_max}</td>  
                                                <td>{data.monthly_volume_avg}</td>
                                                <td>{data.current_packaging}</td>
                                                <td>{data.curr_qty_per_box}</td>
                                                <td>{data.cycle_time}</td>
                                                <td>{data.component_dimension_h}</td>
                                                <td>{data.component_dimension_l}</td>
                                                <td>{data.component_dimension_w}</td>
                                                <td>{data.component_dimension_wt}</td>
                                                {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                <td style={{width:'10%'}}><span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                </td>
                                                }
                                            </tr>  
                                        ))}  
                                    </tbody>
                                </table>
                              </div>
                            </div> }
                            { expandselected  === 1 && opportViewData.data.project_type == 'RETAIL' &&
                           <div className="invoice row col-md-12">
                              <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                  <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                      <thead className="thead-light">
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <th>Service For</th>
                                              <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                          </tr>
                                          <tr>
                                              <th>Origin</th>
                                              <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                          </tr>
                                          <tr>
                                              <th>Destination</th>
                                              <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                          </tr>
                                          <tr>
                                              <th>Business Tenure</th>
                                              <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                          </tr>
                                         
                                      </tbody>
                                  </table>
                              </div> 
                              <div className="table-scrollable col-md-6">
                                  <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                      <thead className="thead-light">
                                      </thead>
                                      <tbody>
                                        <tr>
                                            <th>Expected Closure Date</th>
                                            <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                        </tr>
                                         <tr>
                                              <th>Reference URL</th>
                                              <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                          </tr>
                                          <tr>
                                              <th>Lead Description</th>
                                              <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                <table id="mainTable" className="table padded-table">
                                <thead>
                                </thead>    
                                    <tbody>                               
                                        <tr>
                                            <th>Operation Type</th>
                                            <th>Area Required (Sq.ft.)</th> 
                                            <th>Inventory Days</th>
                                            <th>Avg. Daily Inbound</th>
                                            <th>Avg. Daily Outbound</th>
                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                             <th>Back Up File</th>
                                            }
                                        </tr>
                                        {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                            <tr key={index}>  
                                                <td>{data.operation_type.label}</td>  
                                                <td>{data.area_required}</td>  
                                                <td>{data.inventory_days}</td>  
                                                <td>{data.avg_daily_inbound}</td>  
                                                <td>{data.avg_daily_outbound}</td>
                                                {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                <td> <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                </td>
                                                }
                                            </tr>  
                                        ))}  
                                    </tbody>
                                </table>
                              </div> 
                            </div> }
                            { (expandselected  === 1 && opportViewData.data.project_type == 'AUTO' && opportViewData.data.service_type == 'NRP' &&  (opportViewData.data.products == 'RM' || opportViewData.data.products == 'FG') ) ?
                           <div className="invoice row col-md-12">
                              <div style={{float: 'left'}} className="table-scrollable col-md-6">
                                  <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                      <thead className="thead-light">
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <th>Service For</th>
                                              <td >{opportViewData != '' && opportViewData.data.service_for}</td>
                                          </tr>
                                          <tr>
                                              <th>Origin</th>
                                              <td >{opportViewData != '' && opportViewData.data.origin}</td>
                                          </tr>
                                          <tr>
                                              <th>Destination</th>
                                              <td >{opportViewData != '' && opportViewData.data.destination}</td>
                                          </tr>
                                          <tr>
                                              <th>Business Tenure</th>
                                              <td>{opportViewData != '' && opportViewData.data.business_tenure}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div> 
                              <div className="table-scrollable col-md-6">
                                  <table style={{marginTop: '15px'}} id="mainTable" className="table table-striped">
                                      <thead className="thead-light">
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <th>Component Family</th>
                                              <td>{opportViewData != '' && opportViewData.data.component_family_name}</td>
                                          </tr>
                                          <tr>
                                              <th>Expected Closure Date</th>
                                              <td>{opportViewData != '' && opportViewData.data.expected_closure_date}</td>
                                          </tr>
                                          <tr>
                                              <th>Reference URL</th>
                                              <td >{opportViewData != '' && opportViewData.data.monthly_volume_min}</td>
                                          </tr>
                                          <tr>
                                              <th>Lead Description</th>
                                              <td >{opportViewData != '' && opportViewData.data.lead_desc}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div> 
                              <div style={{marginTop:'20px', float:'left', overflowX:'auto'}} className=" col-md-12">
                                <table id="mainTable" className="table padded-table">
                                <thead>
                                </thead>
                                    <tbody>                               
                                        <tr>
                                            <th>Component Name</th>
                                            <th>Minimum Monthly Volume</th>
                                            <th>Maximum Monthly Volume</th>
                                            <th>Monthly Volume</th>
                                            {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                             <th>Back Up File</th>
                                            }
                                        </tr>
                                        {opportViewData != '' && opportViewData.multi_data.map((data, index) => (  
                                            <tr key={index}>  
                                                <td>{data.component_name}</td>  
                                                <td>{data.monthly_volume_min}</td>  
                                                <td>{data.monthly_volume_max}</td>  
                                                <td>{data.monthly_volume_avg}</td>
                                                {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                    <td> <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompUploadModal" onClick={()=>CompDataUpload(data.comp_id)} >Upload</span>
                                                    <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#CompImageViewModal" onClick={()=>getCompImageData(data.comp_id)} >View</span>
                                                    </td>
                                                }
                                            </tr>  
                                        ))}  
                                    </tbody>
                                </table>
                              </div>
                            </div> : null }
                            { opportViewData != '' && ((opportViewData.data.opportunity_owner_id == usr_id) || (opportViewData.data.solutionUploadBySdHead == 1 && user_role == 'SD Head' && opportViewData.data.opportunity_owner_id != null)) &&
                                <>
                                { opportViewData.data.SentToClientsCount != 1 &&
                                <form className="row g-3" encType="multipart/form-data" onSubmit={handleFormSubmit} style={{marginTop: '5px'}}>
                                    <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                        <Form.Label style={{fontWeight:'bold'}}>Upload Solution File :</Form.Label>
                                        <Form.Control type="file"
                                        ref={bRef}    
                                        name="opportunity_photo_web"
                                        onChange={handleSolFileChange}
                                        />
                                    </div>
                                    <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                        <Form.Label style={{fontWeight:'bold'}}>Upload BOM File :</Form.Label>
                                        <Form.Control type="file"
                                        ref={bRef}    
                                        name="opportunity_photo_bom"
                                        onChange={handleBomFileChange}
                                        />
                                    </div>
                                    <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                                        <Form.Label style={{fontWeight:'bold'}}>Upload Related Ref File :</Form.Label>
                                        <Form.Control type="file"
                                        ref={bRef}    
                                        name="opportunity_photo_ref"
                                        onChange={handleRefFileChange}
                                        />
                                    </div>
                                    <div className="col-md-2" style={{marginTop: '30px'}}>
                                        <button type="submit" className="btn btn-primary">Upload</button>
                                    </div>  
                                </form> }
                                </>
                            }
                            <div className='row'>
                              <form className='col-md-12'>
                                <div className="row" style={{marginTop:'15px'}}>
                                {opportViewData != '' && opportViewData.Solution.slice(0).reverse().map((data, index) => (
                                <div className='card'>
                                    <div className='row card-body '> 
                                        <div className="col-md-12" >
                                            <div className = "row">
                                                <div className = "col-md-1">
                                                    <h5 >{data.draft}</h5>
                                                    {(data.mark_final == 0) ?
                                                    <>
                                                        <h6 style={{marginLeft: '2px'}}><span className="badge rounded-pill bg-primary">Pending</span></h6>
                                                        
                                                    </>
                                                    :
                                                    (data.mark_final == 1) ?
                                                    <>
                                                        <h6 style={{marginLeft: '7px'}}><span className="badge rounded-pill bg-success">Final</span></h6> 
                                                    
                                                    </>
                                                    :
                                                    (data.mark_final == 3) ? 
                                                    <>
                                                        <h6 style={{marginLeft: '5px'}}><span className="badge rounded-pill bg-danger">Reject</span></h6> 
                                                    </>
                                                    : ''
                                                    }
                                                </div>
                                                <div className="col-md-9" >
                                                  <span className="text-success"> {data.apprStatus} </span>
                                                  {data.rejectRemark != '' && <span className="text-danger">(Rejected Reason:  {data.rejectRemark})</span> }
                                                </div> 
                                                <div className="col-md-2" style={{marginTop:'20px', float:'right'}}>
                                                    {data.mark_final == 0  &&
                                                        <>
                                                        {(opportViewData != '' && user_role == 'SD User' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {(opportViewData != '' && user_role == 'SD Head' && (opportViewData.data.opportunity_owner_id == usr_id) &&  opportViewData.data.sd_head_approval_status == null && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 ) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {(opportViewData != '' && user_role == 'SD Head' && (opportViewData.data.opportunity_owner_id == usr_id) &&  opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 ) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }


                                                        {/* {(opportViewData != '' &&  user_role == 'SD Head' && (opportViewData.data.sd_head_approval_status == 0 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 )) ?
                                                            <div className='col-md-11'>
                                                                <span style={{float:'right'}}  className="btn btn-primary btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 1)} >Approve</span>
                                                                <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 3)} >Reject</span>
                                                            </div> : ""
                                                        } */}

                                                    
                                                        {/* 
                                                            {(opportViewData != '' && (opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 0 && opportViewData.data.send_to_client_button == 1 )) &&
                                                                <div className='col-md-11'>
                                                                <span style={{float:'right'}}  className="btn btn-primary btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 1)} >Approve</span>
                                                                </div> 
                                                            } */}
                                                            {/* {(opportViewData != '' && (opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 )) &&
                                                                <div className='col-md-11'>
                                                                <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 3)} >Reject</span>
                                                                </div> 
                                                            } */}
                                                            {/* {(opportViewData != '' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                            <div className='col-md-1' >
                                                                <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data.draft, data.draft_id)} >Upload</span>
                                                            </div>
                                                            } */}
                                                        </>
                                                    }
                                                    {data.mark_final == 1  &&
                                                        <>
                                                        {(opportViewData != '' && user_role == 'SD User' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {(opportViewData != '' && user_role == 'SD Head' && (opportViewData.data.opportunity_owner_id == usr_id) &&  opportViewData.data.sd_head_approval_status == null && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 ) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {(opportViewData != '' && user_role == 'SD Head' && (opportViewData.data.opportunity_owner_id == usr_id) &&  opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 ) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {opportViewData != '' && opportViewData.data.ApproveButtonToUser == 1 && user_role == 'SD Head' &&
                                                        <div className='col-md-11'>
                                                            <span style={{float:'right'}} onClick={()=>confirmdialog(data.draft_id, 1)} className="btn btn-primary btn-sm me-2" >Send To BD </span>
                                                            <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>handledraftId(data.draft_id)} data-bs-toggle="modal" data-bs-target="#draftrejectedModal"  >Reject</span>
                                                        </div> 
                                                        }

                                                        {opportViewData != '' && opportViewData.data.ApproveButtonToUser == 2 && (user_role == 'BD Head' || user_role == 'BD User') &&
                                                        <div className='col-md-11'>
                                                            <span style={{float:'right'}} onClick={()=>confirmdialog(data.draft_id, 1)} className="btn btn-primary btn-sm me-2" >Send To Client</span>
                                                            <span style={{float:'right'}} onClick={()=>handledraftId(data.draft_id)}  className="btn btn-danger btn-sm me-2" data-bs-toggle="modal" data-bs-target="#draftrejectedModal" >Reject</span>
                                                        </div> 
                                                        }

                                                        {(opportViewData != '' && (user_role == 'BD User' || user_role == 'BD Head' ) && (opportViewData.data.ApproveButtonToUser == 4)) &&
                                                        <>
                                                            <div className='col-md-4' >
                                                            <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>handledraftId(data.draft_id)} data-bs-toggle="modal" data-bs-target="#draftrejectedModal" >Reject</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {/* {(opportViewData != '' &&  user_role == 'SD Head' && (opportViewData.data.sd_head_approval_status == 0 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 )) ?
                                                            <div className='col-md-11'>
                                                                <span style={{float:'right'}}  className="btn btn-primary btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 1)} >Approve</span>
                                                                <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 3)} >Reject</span>
                                                            </div> : ""
                                                        } */}

                                                        {/* {(opportViewData != '' &&  user_role == 'BD Head' && (opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == 0 && opportViewData.data.send_to_bdhead_button == 2 && opportViewData.data.send_to_client_button == 1 )) ?
                                                            <div className='col-md-11'>
                                                                <span style={{float:'right'}}  className="btn btn-primary btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 1)} >Approve</span>
                                                                <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 3)} >Reject</span>
                                                            </div> : ""
                                                        } */}

                                                        {/* {(opportViewData != '' &&  user_role == 'BD Head' && (opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == 1 && opportViewData.data.send_to_bdhead_button == 2 && opportViewData.data.send_to_client_button == 0 )) ?
                                                        <div className='col-md-11'>
                                                            <span style={{float:'right'}}  className="btn btn-primary btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 1)} >Approve</span>
                                                            <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 3)} >Reject</span>
                                                        </div> : ""
                                                        } */}

                                                        </>
                                                    }

                                                    {data.mark_final == 3  && 
                                                    <>

                                                    {(opportViewData != '' && user_role == 'SD User' && (opportViewData.data.opportunity_owner_id == usr_id)) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {(opportViewData != '' && user_role == 'SD Head' && (opportViewData.data.opportunity_owner_id == usr_id) &&  opportViewData.data.sd_head_approval_status == null && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 ) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {(opportViewData != '' && user_role == 'SD Head' && (opportViewData.data.opportunity_owner_id == usr_id) &&  opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 ) &&
                                                        <>
                                                            <div className='col-md-1' >
                                                            <span className="btn btn-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#fileUploadModal" onClick={()=>DraftDataUpload(data)} >Upload</span>
                                                            </div>
                                                        </>
                                                        }

                                                        {/* {(opportViewData != '' &&  user_role == 'SD Head' && (opportViewData.data.sd_head_approval_status == 0 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 )) ?
                                                            <div className='col-md-11'>
                                                                <span style={{float:'right'}}  className="btn btn-primary btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 1)} >Approve</span>
                                                                <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 3)} >Reject</span>
                                                            </div> : ""
                                                        } */}

                                                        {/* {(opportViewData != '' &&  user_role == 'BD Head' && (opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == 1 && opportViewData.data.send_to_bdhead_button == 2 && opportViewData.data.send_to_client_button == 1 )) ?
                                                        <div className='col-md-11'>
                                                            <span style={{float:'right'}}  className="btn btn-primary btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 1)} >Approve</span>
                                                            <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 3)} >Reject</span>
                                                        </div> : ""
                                                        } */}

                                                        {/* {(opportViewData != '' &&  user_role == 'BD Head' && (opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == 0 && opportViewData.data.send_to_bdhead_button == 2 && opportViewData.data.send_to_client_button == 1 )) ?
                                                            <div className='col-md-11'>
                                                                <span style={{float:'right'}}  className="btn btn-primary btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 1)} >Approve</span>
                                                                <span style={{float:'right'}}  className="btn btn-danger btn-sm me-2" onClick={()=>confirmdialog(data.draft_id, 3)} >Reject</span>
                                                            </div> : ""
                                                        } */}
                                                    
                                                    </>
                                                    }
                                                
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-md-12" style={{marginTop:'12px'}}>
                                            <div className="row">
                                            {data.SolutionImg.map((data, index) => {
                                                let ext = getExtension(data.img);
                    
                                                return(
                                                <div className="col-md-3">
                                                {(opportViewData != '' && (user_role == 'SD Head' || opportViewData.data.opportunity_owner_id == usr_id) && data.file_type == 'ref') && 
                                                <>
                                                    { (ext == 'png' || ext == 'jpg' || ext == 'jpeg') ?
                                                    <Link to={data.img_url} target="_blank"><img  src={data.img_url} key={data.draft_id} alt="logo" style={{height:'60px', width:'60px', marginTop:"5px"}}/></Link> :
                                                    <Link to={data.img_url} target="_blank"><img  src='../../ulms_assets/images/doc.png' key={data.draft_id} alt="logo" style={{height:'60px', width:'60px', marginTop:"5px"}}/></Link>
                                                    }
                                                    <div>{data.img}( {data.file_type} )</div> 
                                                    
                                                </>
                                                }
                                                { data.file_type != 'ref' &&
                                                 <>
                                                   { (ext == 'png' || ext == 'jpg' || ext == 'jpeg') ?
                                                    <Link to={data.img_url} target="_blank"><img  src={data.img_url} key={data.draft_id} alt="logo" style={{height:'60px', width:'60px'}}/></Link> :
                                                    <Link to={data.img_url} target="_blank"><img  src='../../ulms_assets/images/doc.png' key={data.draft_id} alt="logo" style={{height:'60px', width:'60px', marginTop:"5px"}}/></Link>
                                                    }
                                                     <div>{data.img} ( {data.file_type} )</div>
                                                 </>
                                                }
                                                </div>)
                                            })} 
                                            </div> 
                                        </div>
                                    </div>
                                  </div>
                                ))}  
                                </div> 
                                <div className='row' style={{marginTop: '26px'}}>

                                    {/* {(opportViewData != '' && user_role == 'SD User' && (opportViewData.data.opportunity_owner_id == usr_id ) &&(opportViewData.Solution.length > 0)) &&
                                         <div className='col-md-2'>
                                          <span style={{float:'left'}} onClick={sendToSDHead} className="btn btn-primary btn-sm me-2" >Send To SD Head</span>
                                         </div> 
                                    } */}
                                    {/* {(opportViewData != '' && user_role == 'SD Head' && (opportViewData.Solution.length > 0) && (opportViewData.data.opportunity_owner_id == usr_id) && ((opportViewData.data.sd_head_approval_status == null) && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 1 && opportViewData.data.send_to_client_button == 1 ) )?
                                      <div className='col-md-2'>
                                        <span style={{float:'left'}} onClick={sendToSDHead} className="btn btn-primary btn-sm me-2" >Send To SD Head</span>
                                      </div> : ''
                                    } */}
                                    {/* {opportViewData != '' && (opportViewData.data.sd_head_approval_status == 1 && opportViewData.data.bd_head_approval_status == null && opportViewData.data.send_to_bdhead_button == 0 && opportViewData.data.send_to_client_button == 1 ) &&
                                      <div className='col-md-2'>
                                        <span style={{float:'left'}} onClick={sendToBDHead} className="btn btn-primary btn-sm me-2" >Send To BD Head</span>
                                      </div> 
                                    } */}
                                    {/* {opportViewData != '' && user_role == 'BD Head' && ((opportViewData.data.sd_head_approval_status == 1) && opportViewData.data.bd_head_approval_status == 1 && opportViewData.data.send_to_bdhead_button == 2 && opportViewData.data.send_to_client_button == 0 ) ?
                                      <div className='col-md-2'>
                                        <span style={{float:'left'}} onClick={sendToClient} className="btn btn-primary btn-sm me-2" >Send To Client</span>
                                      </div> : ''
                                    } */}
                                </div>
                                <div className='row' style={{marginTop: '15px'}}>
                                    <div className="col-md-5" controlid="formFileMultiple" >
                                        <Form.Label>Opportunity Related Reference File (File name should be in alphanumeric , - , _ , . ) :</Form.Label>
                                        <Form.Control multiple type="file"  
                                        ref={aRef}  
                                        name="opportunity_photo_web"
                                        onChange={handleFileInput}
                                        />
                                    </div>
                                    <div className="col-md-2" style={{marginTop: '26px'}}>
                                        <button type="submit" className="btn btn-primary" onClick={handleRefFileSubmit} >Upload</button>
                                    </div>  
                                    <div className="col-md-5" style={{float:'right'}}>
                                        <a className="nav-link nav-link-notify" data-count={opportViewData != '' && opportViewData.data.QueryCount} ><img src="/ulms_assets/images/chat_icon.png" alt="icon" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{height:'70px', width:'75px', float:'right'}} /></a>
                                    </div>
                                </div>
                              </form>
                            </div>
                    </div>
                </div>
            </div>
        </div> 
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                {chatmessage  &&  <div className="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>{chatmessage}</strong>
                    </div>}
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"> CHAT </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="chat-content empty-chat-wrapper col-md-12" style={myComponent}>
                            <div className="card-title">
                            </div>

                            {opportViewData != '' && opportViewData.AdditionInfo.map((chatdata) => (  
                            <div>
                                {chatdata.UserId != usr_id  &&
                                <div className="messages" style={{ float:'right', width:'100%'}}>
                                    <div className="message-item col-md-12">
                                        <span className="time small text-muted font-italic" >{chatdata.UserName}</span>
                                        <div className='row'>
                                          <div className="alert alert-primary col-md-9" style={{marginLeft:'10px'}}>{chatdata.LeadQuery}</div>
                                          <span className="time small text-muted font-italic col-md-2">{chatdata.Created} </span>
                                        </div>
                                    </div>
                                </div>
                                }
                                {chatdata.UserId == usr_id  &&
                                <div className="messages" style={{ float:'left', width:'100%'}}>
                                    <div className="message-item col-md-12" style={{ whiteSpace: 'pre-wrap'}}>
                                        <span className="time small text-muted font-italic " style={{float:'right', width:'100%', textAlign:'right', marginRight:'10px'}}>{chatdata.UserName}</span>
                                        <div className="alert alert-success col-md-9" style={{float: 'right', whiteSpace: 'pre-wrap', marginRight: '10px'}}>{chatdata.LeadQuery}</div>
                                        <span className="time small text-muted font-italic col-md-2">{chatdata.Created} </span>
                                    </div>
                                </div>
                                }                            
                            </div>
                            ))}  

                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="chat-footer col-md-12">
                            <form className="d-flex col-md-12" onSubmit={handleChatSubmit} >
                                <button className="btn btn-primary btn-rounded flex-shrink-0" type="button" style={{marginRight:'5px', marginBottom:'5px'}}>
                                    {/* <i className="bi bi-three-dots"></i> */}
                                </button>
                                <Form.Control 
                                    type="text" 
                                    className="form-control" autoFocus 
                                    placeholder="Write message..."
                                    name='query'
                                    value={queryCreate.query}
                                    onChange={handleFormInput}
                                />
                                <button className="btn btn-primary btn-rounded flex-shrink-0 ms-3" type="submit" >Send</button>
                            </form>
                        </div>
                    </div>     
                </div>
            </div>
        </div>  
        { show == true &&
            <div className="modal fade" id="assigntosdmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{usermessage}</strong>
                    </div>} */}
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Lead Allocate To SD Team</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={handleSDFormSubmit}>
                    <div className="modal-body">
                        <div className="col-md-6">
                            <label htmlFor="inputState" className="form-label"> Select SD User : </label> 
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="bd_user"
                                options={sdUserListData}
                                onChange={handleSDUserInput}
                            />
                        </div> 
                        <div className="col-md-4" style={{marginTop: '5px'}}>
                            <Form.Label>Expected Closure Date:</Form.Label>
                            <Form.Control
                            type="date"
                            name="expected_close_date"
                            value={assignSDTeam.expected_close_date}
                            onChange={handleSDFormInput}
                            id="expected_close_date"
                            className="form-control"  />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        }

        { show == true &&
            <div className="modal fade" id="leadReallocateModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{usermessage}</strong>
                    </div>} */}
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Lead Re-allocate To SD Team</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={handleReassignSDSubmit}>
                    <div className="modal-body">
                        <div className="col-md-6">
                            <label htmlFor="inputState" className="form-label"> Select SD User : </label> 
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="bd_user"
                                options={sdUserListData}
                                onChange={handleSDUserInput}
                            />
                        </div> 
                        <div className="col-md-4" style={{marginTop: '5px'}}>
                            <Form.Label>Expected Closure Date:</Form.Label>
                            <Form.Control
                            type="date"
                            name="expected_close_date"
                            value={assignSDTeam.expected_close_date}
                            onChange={handleSDFormInput}
                            id="expected_close_date"
                            className="form-control"  />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        }

        { show == true &&
            <div className="modal fade" id="fileUploadModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{usermessage}</strong>
                    </div>} */}
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Upload {draftIdData} Related Files</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={handleExtraFileSubmit}>
                    <div className="modal-body">
                        {solFileCheck == 0 && 
                        <div className="col-md-8" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight:'bold'}}>Upload Solution File :</Form.Label>
                            <Form.Control type="file"
                            ref={cRef}    
                            name="opportunity_photo_web"
                            onChange={handleSolFileChange}
                            />
                        </div> }
                        {bomFileCheck == 0 && 
                        <div className="col-md-8" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight:'bold'}}>Upload BOM File :</Form.Label>
                            <Form.Control type="file"
                            ref={cRef}    
                            name="opportunity_photo_bom"
                            onChange={handleBomFileChange}
                            />
                        </div> }
                        <div className="col-md-8" controlid="formFileMultiple" style={{marginTop: '5px'}}>
                            <Form.Label style={{fontWeight:'bold'}}>Upload Related Ref File :</Form.Label>
                            <Form.Control type="file"
                            ref={cRef}    
                            name="opportunity_photo_ref"
                            onChange={handleRefFileChange}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Upload</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        }

        { show == true &&
            <div className="modal fade" id="CompUploadModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Upload Component Related Files</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form className="row g-3" onSubmit={componentFileSubmit}>
                    <div className="modal-body">
                        <div className="col-md-8" controlid="formFileMultiple" >
                            <Form.Label>Component Related Solution File:</Form.Label>
                            <Form.Control multiple type="file"  
                            ref={dRef}   
                            name="opportunity_photo_web"
                            onChange={handleFileInput}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Upload</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        }
        { show == true &&
            <div className="modal fade" id="CompImageViewModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Component Related Files</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                    <div className="modal-body">
                        <div className='row'>
                            {compImageData != '' && compImageData.comp_img.map((data, index) => (
                                <div className="col-md-2">
                                <Link to={data.img_url} target="_blank"><img  src="/ulms_assets/images/thumb.webp" alt="logo" style={{height:'40px', width:'40px'}}/></Link>
                                </div>
                            ))}
                            {compImageData == '' &&
                                <div className="col-md-5">
                                    No Document Available
                                </div>
                            }   
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
              </div>
            </div>
        }
            <div className="modal fade" id="draftrejectedModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Draft Rejected Reason</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                    <div className="modal-body">
                        <div className="col-md-8" controlid="formFileMultiple" >
                            <Form.Label>Enter Rejected Remark:</Form.Label>
                            <Form.Control multiple type="text"  
                            name="reject_remark"
                            value={rejectedDraftData.reject_remark}
                            onChange={RejectedRemarkDraft}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button className="btn btn-primary" onClick={() => { handleRejectedRemark(); handleCloseModal(); }} >Submit</button>
                    </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="deleteremarkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reference File Remark</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <Form onSubmit={handleDocDeleteForm}>
                            <div className="modal-body">
                                <div className="col-md-8" controlid="formFileMultiple" >
                                    <Form.Label>Enter Delete Remark:</Form.Label>
                                    <Form.Control type="text"  
                                    name="remark"
                                    value={deleteLeadFile.remark}
                                    onChange={handleRemarkInput}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            <button className="btn btn-primary" type='submit' onClick={handleCloseModal}  >Submit</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            { show == true &&
                <div className="modal fade" id="LeadAssignModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    {/* {usermessage &&  <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>{usermessage}</strong>
                        </div>} */}
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> Allocate Lead </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form className="row g-3" onSubmit={handleLeadReAssignForm}>
                        <div className="modal-body">
                            <div className="col-md-6">
                                <label htmlFor="inputState" className="form-label"> Select BD User : </label> 
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="bd_user"
                                    options={bdUserListData}
                                    onChange={handleLeadSourceInput}
                                />
                            </div> 
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary" onClick={handleCloseModal} >Assign</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            }
    </>
  )
}

export default SolutionDevelopment